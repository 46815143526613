import React from 'react'

import { NameHeader } from 'components'
import { useResource } from 'utils'

import { NameProps } from '.'
export const Name = ({
  isRenaming,
  isRenamable,
  text,
  setText,
  rename,
}: NameProps) => {
  const { name } = useResource()

  return (
    <NameHeader
      name={name}
      isRenaming={isRenaming}
      isRenamable={isRenamable}
      text={text}
      setText={setText}
      submit={rename}
    />
  )
}
