import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Color, DeleteIcon, StyledTable } from 'components'

import { Workflows } from 'store'
import { useAppDispatch, useAsyncResult } from 'utils'

const Row: React.FC<Workflows.WithSummary> = ({
  workflow: { id, name, alias },
}) => {
  const dispatch = useAppDispatch()

  const [{ isPending, error, dismissError }, remove] = useAsyncResult(() =>
    dispatch(Workflows.remove(id))
  )

  return (
    <>
      <td className='name-container'>
        <Link to={`/workflows/${alias}`}>
          <div className='name'>{name}</div>
        </Link>
      </td>
      <td className='remove-container'>
        <DeleteIcon
          isPending={isPending}
          onClick={remove}
          error={error}
          dismissError={dismissError}
        />
      </td>
    </>
  )
}

const Table = styled(StyledTable)`
  .name-container {
    width: 100%;
  }
  .name {
    color: ${Color.veryDarkGray};
    &:hover {
      color: ${Color.landrushOrange};
    }
  }
  .remove-container {
    padding-left: 48px;
  }
`

export const List: React.FC<Workflows.WithList> = ({ workflows }) => {
  return (
    <Table>
      <tbody>
        {workflows.map((workflow) => (
          <tr key={workflow.id}>
            <Row workflow={workflow} />
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
