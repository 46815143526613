import React, { useEffect, useRef } from 'react'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List as VList,
} from 'react-virtualized'

import { Measures } from 'components'
import { ExecutionState } from 'store'
import { useAppSelector, useWindowWidth } from 'utils'

import { Row } from './row'
import { useVisibleExecutions } from './hooks'

export const List = () => {
  const executions = useVisibleExecutions()

  const taggableHeaderHeight = 42.5 + 44 + 8
  const offsets =
    Measures.headerHeight +
    Measures.footerHeight +
    Measures.bodyMarginTop +
    Measures.bodyMarginBottom +
    taggableHeaderHeight

  const list = useRef<VList | null>(null)
  const cache = useRef(
    new CellMeasurerCache({ fixedWidth: true, defaultHeight: 41 })
  )
  const width = useWindowWidth()
  useEffect(() => {
    list.current?.recomputeRowHeights(0)
    cache.current.clearAll()
  }, [width, executions.length])

  const filterText = useAppSelector(ExecutionState.selectFilterText)
  useEffect(() => list.current?.scrollToPosition(), [filterText])

  return (
    <div style={{ width: '100%', height: `calc(100vh - ${offsets}px)` }}>
      <AutoSizer>
        {({ width, height }) => (
          <VList
            ref={(ref) => (list.current = ref)}
            width={width}
            height={height}
            deferredMeasurementCache={cache.current}
            rowHeight={cache.current.rowHeight}
            rowCount={executions.length}
            rowRenderer={({ index, parent, style }) => (
              <CellMeasurer
                key={executions[index].id}
                cache={cache.current}
                parent={parent}
                columnIndex={0}
                rowIndex={index}
              >
                <Row execution={executions[index]} style={style} />
              </CellMeasurer>
            )}
          />
        )}
      </AutoSizer>
    </div>
  )
}
