import isequal from 'lodash.isequal'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMount } from 'react-use'
import styled from 'styled-components'

import { Execution } from '@landrush/common'

import {
  Color,
  ErrorMessage,
  HeaderDate,
  Message,
  NameHeader,
} from 'components'
import { Executions } from 'store'
import { useAppDispatch, useAppSelector, useAsyncResult } from 'utils'

import Details from './execution'

type Props = { execution: Execution }
const Body: React.FC<Props> = React.memo(({ execution }) => {
  const dispatch = useAppDispatch()

  const [text, setText] = useState<string>()
  const [
    { error, isPending, dismissError },
    rename,
  ] = useAsyncResult(async () => {
    if (text === undefined) throw new Error('Invalid rename')
    return dispatch(Executions.patch(execution.id, { name: text }))
  }, [execution, text])

  useEffect(() => {
    setText(undefined)
  }, [execution.name])

  return (
    <Styles>
      <NameHeader
        className='execution-name'
        name={execution.name}
        isRenaming={isPending}
        text={text}
        setText={setText}
        submit={rename}
      />
      <HeaderDate date={execution.createdAt} />
      {error ? (
        <ErrorMessage onDismiss={dismissError}>{error}</ErrorMessage>
      ) : null}
      <Details execution={execution} />
    </Styles>
  )
}, isequal)

type Params = { alias: string }
const Contents = () => {
  const { alias } = useParams<Params>()
  const execution = useAppSelector(Executions.useDenormalizeByAlias(alias))

  if (!execution) throw new Error('Invalid internal state')
  return <Body execution={execution} />
}

const Loader = () => {
  const dispatch = useAppDispatch()
  const { alias } = useParams<Params>()

  const [{ error, value }, hydrate] = useAsyncResult(async () =>
    dispatch(Executions.hydrateByAlias(alias))
  )
  useMount(hydrate)

  if (error) return <ErrorMessage onRetry={hydrate}>{error}</ErrorMessage>
  if (!value) return <Message spin>Loading...</Message>
  return <Contents />
}
export default Loader

const Styles = styled.div`
  .execution-name {
    padding-bottom: 2px;
    border-bottom: 2px solid ${Color.landrushOrange};
  }

  .progress-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .progress-container {
    display: flex;
    flex-direction: column;
    max-width: 480px;
    align-items: stretch;
  }

  .preview-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .preview-placeholder {
    padding: 64px;
    border-radius: 8px;
    background-color: ${Color.backgroundGray};
    font-size: small;
    color: ${Color.muted};
  }
  .preview-image {
    width: 100%;
    height: auto;
    border: 1px solid ${Color.veryLightGray};
    border-radius: 4px;
  }
  .preview-caption {
    margin-left: auto;
    text-align: right;
  }

  .output-pathname {
    color: ${Color.veryDarkGray};
    &:hover {
      color: ${Color.landrushOrange};
    }
  }
  .output-preview-icon {
    cursor: pointer;
    color: ${Color.lightGray};
    &:hover {
      cursor: pointer;
      color: ${Color.darkGray};
    }
  }

  .output-container {
    display: flex;
  }
  .output-list-column {
    flex: 1;
    padding-right: 8px;
  }
  .output-preview-column {
    flex: 1;
    padding-left: 8px;
  }

  .resource-name,
  .region-name,
  .workflow-name {
    color: ${Color.veryDarkGray};
    &:hover {
      color: ${Color.landrushOrange};
    }
  }

  .header {
    padding-top: 24px;
  }
`
