import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { faCompress, faExpand } from '@fortawesome/free-solid-svg-icons'

import { Viewer } from 'store'

import { StyledIcon } from './icon'

export const Icon = () => {
  const dispatch = useDispatch()
  const isFullscreen = useSelector(Viewer.selectFullscreen)
  const toggleFullscreen = () => dispatch(Viewer.setFullscreen(!isFullscreen))

  const isFirst = useRef(true)
  const [isChanged, setChanged] = useState(false)
  useEffect(() => {
    // Don't run this effect with the component initially mounts.
    if (isFirst.current) {
      isFirst.current = false
      return
    }

    setChanged(true)
    const timeout = setTimeout(() => setChanged(false), 400)
    return () => clearTimeout(timeout)
  }, [isFirst, isFullscreen])

  return (
    <StyledIcon
      className='icon'
      icon={isFullscreen ? faCompress : faExpand}
      onClick={toggleFullscreen}
      $isActive={isChanged}
    />
  )
}
