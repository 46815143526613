import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faHome } from '@fortawesome/free-solid-svg-icons'

import {
  Color,
  Control,
  CredentialProfileIcon,
  Spaced,
  Style,
} from 'components'
import { Navigator } from 'store'

import { usePathDisplayName } from './hooks'

type WithStyle = { style?: React.CSSProperties }

const StyledHomeButton = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: ${Color.gray};
  &:hover {
    color: ${Color.landrushOrange};
  }
`
const HomeButton: React.FC<WithStyle> = ({ style }) => {
  const dispatch = useDispatch()
  const onClick = () => dispatch(Navigator.navigateTo(undefined))
  return (
    <StyledHomeButton
      icon={faHome}
      onClick={onClick}
      style={style}
      fixedWidth
    />
  )
}

const separatorMargin = 8
const Separator = () => (
  <div
    style={{
      color: Color.landrushOrange,
      paddingLeft: separatorMargin,
      paddingRight: separatorMargin,
    }}
  >
    •
  </div>
)

const Part = styled.div`
  cursor: pointer;
  font-weight: bold;
`
const Parts = () => {
  const dispatch = useDispatch()
  const cwd = useSelector(Navigator.forceSelectCwd)
  const displayName = usePathDisplayName(cwd)
  const credential = useSelector(Navigator.selectCwdCredential)
  const root = credential.protocol + '://' + displayName
  const { parts } = cwd

  const navigateTo = (parts: string[]) => {
    dispatch(Navigator.navigateTo({ ...cwd, parts }))
  }

  return (
    <>
      <Part onClick={() => navigateTo([])}>{root}</Part>
      {parts.map((part, i) => (
        <React.Fragment key={i}>
          <Separator />
          <Part onClick={() => navigateTo(parts.slice(0, i + 1))}>{part}</Part>
        </React.Fragment>
      ))}
    </>
  )
}

const ManageDataSourcesButton: React.FC<WithStyle> = ({ style }) => (
  <Link to='/credentials'>
    <Control style={style}>
      <FontAwesomeIcon icon={faCog} />
    </Control>
  </Link>
)

export default () => {
  const credential = useSelector(Navigator.maybeSelectCwdCredential)

  if (!credential) {
    return (
      <Spaced>
        <div style={Style.strong}>Data sources</div>
        <ManageDataSourcesButton />
      </Spaced>
    )
  }

  return (
    <Spaced>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <HomeButton style={{ marginRight: 16 }} />
        <Parts />
      </div>
      <div style={{ display: 'flex' }}>
        <CredentialProfileIcon name={credential.name} />
        <ManageDataSourcesButton style={{ paddingLeft: 16 }} />
      </div>
    </Spaced>
  )
}
