import { useEffect, useRef, useState } from 'react'

const getWidth = () => window.innerWidth
export function useWindowWidth() {
  const [width, setWidth] = useState(getWidth())

  let timeout = useRef<number | undefined>()

  useEffect(() => {
    const onResize = () => {
      if (timeout.current) clearTimeout(timeout.current)
      timeout.current = window.setTimeout(() => {
        setWidth(getWidth())
        timeout.current = undefined
      }, 50)
    }

    window.addEventListener('resize', onResize)
    return () => {
      if (timeout.current) clearTimeout(timeout.current)
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return width
}
