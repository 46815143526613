import React from 'react'
import { useDispatch } from 'react-redux'
import { Route, Switch, useParams } from 'react-router-dom'
import { useMount, useUnmount } from 'react-use'

import { ResourceProvider } from 'components'
import { Viewer } from 'store'

import Info from './info'
import View from './view'
import Settings from './settings'
import { Resources } from 'store'

export const routes = ['info', 'view', 'exec', 'settings']

export default () => {
  const dispatch = useDispatch()
  const load = () => {
    dispatch(Resources.Tags.load())
  }
  useMount(load)
  const { alias } = useParams<{ alias: string }>()
  useUnmount(() => dispatch(Viewer.destroy()))
  return (
    <ResourceProvider alias={alias}>
      <Switch>
        <Route exact path='/info/:alias' component={Info} />
        <Route exact path='/view/:alias' component={View} />
        <Route exact path='/settings/:alias' component={Settings} />
      </Switch>
    </ResourceProvider>
  )
}
