import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import { Color } from './color'
import { Style } from './style'

type StyledIcon = { onClick?: () => void }
const StyledIcon = styled(FontAwesomeIcon)(({ onClick }: StyledIcon) => ({
  color: Color.dangerRed,
  fontSize: 48,
  ...(onClick ? Style.clickable : undefined),
}))

type BigError = {
  onClick?: () => void
  style?: React.CSSProperties
}
export const BigError: React.FC<BigError> = ({ onClick, style }) => (
  <StyledIcon onClick={onClick} icon={faExclamationTriangle} style={style} />
)
