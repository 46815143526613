import styled from 'styled-components'

import { Color } from './color'

const Container = styled.div`
  width: 100%;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
  :hover {
    background-color: #f2f2f2;
  }
  border-bottom: 1px solid ${Color.veryLightGray};
  color: ${Color.veryDarkGray};
`

export const List = { Container, Row }
