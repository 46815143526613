import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { Protocol } from '@landrush/common'
import { ialphaSort } from '@landrush/util'

import {
  Color,
  CredentialProfileIcon,
  StyledTable,
  ProtocolIcon,
} from 'components'
import { Credentials, Navigator, Staging } from 'store'

import {
  DirProps,
  usePathDisplayName as display,
  useCredentialById,
} from './hooks'

type StyledPath = { $isEnabled: boolean }
const StyledPath = styled.div(({ $isEnabled }: StyledPath) =>
  $isEnabled
    ? `
  cursor: pointer;
  color: ${Color.veryDarkGray};
  &:hover {
    color: ${Color.landrushOrange};
  }
`
    : `
  cursor: not-allowed;
  color: ${Color.lightGray};
`
)

type Path = { directory: DirProps; isEnabled: boolean }
const Path: React.FC<Path> = ({ directory, isEnabled }) => {
  const dispatch = useDispatch()
  return (
    <StyledPath
      $isEnabled={isEnabled}
      onClick={() => {
        if (isEnabled) dispatch(Navigator.navigateTo(directory))
      }}
    >
      {display(directory)}
    </StyledPath>
  )
}

const DirectoryTable = styled(StyledTable)`
  .directory {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
`
export default () => {
  const credentials = useSelector(Credentials.selectCredentials)

  const dirs: DirProps[] = credentials
    .reduce<DirProps[]>(
      (dirs, { id, protocol, paths }) => [
        ...dirs,
        ...paths.reduce<DirProps[]>(
          (dirs, path) => [
            ...dirs,
            { credentialId: id, root: path, parts: [] },
          ],
          []
        ),

        // The Dropbox credential won't have any paths, but implicitly
        // points at the root of the user's /Apps/Landrush folder.
        ...(protocol === 'dropbox'
          ? [{ credentialId: id, root: '', parts: [] }]
          : []),
      ],
      []
    )
    .sort((a, b) => ialphaSort(display(a), display(b)))

  return (
    <DirectoryTable>
      <tbody>
        {dirs.map((dir) => {
          const c = useCredentialById(dir.credentialId)
          const stagedCredential = useSelector(Staging.selectStagedCredential)
          const isEnabled = !stagedCredential || c.id === stagedCredential.id
          return (
            <tr key={Navigator.getDirectoryKey(dir)}>
              <td>
                <ProtocolIcon protocol={Protocol.parse(c.protocol)} />
              </td>
              <td className='directory'>
                <Path directory={dir} isEnabled={isEnabled} />
              </td>
              <td>
                <CredentialProfileIcon name={c.name} isEnabled={isEnabled} />
              </td>
            </tr>
          )
        })}
      </tbody>
    </DirectoryTable>
  )
}
