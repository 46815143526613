import React from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useMount } from 'react-use'

import { Message } from 'components'
import { Credentials, Session } from 'store'
import { Browser, useAsync } from 'utils'

const { getHashParam } = Browser

export default () => {
  const dispatch = useDispatch()
  const client = useSelector(Session.selectClient)

  const [{ value, error }, submit] = useAsync(async () => {
    const hashError = getHashParam('error_description')?.replace(/\+/g, ' ')
    if (hashError) throw new Error(hashError)

    const token = getHashParam('access_token')
    if (!token) throw new Error('Unexpected response contents')

    const c = await client.credentials.add('dropbox', 'dropbox', { token })
    dispatch(Credentials.addSuccess(c))
    return true
  }, [])

  useMount(submit)

  const dst = '/add-credential'
  if (error) {
    return (
      <Redirect
        to={{
          pathname: dst,
          state: { protocol: 'dropbox', error },
        }}
      />
    )
  }

  if (value) {
    return <Redirect to={{ pathname: dst, state: { protocol: 'dropbox' } }} />
  }

  return <Message spin>Linking account...</Message>
}
