import React from 'react'

import Header from '../header'
import Shares from './shares'
import Programmatic from './programmatic'

export default () => {
  return (
    <>
      <Header />
      <Shares />
      <Programmatic />
    </>
  )
}
