import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons'

import { Color } from './color'
import { Panel } from './panel'
import { Spaced } from './spaced'

type Step = { type: string } & Record<string, unknown>

const Connector = () => (
  <div className='step-connector'>
    <FontAwesomeIcon icon={faLongArrowAltDown} />
  </div>
)

type WithValue = { value: unknown }
const Value: React.FC<WithValue> = ({ value }) => {
  if (value instanceof Array) {
    return (
      <>
        {value
          .map((v, i) => <Value key={i} value={v} />)
          .reduce<JSX.Element[]>(
            (agg, cur, i) =>
              agg.length
                ? [
                    ...agg,
                    <React.Fragment key={`value-${i}`}> </React.Fragment>,
                    cur,
                  ]
                : [cur],
            []
          )}
      </>
    )
  }
  return value as any
}

type WithStage = { step: Step }
const Step: React.FC<WithStage> = ({ step }) => {
  const { type, ...options } = step

  return (
    <Panel className='step'>
      <div className='step-type'>{type}</div>
      {Object.entries(options).map(([option, value], i) => (
        <Spaced className='option-pair' key={i}>
          <div className='option-name'>{option}</div>
          <div className='option-value'>
            <Value value={value} />
          </div>
        </Spaced>
      ))}
    </Panel>
  )
}

type WithSteps = { steps: Step[] }
export const Steps: React.FC<WithSteps> = ({ steps }) => (
  <Styles>
    {steps
      .map((step, i) => <Step key={i} step={step} />)
      .reduce<JSX.Element[]>(
        (agg, cur, i) =>
          agg.length
            ? [...agg, <Connector key={`connector-${i}`} />, cur]
            : [cur],
        []
      )}
  </Styles>
)

const Styles = styled.div`
  padding-bottom: 16px;

  .step {
  }
  .step-type {
    text-align: center;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: #f6f6f6;
    border-bottom: 1px solid ${Color.veryLightGray};
  }
  .step-connector {
    text-align: center;
    color: ${Color.gray};
  }
  .option-pair {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    &:hover {
      background-color: #f2f2f2;
    }
    :not(:last-child) {
      border-bottom: 1px solid ${Color.veryLightGray};
    }
  }
  .option-name {
  }
  .option-value {
  }
`
