import React from 'react'
import styled from 'styled-components'

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 8,
})

type Column = { style?: React.CSSProperties; className?: string }
export const Column: React.FC<Column> = ({ children, style, className }) => (
  <Container style={style} className={className}>
    {children}
  </Container>
)
