import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Auth } from '@landrush/client'

import { Button, ErrorMessage, InfoMessage, TextInput } from 'components'
import { Session } from 'store'
import { LocalStorage, useAppDispatch, useAppSelector, useAsync } from 'utils'

const Prompt: React.FC<{ from?: string }> = ({ from }) => {
  if (from === '/reset-password') {
    return (
      <InfoMessage
        icon='check'
        title='Password successfully reset'
        body='Please log in'
      />
    )
  }

  if (from === '/confirm') {
    return (
      <InfoMessage
        icon='check'
        title='Email successfully confirmed'
        body='Please log in'
      />
    )
  }

  return <h1 className='header'>Log in to Landrush</h1>
}

type FromState = { email?: string; from?: string }
export default () => {
  const dispatch = useAppDispatch()
  const { state } = useLocation<FromState | undefined>()
  const client = useAppSelector(Session.selectUnconnectedClient)

  const emailFromLocalStorage = LocalStorage.get('email')
  const emailFromRedirect = state?.email
  const from = state?.from

  const [email, setEmail] = useState(
    emailFromRedirect || emailFromLocalStorage || ''
  )
  const [password, setPassword] = useState('')

  const [
    { isPending, error, dismissError, value: user },
    submit,
  ] = useAsync(async () => {
    const user = await client.auth.login(email, password)
    if (user !== 'NEW_PASSWORD_REQUIRED') dispatch(Session.loginSuccess(user))
    LocalStorage.set('email', email)
    return user
  }, [email, password])

  const isValid = email.length > 0 && password.length > 0

  if (user === 'NEW_PASSWORD_REQUIRED') {
    return <ForceChangePassword email={email} oldpassword={password} />
  }

  return (
    <Styles>
      <div className='container'>
        <Prompt from={from} />
        <TextInput
          id='email-input'
          placeholder='Email'
          value={email}
          onChange={setEmail}
          onSubmit={submit}
          isEnabled={!isPending}
          isValid={isValid}
        />
        <TextInput
          id='password-input'
          value={password}
          onChange={setPassword}
          onSubmit={submit}
          type='password'
          placeholder='Password'
          isEnabled={!isPending}
          isValid={isValid}
        />
        {error && (
          <ErrorMessage onDismiss={dismissError}> {error} </ErrorMessage>
        )}
        <Button
          isEnabled={isValid && !isPending}
          onClick={submit}
          style={{ alignSelf: 'center' }}
          spin={isPending}
        >
          Log in
        </Button>
        {!isPending && (
          <div className='prompt'>
            <p>
              Not registered yet?{' '}
              <Link to={{ pathname: '/signup', state: { email } }}>
                Sign up
              </Link>
              .
            </p>
            <Link to={{ pathname: '/forgot-password', state: { email } }}>
              Forgot password?
            </Link>
          </div>
        )}
      </div>
    </Styles>
  )
}

const Styles = styled.div`
  .container {
    width: 480px;

    display: flex;
    flex-direction: column;
  }

  .header {
    align-self: center;
  }

  .prompt {
    text-align: right;
  }
`

type ForceChangePassword = { email: string; oldpassword: string }
const ForceChangePassword: React.FC<ForceChangePassword> = ({
  email,
  oldpassword,
}) => {
  const dispatch = useAppDispatch()
  const client = useAppSelector(Session.selectUnconnectedClient)

  const [password, setPassword] = useState('')
  const [dup, setDup] = useState('')

  const isValid = password === dup && password.length > 0

  const [
    { isPending, error, dismissError, value },
    submit,
  ] = useAsync(async () => {
    if (password !== dup) throw new Error('Passwords do not match')

    // Complete the forced-password-change process and then log in.
    await client.auth.completeNewPassword(email, oldpassword, password)
    const user = await client.auth.login(email, password)

    if (user === 'NEW_PASSWORD_REQUIRED') {
      throw new Error('Unexpected authentication response')
    }

    dispatch(Session.loginSuccess(user))
    LocalStorage.set('email', email)
    return user
  }, [dispatch, email, password, dup])

  return (
    <Styles>
      <div className='container'>
        <InfoMessage
          icon='lightbulb'
          title='Password change required'
          body='Please choose a password'
        />
        <TextInput
          autoFocus
          id='password-input'
          type='password'
          placeholder='New password'
          value={password}
          onChange={setPassword}
          onSubmit={submit}
          isEnabled={!isPending}
          isValid={isValid}
        />
        <TextInput
          id='confirm-input'
          type='password'
          placeholder='Confirm password'
          value={dup}
          onChange={setDup}
          onSubmit={submit}
          isEnabled={!isPending}
          isValid={isValid}
        />
        {error && (
          <ErrorMessage onDismiss={dismissError}> {error} </ErrorMessage>
        )}
        <Button
          isEnabled={isValid && !isPending}
          onClick={submit}
          style={{ alignSelf: 'center' }}
          spin={isPending}
        >
          Submit
        </Button>
      </div>
    </Styles>
  )
}
