import cn from 'classnames'
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'

import { Color } from 'components'

import { accept } from './accept'

type Dropzone = { analyze(f: File): void }
export const Dropzone: React.FC<Dropzone> = ({ analyze }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDrop = useCallback((files: File[]) => analyze(files[0]), [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    disabled: false,
    multiple: false,
  })

  return (
    <Styles>
      <div
        className={cn('dropzone-wrapper', { dragging: isDragActive })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div className={cn('dropzone-inner', { dragging: isDragActive })}>
          Zipped shapefile or GeoJSON
        </div>
      </div>
    </Styles>
  )
}

type Styles = { $isEnabled: boolean }
const Styles = styled.div`
  .dropzone-wrapper {
    height: 128px;
    border-radius: 8px;
    border-style: dashed;
    border-width: 2px;
    border-color: ${Color.veryLightGray};
  }

  .dropzone-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${Color.darkGray};
    cursor: pointer;

    background-color: #f8f8f8;
    height: 100%;
    border-radius: 4px;
  }
`
