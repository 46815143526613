import React from 'react'

import { Protocol } from '@landrush/common'

import { ProtocolIcon, Radio } from 'components'

const fontSize = 32
type WithProtocol = { protocol: Protocol; setProtocol(p: Protocol): void }
export default ({ protocol, setProtocol }: WithProtocol) => (
  <Radio
    value={protocol}
    onChange={setProtocol}
    options={[
      {
        value: 's3',
        component: <ProtocolIcon protocol='s3' style={{ fontSize }} />,
      },
      {
        value: 'dropbox',
        component: <ProtocolIcon protocol='dropbox' style={{ fontSize }} />,
      },
    ]}
  />
)
