import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faShare } from '@fortawesome/free-solid-svg-icons'

import { join } from '@landrush/util'

import { Color, ErrorMessage, Style } from 'components'
import { Credentials, Session } from 'store'

const IconContainer = styled.div`
  margin-top: 36px;
  margin-bottom: 8px;
`
const StyledIcon = styled(FontAwesomeIcon)`
  color: ${Color.landrushOrange};
  font-size: 48px;
`
const StyledMessage = styled.p({ ...Style.small, ...Style.muted })

type Props = { remoteError?: string }
export default ({ remoteError }: Props) => {
  const { dbxAppKey } = useSelector(Session.selectConfig)
  const isLinked = Boolean(useSelector(Credentials.selectDropbox))

  const [error, setError] = useState(remoteError)
  const dismissError = () => setError(undefined)

  const callback = join(window.location.origin, 'added-dropbox')
  const outgoing =
    'https://www.dropbox.com/1/oauth2/authorize?response_type=token' +
    `&client_id=${dbxAppKey}&redirect_uri=${callback}`

  if (error) {
    return <ErrorMessage onDismiss={dismissError}>{error}</ErrorMessage>
  }

  const icon = isLinked ? (
    <StyledIcon icon={faCheck} />
  ) : (
    <a href={outgoing}>
      <StyledIcon icon={faShare} />
    </a>
  )

  const message = isLinked ? 'Dropbox account linked' : 'Link a Dropbox account'

  return (
    <>
      <IconContainer>{icon}</IconContainer>
      <StyledMessage>{message}</StyledMessage>
    </>
  )
}
