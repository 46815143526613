import React from 'react'
import { useSelector } from 'react-redux'

import { Info, Task } from '@landrush/common'
import { Srs } from '@landrush/entwine'

import { Staging } from 'store'
import { Proj } from 'utils'

export default () => {
  const items = useSelector(Staging.selectItems)
  const infoList = items
    .map((item) => item.info)
    .filter((info): info is Info => Boolean(info))
    .filter(Task.isSuccess)

  const uniqueSrsList = infoList
    .map((v) => v.srs)
    .filter((v): v is Srs => Boolean(v))
    .reduce<Srs[]>(
      (agg, srs) =>
        agg.some((v) => Srs.equals(v, srs)) ? agg : agg.concat(srs),
      []
    )

  const warnings: string[] = []
  if (uniqueSrsList.length === 0) {
    warnings.push(
      'No coordinate system found - interoperability will be limited'
    )
  } else if (uniqueSrsList.length > 1) {
    warnings.push('Multiple coordinate systems found')
  } else {
    const srs = uniqueSrsList[0]
    const codeString = Srs.getCodeString(srs)
    if (!codeString) {
      warnings.push('No SRS code found - interoperability may be limited')
    } else if (!Proj.hasDef(srs)) {
      warnings.push('Unable to reproject from this SRS code')
    }
  }

  if (warnings.length === 0) return null
  return (
    <>
      <h4>Warnings</h4>
      <ul>
        {warnings.map((w, i) => (
          <li key={i}>{w}</li>
        ))}
      </ul>
    </>
  )
}
