import React, { useEffect, useState } from 'react'
import { usePopper, PopperChildrenProps } from 'react-popper'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faPen, faTag } from '@fortawesome/free-solid-svg-icons'

import { Id, Tag } from '@landrush/common'

import { Color } from './color'
import { List as ItemList } from './list'
import { TextInput } from './text-input'

type Row = { tag: Tag; isEnabled: boolean; applyTag(id: Id): void }
const Row: React.FC<Row> = ({
  tag: { id, name, color },
  isEnabled,
  applyTag,
}) => {
  return (
    <StyledRow
      key={id}
      onClick={() => isEnabled && applyTag(id)}
      $isEnabled={isEnabled}
    >
      <div className='apply-tags-tag-color'>
        <FontAwesomeIcon icon={faCircle} color={`#${color}`} />
      </div>
      <div className='apply-tags-tag-name'>{name}</div>
    </StyledRow>
  )
}
type StyledRow = { $isEnabled: boolean }
const StyledRow = styled(ItemList.Row)(
  ({ $isEnabled }: StyledRow) => `
  cursor: ${$isEnabled ? 'pointer' : 'default'};
  background-color: ${$isEnabled ? 'white' : Color.disabled};
  &:hover {
    background-color: ${$isEnabled ? '#f2f2f2' : Color.disabled};
  }

  .apply-tags-tag-color {
    font-size: 16px;
    padding-right: 6px;
  }

  .apply-tags-tag-name {
    font-size: small;
    color: ${$isEnabled ? Color.veryDarkGray : Color.gray};
  }
`
)

type ApplyTags = {
  targetName: 'resource' | 'region' | 'execution'
  tags: Tag[]
  selectedTargetsCount: number
  isModifiable: boolean
  isApplyingTag: boolean
  applyTag(id: Id): void
  icon?: 'tag' | 'plus'
  placement?: PopperChildrenProps['placement']
}
const ApplyTags: React.FC<ApplyTags> = ({
  targetName,
  tags: allTags,
  selectedTargetsCount,
  isModifiable,
  isApplyingTag,
  applyTag,
}) => {
  const [filterText, setFilterText] = useState('')
  const tags =
    filterText.length > 0
      ? allTags.filter((tag) =>
          tag.name.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
        )
      : allTags

  return (
    <ApplyTagsStyles>
      <div className='apply-tags-header'>
        <div className='apply-tags-header-text'>
          {isModifiable && selectedTargetsCount > 0
            ? 'Apply tags'
            : 'Filter by tag'}
        </div>
        <Link to={`/${targetName}s/tags`} className='apply-tags-header-link'>
          <FontAwesomeIcon className='apply-tags-header-icon' icon={faPen} />
        </Link>
      </div>
      <div className='apply-tags-filter-container'>
        <TextInput
          className='apply-tags-filter-text'
          autoFocus
          value={filterText}
          onChange={setFilterText}
          placeholder='Filter tag names...'
        />
        {isModifiable &&
          (selectedTargetsCount > 0 ? (
            <p className='context-message'>
              Apply a tag to {selectedTargetsCount} selected {targetName}
              {selectedTargetsCount > 1 ? 's' : ''}
            </p>
          ) : (
            <>
              <p className='context-message'>Select a tag to filter</p>
              <p className='context-message'>
                To apply a tag, first select {targetName}s
              </p>
            </>
          ))}
      </div>
      {tags.map((tag) => (
        <Row
          key={tag.id}
          tag={tag}
          isEnabled={!isApplyingTag}
          applyTag={applyTag}
        />
      ))}
    </ApplyTagsStyles>
  )
}
const ApplyTagsStyles = styled.div`
  background-color: white;
  border: 1px solid ${Color.lightGray};
  border-radius: 4px;
  width: 20vw;
  min-width: 280px;
  z-index: 99;

  .apply-tags-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${Color.veryLightGray};
  }

  .apply-tags-header-text {
    font-weight: bold;
    font-size: small;
    padding: 8px;
  }

  .apply-tags-header-link {
    font-size: 16px;
    padding-right: 8px;
  }

  .apply-tags-header-icon {
    color: ${Color.lightGray};
    &:hover {
      color: ${Color.darkGray};
    }
  }

  .apply-tags-filter-container {
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 4px;
    padding-right: 4px;
    border-bottom: 1px solid ${Color.veryLightGray};
  }

  .apply-tags-filter-text {
    font-size: small;
    margin-bottom: 0;
  }

  .context-message {
    text-align: center;
    font-size: small;
    color: ${Color.gray};
    margin: 4px;
  }
`

export const TaggablePopper: React.FC<ApplyTags> = ({
  placement = 'bottom',
  ...params
}) => {
  const [targetRef, setTargetRef] = useState<any>(null)
  const [popperRef, setPopperRef] = useState<any>(null)
  const [isOpen, setOpen] = useState(false)

  const { styles, attributes } = usePopper(targetRef, popperRef, {
    placement,
    modifiers: [{ name: 'offset', enabled: true, options: { offset: [0, 8] } }],
    strategy: 'fixed',
  })

  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      if (
        targetRef &&
        !targetRef?.contains(e.target) &&
        popperRef &&
        !popperRef?.contains(e.target)
      ) {
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', onClick)
    return () => document.removeEventListener('mousedown', onClick)
  }, [targetRef, popperRef])

  return (
    <>
      <div
        className='apply-tags-icon'
        ref={setTargetRef}
        onClick={() => setOpen((isOpen) => !isOpen)}
      >
        <StyledTagIcon title='Tags' icon={faTag} $isOpen={isOpen} />
      </div>

      {isOpen && (
        <div
          ref={setPopperRef}
          style={{ ...styles.popper, zIndex: 1 }}
          {...attributes.popper}
        >
          <div style={{ ...styles.offset }}>
            <ApplyTags {...params} />
          </div>
        </div>
      )}
    </>
  )
}
type StyledTagIcon = { $isOpen: boolean }
const StyledTagIcon = styled(FontAwesomeIcon)(
  ({ $isOpen }: StyledTagIcon) => `
  cursor: pointer;
  color: ${$isOpen ? Color.landrushOrange : Color.gray};
  &:hover {
    color: ${$isOpen ? Color.landrushOrange : Color.darkGray};
  }
`
)
