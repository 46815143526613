import { combineReducers, AnyAction } from 'redux'
import { configureStore as doConfigureStore, Action } from '@reduxjs/toolkit'
import thunk, {
  ThunkAction,
  ThunkDispatch as ReduxThunkDispatch,
  ThunkMiddleware,
} from 'redux-thunk'

import { Id } from '@landrush/common'

import { reducer as accessTokens, AccessTokens } from './access-tokens'
import {
  reducer as addExecutionsState,
  AddExecutionsState,
} from './add-executions-state'
import {
  reducer as addRegionsState,
  AddRegionsState,
} from './add-regions-state'
import { reducer as copies, Copies } from './copies'
import { reducer as credentials, Credentials } from './credentials'
import { reducer as executions, Executions } from './executions'
import { reducer as executionState, ExecutionState } from './execution-state'
import { reducer as navigator, Navigator } from './navigator'
import {
  reducer as regions,
  tagReducer as regionTags,
  Regions,
} from './regions'
import { reducer as regionState, RegionState } from './region-state'
import {
  reducer as resources,
  tagReducer as resourceTags,
  Resources,
} from './resources'
import { reducer as resourceState, ResourceState } from './resource-state'
import { reducer as session, Session } from './session'
import { reducer as shares, Shares } from './shares'
import { reducer as staging, Staging } from './staging'
import { reducer as viewer, Viewer } from './viewer'
import { reducer as workflows, Workflows } from './workflows'
import { reducer as workflowHistory, WorkflowHistory } from './workflow-history'

import * as Types from './types'

export type LazyState = {
  loadState?: 'loading' | 'done'
  ids: Id[]
}
export { Types }

export {
  AccessTokens,
  AddExecutionsState,
  AddRegionsState,
  Copies,
  Credentials,
  Executions,
  ExecutionState,
  Navigator,
  Regions,
  RegionState,
  Resources,
  ResourceState,
  Session,
  Shares,
  Staging,
  Viewer,
  Workflows,
  WorkflowHistory,
}

// const regionTags = RegionTags.reducer

const appReducer = combineReducers({
  accessTokens,
  addExecutionsState,
  addRegionsState,
  copies,
  credentials,
  executions,
  executionState,
  navigator,
  regions,
  regionTags,
  regionState,
  resources,
  resourceTags,
  resourceState,
  session,
  shares,
  staging,
  viewer,
  workflows,
  workflowHistory,
})

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === Session.logoutSuccess.type) {
    // Nuke the logged-in-specific portions of our state.
    const { session } = state
    state = { session }
  }
  return appReducer(state, action)
}

export function configureStore() {
  return doConfigureStore({
    reducer: rootReducer,
    middleware: [thunk as ThunkMiddleware<AppState, AnyAction>],
  })
}

export type Store = ReturnType<typeof configureStore>
export type AppDispatch = Store['dispatch']
export type AppState = ReturnType<typeof appReducer>
export type AppThunk<T = void> = ThunkAction<
  Promise<T>,
  AppState,
  unknown,
  Action<string>
>

export type ThunkResult<T> = ThunkAction<Promise<T>, AppState, null, AnyAction>
export type ThunkDispatch = ReduxThunkDispatch<AppState, null, AnyAction>

// export const useAppDispatch = () => useDispatch<AppDispatch>()

// type ThunkOptions = { dispatch: AppDispatch; state: AppState }
// type ThunkOptions = BaseThunkAPI
/*
// type ThunkOptions =
type ThunkFunction<TResult, TArg = void> = (
  a: TArg,
  b: ThunkOptions
) => Promise<TResult>
export function createAsyncAppThunk<TResult, TArg>(
  typePrefix: string,
  f: ThunkFunction<TResult, TArg>
) {
  return createAsyncThunk<TResult, TArg, ThunkOptions>(typePrefix, f)
}
*/

/*
type ThunkFunction<TResult, TArg = void> = (
  a: TArg,
  b: ThunkOptions
) => Promise<TResult>
*/
/*
// type ThunkFunction<TResult, TArg = void> = (a: TArg, b: any) => Promise<TResult>
interface TmpState extends AppState {}
// interface TmpDispatch extends AppDispatch {}
type ThunkFunction<TResult, TArg = void> = AsyncThunkPayloadCreator<
  TResult,
  TArg,
  {
    state: TmpState
    // dispatch: AppDispatch
  }
>
export function createAsyncAppThunk<TResult, TArg>(
  typePrefix: string,
  f: ThunkFunction<TResult, TArg>
) {
  return createAsyncThunk<
    TResult,
    TArg,
    {
      state: TmpState
      // dispatch: TmpDispatch
    }
  >(typePrefix, f)
  // return createAsyncThunk<TResult, TArg, TThunkApi>(typePrefix, f)
}
*/
