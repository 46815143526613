import React, { ReactNode, useRef, isValidElement } from 'react'
import ReactTooltip from 'react-tooltip'

type Props = {
  children: ReactNode
  content: ReactNode
  [rest: string]: any
}

export const Tooltip = ({ content, children, ...rest }: Props) => {
  const ref = useRef(null)
  const tooltipProps = {
    'data-tip': content,
    'data-effect': 'solid',
    'data-for': ref.current,
  }
  return (
    <>
      {isValidElement(children) ? (
        React.cloneElement(children, tooltipProps)
      ) : (
        <div {...tooltipProps}>{children}</div>
      )}
      <ReactTooltip className='lr-tooltip' ref={ref} {...rest} />
    </>
  )
}
