import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { ErrorMessage, HeaderDate, Spaced } from 'components'
import { Resources } from 'store'
import { useAppDispatch, useAsync, useResource } from 'utils'

import { Control } from './control'
import { Edit, Save } from './edit'
import { Name } from './name'
import { Styles } from './styles'

export type NameProps = {
  text?: string
  setText(v?: string): void
  isRenamable: boolean
  isRenaming: boolean
  rename(): void
}

export default () => {
  const dispatch = useAppDispatch()
  const { id, name, alias, createdAt } = useResource()

  const match = useRouteMatch<{ page: string }>('/:page/:alias')
  const page = match?.params.page

  const isRenamable = page === 'settings'

  const [text, setText] = useState<string>()
  const [{ error, dismissError, isPending }, rename] = useAsync(async () => {
    if (!text) return
    const { error } = await dispatch(Resources.rename(id, text))
    if (error) throw new Error(error)
  }, [dispatch, id, text])

  const nameProps: NameProps = {
    text,
    setText,
    isRenamable,
    isRenaming: isPending,
    rename,
  }

  useEffect(() => {
    setText(undefined)
  }, [name, error])

  return (
    <>
      <Styles>
        <Spaced>
          <Name {...nameProps} />
          <div className='controls'>
            <Save {...nameProps} />
            <Edit {...nameProps} />
            <Control
              icon='info-circle'
              to={`/info/${alias}`}
              isActive={page === 'info'}
            />
            <Control
              icon='map-marker-alt'
              to={`/view/${alias}`}
              isActive={page === 'view'}
            />
            <Control
              icon='cog'
              to={`/settings/${alias}`}
              isActive={page === 'settings'}
            />
          </div>
        </Spaced>
      </Styles>
      <HeaderDate date={createdAt} />
      {error ? (
        <ErrorMessage onDismiss={dismissError}>{error}</ErrorMessage>
      ) : null}
    </>
  )
}
