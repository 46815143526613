import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExport } from '@fortawesome/free-solid-svg-icons'

import { Tag, Task } from '@landrush/common'

import { Color, Popper, TaggableHeader } from 'components'
import { Executions, ExecutionState } from 'store'
import { useAppDispatch, useAsync } from 'utils'

import { CopyMenu } from './copy'
import { useTags, useVisibleExecutions } from './hooks'

export const Header = () => {
  const dispatch = useAppDispatch()
  const tags = useTags()
  const visibleRegions = useVisibleExecutions()
  const visibleCount = visibleRegions.length
  const executionsCount = useSelector(Executions.selectTotal)
  const selectedCount = useSelector(
    ExecutionState.selectSelectedExecutionsCount
  )
  const selected = useSelector(ExecutionState.selectSelectedExecutions)

  const isEverythingVisibleSelected = selectedCount === visibleCount

  const handleAll = () => {
    if (isEverythingVisibleSelected)
      dispatch(ExecutionState.performUnselectAll())
    else {
      dispatch(
        ExecutionState.performSelectMany(
          visibleRegions.map((region) => region.id)
        )
      )
    }
  }

  const [{ isPending }, removeSelected] = useAsync(async () => {
    await dispatch(ExecutionState.removeSelected(Object.keys(selected)))
  }, [selected])

  const filterText = useSelector(ExecutionState.selectFilterText)
  const setFilterText = (text: string) =>
    dispatch(ExecutionState.setFilterText(text))

  const filterStatus = useSelector(ExecutionState.selectFilterStatus)
  const setFilterStatus = (s: Task.Status) =>
    dispatch(ExecutionState.setFilterStatus(s))

  const filterTags: Tag[] = []

  return (
    <TaggableHeader
      targetName='execution'
      totalTargetsCount={executionsCount}
      selectedTargetsCount={selectedCount}
      visibleTargetsCount={visibleCount}
      filterText={filterText}
      setFilterText={setFilterText}
      filterStatus={filterStatus}
      setFilterStatus={setFilterStatus}
      filterTags={filterTags}
      removeFilterTag={() => {}}
      handleCheckAll={handleAll}
      tags={tags}
      isModifiable={false}
      applyTag={() => {}}
      isApplyingTag={false}
      handleRemoveSelected={removeSelected}
      isRemovingSelected={isPending}
    >
      <Styles>
        <Popper
          target={
            <FontAwesomeIcon
              title='Exports'
              icon={faFileExport}
              className='export-icon'
              onClick={() => {}}
            />
          }
          popper={<CopyMenu />}
        />
      </Styles>
    </TaggableHeader>
  )
}

const Styles = styled.div`
  display: flex;
  align-items: center;
  .export-icon {
    margin-right: 12px;
    font-size: 20px;
    color: ${Color.gray};
    &:hover {
      color: ${Color.darkGray};
    }
  }
`
