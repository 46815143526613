import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faExclamation,
  faSyncAlt,
} from '@fortawesome/free-solid-svg-icons'

import { Task } from '@landrush/common'
import { dateString, isSameDay, timeString } from '@landrush/util'

import {
  Color,
  DataTable,
  DeleteIcon,
  FileTypeIcon,
  Progress,
  Spinner,
  Style,
} from 'components'
import { Staging } from 'store'

type WithItem = { item: Staging.Item }
const StyledRedoIcon = styled(FontAwesomeIcon)`
  color: ${Color.veryLightGray};
  &:hover {
    color: ${Color.gray};
  }
`
const ProgressOrControl: React.FC<WithItem> = ({
  item: { identifier, info },
}) => {
  const dispatch = useDispatch()
  if (!info) return null

  if (Task.isRunning(info)) {
    const { index, total } = info
    if (!index || !total) return null
    return (
      <div style={{ width: '25%', minWidth: 120 }}>
        <Progress index={index} total={total} />
      </div>
    )
  }

  const { createdAt } = info
  const at = isSameDay(createdAt, new Date())
    ? timeString(createdAt)
    : dateString(createdAt)
  const message = `Analyzed ${at}. Click to re-analyze.`

  return (
    <div
      style={{ ...Style.clickable }}
      onClick={() => dispatch(Staging.redo(identifier))}
      data-tip={message}
      data-place='left'
      data-effect='solid'
    >
      <StyledRedoIcon icon={faSyncAlt} />
      <ReactTooltip />
    </div>
  )
}

const Indicator: React.FC<WithItem> = ({ item }) => {
  const dispatch = useDispatch()
  const { info } = item

  if (!info || Task.isRunning(info)) {
    return (
      <div
        style={{ ...Style.clickable }}
        onClick={() => dispatch(Staging.cancel(item))}
        data-tip='Click to cancel analysis'
        data-place='left'
        data-effect='solid'
      >
        <Spinner small />
        <ReactTooltip />
      </div>
    )
  }

  if (Task.isFailure(info)) {
    const { errors } = info
    return (
      <div data-tip={errors} data-place='left' data-effect='solid'>
        <FontAwesomeIcon
          style={{ color: Color.dangerRed }}
          icon={faExclamation}
        />
        <ReactTooltip />
      </div>
    )
  }

  return (
    <FontAwesomeIcon icon={faCheck} style={{ color: Color.landrushOrange }} />
  )
}

const Item: React.FC<WithItem> = ({ item }) => {
  const dispatch = useDispatch()
  return (
    <tr>
      <td>
        <FileTypeIcon type={item.identifier.type} />
      </td>
      <td style={{ width: '75%', paddingLeft: 16 }}>{item.identifier.path}</td>
      <td style={{ paddingLeft: 48 }}>
        <ProgressOrControl item={item} />
      </td>
      <td style={{ paddingLeft: 48 }}>
        <Indicator item={item} />
      </td>
      <td style={{ paddingLeft: 48 }}>
        <DeleteIcon
          icon='minus'
          isPending={false}
          onClick={() => dispatch(Staging.remove(item.identifier))}
        />
      </td>
    </tr>
  )
}

function getItemKey(item: Staging.Item) {
  const {
    identifier: { credentialId, path },
  } = item
  return `${credentialId}@${path}`
}
export default () => {
  const items = useSelector(Staging.selectItems)
  if (!items.length) {
    return (
      <div style={{ ...Style.alignCenter, ...Style.muted, padding: 8 }}>
        Add items below
      </div>
    )
  }
  return (
    <DataTable>
      <tbody>
        {items.map((item) => (
          <Item key={getItemKey(item)} item={item} />
        ))}
      </tbody>
    </DataTable>
  )
}
