import React from 'react'
import styled from 'styled-components'

import { Button } from './button'
import { Color } from './color'
import { ErrorMessage } from './error-message'

type CancelOrSubmit = {
  error?: string
  dismissError?: () => void
  isEnabled?: boolean
  isPending?: boolean
  onCancel(): void
  onSubmit(): void
}

export const CancelOrSubmit = ({
  error,
  dismissError,
  isEnabled = true,
  isPending = false,
  onCancel,
  onSubmit,
}: CancelOrSubmit) => {
  return (
    <>
      {error && <ErrorMessage onDismiss={dismissError}>{error}</ErrorMessage>}
      <Styles>
        <Button
          className='cancel-button'
          onClick={onCancel}
          isEnabled={!isPending}
        >
          Cancel
        </Button>
        <Button
          className='submit-button'
          onClick={isEnabled ? onSubmit : undefined}
          isEnabled={!isPending}
          spin={isPending}
        >
          Submit
        </Button>
      </Styles>
    </>
  )
}

const Styles = styled.div`
  display: flex;
  justify-content: center;

  .cancel-button {
    margin-right: 2px;
    background-color: ${Color.lightGray};
    &:hover {
      background-color: ${Color.dangerRed};
    }
  }
  .submit-button {
    margin-left: 2px;
  }
`
