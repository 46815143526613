import { useMemo } from 'react'
import { createSelector } from '@reduxjs/toolkit'

import { Id } from '@landrush/common'

import { Slice } from './slice'
import { adapter } from './types'

type State = { workflows: Slice }
const selectSlice = (s: State) => s.workflows

export const {
  selectAll,
  selectById,
  selectEntities,
  selectIds,
  selectTotal,
} = adapter.getSelectors(selectSlice)
export const selectIsListed = createSelector(selectSlice, (s) => s.isListed)

export const selectByAlias = (state: State, alias: string) =>
  selectAll(state).find((v) => v.alias === alias)

// TODO: Should probably be moved to a "hooks" file.
export const useSelectById = (id: Id) =>
  useMemo(
    () =>
      createSelector(
        (s: State) => s,
        (state) => selectById(state, id)
      ),
    [id]
  )

export const useSelectByAlias = (alias: string) =>
  useMemo(
    () =>
      createSelector(
        (s: State) => s,
        (state) => selectByAlias(state, alias)
      ),
    [alias]
  )
