import React, { useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { useMount } from 'react-use'

import { ErrorMessage, Message } from 'components'
import { WorkflowHistory } from 'store'
import { useAppDispatch, useAppSelector, useClient } from 'utils'

import { Body } from './body'

function parseVersion(s?: string) {
  if (s === undefined) return
  const v = parseInt(s)
  if (!isNaN(v)) return v
}

type Params = { alias: string; version?: string }
export default () => {
  const dispatch = useAppDispatch()

  const [error, setError] = useState<string>()

  const params = useParams<Params>()
  const { alias } = params
  const urlVersion = parseVersion(params.version)
  const [version, setVersion] = useState(urlVersion)

  const client = useClient()
  const history = useAppSelector(WorkflowHistory.maybeSelectHistory)

  useMount(async () => {
    if (history?.alias === alias) return
    console.log('Loading...')
    try {
      const response = await client.workflows.getHistoryByAlias(alias)
      dispatch(WorkflowHistory.actions.assign(response))
    } catch (e) {
      setError('Failed to load workflow')
    }
  })

  if (error) return <ErrorMessage>{error}</ErrorMessage>
  if (!history) return <Message>Loading...</Message>

  const latestVersion = history.list.length

  if (!version) {
    console.log('Loaded history, updating to latest:', latestVersion)
    setVersion(latestVersion)
    return <Message>Loading...</Message>
  }

  // The rules here are slightly confusing since the lack of an explicit version
  // in the URL indicates "latest".  We want to do a redirect if:
  //  - There is NO version in the URL itself (i.e. we are currently viewing
  //    the latest version) and the version state is NOT the latest
  // or
  //  - There IS a version in the URL (i.e. we are viewing an old version) and
  //    the version state does not match this URL version.
  if (
    (!urlVersion && version < latestVersion) ||
    (urlVersion && version !== urlVersion)
  ) {
    console.log('Redirecting')
    const pathname =
      version === latestVersion
        ? `/workflows/${alias}`
        : `/workflows/${alias}/${version}`
    return <Redirect to={pathname} />
  }

  return <Body history={history} version={version} setVersion={setVersion} />
}
