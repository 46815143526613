import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'

import {
  Bookmark,
  Event,
  Id,
  Potree,
  Resource,
  Share,
  Tag,
} from '@landrush/common'
import { Result, ialphaSort } from '@landrush/util'

import { AppState, AppThunk } from 'store'
import { Session } from 'store/session'
import { Shares } from 'store/shares'
import { createTagSlice, createTagOperations } from 'store/tags'

import { extractId, extractTags } from './utils'

const selectTagSlice = (state: AppState) => state.resourceTags
const tagSlice = createTagSlice('resources')
const tagReducer = tagSlice.reducer
const Tags = createTagOperations('resources', tagSlice, selectTagSlice)

// For some reason if we do this omission based on simply the Resource.Summary
// type, we lose the ability to treat this type as a Task.
type Omissions = 'shares' | 'tags'

declare namespace Normalized {
  type Summary = { shares: Id[]; tags: Id[] } & (
    | Omit<Resource.Summary.Running, Omissions>
    | Omit<Resource.Summary.Failure, Omissions>
    | Omit<Resource.Summary.Success, Omissions>
  )

  type Detailed = { shares: Id[]; tags: Id[] } & (
    | Omit<Resource.Running, Omissions>
    | Omit<Resource.Failure, Omissions>
    | Omit<Resource.Success, Omissions>
  )
}
type Normalized = Normalized.Summary | Normalized.Detailed

function isDetailed(n: Normalized): n is Normalized.Detailed {
  return 'points' in n
}

const normalize = (resource: Resource.Summary): Normalized => ({
  ...resource,
  shares: resource.shares.map(extractId),
  tags: resource.tags.map(extractId),
})
const denormalize = (state: AppState, norm: Normalized): Resource.Summary => ({
  ...norm,
  shares: norm.shares
    .map((id) => Shares.selectById(state, id))
    .filter((s): s is Share => Boolean(s)),
  tags: norm.tags
    .map((id) => Tags.selectById(state, id))
    .filter((t): t is Tag => Boolean(t)),
})

const adapter = createEntityAdapter<Normalized>({
  sortComparer: (a, b) => ialphaSort(a.name, b.name),
})

type TagIntersection = { targetIds: Id[]; tagId: Id }

type CustomState = {
  loadState?: 'loading' | 'done'
  loadError?: string
}
const initialState = adapter.getInitialState<CustomState>({})
const slice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    loadRequest(state) {
      state.loadState = 'loading'
      state.loadError = undefined
    },
    loadFailure(state, action: PayloadAction<string>) {
      state.loadState = undefined
      state.loadError = action.payload
    },
    loadSuccess(state, action: PayloadAction<Resource.Summary[]>) {
      const resources = action.payload
      state.loadState = 'done'
      const norm = resources.map(normalize)
      adapter.addMany(state, norm)
    },
    destroySuccess() {
      return initialState
    },
    addManySuccess(state, action: PayloadAction<Resource.Summary[]>) {
      const resources = action.payload.map(normalize)
      adapter.addMany(state, resources)
    },
    receive(state, action: PayloadAction<Event[]>) {
      action.payload.forEach((v) => {
        if (v.target !== 'resource') return
        if (v.action !== 'update') return

        adapter.updateOne(state, { id: v.payload.id, changes: v.payload })
      })
    },
    hydrateSuccess(state, action: PayloadAction<Resource>) {
      const resource = normalize(action.payload)
      adapter.upsertOne(state, resource)
    },
    removeSuccess(state, action: PayloadAction<Id[]>) {
      const ids = action.payload
      adapter.removeMany(state, ids)
    },
    applySuccess(state, action: PayloadAction<TagIntersection>) {
      const { targetIds, tagId } = action.payload
      targetIds.forEach((targetId) => {
        const target = state.entities[targetId]
        if (target && !target.tags.includes(tagId)) target.tags.push(tagId)
      })
    },
    unapplySuccess(state, action: PayloadAction<TagIntersection>) {
      const { targetIds, tagId } = action.payload
      targetIds.forEach((targetId) => {
        const target = state.entities[targetId]
        if (target) target.tags = target.tags.filter((id) => id !== tagId)
      })
    },
    addShare(state, action: PayloadAction<{ resourceId: Id; shareId: Id }>) {
      const { resourceId, shareId } = action.payload
      const resource = state.entities[resourceId]
      if (!resource) return
      resource.shares.push(shareId)
    },
    removeShare(state, action: PayloadAction<{ resourceId: Id; shareId: Id }>) {
      const { resourceId, shareId } = action.payload
      const resource = state.entities[resourceId]
      if (!resource) return
      resource.shares = resource.shares.filter((s) => s !== shareId)
    },
    addBookmark(
      state,
      action: PayloadAction<{ resourceId: Id; bookmark: Bookmark }>
    ) {
      const { resourceId, bookmark } = action.payload
      const resource = state.entities[resourceId]
      if (!resource || !isDetailed(resource)) return
      resource.bookmarks = [...resource.bookmarks, bookmark].sort((a, b) =>
        ialphaSort(a.name, b.name)
      )
    },
    patchBookmark(
      state,
      action: PayloadAction<{ resourceId: Id; bookmark: Bookmark }>
    ) {
      const { resourceId, bookmark } = action.payload
      const resource = state.entities[resourceId]
      if (!resource || !isDetailed(resource)) return
      resource.bookmarks = [
        ...resource.bookmarks.filter((b) => b.id !== bookmark.id),
        bookmark,
      ].sort((a, b) => ialphaSort(a.name, b.name))
    },
    removeBookmark(
      state,
      action: PayloadAction<{ resourceId: Id; bookmarkId: Id }>
    ) {
      const { resourceId, bookmarkId } = action.payload
      const resource = state.entities[resourceId]
      if (!resource || !isDetailed(resource)) return
      resource.bookmarks = resource.bookmarks.filter((b) => b.id !== bookmarkId)
    },
  },
})

const { actions, reducer } = slice
const {
  loadRequest,
  loadSuccess,
  loadFailure,
  destroySuccess,
  addManySuccess,
  hydrateSuccess,
  removeSuccess,
} = actions

const selectSlice = (state: AppState) => state.resources
const adapterSelectors = adapter.getSelectors(selectSlice)

const selectAll = (s: AppState) =>
  adapterSelectors.selectAll(s).map((norm) => denormalize(s, norm))

const selectById = (s: AppState, id: Id): Resource.Summary | undefined => {
  const norm = adapterSelectors.selectById(s, id)
  if (!norm) return
  return denormalize(s, norm)
}

const selectByAlias = (
  state: AppState,
  alias: string
): Resource.Summary | undefined => {
  const norm = adapterSelectors.selectAll(state).find((v) => v?.alias === alias)
  return norm && denormalize(state, norm)
}

const selectManyById = (s: AppState, ids: Id[]): Resource.Summary[] => {
  return ids
    .map((id) => selectById(s, id))
    .filter((r): r is Resource.Summary => Boolean(r))
}

const selectLoadState = createSelector(selectSlice, (s) => s.loadState)
const selectLoadError = createSelector(selectSlice, (s) => s.loadError)
const selectCount = createSelector(adapterSelectors.selectAll, (s) => s.length)

const selectors = {
  selectSlice,
  selectLoadState,
  selectLoadError,
  selectAll,
  selectById,
  selectByAlias,
  selectManyById,
  selectCount,
}

const load = (): AppThunk<void> => async (dispatch, getState) => {
  try {
    if (selectLoadState(getState()) === 'done') return

    dispatch(loadRequest())
    const client = Session.selectClient(getState())
    const resources: Resource.Summary[] = await client.resources.list()

    const tags = extractTags(resources)
    dispatch(Tags.upsertMany(tags))

    resources.forEach(({ shares }) => {
      shares.forEach((share) => dispatch(Shares.actions.add(share)))
    })

    dispatch(loadSuccess(resources))
  } catch (e) {
    dispatch(loadFailure(e.message))
  }
}

const destroy = (): AppThunk => async (dispatch, getState) => {
  dispatch(destroySuccess())
}

const add = (ids: Id[], name?: string): AppThunk<Result<Resource>> => async (
  dispatch,
  getState
) => {
  try {
    const client = Session.selectClient(getState())
    const resource = await client.resources.add({ input: ids, name })
    dispatch(hydrateSuccess(resource))
    return Result.success(resource)
  } catch (e) {
    const error: string = e.message
    return Result.failure(error)
  }
}

const addExternal = (
  url: string
): AppThunk<Result<Resource.Summary[]>> => async (dispatch, getState) => {
  try {
    const client = Session.selectClient(getState())

    if (url.endsWith('.geojson')) {
      const resources = await client.resources.addManyExternal({ url })
      dispatch(addManySuccess(resources))
      return Result.success(resources)
    }

    const resource = await client.resources.addExternal({ url })
    dispatch(hydrateSuccess(resource))
    return Result.success([resource])
  } catch (e) {
    const error: string = e.message
    return Result.failure(error)
  }
}

const rename = (id: Id, name: string): AppThunk<Result<Resource>> => async (
  dispatch,
  getState
) => {
  try {
    const client = Session.selectClient(getState())
    const resource = await client.resources.rename(id, name)
    dispatch(hydrateSuccess(resource))
    return Result.success(resource)
  } catch (e) {
    return Result.failure(e.message)
  }
}

const doHydrate = (
  select: () => Resource.Summary | undefined,
  fetch: () => Promise<Resource>
): AppThunk<Result<Resource>> => async (dispatch) => {
  try {
    const existing = select()

    if (existing && Resource.isDetailed(existing)) {
      return Result.success(existing)
    }

    const resource = await fetch()
    dispatch(Tags.upsertMany(resource.tags))
    resource.shares.forEach((share) => dispatch(Shares.actions.add(share)))
    dispatch(hydrateSuccess(resource))
    return Result.success(resource)
  } catch (e) {
    const error: string = e.message
    return Result.failure(error)
  }
}

const hydrate = (id: Id): AppThunk<Result<Resource>> => async (
  dispatch,
  getState
) => {
  return dispatch(
    doHydrate(
      () => selectById(getState(), id),
      () => Session.selectClient(getState()).resources.get(id)
    )
  )
}

const hydrateByAlias = (alias: string): AppThunk<Result<Resource>> => async (
  dispatch,
  getState
) => {
  return dispatch(
    doHydrate(
      () => selectByAlias(getState(), alias),
      () => Session.selectClient(getState()).resources.getByAlias(alias)
    )
  )
}

const remove = (id: Id): AppThunk<Result<true>> => async (
  dispatch,
  getState
) => {
  try {
    const client = Session.selectClient(getState())
    await client.resources.remove(id)
    dispatch(removeSuccess([id]))
    return Result.success(true)
  } catch (e) {
    return Result.failure(e.message)
  }
}

const removeMany = (ids: Id[]): AppThunk<Result<true>> => async (
  dispatch,
  getState
) => {
  try {
    const client = Session.selectClient(getState())
    await client.resources.removeMany(ids)
    dispatch(removeSuccess(ids))
    return Result.success(true)
  } catch (e) {
    console.log(e)
    return Result.failure(e.message)
  }
}

const toggleTag = (tagId: Id, resourceId: Id): AppThunk<Result<Id>> => async (
  dispatch,
  gs
) => {
  try {
    const resource = Resources.selectById(gs(), resourceId)
    if (resource === undefined) return Result.failure('Resource not found')
    if (resource.tags.some((t) => t.id === tagId)) {
      return dispatch(unapplyTag(tagId, resourceId))
    } else {
      return dispatch(applyTag(tagId, resourceId))
    }
  } catch (e) {
    return Result.failure(e.message)
  }
}

const unapplyTag = (tagId: Id, resourceId: Id): AppThunk<Result<Id>> => async (
  dispatch,
  gs
) => {
  const client = Session.selectClient(gs())
  await client.resources.tags.unapply(tagId, [resourceId])
  dispatch(Resources.unapplySuccess({ tagId, targetIds: [resourceId] }))
  return Result.success(tagId)
}

const applyTag = (tagId: Id, resourceId: Id): AppThunk<Result<Id>> => async (
  dispatch,
  gs
) => {
  const client = Session.selectClient(gs())
  await client.resources.tags.apply(tagId, [resourceId])
  dispatch(Resources.applySuccess({ tagId, targetIds: [resourceId] }))
  return Result.success(tagId)
}

const addBookmark = (
  resourceId: Id,
  name: string,
  rendererState: Partial<Potree.State>
): AppThunk<Result<Bookmark>> => async (dispatch, getState) => {
  try {
    const client = Session.selectClient(getState())
    const { fps, pointBudget, ...state } = rendererState
    const bookmark = await client.bookmarks.add(resourceId, { name, state })
    dispatch(actions.addBookmark({ resourceId, bookmark }))
    return Result.success(bookmark)
  } catch (e) {
    return Result.failure(e.message)
  }
}

type PatchBookmark = { name?: string; state?: Partial<Potree.State> }
const patchBookmark = (
  resourceId: Id,
  id: Id,
  { name, state: rendererState }: PatchBookmark
): AppThunk<Result<Bookmark>> => async (dispatch, getState) => {
  try {
    const client = Session.selectClient(getState())
    const state = rendererState ? { ...rendererState } : undefined
    if (state) {
      delete state.fps
      delete state.pointBudget
    }
    const bookmark = await client.bookmarks.patch(id, { name, state })
    dispatch(actions.patchBookmark({ resourceId, bookmark }))
    return Result.success(bookmark)
  } catch (e) {
    return Result.failure(e.message)
  }
}

const removeBookmark = (
  resourceId: Id,
  bookmarkId: Id
): AppThunk<Result<true>> => async (dispatch, getState) => {
  try {
    const client = Session.selectClient(getState())
    await client.bookmarks.remove(bookmarkId)
    dispatch(actions.removeBookmark({ resourceId, bookmarkId }))
    return Result.success(true)
  } catch (e) {
    return Result.failure(e.message)
  }
}

const thunks = {
  load,
  destroy,
  add,
  addExternal,
  rename,
  hydrate,
  hydrateByAlias,
  remove,
  removeMany,
  toggleTag,
  bookmarks: { add: addBookmark, patch: patchBookmark, remove: removeBookmark },
}

export const Resources = { ...actions, ...selectors, ...thunks, Tags }
export { reducer, tagReducer }
