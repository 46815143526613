import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityAdapter,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit'

import { Id, Tag } from '@landrush/common'
import { ialphaSort, Result } from '@landrush/util'

import { AppState, AppThunk, LazyState } from 'store'
import { Session } from 'store/session'

type CustomState = {
  loadState?: 'loading' | 'done'
  loadError?: string
  byTarget: { [targetId: string]: LazyState | undefined }
}

const adapter = createEntityAdapter<Tag>({
  sortComparer: (a, b) => ialphaSort(a.name, b.name),
})

const getAdapterOperations = (adapter: EntityAdapter<Tag>) => {
  const {
    addOne,
    addMany,
    setAll,
    removeOne,
    removeMany,
    updateOne,
    updateMany,
    upsertOne,
    upsertMany,
  } = adapter
  return {
    addOne,
    addMany,
    setAll,
    removeOne,
    removeMany,
    updateOne,
    updateMany,
    upsertOne,
    upsertMany,
  }
}

export const createTagSlice = (target: string) => {
  const initialState = adapter.getInitialState<CustomState>({ byTarget: {} })
  const slice = createSlice({
    name: `${target}-tags`,
    initialState,
    reducers: {
      loadRequest(state) {
        state.loadState = 'loading'
        state.loadError = undefined
      },
      loadFailure(state, action: PayloadAction<string>) {
        state.loadState = undefined
        state.loadError = action.payload
      },
      loadSuccess(state, action: PayloadAction<Tag[]>) {
        const tags = action.payload
        adapter.addMany(state, tags)
        state.loadState = 'done'
      },
      destroy() {
        return initialState
      },
      ...getAdapterOperations(adapter),
    },
  })

  return slice
}

type TagSlice = ReturnType<typeof createTagSlice>
type TagState = EntityState<Tag> & CustomState
type SelectTagSlice = (state: AppState) => TagState

type Target = 'regions' | 'resources'

export const createTagOperations = (
  target: Target,
  slice: TagSlice,
  selectSlice: SelectTagSlice
) => {
  const { actions } = slice
  const {
    loadRequest,
    loadFailure,
    loadSuccess,
    upsertOne,
    removeOne,
  } = actions

  const adapterSelectors = adapter.getSelectors(selectSlice)
  const { selectAll } = adapterSelectors
  const selectLoadState = createSelector(selectSlice, (s) => s.loadState)
  const selectLoadError = createSelector(selectSlice, (s) => s.loadError)

  const selectors = {
    ...adapterSelectors,
    selectLoadState,
    selectLoadError,
  }

  const load = (): AppThunk<Result<Tag[]>> => async (dispatch, gs) => {
    try {
      const loadState = selectLoadState(gs())
      if (loadState === 'done') return Result.success(selectAll(gs()))

      dispatch(loadRequest())
      const client = Session.selectClient(gs())
      const tags = await client[target].tags.list()
      dispatch(loadSuccess(tags))
      return Result.success(tags)
    } catch (e) {
      dispatch(loadFailure(e.message))
      return Result.failure(e.message)
    }
  }

  const add = (params: Tag.Add): AppThunk<Result<Tag>> => async (
    dispatch,
    gs
  ) => {
    try {
      const client = Session.selectClient(gs())
      const tag = await client[target].tags.add(params)
      dispatch(upsertOne(tag))
      return Result.success(tag)
    } catch (e) {
      return Result.failure(e.message)
    }
  }

  const update = (id: Id, params: Tag.Patch): AppThunk<Result<Tag>> => async (
    dispatch,
    gs
  ) => {
    try {
      const client = Session.selectClient(gs())
      const tag = await client[target].tags.patch(id, params)
      dispatch(upsertOne(tag))
      return Result.success(tag)
    } catch (e) {
      return Result.failure(e.message)
    }
  }

  const remove = (id: Id): AppThunk<Result<true>> => async (dispatch, gs) => {
    try {
      const client = Session.selectClient(gs())
      await client[target].tags.remove(id)
      dispatch(removeOne(id))
      return Result.success(true)
    } catch (e) {
      return Result.failure(e.message)
    }
  }

  const thunks = { load, add, update, remove }
  return { ...actions, ...selectors, ...thunks }
}
