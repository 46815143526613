import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ErrorMessage, InfoMessage, Message, Overview } from 'components'
import { Credentials } from 'store'

import CredentialDisplay from './credential'
import { CredentialState, useCredentialState } from './state'

export { useCredentialState }
export type { CredentialState }

const Body = () => {
  const dispatch = useDispatch()

  const error = useSelector(Credentials.selectError)
  const loadState = useSelector(Credentials.selectLoadState)
  const credentials = useSelector(Credentials.selectCredentials)

  const maybeLoad = () => {
    dispatch(Credentials.maybeLoad())
  }
  useEffect(maybeLoad, [])

  if (error) return <ErrorMessage onRetry={maybeLoad}>{error}</ErrorMessage>
  if (loadState === 'loading') return <Message spin>Loading...</Message>
  return (
    <>
      {credentials.length ? (
        credentials.map((c) => <CredentialDisplay key={c.id} credential={c} />)
      ) : (
        <div
          style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}
        >
          <InfoMessage>Nothing here</InfoMessage>
        </div>
      )}
    </>
  )
}

export default () => (
  <>
    <Overview page='credentials' create='/add-credential' />
    <Body />
  </>
)
