export const pipeline = JSON.stringify(
  [
    {
      type: 'writers.gdal',
      filename: '{{id}}.tif',
      resolution: '{{resolution}}',
      bounds: '{{bounds}}',
    },
  ],
  null,
  2
)
export const reducers = JSON.stringify(
  [
    { type: 'gdal.vrt', input: '*.tif', output: 'tiles.vrt' },
    {
      type: 'gdal.dem',
      mode: 'hillshade',
      input: 'tiles.vrt',
      output: 'hillshade.tif',
    },
    {
      type: 'gdal.translate',
      args: ['-of', 'PNG'],
      input: 'hillshade.tif',
      output: 'hillshade.png',
    },
  ],
  null,
  2
)
