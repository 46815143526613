import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCog,
  faInfoCircle,
  faMapMarkerAlt,
  faRocket,
} from '@fortawesome/free-solid-svg-icons'

import { Color } from 'components'

type Icon = 'cog' | 'info-circle' | 'map-marker-alt' | 'rocket'
function getIcon(i: Icon) {
  switch (i) {
    case 'cog':
      return faCog
    case 'info-circle':
      return faInfoCircle
    case 'map-marker-alt':
      return faMapMarkerAlt
    case 'rocket':
      return faRocket
  }
}

type StyledIcon = { $isActive: boolean }
const StyledIcon = styled(FontAwesomeIcon)(
  ({ $isActive }: StyledIcon) => `
  color: ${$isActive ? Color.landrushOrange : Color.lightGray};
  &:hover {
    color: ${$isActive ? Color.landrushOrange : Color.darkGray};
  }
`
)

export type Control = { icon: Icon; to: string; isActive: boolean }
export const Control = ({ icon, to, isActive }: Control) => (
  <div className='control-container'>
    <Link to={to}>
      <StyledIcon
        icon={getIcon(icon)}
        $isActive={isActive}
        className='control'
      />
    </Link>
  </div>
)
