import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Color } from 'components'

type StyledIcon = { $isActive: boolean }
export const StyledIcon = styled(FontAwesomeIcon)(
  ({ $isActive }: StyledIcon) => `
  color: ${$isActive ? Color.landrushOrange : Color.darkGray};
`
)
