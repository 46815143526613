import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Color } from './color'
import { Style } from './style'

type WithStyle = { style?: React.CSSProperties }
const Control: React.FC<WithStyle> = ({ children, style }) => (
  <div style={{ paddingLeft: 48, fontSize: 20, ...style }}>{children}</div>
)

type ControlIcon = { isEnabled?: boolean; hoverColor?: string }
const ControlIcon = styled(FontAwesomeIcon)(
  ({
    isEnabled: $isEnabled = true,
    hoverColor: $hoverColor = Color.landrushOrange,
  }: ControlIcon) => ({
    ...($isEnabled
      ? { '&:hover': { cursor: 'pointer', color: $hoverColor } }
      : Style.unclickable),
  })
)
export { Control, ControlIcon }
