import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons'

import { Color, TextArea } from 'components'
import { Json } from 'utils'

import { Options } from './options'
import { EditingSettings } from './types'

export const EditSettings = ({
  history,
  version,
  text,
  setText,
  submit,
  isPending,
  error,
  dismissError,
}: EditingSettings) => {
  const pipeline = Json.isValid(text.pipeline)
    ? JSON.parse(text.pipeline)
    : undefined
  const reducers = Json.isValid(text.reducers)
    ? JSON.parse(text.reducers)
    : undefined

  return (
    <Styles>
      <div className='left'>
        <h3>Pipeline</h3>
        <div className='editor'>
          <TextArea
            value={text.pipeline}
            setValue={(pipeline) => setText({ ...text, pipeline })}
          />
          <Formatter
            isValid={Boolean(pipeline)}
            format={() =>
              setText({ ...text, pipeline: Json.format(text.pipeline) })
            }
          />
        </div>

        <h3>Reducers</h3>
        <div className='editor'>
          <TextArea
            value={text.reducers}
            setValue={(reducers) => setText({ ...text, reducers })}
          />
          <Formatter
            isValid={Boolean(reducers)}
            format={() =>
              setText({ ...text, reducers: Json.format(text.reducers) })
            }
          />
        </div>
      </div>
      <div className='right'>
        <Options
          history={history}
          version={version}
          text={text}
          setText={setText}
          submit={submit}
          isPending={isPending}
          error={error}
          dismissError={dismissError}
        />
      </div>
    </Styles>
  )
}

const Styles = styled.div`
  display: flex;

  .left {
    flex: 1 1 0;
    margin-right: 8px;
  }
  .right {
    flex: 1 1 0;
    margin-left: 8px;
  }

  .editor {
    position: relative;
    margin-bottom: 16px;
  }

  .format-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 24px;
  }
  .valid {
    color: ${Color.successGreen};
    cursor: pointer;
  }
  .invalid {
    color: ${Color.dangerRed};
  }
`

type Formatter = { isValid: boolean; format(): void }
const Formatter: React.FC<Formatter> = ({ isValid, format }) =>
  isValid ? (
    <FontAwesomeIcon
      icon={faCheckCircle}
      title='Format JSON'
      className='format-icon valid'
      onClick={format}
    />
  ) : (
    <FontAwesomeIcon
      icon={faExclamationCircle}
      className='format-icon invalid'
    />
  )
