import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faExclamation,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'

import { Resource } from '@landrush/common'
import { Result, dateString, substitute, timeString } from '@landrush/util'

import { Button, Color, ErrorMessage, List } from 'components'
import {
  AddExecutionsState as S,
  Executions,
  Regions,
  RegionState,
  Resources,
  ResourceState,
  Workflows,
} from 'store'
import {
  useAppDispatch,
  useAppSelector,
  useAsync,
  useForwardingSelector,
} from 'utils'

type Row = {
  title: string
  badge?: string
  page: S.Page
  isValid: boolean
  isPossible: boolean
}
const Row: React.FC<Row> = ({ title, badge, page, isValid, isPossible }) => {
  const dispatch = useAppDispatch()
  const activate = () => dispatch(S.setPage(page))
  const isActive = useAppSelector(S.selectPage) === page

  return (
    <StyledRow
      $isActive={isActive}
      $isValid={isValid}
      $isPossible={isPossible}
      onClick={activate}
    >
      <div className='nav-title-container'>
        <span className='nav-title'> {title}</span>
        {Boolean(badge) && <span className='nav-badge'>{badge}</span>}
      </div>
      <div className='status-icon-container'>
        <FontAwesomeIcon
          className='status-icon'
          icon={isValid ? faCheck : isPossible ? faCircle : faExclamation}
        />
      </div>
    </StyledRow>
  )
}
type StyledRow = { $isActive: boolean; $isValid: boolean; $isPossible: boolean }
const StyledRow = styled(List.Row)(
  ({ $isActive, $isValid, $isPossible }: StyledRow) => `
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  color: ${$isActive ? Color.veryDarkGray : Color.lightGray};
  background-color: ${$isActive ? '#ececec' : undefined};

  &:hover {
    color: ${$isActive ? Color.veryDarkGray : Color.darkGray};
    background-color: ${$isActive ? '#ececec' : '#f2f2f2'};
  }
  .nav-title-container {
    display: flex;
    align-items: center;
  }
  .nav-title {
    font-weight: ${$isActive ? 'bold' : 'normal'};
  }
  .nav-badge {
    font-size: 10px;
    margin-left: 8px;
    background-color: ${Color.gray};
    color: white;
    border-radius: 10px;

    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .status-icon-container {
    display: flex;
    justify-content: center;
    width: 24px;
  }

  .status-icon {
    color: ${
      $isValid
        ? Color.landrushOrange
        : $isPossible
        ? Color.dangerYellow
        : Color.dangerRed
    };
    font-size: ${$isValid || !$isPossible ? 'default' : '10px'};
  }
`
)

const Buttons = () => {
  const dispatch = useAppDispatch()
  const isAllValid = useAppSelector(S.selectAllValid)

  const name = useAppSelector(S.selectName)
  const resourcesRecord = useAppSelector(ResourceState.selectSelectedResources)
  const regionsRecord = useAppSelector(RegionState.selectSelectedRegions)
  const workflowId = useAppSelector(S.selectWorkflowId)
  const context = useAppSelector(S.selectCoercedContext)

  const [
    { isPending, error, dismissError, value },
    submit,
  ] = useAsync(async () => {
    if (!workflowId) throw new Error('Missing workflow')

    const now = new Date()
    const nameWithDates = substitute(name, {
      date: dateString(now),
      time: timeString(now),
    })

    console.log('Name:', name)

    const result = await dispatch(
      Executions.addMany({
        name: nameWithDates,
        resourceIds: Object.keys(resourcesRecord),
        regionIds: Object.keys(regionsRecord),
        workflowId: workflowId,
        context,
      })
    )
    if (Result.isFailure(result)) throw new Error(result.error)
    return result.value
  }, [name, resourcesRecord, regionsRecord, workflowId, context])

  if (value) return <Redirect to='/executions' />

  return (
    <div className='nav-launch-button-container'>
      {error && <ErrorMessage onDismiss={dismissError}>{error}</ErrorMessage>}
      {isPending ? (
        <Button className='nav-cancel-button' isEnabled={false}>
          Cancel
        </Button>
      ) : (
        <Link to='/executions'>
          <Button className='nav-cancel-button'>Cancel</Button>
        </Link>
      )}
      <Button
        className='nav-launch-button'
        isEnabled={isAllValid}
        spin={isPending}
        onClick={submit}
      >
        Launch
      </Button>
    </div>
  )
}

const Controls = () => {
  const dispatch = useAppDispatch()
  const prev = () => dispatch(S.goToPreviousPage())
  const next = () => dispatch(S.goToNextPage())

  const [isInfoOpen, setInfoOpen] = useState(false)
  const toggleInfo = () => setInfoOpen((v) => !v)

  return (
    <ControlStyles>
      <div className='nav-control-container'>
        <FontAwesomeIcon
          className='nav-control'
          icon={faChevronLeft}
          onClick={prev}
        />
        <FontAwesomeIcon
          className='nav-control'
          icon={faChevronRight}
          onClick={next}
        />
        <FontAwesomeIcon
          className='nav-control'
          icon={faInfoCircle}
          onClick={toggleInfo}
        />
      </div>
      {isInfoOpen && (
        <div className='nav-info'>
          Compose a combination of point cloud data, regions of interest, and
          workflow settings to generate products.
        </div>
      )}
      <Buttons />
    </ControlStyles>
  )
}
const ControlStyles = styled.div`
  .nav-launch-button-container {
    text-align: center;
    margin-top: 8px;
  }
  .nav-cancel-button {
    margin-right: 4px;
    background-color: ${Color.lightGray};
    &:hover {
      background-color: ${Color.dangerRed};
    }
  }
  .nav-launch-button {
    margin-left: 4px;
  }

  .nav-control-container {
    display: flex;
    justify-content: center;
  }

  .nav-control {
    cursor: pointer;
    font-size: 24px;
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: 24px;
    margin-right: 24px;
    color: ${Color.gray};
    &:hover {
      color: ${Color.darkGray};
    }
  }

  .nav-info {
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 12px;
    font-size: small;
    color: ${Color.gray};
    text-align: center;
  }
`

export default () => {
  const isWaiting = useAppSelector(S.selectLoadState) !== 'done'

  const selectedResourceCount = useAppSelector(
    ResourceState.selectSelectedResourcesCount
  )
  const selectedResources = useAppSelector(
    ResourceState.selectSelectedResources
  )
  const isResourcePossible = useAppSelector(Resources.selectCount) > 0
  const isResourceValid = useAppSelector(S.selectResourcesValid)

  const selectedRegionCount = useAppSelector(
    RegionState.selectSelectedRegionsCount
  )
  const isRegionPossible = useAppSelector(Regions.selectCount) > 0
  const isRegionValid = useAppSelector(S.selectRegionsValid)

  const isWorkflowPossible = useAppSelector(Workflows.selectTotal) > 0
  const isWorkflowValid = useAppSelector(S.selectWorkflowValid)
  const isContextValid = useAppSelector(S.selectContextValid)

  const workflows = useAppSelector(Workflows.selectAll)
  const workflowId = useAppSelector(S.selectWorkflowId)
  const workflow = workflowId
    ? workflows.find((v) => v.id === workflowId)
    : undefined

  const singleResourceId =
    selectedResourceCount === 1 ? Object.keys(selectedResources)[0] : undefined
  const singleResource: Resource.Summary | undefined = useForwardingSelector(
    Resources.selectManyById,
    singleResourceId ? [singleResourceId] : []
  )[0]

  return (
    <Styles>
      <List.Container className='nav-list-container'>
        <Row
          title='Resources'
          page='resources'
          badge={
            selectedResourceCount > 0
              ? singleResource?.name || selectedResourceCount.toLocaleString()
              : undefined
          }
          isValid={isResourceValid}
          isPossible={isWaiting || isResourcePossible}
        />
        <Row
          title='Regions'
          page='regions'
          badge={
            selectedRegionCount > 0
              ? selectedRegionCount.toLocaleString()
              : undefined
          }
          isValid={isRegionValid}
          isPossible={isWaiting || isRegionPossible}
        />
        <Row
          title='Workflow'
          page='workflows'
          badge={workflow?.name}
          isValid={isWorkflowValid && isContextValid}
          isPossible={isWaiting || isWorkflowPossible}
        />
        <Row
          title='Settings'
          page='settings'
          isValid={true}
          isPossible={true}
        />
      </List.Container>
      <Controls />
    </Styles>
  )
}
const Styles = styled.div`
  border: 1px solid ${Color.veryLightGray};
  border-radius: 4px;

  .nav-list-container {
  }
`
