import React from 'react'
import styled from 'styled-components'

import { Id, Tag } from '@landrush/common'

import { Tag as TagDisplay } from './tag'

type InlineTagList = {
  tags: Tag[]
  onClick?(id: Id): void
}
export const InlineTagList: React.FC<InlineTagList> = ({ tags, onClick }) => {
  return (
    <Styles>
      {tags.map((tag) => (
        <TagDisplay
          className='taggable-tag'
          key={tag.id}
          {...tag}
          onClick={onClick ? () => onClick(tag.id) : undefined}
        />
      ))}
    </Styles>
  )
}

const Styles = styled.span`
  display: inline-block;

  .taggable-tag {
    margin-right: 6px;
    /* margin-bottom: 3px; */
  }
`
