import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { Color } from './color'
import { Style } from './style'

const verticalPadding = 8
const horizontalPadding = 20

export const StyledTable = styled.table`
  width: 100%;

  tbody > tr {
    :hover {
      background-color: #f2f2f2;
    }
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th,
  td {
    width: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid ${Color.veryLightGray};
    color: ${Color.veryDarkGray};

    :first-child {
      padding-left: 20px;
    }
    :last-child {
      padding-right: 20px;
    }
  }
`

type DataHeader = { columns: React.ReactNode[] }
export const DataHeader = ({ columns }: DataHeader) => (
  <thead>
    <tr>
      {columns.map((col, i) => (
        <th key={i}>{col}</th>
      ))}
    </tr>
  </thead>
)

type WithStyle = { style?: React.CSSProperties }
export const DataTable: React.FC<WithStyle> = ({ children, style }) => (
  <StyledTable style={style}>{children}</StyledTable>
)

// TODO: The rest of this should be removed once the rest of the codebase is
// updated to the new styled-component-based API.

type CellStyle = {
  first: boolean
  last: boolean
  style?: React.CSSProperties
}
const cellStyle = ({ first, last, style }: CellStyle) => ({
  paddingTop: verticalPadding,
  paddingBottom: verticalPadding,
  ...(first
    ? { paddingLeft: horizontalPadding, ...Style.alignLeft }
    : undefined),
  ...(last
    ? { paddingRight: horizontalPadding, ...Style.alignRight }
    : undefined),
  ...style,
})

type Cell = {
  first?: boolean
  last?: boolean
  style?: React.CSSProperties
  children?: ReactNode
  [rest: string]: any
}
export const Th = ({
  children,
  first = false,
  last = false,
  style,
  ...rest
}: Cell) => (
  <th style={cellStyle({ first, last, style })} {...rest}>
    {children}
  </th>
)

export const Td = ({
  children,
  first = false,
  last = false,
  style,
  ...rest
}: Cell) => (
  <td style={cellStyle({ first, last, style })} {...rest}>
    {children}
  </td>
)

type RowStyle = {
  hover: boolean
  enabled: boolean
  topless: boolean
}
export const border = `1px solid ${Color.veryLightGray}`
export const rowStyle = ({ hover, enabled, topless }: RowStyle) => ({
  ...(hover && enabled
    ? { '&:hover': { backgroundColor: '#eee' } }
    : undefined),
  ...(enabled
    ? undefined
    : { backgroundColor: Color.disabled, color: Color.gray }),
  ...(topless ? undefined : { borderTop: border }),
})

type RowProps = {
  hover?: boolean
  enabled?: boolean
  topless?: boolean
  style?: React.CSSProperties
  children: ReactNode
  [rest: string]: any
}

// These are mostly no-op pass-throughs at the moment, but we'll expose them as
// exports so we have the ability to modify their behavior later.
export const Row = ({
  children,
  style,
  hover = true,
  enabled = true,
  topless = false,
  ...rest
}: RowProps) => (
  <tr style={{ ...rowStyle({ hover, enabled, topless }), ...style }} {...rest}>
    {children}
  </tr>
)
export const TRow = Row

type ComponentProps = {
  children: ReactNode
  [rest: string]: any
}
export const Head = ({ children, ...rest }: ComponentProps) => (
  <thead {...rest}>{children}</thead>
)
export const THead = Head

export const Body = ({ children, ...rest }: ComponentProps) => (
  <tbody {...rest}>{children}</tbody>
)
export const TBody = Body

export const Table = ({ children, style, ...rest }: ComponentProps) => (
  <StyledTable style={{ width: '100%', ...style }} {...rest}>
    <table>{children}</table>
  </StyledTable>
)
