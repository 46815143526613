import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey } from '@fortawesome/free-solid-svg-icons'

import { Color } from 'components'

type Container = { $isEnabled: boolean }
const Container = styled.div(
  ({ $isEnabled }: Container) => `
  display: flex;
  align-items: center;
  color: ${$isEnabled ? Color.gray : Color.veryLightGray};
  cursor: default;
`
)

type Props = { name: string; isEnabled?: boolean }
export function CredentialProfileIcon({ name, isEnabled = true }: Props) {
  return (
    <Container $isEnabled={isEnabled}>
      <FontAwesomeIcon icon={faKey} />
      <div style={{ marginLeft: 8 }}>{name}</div>
    </Container>
  )
}
