import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import ConfirmRegistration from 'confirm-registration'
import ForgotPassword from 'forgot-password'
import Home from 'outside-home'
import Login from 'login'
import ResetPassword from 'reset-password'
import Signup from 'signup'
import SharedView from 'shared-view'

const RedirectToHome = () => <Redirect to='/' />

export default function () {
  return (
    <Switch>
      <Route exact path='/' component={Home} />
      <Route exact path='/signup' component={Signup} />
      <Route exact path='/login' component={Login} />
      <Route exact path='/confirm' component={ConfirmRegistration} />
      <Route exact path='/forgot-password' component={ForgotPassword} />
      <Route exact path='/reset-password' component={ResetPassword} />
      <Route exact path='/v/:token' component={SharedView} />
      <Route component={RedirectToHome} />
    </Switch>
  )
}
