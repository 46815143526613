import React, { useEffect, useRef, useState, useContext } from 'react'
import { useMount } from 'react-use'

import { Cesium, Potree } from 'renderers'

type FullState = { renderer: Potree.Renderer; state: Potree.State }
const StateContext = React.createContext<FullState>(undefined as any)

function applyAll(renderer: Potree.Renderer, state: Potree.State) {
  const {
    position: pos,
    target: tgt,
    // rotation,

    background,
    isEdlEnabled,
    edlRadius,
    edlStrength,
    elevationRange: era,
    intensityRange: ira,
    fps,
    pointBudget,
    pointColor,
    // pointShape,
    pointSize,
    // pointSizeMethod,
  } = state

  renderer.setCamera(pos, tgt)

  renderer.setBackground(background)
  renderer.setFps(fps)

  renderer.setEdlEnabled(isEdlEnabled)
  renderer.setEdlRadius(edlRadius)
  renderer.setEdlStrength(edlStrength)

  renderer.setPointSize(pointSize)
  renderer.setPointBudget(pointBudget)
  renderer.setPointColor(pointColor)

  renderer.setIntensityRange(ira)
  renderer.setElevationRange(era)
}

type PointCloud = { name: string; endpoint: string }
export const PointCloud = ({ name, endpoint }: PointCloud) => {
  const { renderer, state } = useContext(StateContext)

  useMount(() => {
    async function loadPointCloud() {
      try {
        await renderer.loadPointCloud(endpoint, name)

        // We need to do this because settings like point color are applied
        // per pointcloud.
        applyAll(renderer, state)
      } catch (e) {
        console.log('Failed to load point cloud:', e)
      }
    }
    loadPointCloud()
  })

  // TODO: useUnmount(() => renderer.removePointCoud())

  return null
}

type Listener = {
  renderer: Potree.Renderer
  state: Potree.State
}
const Listener = ({ renderer: r, state }: Listener) => {
  const {
    // position: pos,
    // target: tgt,

    background,
    isEdlEnabled,
    edlRadius,
    edlStrength,
    elevationRange: era,
    intensityRange: ira,
    fps,
    pointBudget,
    pointColor,
    // pointShape,
    pointSize,
    // pointSizeMethod,
    clipType,
  } = state

  useMount(() => applyAll(r, state))

  // useEffect(() => { renderer.setCamera(pos, tgt) }, [pos, tgt])

  useEffect(() => r.setBackground(background), [r, background])
  useEffect(() => r.setFps(fps), [r, fps])

  useEffect(() => r.setEdlEnabled(isEdlEnabled), [r, isEdlEnabled])
  useEffect(() => r.setEdlRadius(edlRadius), [r, edlRadius])
  useEffect(() => r.setEdlStrength(edlStrength), [r, edlStrength])

  useEffect(() => r.setPointSize(pointSize), [r, pointSize])
  useEffect(() => r.setPointBudget(pointBudget), [r, pointBudget])
  useEffect(() => r.setPointColor(pointColor), [r, pointColor])
  // TODO: Point shape.
  // TODO: Point size method.

  useEffect(() => r.setIntensityRange(ira), [r, ira])
  useEffect(() => r.setElevationRange(era), [r, era])

  useEffect(() => r.setClipType(clipType), [r, clipType])

  return null
}

type Renderer = {
  state: Potree.State
  onMount: (r: Potree.Renderer) => void
  onMove: Potree.OnMove
  children?: React.ReactNode
}
export const Renderer = ({ state, onMove, onMount, children }: Renderer) => {
  const ref = useRef<HTMLDivElement>(null)
  const [renderer, setRenderer] = useState<Potree.Renderer>()

  useEffect(() => {
    if (!ref.current) return
    const renderer = Potree.Renderer.create(ref.current, onMove)
    setRenderer(renderer)
    onMount(renderer)
    return () => renderer.destroy()
  }, [ref, onMount, onMove])

  return (
    <div ref={ref} style={{ display: 'flex', flexGrow: 1 }}>
      {renderer ? (
        <StateContext.Provider value={{ renderer, state }}>
          <Listener renderer={renderer} state={state} />
          {children}
        </StateContext.Provider>
      ) : null}
    </div>
  )
}

type Terrain = { toLonLat: proj4.Converter }
export const Terrain = ({ toLonLat }: Terrain) => {
  const ref = useRef<HTMLDivElement>(null)
  const { renderer } = useContext(StateContext)

  useEffect(() => {
    if (!ref.current) return
    const cesium = Cesium.create(ref.current)
    cesium.syncTo(renderer, toLonLat)
    return cesium.destroy
  }, [ref, renderer, toLonLat])

  const style = {
    position: 'relative' as const,
    width: '100%',
    height: '100%',
    zIndex: -1,
  }
  return <div ref={ref} style={style} />
}

/*
type Volume = { onChange(bounds: Bounds): void }
export const Volume: React.FC<Volume> = ({ onChange }) => {
  const { renderer } = useContext(StateContext)
  useEffect(() => renderer.addVolume(onChange), [renderer, onChange])
  return null
}

type FixedVolume = { bounds: Bounds }
export const FixedVolume: React.FC<FixedVolume> = ({ bounds }) => {
  const { renderer } = useContext(StateContext)
  useEffect(() => renderer.setVolume(bounds), [renderer, bounds])
  return null
}
*/
