import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'

import { Tag } from '@landrush/common'

import {
  Color,
  DeleteIcon,
  EditTag,
  ErrorMessage,
  Tag as TagDisplay,
  List as ItemList,
} from 'components'
import { useAsync } from 'utils'

type Row = {
  tag: Tag
  removeTag(): Promise<true>
  updateTag(params: Tag.Patch): Promise<Tag>
}
const Row: React.FC<Row> = ({
  tag: { id, name, color, description },
  removeTag,
  updateTag,
}) => {
  const [isEditing, setEditing] = useState(false)
  const stopEditing = () => setEditing(false)
  const [removeState, onRemove] = useAsync(removeTag)
  const [updateState, onUpdate] = useAsync(updateTag)

  useEffect(() => updateState.value && stopEditing(), [updateState.value])

  return (
    <Styles>
      {isEditing ? (
        <div className='manage-tag-edit-container'>
          <EditTag
            name={name}
            color={color}
            description={description}
            isPending={updateState.isPending}
            onSubmit={onUpdate}
            onCancel={stopEditing}
          />
          {updateState.error && (
            <ErrorMessage
              style={{ marginTop: 8 }}
              onDismiss={updateState.dismissError}
            >
              {updateState.error}
            </ErrorMessage>
          )}
        </div>
      ) : (
        <div className='manage-tag-control-row'>
          <div className='manage-tag-row-left'>
            <div className='manage-tag-name'>
              <TagDisplay name={name} color={color} />
            </div>
            <div className='manage-tag-description'>{description}</div>
          </div>
          <div className='manage-tag-row-right'>
            <FontAwesomeIcon
              className='manage-tag-edit-icon'
              icon={faPen}
              onClick={() => setEditing(true)}
            />
            <DeleteIcon
              onClick={onRemove}
              isPending={removeState.isPending}
              error={removeState.error}
              dismissError={removeState.dismissError}
            />
          </div>
        </div>
      )}
    </Styles>
  )
}

const Styles = styled(ItemList.Row)`
  overflow-y: hidden;

  .manage-tag-control-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .manage-tag-row-left {
    display: flex;
    align-items: center;
  }

  .manage-tag-name {
    margin-right: 8px;
  }

  .manage-tag-description {
    color: ${Color.darkGray};
    font-size: small;
  }

  .manage-tag-row-right {
    display: flex;
    align-items: center;
  }

  .manage-tag-edit-icon {
    cursor: pointer;
    color: ${Color.lightGray};
    &:hover {
      color: ${Color.darkGray};
    }
    margin-right: 42px;
  }
`

export default Row
