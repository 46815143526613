import React, { ReactNode } from 'react'
import Media from 'react-media'

import { Footer } from './footer'
import { Header } from './header'
import { Measures } from './measures'

type Wrapper = { children?: ReactNode }
export const Wrapper = ({ children }: Wrapper) => {
  return (
    <>
      <Header />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Media query={{ minWidth: 767 }}>
          {(matches) => (
            <div
              style={{
                width: matches ? '85%' : '95%',
                marginTop: Measures.bodyMarginTop,
                marginBottom: Measures.bodyMarginBottom,
              }}
            >
              {children}
            </div>
          )}
        </Media>
      </div>
      <Footer />
    </>
  )
}
