import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faPen, faScroll } from '@fortawesome/free-solid-svg-icons'

import { Color } from 'components'

import { WithPage } from './types'

type WithEditing = {
  isEditing: boolean
  toggleEditing(): void
  isEditable?: boolean
}

type Controls = WithPage & WithEditing
export const Controls = ({
  isEditing,
  toggleEditing,
  isEditable = true,
  page,
  setPage,
}: Controls) => {
  return (
    <Styles>
      <PageIcon
        fixedWidth
        icon={faScroll}
        onClick={() => setPage('readme')}
        $isActive={page === 'readme'}
        title='Readme'
      />
      <PageIcon
        fixedWidth
        icon={faCog}
        onClick={() => setPage('settings')}
        $isActive={page === 'settings'}
        title='Settings'
      />
      <PageIcon
        fixedWidth
        icon={faPen}
        onClick={isEditable ? toggleEditing : undefined}
        $isActive={isEditing}
        $isEnabled={isEditable}
        title={
          isEditing
            ? 'Cancel edit'
            : isEditable
            ? 'Edit content'
            : 'Cannot edit previous versions'
        }
      />
    </Styles>
  )
}
const Styles = styled.div`
  font-size: 22px;
  text-align: right;
  svg {
    margin-left: 12px;
  }
`

type PageIcon = { $isActive: boolean; $isEnabled?: boolean }
const PageIcon = styled(FontAwesomeIcon)(
  ({ $isActive, $isEnabled = true }: PageIcon) =>
    $isActive
      ? `
      color: ${Color.landrushOrange};
      &:hover { cursor: pointer; }
    `
      : $isEnabled
      ? `
      color: ${Color.gray};
      &:hover { cursor: pointer; color: ${Color.darkGray}; }
    `
      : `
      color: ${Color.veryLightGray};
    `
)
