import React from 'react'
import ErrorMessage from './error-message'

function logErrorToMyService(_error: Error, _errorInfo: React.ErrorInfo) {
  // TODO: Send to slack.
}

type State = { error?: string }
export class ErrorBoundary extends React.Component {
  state: State
  constructor(props: object) {
    super(props)
    this.state = {}
  }

  static getDerivedStateFromError(e: Error) {
    return { error: e.message || 'An error occurred' }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    logErrorToMyService(error, errorInfo)
  }

  render() {
    const { error } = this.state
    const { children } = this.props
    return error ? <ErrorMessage>{error}</ErrorMessage> : children
  }
}
