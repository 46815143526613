import * as Browser from './browser'

export * from './async-result'
export { Browser }
export { Fetch } from './fetch'
export * as Json from './json'
export { LocalStorage } from './localstorage'
export { Proj } from './proj'
export * from './use-async'
export * from './hooks'
export * from './slice-state'
export { usePrevious } from './use-previous'
export * from './use-visible-targets'
export * from './use-window-width'

export function getOrigin() {
  return (
    window.LANDRUSH_API_ROOT ||
    process.env.REACT_APP_API_URL ||
    window.location.origin
  )
}
