import React from 'react'
import { useSelector } from 'react-redux'

import { Bounds } from '@landrush/entwine'
import { Task } from '@landrush/common'

import { Ol, Color } from 'components'
import { Staging } from 'store'
import { Proj } from 'utils'

type Mappable = { ll: Bounds; name: string }

export default () => {
  const items = useSelector(Staging.selectItems)

  const mappable = items.reduce<Mappable[]>((agg, item) => {
    const { identifier, info } = item
    if (!info || !Task.isSuccess(info)) return agg

    const { bounds, srs } = info
    const defString = Proj.getDefString(srs)
    if (!defString) return agg

    let ll = Proj.reprojectBounds(bounds, defString)
    if (ll.length === 6) ll = [ll[0], ll[1], ll[3], ll[4]]
    return [...agg, { ll, name: identifier.path }]
  }, [])

  if (!mappable.length) return null

  const collection = mappable.reduce<GeoJSON.FeatureCollection>(
    (collection, { ll: [minx, miny, maxx, maxy], name }) => {
      collection.features.push({
        type: 'Feature',
        // If there's only one thing staged, don't overlay its name.
        properties: { name: collection.features.length > 1 ? name : undefined },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [minx, miny],
              [maxx, miny],
              [maxx, maxy],
              [minx, maxy],
              [minx, miny],
            ],
          ],
        },
      })
      return collection
    },
    { type: 'FeatureCollection', features: [] }
  )

  return (
    <Ol.Map>
      <Ol.Collection collection={collection} />
    </Ol.Map>
  )
}
