import React from 'react'

import { Color } from './color'

type Props = {
  index: number
  total: number
  striped?: boolean
  animated?: boolean
  explicit?: boolean
  style?: React.CSSProperties
  className?: string
}
export const Progress = ({
  index,
  total,
  striped = false,
  animated = false,
  explicit = false,
  style,
  className,
}: Props) => {
  const percentage = Math.max(
    2,
    Math.min(98, Math.round((index / total) * 100))
  )

  let classes = 'progress-bar'
  if (striped) classes += ' progress-bar-striped'
  if (animated) classes += ' progress-bar-animated'

  return (
    <div className={`progress ${className}`} style={{ height: 18, ...style }}>
      <div
        className={classes}
        role='progressbar'
        style={{
          width: `${percentage}%`,
          backgroundColor: Color.landrushOrange,
        }}
      >
        {explicit ? `${percentage}%` : null}
      </div>
    </div>
  )
}

export default Progress
