import React from 'react'
import { useSelector } from 'react-redux'

import { Color, Measures } from 'components'
import { Viewer } from 'store'

export const Wrapper: React.FC = ({ children }) => {
  const isFullscreen = useSelector(Viewer.selectFullscreen)
  const style = isFullscreen
    ? {
        position: 'fixed' as const,
        left: 0,
        right: 0,
        top: Measures.headerHeight,
        bottom: Measures.footerHeight,
      }
    : {
        position: 'relative' as const,
        width: '100%',
        height: 0,
        paddingBottom: '62%',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderColor: Color.veryLightGray,
        borderStyle: 'solid',
      }

  return (
    <div style={style}>
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'stretch',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default Wrapper
