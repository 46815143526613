import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Execution, Id } from '@landrush/common'

import { GetSliceState } from 'utils'

import { adapter, normalize } from './types'

const initialState = adapter.getInitialState({ isListed: false })
const slice = createSlice({
  name: 'executions',
  initialState,
  reducers: {
    setListed(state) {
      state.isListed = true
    },
    upsert(state, { payload }: PayloadAction<Execution.Summary[]>) {
      adapter.upsertMany(state, payload.map(normalize))
    },
    update(state, { payload }: PayloadAction<Execution.Update[]>) {
      adapter.updateMany(
        state,
        payload.map((changes) => ({ id: changes.id, changes }))
      )
    },
    remove(state, { payload }: PayloadAction<Id[]>) {
      adapter.removeMany(state, payload)
    },
    destroy() {
      return initialState
    },
  },
})

export const { actions, reducer } = slice
export type Reducer = typeof reducer
export type Slice = GetSliceState<Reducer>
