import React from 'react'

import { Id, Tag } from '@landrush/common'

import { Color } from 'components'

import Row from './row'

type List = {
  tags: Tag[]
  updateTag(id: Id, params: Tag.Patch): Promise<Tag>
  removeTag(id: Id): Promise<true>
}
export const List: React.FC<List> = ({ tags, removeTag, updateTag }) => {
  if (!tags.length) return null

  return (
    <div style={{ borderTop: `1px solid ${Color.veryLightGray}` }}>
      {tags.map((tag) => (
        <Row
          key={tag.id}
          tag={tag}
          updateTag={(params) => updateTag(tag.id, params)}
          removeTag={() => removeTag(tag.id)}
        />
      ))}
    </div>
  )
}

export default List
