import { useMount } from 'react-use'
import styled from 'styled-components'

import { Copy, Task } from '@landrush/common'
import { dateString, timeString } from '@landrush/util'

import {
  Color,
  DeleteIcon,
  ErrorMessage,
  InfoMessage,
  List as ItemList,
  Message,
  Overview,
  Spaced,
  TaskStatus,
} from 'components'
import { Copies } from 'store'
import { useAppSelector, useAppDispatch, useAsyncResult } from 'utils'

const displayDate = (d: Date) => `${dateString(d)} ${timeString(d)}`

type WithCopy = { copy: Copy }
const Row: React.FC<WithCopy> = ({ copy }) => {
  const dispatch = useAppDispatch()
  const { id, output, createdAt } = copy

  const [{ isPending, error, dismissError }, remove] = useAsyncResult(() =>
    dispatch(Copies.remove(id))
  )

  return (
    <StyledRow>
      <Spaced>
        {output}
        <div className='right'>
          {!Task.isRunning(copy) && (
            <div className='time'>{displayDate(createdAt)}</div>
          )}
          <div className='task-status'>
            <TaskStatus task={copy} />
          </div>
          <DeleteIcon
            className='delete-icon'
            onClick={remove}
            isPending={isPending}
            error={error}
            dismissError={dismissError}
          />
        </div>
      </Spaced>
    </StyledRow>
  )
}
const StyledRow = styled(ItemList.Row)`
  .right {
    display: flex;
    align-items: center;
  }

  .time {
    color: ${Color.lightGray};
    &:hover {
      color: ${Color.gray};
    }
  }

  .task-status {
    margin-left: 24px;
  }

  .delete-icon {
    margin-left: 24px;
  }
`

const List = () => {
  const copies = useAppSelector(Copies.selectAll)

  if (!copies.length) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <InfoMessage>Nothing here</InfoMessage>
      </div>
    )
  }

  return (
    <ItemList.Container
      style={{ borderTop: `1px solid ${Color.veryLightGray}` }}
    >
      {copies.map((copy) => (
        <Row key={copy.id} copy={copy} />
      ))}
    </ItemList.Container>
  )
}

const Body = () => {
  return (
    <>
      <Overview page='executions' />
      <h3 style={{ paddingTop: 32 }}>Copies</h3>
      <List />
    </>
  )
}

export default () => {
  const dispatch = useAppDispatch()

  const isListed = useAppSelector(Copies.selectIsListed)
  const [{ error }, list] = useAsyncResult(() => dispatch(Copies.list()))
  useMount(list)

  if (error) return <ErrorMessage onRetry={list}>{error}</ErrorMessage>
  if (!isListed) return <Message spin>Loading...</Message>
  return <Body />
}
