async function loadScript(src: string) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.addEventListener('load', resolve)
    script.addEventListener('error', reject)
    document.body.appendChild(script)
  })
}

const scripts = [
  '/lib/jquery/3.4.0/jquery.min.js',
  '/lib/three.js/build/three.min.js',
  '/lib/other/BinaryHeap.js',
  '/lib/tween/tween.min.js',
  '/lib/proj4/proj4.js',
  '/lib/openlayers3/ol.js',
  '/lib/potree/potree.js',
  '/lib/plasio/js/laslaz.js',
  '/lib/Cesium/Cesium.js',
]
async function actuallyLoadScripts() {
  for (const src of scripts) await loadScript(src)
}

let scriptLoader: Promise<void> | undefined = undefined
export async function loadScripts() {
  if (!scriptLoader) scriptLoader = actuallyLoadScripts()
  return scriptLoader
}
