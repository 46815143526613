import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useMount } from 'react-use'

import { Task } from '@landrush/common'

import { Message, Style } from 'components'
import { Potree } from 'renderers'
import { Viewer } from 'store'
import { Proj, getOrigin, useKeyListener, useResource } from 'utils'

import Header from '../header'

import { PointCloud, Renderer, Terrain } from './renderer'
import Sidebar from './sidebar'
import Wrapper from './wrapper'

export default () => {
  const dispatch = useDispatch()
  const resource = useResource()

  const isFullscreen = useSelector(Viewer.selectFullscreen)
  const toggleFullscreen = () => dispatch(Viewer.setFullscreen(!isFullscreen))
  useKeyListener('f', toggleFullscreen, [toggleFullscreen])

  const rendererState = useSelector(Viewer.selectRendererState)

  const state = useSelector(Viewer.selectRendererState)
  const background = useSelector(Viewer.selectBackground)
  const toLonLat = useSelector(Viewer.selectToLonLat)

  // Don't destroy on unmount - want to retain the state until the active
  // resource is destroyed.
  useMount(() => dispatch(Viewer.loadScripts()))
  const isReady = useSelector(Viewer.selectReady)

  const onMount = useCallback(
    (renderer) => {
      dispatch(
        Viewer.loadSuccess({
          renderer,
          toLonLat: Proj.getLonLatConverter(resource.srs),
        })
      )
    },
    [dispatch, resource.srs]
  )
  const onMove = useCallback(
    (camera) => dispatch(Viewer.onCameraChange(camera)),
    [dispatch]
  )

  useEffect(() => {
    if (!isReady) return

    const state: Potree.State = {
      ...Potree.getInitialState(resource),
      ...rendererState,
    }
    dispatch(Viewer.setRendererState(state))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady])

  const { id, name, alias } = resource
  if (!Task.isSuccess(resource)) return <Redirect to={`/info/${alias}`} />

  if (!isReady) {
    return (
      <>
        <Header />
        <Message spin>Loading...</Message>
      </>
    )
  }

  return (
    <>
      {isFullscreen ? null : <Header />}
      <Wrapper>
        <Sidebar resource={resource} />
        <div style={Style.grow}>
          <Renderer state={state} onMount={onMount} onMove={onMove}>
            <PointCloud
              name={name}
              endpoint={`${getOrigin()}/data/resources/${id}/ept.json`}
            />
            {background === 'terrain' && toLonLat ? (
              <Terrain toLonLat={toLonLat} />
            ) : null}
            {/* volume ? (
              volume.isMutable ? (
                <Volume
                  onChange={(bounds: Bounds) =>
                    dispatch(Viewer.setVolume({ bounds, isMutable: true }))
                  }
                />
              ) : (
                <FixedVolume bounds={volume.bounds} />
              )
            ) : null */}
          </Renderer>
        </div>
      </Wrapper>
    </>
  )
}
