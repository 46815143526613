import React from 'react'
import styled from 'styled-components'

const StyledTextArea = styled.textarea`
  width: 100%;
  min-height: 360px;
  font-family: 'Lucida Console', Monaco, monospace;
  border-radius: 4px;
  border: 1px solid rgb(206, 212, 218);
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 6px;
  padding-right: 6px;
`

type TextArea = {
  value: string
  setValue(s: string): void
  className?: string
  style?: React.CSSProperties
  isEnabled?: boolean
}
export const TextArea = ({
  value,
  setValue,
  className,
  style,
  isEnabled = true,
}: TextArea) => (
  <StyledTextArea
    value={value}
    onChange={(e) => setValue(e.target.value)}
    spellCheck={false}
    className={className}
    style={style}
    disabled={!isEnabled}
  />
)
