import React from 'react'
import { Link } from 'react-router-dom'

import { ErrorMessage } from 'components'

export default function () {
  return (
    <Link to='/'>
      <ErrorMessage>Page not found - click to go home</ErrorMessage>
    </Link>
  )
}
