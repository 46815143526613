import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'

import { Button, Column, ErrorMessage, TextInput } from 'components'
import { Session } from 'store'
import { useAsync } from 'utils'

type FromState = { email?: string }
export default () => {
  const location = useLocation<FromState | undefined>()
  const client = useSelector(Session.selectUnconnectedClient)

  const fromRedirect = location.state?.email
  const [email, setEmail] = useState(fromRedirect || '')

  const [
    { isPending, error, value, dismissError },
    submit,
  ] = useAsync(async () => {
    await client.auth.requestPasswordReset(email)
    return true
  }, [email])

  if (value) {
    return <Redirect to={{ pathname: '/reset-password', state: { email } }} />
  }

  const isValid = Boolean(!isPending && email.length)
  return (
    <Column style={{ maxWidth: 480 }}>
      <h2>Request password reset</h2>
      <TextInput
        id='email-input'
        placeholder='Email'
        value={email}
        onChange={setEmail}
        onSubmit={submit}
        isEnabled={!isPending}
        isValid={isValid}
      />
      {error ? (
        <ErrorMessage onDismiss={dismissError}> {error}</ErrorMessage>
      ) : null}
      <Button
        isEnabled={isValid && !isPending}
        onClick={submit}
        style={{ alignSelf: 'center' }}
        spin={isPending}
      >
        Submit
      </Button>
    </Column>
  )
}
