import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'

import {
  Button,
  ErrorMessage,
  Panel,
  PanelHeader,
  Style,
  TextInput,
} from 'components'
import { Resources } from 'store'
import { useAppDispatch, useAsync } from 'utils'

const extensionError = 'Endpoint must end with "ept.json" or ".geojson"'

export default () => {
  const dispatch = useAppDispatch()
  const [endpoint, setEndpoint] = useState('')
  const [
    { isPending, error, dismissError, value },
    submit,
  ] = useAsync(async () => {
    if (!endpoint.endsWith('ept.json') && !endpoint.endsWith('.geojson')) {
      throw new Error(extensionError)
    }

    const result = await dispatch(Resources.addExternal(endpoint))
    if (result.error) throw new Error(result.error)
    return result.value
  }, [endpoint])

  if (value) return <Redirect to='/' />

  return (
    <Panel>
      <PanelHeader>
        <div style={Style.strong}>External resources</div>
      </PanelHeader>
      {error ? (
        <ErrorMessage onDismiss={dismissError} style={{ paddingTop: 8 }}>
          {error}
        </ErrorMessage>
      ) : null}
      <div
        style={{
          display: 'flex',
          alignItems: 'stretch',
          marginTop: 8,
          marginLeft: 8,
          marginRight: 8,
        }}
      >
        <TextInput
          autoFocus
          value={endpoint}
          isValid={endpoint.length > 0}
          onChange={setEndpoint}
          onSubmit={submit}
          isEnabled={!isPending}
          placeholder='Public EPT endpoint ending with "ept.json" or ".geojson"'
        />
        <Button
          style={{ marginLeft: 8 }}
          isEnabled={!isPending && endpoint.length > 0}
          onClick={submit}
          spin={isPending}
        >
          Import
        </Button>
      </div>
    </Panel>
  )
}
