"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Events = void 0;
var evt_1 = require("evt");
function create() {
    var events = new evt_1.Evt();
    function attach(handler) {
        events.attach(handler);
    }
    function detach(handler) {
        events
            .getHandlers()
            .filter(function (f) { return !handler || f.callback === handler; })
            .forEach(function (_a) {
            var detach = _a.detach;
            return detach();
        });
    }
    return { events: events, attach: attach, detach: detach };
}
exports.Events = { create: create };
