import React, { useState } from 'react'
import gfm from 'remark-gfm'
import Markdown from 'react-markdown'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

import { Id, Workflow } from '@landrush/common'
import { coerce, ialphaSort, substitute } from '@landrush/util'

import {
  Color,
  ErrorMessage,
  InfoMessage,
  Message,
  Select,
  Steps,
  Style,
  WorkflowAnalysis,
} from 'components'
import { AddExecutionsState, Workflows } from 'store'
import { useAppDispatch, useAppSelector } from 'utils'

import ContextEditor from './context-editor'

const SelectedWorkflow = () => {
  const dispatch = useAppDispatch()
  const { pipeline, reducers, readme, options } = useAppSelector(
    AddExecutionsState.selectHydratedWorkflow
  )
  const context = useAppSelector(AddExecutionsState.selectContextRecord)
  const setContextValue = (key: string, value: string) =>
    dispatch(AddExecutionsState.setContextValue({ key, value }))

  const [isAdvanced, setAdvanced] = useState(false)

  // When displaying the pipeline/reducers, if the associated context value is
  // still empty, display the key name as the value.
  const hintedContext = Object.entries(context)
    .sort((a, b) => ialphaSort(a[0], b[0]))
    .reduce((agg, [k, v]) => ({ ...agg, [k]: v || `{{${k}}}` }), {})
  const coercedHintedContext = coerce(hintedContext)

  return (
    <Styles>
      <ContextEditor context={context} set={setContextValue} />

      <WorkflowAnalysis
        pipeline={pipeline}
        reducers={reducers}
        options={options}
      />

      <div className='readme-container'>
        <Markdown plugins={[gfm]}>{readme}</Markdown>
      </div>

      <div className='advanced-icon-container'>
        <FontAwesomeIcon
          className='advanced-icon'
          icon={isAdvanced ? faChevronUp : faChevronDown}
          onClick={() => setAdvanced((s) => !s)}
        />
      </div>

      {isAdvanced && (
        <>
          <h4>Pipeline</h4>
          <Steps
            steps={substitute(
              [Workflow.eptReaderTemplate, ...pipeline],
              coercedHintedContext
            )}
          />

          <h4>Reducers</h4>
          {reducers ? (
            <Steps steps={substitute(reducers, coercedHintedContext)} />
          ) : (
            <div style={Style.small}>None</div>
          )}
        </>
      )}
    </Styles>
  )
}
const Styles = styled.div`
  .advanced-icon-container {
    display: flex;
    justify-content: center;
  }
  .advanced-icon {
    font-size: 24px;
    margin-top: 32px;
    text-align: center;
    cursor: pointer;
    color: ${Color.lightGray};
    &:hover {
      color: ${Color.darkGray};
    }
  }
  .readme-container {
    margin-top: 24px;
  }
`

export default () => {
  const dispatch = useAppDispatch()
  const workflows = useAppSelector(Workflows.selectAll)

  const id = useAppSelector(AddExecutionsState.selectWorkflowId)
  const workflow = id ? workflows.find((v) => v.id === id) : undefined
  const error = useAppSelector(AddExecutionsState.selectWorkflowError)

  const set = (id: Id) => dispatch(AddExecutionsState.activateWorkflow(id))

  const body = (() => {
    if (error) {
      return (
        <ErrorMessage onRetry={id ? () => set(id) : undefined}>
          {error}
        </ErrorMessage>
      )
    }
    if (!id) {
      return (
        <InfoMessage icon='lightbulb' body='Select a workflow to get started' />
      )
    }
    if (!workflow || !Workflow.isDetailed(workflow)) {
      return <Message spin>Loading...</Message>
    }
    return <SelectedWorkflow />
  })()

  return (
    <>
      <Select
        className='select-workflow'
        options={workflows.map((w) => ({ label: w.name, value: w.id }))}
        value={id}
        onChange={set}
      />
      {body}
    </>
  )
}
