import React from 'react'
import styled from 'styled-components'

const Container = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
})

type Spaced = { className?: string; style?: React.CSSProperties }
export const Spaced: React.FC<Spaced> = ({ className, style, children }) => (
  <Container className={className} style={style}>
    {children}
  </Container>
)
