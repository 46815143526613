import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { usePrevious } from 'react-use'

import {
  Button,
  Color,
  DataTable,
  DeleteIcon,
  Prefix,
  TextInput,
} from 'components'
import { CredentialState } from 'credentials'

const List = ({ credential, flowState, removePath }: CredentialState) => {
  const [isRemoving, setRemoving] = useState<string | undefined>()
  useEffect(() => {
    if (flowState !== 'removing-path') setRemoving(undefined)
  }, [flowState])

  const { paths, protocol } = credential
  const effectivePaths = protocol === 'dropbox' ? ['Apps/Landrush'] : paths

  return (
    <DataTable>
      <tbody>
        {effectivePaths.map((path) => (
          <tr
            key={path}
            style={{ backgroundColor: flowState ? '#eee' : undefined }}
          >
            <td style={{ width: '100%' }}>
              <Link to='/add-resources'>
                <span style={{ color: Color.veryDarkGray }}>{path}</span>
              </Link>
            </td>
            <td>
              {protocol === 'dropbox' ? null : (
                <DeleteIcon
                  icon='times'
                  isEnabled={!flowState}
                  isPending={
                    flowState === 'removing-path' && isRemoving === path
                  }
                  onClick={() => {
                    setRemoving(path)
                    removePath(path)
                  }}
                />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </DataTable>
  )
}

function Add({ credential, flowState, addPath, error }: CredentialState) {
  const { protocol } = credential

  const [text, setText] = useState('')
  const prevAction = usePrevious(flowState)
  useEffect(() => {
    if (prevAction === 'adding-path' && !error) setText('')
  }, [prevAction, error])

  // Cannot add to dropbox paths - we just use the root app path.
  if (protocol === 'dropbox') return null

  const submit = () => addPath(text)
  const isValid = Boolean(text.length)
  const isIdle = !flowState

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'stretch',
        marginTop: 8,
        marginLeft: 8,
        marginRight: 8,
      }}
    >
      <Prefix>{`${protocol}://`}</Prefix>
      <TextInput
        placeholder='bucket path'
        value={text}
        onChange={setText}
        onSubmit={submit}
        isEnabled={isIdle}
        isValid={isValid}
        style={{
          marginRight: 8,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      />
      <Button
        isEnabled={isIdle && isValid}
        spin={flowState === 'adding-path'}
        onClick={submit}
      >
        Add path
      </Button>
    </div>
  )
}

export default (props: CredentialState) => {
  return (
    <>
      <List {...props} />
      <Add {...props} />
    </>
  )
}
