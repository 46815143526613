import { Bounds } from '@landrush/entwine'

export function getZoom(
  bounds: Bounds
): { position: number[]; target: number[] } {
  const node = new THREE.Object3D()
  const factor = 0.7
  node.boundingBox = new THREE.Box3().setFromArray(bounds)

  const fov = 60
  const aspect = 1.8936936936936937
  const near = 50
  const far = 50000
  const camera = new THREE.PerspectiveCamera(fov, aspect, near, far)

  camera.rotation.order = 'ZXY'
  camera.rotation.x = Math.PI / 6
  camera.rotation.z = 0
  camera.updateMatrix()
  camera.updateMatrixWorld()
  camera.zoomTo(node, factor)

  const boundingSphere = (() => {
    if (node.boundingSphere) return node.boundingSphere
    if (node.geometry && node.geometry.boundingSphere) {
      return node.geometry.boundingSphere
    }
    return node.boundingBox.getBoundingSphere(new THREE.Sphere())
  })()
    .clone()
    .applyMatrix4(node.matrixWorld)
  return {
    position: camera.position.toArray(),
    target: boundingSphere.center.toArray(),
  }
}

// TODO: Should remove this one since getZoom is now exposed as a utility.
export async function zoomTo({
  viewer,
  bounds,
  ms,
}: {
  viewer: Potree.Viewer
  bounds: Bounds
  ms: number
}): Promise<void> {
  const { position, target } = getZoom(bounds)
  return navigateTo({ viewer, ms, position, target })
}

export async function navigateTo({
  viewer,
  position: cpos,
  target: ctgt,
  ms = 400,
}: {
  viewer: any
  position: number[]
  target: number[]
  ms?: number
}): Promise<void> {
  return new Promise((resolve) => {
    const view = viewer.scene.view

    const startPosition = view.position.clone()
    const startTarget = view.getPivot()

    const endPosition = new THREE.Vector3(...cpos)
    const endTarget = new THREE.Vector3(...ctgt)

    const easing = TWEEN.Easing.Quartic.Out

    // Animate camera position.
    {
      const pos = startPosition.clone()
      const tween = new TWEEN.Tween(pos).to(endPosition, ms)
      tween.easing(easing)
      tween.onUpdate(() => view.position.copy(pos))
      tween.start()
    }

    // Animate camera target.
    {
      const target = startTarget.clone()
      const tween = new TWEEN.Tween(target).to(endTarget, ms)
      tween.easing(easing)
      tween.onUpdate(() => view.lookAt(target))
      tween.onComplete(() => {
        view.lookAt(target)
        resolve()
        viewer.dispatchEvent({
          type: 'focusing_finished',
          target: viewer,
        })
      })

      viewer.dispatchEvent({
        type: 'focusing_started',
        target: viewer,
      })
      tween.start()
    }
  })
}

type SetCamera = {
  viewer: Potree.Viewer
  position: number[]
  target: number[]
}
export function setCamera({ viewer, position, target }: SetCamera) {
  viewer.scene.view.position.copy(new THREE.Vector3(...position))
  viewer.scene.view.lookAt(new THREE.Vector3(...target))
}

type RotateToNorth = { viewer: Potree.Viewer; ms?: number }
export async function rotateToNorth({
  viewer,
  ms = 400,
}: RotateToNorth): Promise<void> {
  const currentPosition: number[] = viewer.scene.view.position.toArray()
  const currentTarget: number[] = viewer.scene.view.getPivot().toArray()
  const currentRotation = viewer.scene.getActiveCamera().rotation

  const target = currentTarget.slice()
  const offset = currentPosition.map((v, i) => v - target[i])
  const angle = -currentRotation.z

  const cos = Math.cos(angle)
  const sin = Math.sin(angle)

  const position = [
    offset[0] * cos - offset[1] * sin + target[0],
    offset[1] * cos + offset[0] * sin + target[1],
    currentPosition[2],
  ]

  // Our rotation is a degenerate case if we're directly overhead of our
  // and will fail to actually rotate.  In this case, tilt our view away
  // from us slightly and then perform our rotation from there.
  if (position[0] === target[0] && position[1] === target[1]) {
    const distance = target[2] - position[2]
    const current = currentPosition.slice()
    current[0] += (sin * distance) / 32
    current[1] += (cos * distance) / 32

    await navigateTo({ viewer, position: current, target, ms: 0 })
    return rotateToNorth({ viewer, ms })
  }

  return navigateTo({ viewer, position, target, ms })
}
