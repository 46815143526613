const landrushOrange = '#f5751c'
const landrushComplement = '#2050a2'
const polygonColors = ['#588265', '#445454', '#41A588', '#5D5065']
const randomColors = polygonColors
/*
const randomColors = [
  landrushComplement,
  '#2364AA', // Lapis lazuli.
  '#72195A', // Pansy purple.
  '#E8FFB7', // Pale spring bud.

  '#B7F0AD', // Light moss green.
  '#9DD9D2', // Pale robin's egg blue.
  '#FFFC31', // Daffodil.
  '#EC4067', // Paradise pink.

  '#E09F7D', // Dark salmon.
  '#AE8799', // English lavender.
  '#E8D33F', // Sandstorm.
  '#90FCF9', // Electric blue.
]
*/
export const Color = {
  landrushOrangeArray: [245, 117, 28],
  landrushOrange,
  landrushGray: '#58585a',
  landrushComplement,
  dangerRed: '#f5401c',
  dangerYellow: '#f5e31c',
  successGreen: '#298800',
  veryDarkGray: '#444',
  darkGray: '#666',
  gray: '#888',
  lightGray: '#bbb',
  veryLightGray: '#ddd',
  backgroundGray: 'rgb(246, 248, 250)',
  borderGray: 'rgb(206, 212, 218)',
  disabled: 'rgb(233, 236, 239)',
  muted: '#6c757d',

  random(i = Math.floor(Math.random() * randomColors.length)) {
    return randomColors[i % randomColors.length]
  },
}
