import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import { useMount } from 'react-use'

import {
  Button,
  Column,
  ErrorMessage,
  InfoMessage,
  TextInput,
} from 'components'
import { Session } from 'store'
import { Browser, useAsync } from 'utils'

const { getQueryParam } = Browser

type FromState = { email?: string }
export default () => {
  const { state, pathname } = useLocation<FromState | undefined>()
  const client = useSelector(Session.selectUnconnectedClient)

  const fromRedirect = state?.email
  const fromQuery = getQueryParam('email')
  const [email, setEmail] = useState(fromRedirect || fromQuery || '')
  const [code, setCode] = useState('')

  useMount(() => {
    if (fromQuery) window.history.replaceState('', document.title, pathname)
  })

  const [
    { isPending, value, error, dismissError },
    submit,
  ] = useAsync(async () => {
    await client.auth.submitConfirmation(email, code)
    return true
  }, [email, code])

  const isValid = Boolean(!isPending && email.length && code.length)
  if (value) {
    return (
      <Redirect to={{ pathname: '/login', state: { email, from: pathname } }} />
    )
  }

  const justRegistered = Boolean(fromRedirect)
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Column style={{ width: 480 }}>
        {justRegistered ? (
          <InfoMessage
            icon='envelope'
            title='Confirmation required'
            body='Please check email for confirmation code'
          />
        ) : (
          <h2>Confirm email address</h2>
        )}
        <TextInput
          id='email-input'
          placeholder='Email'
          value={email}
          onChange={setEmail}
          onSubmit={submit}
          isEnabled={!isPending}
          isValid={isValid}
        />
        <TextInput
          id='confirmation-code-input'
          placeholder='Confirmation code'
          value={code}
          onChange={setCode}
          onSubmit={submit}
          isEnabled={!isPending}
          isValid={isValid}
        />
        {error ? (
          <ErrorMessage onDismiss={dismissError}>{error}</ErrorMessage>
        ) : null}
        <Button
          isEnabled={isValid && !isPending}
          onClick={submit}
          style={{ alignSelf: 'center' }}
          spin={isPending}
        >
          Confirm
        </Button>
      </Column>
    </div>
  )
}
