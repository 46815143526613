import React from 'react'
import { useSelector } from 'react-redux'
import { useMount, useUnmount } from 'react-use'
import styled from 'styled-components'

import { Color, ErrorMessage, Message, Overview } from 'components'
import { AddExecutionsState } from 'store'
import { useAppDispatch, useKeyListener } from 'utils'

import ComposeWorkflow from './compose-workflow'
import Navigation from './navigation'
import Settings from './settings'

// TODO: These should be moved somewhere more reasonable.
import RegionHeader from 'regions/header'
import RegionList from 'regions/list'
import ResourceHeader from 'resources/header'
import ResourceList from 'resources/list'

const PageDisplay = () => {
  const dispatch = useAppDispatch()
  const prev = () => dispatch(AddExecutionsState.goToPreviousPage())
  const next = () => dispatch(AddExecutionsState.goToNextPage())
  useKeyListener('arrowleft', prev)
  useKeyListener('arrowright', next)
  useKeyListener('enter', next)

  const page = useSelector(AddExecutionsState.selectPage)

  switch (page) {
    case 'resources':
      return (
        <div style={{ borderTop: `1px solid ${Color.veryLightGray}` }}>
          <ResourceHeader isModifiable={false} />
          <ResourceList isNavigable={false} isShort />
        </div>
      )
    case 'regions':
      return (
        <div style={{ borderTop: `1px solid ${Color.veryLightGray}` }}>
          <RegionHeader isModifiable={false} />
          <RegionList isShort />
        </div>
      )
    case 'workflows':
      return <ComposeWorkflow />
    case 'settings':
      return <Settings />
  }
}

const Loader: React.FC = () => {
  const dispatch = useAppDispatch()
  const load = () => {
    dispatch(AddExecutionsState.load())
  }

  useMount(load)
  useUnmount(() => dispatch(AddExecutionsState.destroy()))

  const loadState = useSelector(AddExecutionsState.selectLoadState)
  const loadError = useSelector(AddExecutionsState.selectLoadError)

  if (loadError) {
    return (
      <div style={{ marginTop: 16 }}>
        <ErrorMessage onRetry={load}>{loadError}</ErrorMessage>
      </div>
    )
  }
  if (loadState !== 'done') {
    return (
      <div style={{ marginTop: 16 }}>
        <Message spin>Loading...</Message>
      </div>
    )
  }

  return <PageDisplay />
}

export default () => {
  return (
    <Styles>
      <Overview page='executions' />

      <div className='add-execution-header'>
        <h2 style={{ marginTop: 16 }}>Generate products</h2>
      </div>

      <div className='add-execution-workspace-container'>
        <div className='add-execution-current-page-container'>
          <Loader />
        </div>
        <div className='add-execution-cart-container'>
          <Navigation />
        </div>
      </div>
    </Styles>
  )
}
const Styles = styled.div`
  .add-execution-header {
  }
  .add-execution-workspace-container {
    display: flex;
  }
  .add-execution-current-page-container {
    flex: 4;
    margin-right: 8px;
  }
  .add-execution-cart-container {
    flex: 2;
  }
`
