import React from 'react'
import { useSelector } from 'react-redux'

import { Id, Tag } from '@landrush/common'

import { Overview } from 'components'
import { Resources } from 'store'
import { asyncResult, useAppDispatch } from 'utils'

import { ManageTags } from 'manage-tags'

export default () => {
  const dispatch = useAppDispatch()

  const load = () => {
    dispatch(Resources.load())
    dispatch(Resources.Tags.load())
  }
  const loadState = useSelector(Resources.Tags.selectLoadState)
  const loadError = useSelector(Resources.Tags.selectLoadError)
  const tags = useSelector(Resources.Tags.selectAll)

  const addTag = async (params: Tag.Add) =>
    asyncResult(dispatch(Resources.Tags.add(params)))
  const updateTag = async (id: Id, params: Tag.Patch) =>
    asyncResult(dispatch(Resources.Tags.update(id, params)))
  const removeTag = async (id: Id) =>
    asyncResult(dispatch(Resources.Tags.remove(id)))

  const props = {
    load,
    loadState,
    loadError,
    tags,
    addTag,
    updateTag,
    removeTag,
  }

  return (
    <>
      <Overview page='home' />
      <ManageTags {...props} />
    </>
  )
}
