import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import { Button, Color, Column, ErrorMessage, TextInput } from 'components'
import { Session } from 'store'
import { useAsync } from 'utils'

export default function () {
  const client = useSelector(Session.selectClient)
  const [old, setOld] = useState('')
  const [val, setVal] = useState('')
  const [dup, setDup] = useState('')

  const isValid =
    old.length > 0 && val.length > 0 && dup.length > 0 && val === dup

  const [
    { isPending, error, value, dismissError },
    submit,
  ] = useAsync(async () => {
    if (val !== dup) throw new Error('Passwords do not match')
    await client.auth.changePassword(old, val)
    return true
  }, [old, val, dup])

  if (value) {
    return (
      <Column style={{ maxWidth: 480 }}>
        <div style={{ marginTop: 36, marginBottom: 8 }}>
          <FontAwesomeIcon
            icon={faCheck}
            style={{ color: Color.landrushOrange, fontSize: 48 }}
          />
        </div>
        <p>Password successfully changed</p>
        <Link to='/account'>
          <Button>Go back</Button>
        </Link>
      </Column>
    )
  }

  return (
    <Column style={{ maxWidth: 480 }}>
      <h2>Change password</h2>
      <Label htmlFor='old-password'>Current password</Label>
      <TextInput
        id='old-password'
        type='password'
        value={old}
        onChange={setOld}
        onSubmit={submit}
        isEnabled={!isPending}
        isValid={isValid}
      />
      <Label htmlFor='new-password'>New password</Label>
      <TextInput
        id='new-password'
        type='password'
        value={val}
        onChange={setVal}
        onSubmit={submit}
        isEnabled={!isPending}
        isValid={isValid}
      />
      <Label htmlFor='confirm-password'>New password again</Label>
      <TextInput
        id='confirm-password'
        type='password'
        value={dup}
        onChange={setDup}
        onSubmit={submit}
        isEnabled={!isPending}
        isValid={isValid}
      />
      {error ? (
        <ErrorMessage onDismiss={dismissError}> {error}</ErrorMessage>
      ) : null}
      <Button
        isEnabled={isValid && !isPending}
        onClick={submit}
        style={{ alignSelf: 'center' }}
        spin={isPending}
      >
        Submit
      </Button>
    </Column>
  )
}

type Label = { htmlFor: string }
const Label: React.FC<Label> = ({ htmlFor, children }) => (
  <label htmlFor={htmlFor} style={{ marginBottom: 0 }}>
    {children}
  </label>
)
