import * as Potree from '../potree'

type Xyz = { x: number; y: number; z: number }
export function convert(p: Xyz, toWgs84: proj4.Converter) {
  const xy = [p.x, p.y]
  const height = p.z
  const deg = toWgs84.forward(xy)
  return Cesium.Cartesian3.fromDegrees(...deg, height)
}

export function create(el: HTMLDivElement, params = {}) {
  Cesium.Ion.defaultAccessToken =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyMGNkY2ZmZS1hMjk0LTQwNmEtOTM3MS0yMzhmNmQ4OTQ3M2UiLCJpZCI6MTE2ODAsInNjb3BlcyI6WyJhc3IiLCJnYyJdLCJpYXQiOjE1NTk1NzY4MDN9.kRiLk-_QTkDDkqIg39P2jGqafdwDPOXLFLywldV3sIw'

  const imageryProviders = Cesium.createDefaultImageryProviderViewModels()
  // const terrainProviders = Cesium.createDefaultTerrainProviderViewModels()
  const viewer = new Cesium.Viewer(el, {
    useDefaultRenderLoop: false,
    animation: false,
    fullscreenButton: false,
    geocoder: false,
    homeButton: false,
    infoBox: false,
    sceneModePicker: false,
    selectionIndicator: false,
    timeline: false,
    navigationHelpButton: false,
    terrainShadows: Cesium.ShadowMode.DISABLED,
    terrainProvider: Cesium.createWorldTerrain(),
    baseLayerPicker: false,
    imageryProviderViewModels: imageryProviders,
    selectedImageryProviderViewModel: imageryProviders[6],
    ...params,
  })

  const Cartesian3 = Cesium.Cartesian3
  const cDir = new Cesium.Cartesian3()
  const cUp = new Cesium.Cartesian3()
  const cDirNorm = new Cesium.Cartesian3()
  const cUpNorm = new Cesium.Cartesian3()

  let master: Potree.Renderer | undefined = undefined
  function syncTo(potree: Potree.Renderer, toLonLat: proj4.Converter) {
    master = potree
    master.setOnTick(() => {
      const camera = (potree as any).viewer.scene.getActiveCamera()
      const matrixWorld = camera.matrixWorld
      const pPos = new THREE.Vector3(0, 0, 0).applyMatrix4(matrixWorld)
      const pUp = new THREE.Vector3(0, 1, 0).applyMatrix4(matrixWorld)
      const pTarget = (potree as any).viewer.scene.view.getPivot()

      const cPos = convert(pPos, toLonLat)
      const cUpTarget = convert(pUp, toLonLat)
      const cTarget = convert(pTarget, toLonLat)

      Cesium.Cartesian3.subtract(cTarget, cPos, cDir)
      Cesium.Cartesian3.subtract(cUpTarget, cPos, cUp)

      Cesium.Cartesian3.normalize(cDir, cDirNorm)
      Cesium.Cartesian3.normalize(cUp, cUpNorm)

      viewer.camera.setView({
        destination: cPos,
        orientation: { direction: cDirNorm, up: cUpNorm },
      })

      if (camera.aspect < 1) {
        const fovy = Math.PI * (camera.fov / 180)
        viewer.camera.frustum.fov = fovy
      } else {
        const fovy = Math.PI * (camera.fov / 180)
        const fovx = Math.atan(Math.tan(0.5 * fovy) * camera.aspect) * 2
        viewer.camera.frustum.fov = fovx
      }

      viewer.render()
    })
  }

  function destroy() {
    if (master) master.setOnTick(() => {})
    viewer.destroy()
  }

  return { viewer, Cartesian3, cDir, cUp, cDirNorm, cUpNorm, syncTo, destroy }
}
