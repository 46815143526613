import gfm from 'remark-gfm'
import React from 'react'
import Markdown from 'react-markdown'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPen } from '@fortawesome/free-solid-svg-icons'

import { Workflow } from '@landrush/common'

import { Button, Color, ErrorMessage, TextArea } from 'components'

export type WithWorkflow = { workflow: Workflow }
export type ReadmeEditor = {
  readme: string
  submit: () => void
  dismissError: () => void
  error?: string
  isPending: boolean
  text?: string
  setText: (text?: string) => void
}

type Readme = { readme: string }
const Readme = ({ readme }: Readme) => {
  return (
    <Styles>
      <div className='readme-container'>
        <Markdown plugins={[gfm]}>{readme}</Markdown>
      </div>
    </Styles>
  )
}

type EditorWithPreview = {
  text: string
  setText: (text: string | undefined) => void
  isEnabled?: boolean
}
Readme.EditorWithPreview = ({
  text,
  setText,
  isEnabled = true,
}: EditorWithPreview) => {
  return (
    <Styles>
      <div className='side-by-side'>
        <ReadmeEditorField
          text={text}
          setText={setText}
          isEnabled={isEnabled}
        />
        <ReadmePreviewField text={text} />
      </div>
    </Styles>
  )
}

export const ReadmeEditor: React.FC<ReadmeEditor> = ({
  readme,
  submit,
  text,
  setText,
  dismissError,
  error,
  isPending,
}) => {
  if (text === undefined) {
    return (
      <Styles>
        <FontAwesomeIcon
          className='edit-readme-icon'
          icon={faPen}
          onClick={() => setText(readme)}
        />
        <div className='readme-container'>
          <Markdown plugins={[gfm]}>{readme}</Markdown>
        </div>
      </Styles>
    )
  }

  return (
    <Styles>
      <FontAwesomeIcon
        className='edit-readme-icon'
        icon={faMinus}
        onClick={() => setText(undefined)}
      />
      <div className='side-by-side'>
        <ReadmeEditorField
          text={text}
          setText={setText}
          isEnabled={!isPending}
        />
        <ReadmePreviewField text={text} />
      </div>
      <div className='buttons'>
        <Button
          className='cancel-readme-button'
          onClick={() => setText(undefined)}
          isEnabled={!isPending}
        >
          Cancel
        </Button>
        <Button
          className='submit-readme-button'
          onClick={submit}
          isEnabled={!isPending}
        >
          Submit
        </Button>
      </div>
      {error && <ErrorMessage onDismiss={dismissError}>{error}</ErrorMessage>}
    </Styles>
  )
}
type EditorFieldProps = {
  text: string
  setText: (text: string) => void
  isEnabled: boolean
}
export const ReadmeEditorField: React.FC<EditorFieldProps> = ({
  text,
  setText,
  isEnabled,
}) => (
  <div className='markdown-editor'>
    <TextArea value={text} setValue={setText} isEnabled={!isEnabled} />
  </div>
)
type PreviewFieldProps = { text: string }
export const ReadmePreviewField: React.FC<PreviewFieldProps> = ({ text }) => (
  <div className='markdown-preview'>
    <Markdown plugins={[gfm]}>{text}</Markdown>
  </div>
)

export const Styles = styled.div`
  display: flex;
  flex-direction: column;

  .readme-container {
    width: 100%;
    /* max-width: 800px; */
    align-self: center;
  }

  .buttons {
    align-self: center;
    display: flex;
    margin-top: 8px;
  }

  .cancel-readme-button {
    margin-right: 4px;
    background-color: ${Color.lightGray};
    &:hover {
      background-color: ${Color.dangerRed};
    }
  }

  .submit-readme-button {
    margin-left: 4px;
  }

  .edit-readme-icon {
    align-self: flex-end;
    cursor: pointer;
    font-size: 20px;
    margin-top: 8px;

    color: ${Color.lightGray};
    &:hover {
      color: ${Color.darkGray};
    }
  }

  .side-by-side {
    display: flex;
  }
  .markdown-editor {
    flex: 1 1 0;
    margin-right: 12px;
  }
  .markdown-preview {
    flex: 1 1 0;
    margin-left: 12px;
  }
`
