import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import footerImage from 'img/icon.png'

import { Measures } from './measures'

const height = Measures.footerHeight
const Container = styled.footer({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#f5f5f5',
  position: 'absolute',
  bottom: 0,
  width: '100%',
  height,
  borderColor: '#e7e7e7',
  borderTopStyle: 'solid',
  borderTopWidth: 1,
})

const Icon = styled.img({ maxHeight: height * 0.75 })

export const Footer: React.FC = () => (
  <Container>
    <Link to='/' aria-label='home'>
      <Icon src={footerImage} />
    </Link>
  </Container>
)
