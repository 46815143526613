import React from 'react'
import styled from 'styled-components'

import { Bounds } from '@landrush/entwine'

import { Color } from './color'
import { StyledTable } from './table'

type Props = { bounds: Bounds }

const BoundsTable = styled(StyledTable)`
  max-width: 256px;

  .key {
    color: ${Color.muted};
  }

  .val {
    text-align: right;
    margin-left: auto;
  }
`

const pretty = (n: number) =>
  n.toLocaleString(undefined, {
    maximumFractionDigits: 2,
  })
export const BoundsDisplay = ({ bounds }: Props) => {
  const prettyBounds = bounds.map(pretty)
  return (
    <BoundsTable>
      <tbody>
        <tr>
          <td className='key'>X min</td>
          <td>
            <div className='val'>{prettyBounds[0]}</div>
          </td>
        </tr>
        <tr>
          <td className='key'>Y min</td>
          <td>
            <div className='val'>{prettyBounds[1]}</div>
          </td>
        </tr>
        <tr>
          <td className='key'>Z min</td>
          <td>
            <div className='val'>{prettyBounds[2]}</div>
          </td>
        </tr>
        <tr>
          <td className='key'>X max</td>
          <td>
            <div className='val'>{prettyBounds[3]}</div>
          </td>
        </tr>
        <tr>
          <td className='key'>Y max</td>
          <td>
            <div className='val'>{prettyBounds[4]}</div>
          </td>
        </tr>
        <tr>
          <td className='key'>Z max</td>
          <td>
            <div className='val'>{prettyBounds[5]}</div>
          </td>
        </tr>
      </tbody>
    </BoundsTable>
  )
}
