import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Id, Workflow } from '@landrush/common'
import { Result, getSubstitutions } from '@landrush/util'

import { AppState, AppThunk } from 'store'
import { LoadState } from 'store/types'

import { Regions } from 'store/regions'
import { RegionState } from 'store/region-state'
import { Resources } from 'store/resources'
import { ResourceState } from 'store/resource-state'
import { Workflows } from 'store/workflows'

type Page = 'resources' | 'regions' | 'workflows' | 'settings'
const pages: Page[] = ['resources', 'regions', 'workflows', 'settings']

type StringMap = Record<string, string>
type State = {
  errors: string[]
  name: string
  page: Page
  workflowError?: string
  workflowId?: Id
  context: StringMap
}
const initialState: State = {
  errors: [],
  name: '{{workflow}} - {{region}} - {{date}} {{time}}',
  page: 'resources',
  context: {},
}

const slice = createSlice({
  name: 'add-execution-state',
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<Page>) {
      state.page = action.payload
    },
    setWorkflowId(state, action: PayloadAction<Id>) {
      state.workflowId = action.payload
    },
    clearWorkflow(state) {
      state.workflowId = undefined
      state.context = {}
      state.workflowError = undefined
    },
    setWorkflowError(state, action: PayloadAction<string>) {
      state.workflowError = action.payload
    },
    setContext(state, action: PayloadAction<StringMap>) {
      state.context = action.payload
    },
    setContextValue(
      state,
      action: PayloadAction<{ key: string; value: string }>
    ) {
      const { key, value } = action.payload
      state.context = { ...state.context, [key]: value }
    },
    setName(state, action: PayloadAction<string>) {
      state.name = action.payload
    },
    destroy() {
      return initialState
    },
  },
})

const { actions, reducer } = slice
const { setPage, setWorkflowId, destroy: destroySuccess } = actions

const selectSlice = (state: AppState) => state.addExecutionsState
const selectName = createSelector(selectSlice, (s) => s.name)
const selectPage = createSelector(selectSlice, (s) => s.page)
const selectLoadState = createSelector(
  Regions.selectLoadState,
  Regions.Tags.selectLoadState,
  Resources.selectLoadState,
  Resources.Tags.selectLoadState,
  Workflows.selectIsListed,
  (a, b, c, d, e): LoadState | undefined => {
    const list = [a, b, c, d]
    if (list.includes('loading')) return 'loading'
    if (list.includes(undefined)) return undefined
    return e ? 'done' : 'loading'
  }
)
const selectLoadError = createSelector(
  Regions.selectLoadError,
  Regions.Tags.selectLoadError,
  Resources.selectLoadError,
  Resources.Tags.selectLoadError,
  (a, b, c, d): string | undefined => {
    const list = [a, b, c, d]
    return list.find((v) => Boolean(v))
  }
)

const selectWorkflowId = createSelector(selectSlice, (s) => s.workflowId)
const selectWorkflowError = createSelector(selectSlice, (s) => s.workflowError)
const selectHydratedWorkflow = createSelector(
  (state) => state,
  selectWorkflowId,
  (state, id) => {
    if (!id) throw new Error('Invalid selection')
    const workflow = Workflows.selectById(state, id)
    if (!workflow || !Workflow.isDetailed(workflow)) {
      throw new Error('Invalid selection')
    }
    return workflow
  }
)
const selectContextRecord = createSelector(selectSlice, (s) => s.context)
function coerceValue(v: string): any {
  if (v === '') return undefined
  try {
    return JSON.parse(v)
  } catch (e) {
    return v
  }
}

export function coerce(o: StringMap): { [key: string]: unknown } {
  return Object.entries(o).reduce(
    (o, [k, v]) => ({
      ...o,
      [k]: coerceValue(v),
    }),
    {}
  )
}
const selectCoercedContext = createSelector(selectContextRecord, coerce)

const selectResourcesValid = createSelector(
  ResourceState.selectSelectedResourcesCount,
  (c) => c > 0
)
const selectRegionsValid = createSelector(
  RegionState.selectSelectedRegionsCount,
  (c) => c > 0
)
const selectWorkflowValid = createSelector(
  (state) => state,
  selectWorkflowId,
  (state, id) => Boolean(id && Workflows.selectById(state, id))
)
const selectContextValid = createSelector(
  selectCoercedContext,
  (_context) => true
)
const selectAllValid = createSelector(
  selectResourcesValid,
  selectRegionsValid,
  selectWorkflowValid,
  selectContextValid,
  (rs, rg, wf, c) => rs && rg && wf && c
)

const selectors = {
  selectSlice,
  selectName,
  selectPage,
  selectLoadState,
  selectLoadError,
  selectWorkflowId,
  selectWorkflowError,
  selectHydratedWorkflow,
  selectContextRecord,
  selectCoercedContext,
  selectResourcesValid,
  selectRegionsValid,
  selectWorkflowValid,
  selectContextValid,
  selectAllValid,
}

const load = (): AppThunk => async (dispatch) => {
  dispatch(Regions.load())
  dispatch(Regions.Tags.load())
  dispatch(Resources.load())
  dispatch(Resources.Tags.load())
  dispatch(Workflows.list())
}
const destroy = (): AppThunk => async (dispatch) => {
  dispatch(RegionState.destroy())
  dispatch(ResourceState.destroy())
  dispatch(destroySuccess())
}

const defaults = {
  srs: '',
  srid: '',
  read: '0',
  edge: '0',
  collar: '50',
  buffer: '0',
}
const activateWorkflow = (id: Id): AppThunk => async (dispatch) => {
  dispatch(setWorkflowId(id))
  const result = await dispatch(Workflows.hydrate(id))
  if (Result.isFailure(result)) dispatch(actions.setWorkflowError(result.error))
  else {
    const workflow = result.value
    const { pipeline, reducers = [], options = {} } = workflow
    dispatch(
      actions.setContext({
        ...getSubstitutions([pipeline, ...reducers])
          .filter((k) => !['id', 'collar', 'bounds', 'srid'].includes(k))
          .reduce(
            (o, k) => ({ ...o, [k]: options[k]?.default?.toString() || '' }),
            {}
          ),
        ...defaults,
      })
    )
  }
}

const goToNextPage = (): AppThunk => async (dispatch, gs) => {
  const page = selectPage(gs())
  const index = pages.findIndex((p) => p === page)
  dispatch(setPage(pages[Math.min(pages.length - 1, index + 1)]))
}
const goToPreviousPage = (): AppThunk => async (dispatch, gs) => {
  const page = selectPage(gs())
  const index = pages.findIndex((p) => p === page)
  dispatch(setPage(pages[Math.max(0, index - 1)]))
}

const thunks = {
  load,
  activateWorkflow,
  goToNextPage,
  goToPreviousPage,
  destroy,
}

type _Page = Page
export declare namespace AddExecutionsState {
  export type Page = _Page
}
// eslint-disable-next-line no-redeclare
export const AddExecutionsState = { ...actions, ...selectors, ...thunks }
export { reducer }
