import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
  faExclamation,
  faExclamationTriangle,
  faInfoCircle,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Id, Resource, Task } from '@landrush/common'
import { dateString, ialphaSort, isSameDay, timeString } from '@landrush/util'

import {
  BigIcon,
  Color,
  Column,
  DeleteIcon as DeleteControl,
  Progress,
  Spinner,
  TaggableRow,
} from 'components'
import { Resources, ResourceState } from 'store'
import { useAppDispatch, useAsync, useForwardingSelector } from 'utils'

type WithResource = { resource: Resource.Summary }

const InfoIcon: React.FC<WithResource> = ({ resource }) => {
  const { alias, createdAt } = resource

  if (Task.isFailure(resource)) {
    const { errors } = resource
    return (
      <Link to={`/info/${alias}`}>
        <div data-tip={errors} data-place='left' data-effect='solid'>
          <FontAwesomeIcon
            icon={faExclamation}
            className='control-icon error'
            fixedWidth
          />
          {/* <ReactTooltip /> */}
        </div>
      </Link>
    )
  }

  const at = isSameDay(createdAt, new Date())
    ? timeString(createdAt)
    : dateString(createdAt)
  const message = `Created: ${at}`
  return (
    <Link to={`/info/${alias}`}>
      <div
        data-tip={message}
        data-place='left'
        data-effect='solid'
        data-delay-show={400}
      >
        <FontAwesomeIcon
          className='control-icon enabled'
          icon={faInfoCircle}
          fixedWidth
        />
        {/* <ReactTooltip /> */}
      </div>
    </Link>
  )
}

const ViewIcon: React.FC<WithResource> = ({ resource }) => {
  const { alias } = resource
  if (Task.isSuccess(resource)) {
    return (
      <Link to={`view/${alias}`}>
        <FontAwesomeIcon
          className='control-icon enabled'
          icon={faMapMarkerAlt}
          fixedWidth
        />
      </Link>
    )
  }
  return (
    <FontAwesomeIcon
      icon={faMapMarkerAlt}
      className='control-icon disabled'
      fixedWidth
    />
  )
}

const ProgressDisplay: React.FC<WithResource> = ({ resource }) => {
  if (!Task.isRunning(resource)) return null

  const { index, total } = resource
  if (!index || !total) return <Spinner small />
  return <Progress index={index} total={total} className='resource-progress' />
}

const DeleteIcon: React.FC<WithResource> = ({ resource }) => {
  const dispatch = useAppDispatch()
  const { id } = resource

  const [{ error, dismissError, isPending }, remove] = useAsync(async () => {
    const { error } = await dispatch(Resources.remove(id))
    if (error) throw new Error(error)
    // On success we will be dismounted, no action needed.
  })

  return (
    <DeleteControl
      className='control-icon'
      onClick={remove}
      isPending={isPending}
      confirmation={
        <Column style={{ marginTop: 16 }}>
          <BigIcon
            icon={faExclamationTriangle}
            style={{ color: Color.dangerYellow }}
          />
          <p style={{ color: Color.gray }}>
            Delete resource{' '}
            <strong style={{ color: Color.darkGray }}>{resource.name}</strong>?
          </p>
        </Column>
      }
      error={error}
      dismissError={dismissError}
    />
  )
}

type Row = WithResource & {
  isNavigable: boolean
  recomputeHeight(): void
  style?: React.CSSProperties
}
export default ({ resource, isNavigable, recomputeHeight, style }: Row) => {
  const { id, name, alias, tags } = resource
  const dispatch = useAppDispatch()
  const isSelected = useForwardingSelector(
    ResourceState.selectIsResourceSelected,
    id
  )
  const toggleSelected = () => dispatch(ResourceState.toggleResource(id))
  const toggleFilterTag = (id: Id) =>
    dispatch(ResourceState.toggleFilterTag(id))

  useEffect(() => {
    const handle = window.setImmediate(recomputeHeight)
    return () => window.clearImmediate(handle)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, tags])

  return (
    <StyledRow
      name={
        <Link to={`/info/${alias}`}>
          <span className='resource-name'>{name}</span>
        </Link>
      }
      tags={tags.sort((a, b) => ialphaSort(a.name, b.name))}
      isSelected={isSelected}
      toggleSelected={toggleSelected}
      toggleFilterTag={toggleFilterTag}
      style={style}
    >
      <ProgressDisplay resource={resource} />
      {isNavigable && (
        <>
          <InfoIcon resource={resource} />
          <ViewIcon resource={resource} />
          <DeleteIcon resource={resource} />
        </>
      )}
    </StyledRow>
  )
}
const StyledRow = styled(TaggableRow)`
  .resource-name {
    color: ${Color.veryDarkGray};
    &:hover {
      color: ${Color.landrushOrange};
    }
  }

  .control-icon {
    margin-left: 48px;
    font-size: 20px;
  }

  .control-icon.enabled {
    cursor: pointer;
  }

  .control-icon.disabled {
    color: ${Color.veryLightGray};
  }

  .control-icon.error {
    color: ${Color.dangerRed};
  }

  .resource-progress {
    width: 12vw;
    min-width: 120px;
  }
`
