import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List as VList,
} from 'react-virtualized'

import { Button, InfoMessage, List as ItemList, Measures } from 'components'
import { ResourceState } from 'store'
import { useAppDispatch, useWindowWidth } from 'utils'

import { useVisibleResources } from './hooks'
import Row from './row'

type Props = { isNavigable?: boolean; isShort?: boolean }
export default ({ isNavigable = true, isShort = false }: Props) => {
  const dispatch = useAppDispatch()
  const resources = useVisibleResources()

  const list = useRef<VList | null>(null)
  const cache = useRef(
    new CellMeasurerCache({ fixedWidth: true, defaultHeight: 41 })
  )
  const width = useWindowWidth()
  useEffect(() => {
    list.current?.recomputeRowHeights(0)
    cache.current.clearAll()
  }, [width, resources.length])

  const filterText = useSelector(ResourceState.selectFilterText)
  const filterTags = useSelector(ResourceState.selectFilterTags)
  useEffect(() => {
    list.current?.scrollToPosition()
  }, [filterText, filterTags])

  const clearFilters = () => {
    dispatch(ResourceState.removeAllFilterTags())
    dispatch(ResourceState.setFilterText(''))
  }

  if (!resources.length) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
        <InfoMessage>
          Nothing here
          {(filterText.length > 0 || Object.keys(filterTags).length > 0) && (
            <Button
              style={{ marginTop: 16, marginBottom: 0 }}
              onClick={clearFilters}
            >
              Clear filters
            </Button>
          )}
        </InfoMessage>
      </div>
    )
  }

  const recomputeRowHeight = (index: number) => cache.current.clear(index, 0)

  // This is from inspecting a snapshot - should be calculated instead.
  const taggableHeaderHeight =
    42.5 +
    44 +
    8 +
    (Object.keys(filterTags).length ? 27 : 0) +
    (isShort ? 35 + 16 + 8 : 0)
  const offsets =
    Measures.headerHeight +
    Measures.footerHeight +
    Measures.bodyMarginTop +
    Measures.bodyMarginBottom +
    taggableHeaderHeight

  return (
    <ItemList.Container style={{ height: `calc(100vh - ${offsets}px)` }}>
      <AutoSizer>
        {({ width, height }) => (
          <VList
            ref={(ref) => (list.current = ref)}
            width={width}
            height={height}
            deferredMeasurementCache={cache.current}
            rowHeight={cache.current.rowHeight}
            rowCount={resources.length}
            overscanRowCount={25}
            rowRenderer={({ /* key, */ index, parent, style }) => (
              <CellMeasurer
                // Since our rowRenderer `key` is an index, using that key
                // causes all the issues you'd expect with dynamic data.  So
                // we are currently simply ignoring it and using a proper key -
                // react-virtualized doesn't seem to mind.
                key={resources[index].id}
                cache={cache.current}
                parent={parent}
                columnIndex={0}
                rowIndex={index}
              >
                <Row
                  // Make sure to pass a sticky key here - react-virtualized's
                  // key values are indices so we would run into issues with
                  // dynamically updated data here otherwise.
                  key={resources[index].id}
                  resource={resources[index]}
                  isNavigable={isNavigable}
                  recomputeHeight={() => recomputeRowHeight(index)}
                  style={style}
                />
              </CellMeasurer>
            )}
          />
        )}
      </AutoSizer>
    </ItemList.Container>
  )
}
