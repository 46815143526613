import { createEntityAdapter } from '@reduxjs/toolkit'

import { Execution, Id } from '@landrush/common'
import { ialphaSort } from '@landrush/util'

import { extractId } from 'store/utils'

type Omissions = 'resources' | 'regions' | 'workflow'
type Normalize<T> = Omit<T, Omissions> & {
  resourceIds: Id[]
  regionIds: Id[]
  workflowId: Id
}

declare namespace Normalized {
  export type Summary = Execution.Summary
  export type Detailed =
    | Normalize<Execution.Running>
    | Normalize<Execution.Failure>
    | Normalize<Execution.Success>
}

// eslint-disable-next-line no-redeclare
type Normalized = Normalized.Summary | Normalized.Detailed

function isDetailed(v: Normalized): v is Normalized.Detailed {
  return 'resourceIds' in v
}
function isSummary(v: Normalized): v is Normalized.Summary {
  return !isDetailed(v)
}

// eslint-disable-next-line no-redeclare
const Normalized = { isDetailed, isSummary }

export { Normalized }

export const normalize = (v: Execution.Summary): Normalized => {
  if (!Execution.isDetailed(v)) return v

  const { resources, regions, workflow, ...execution } = v
  return {
    resourceIds: resources.map(extractId),
    regionIds: regions.map(extractId),
    workflowId: extractId(workflow),
    ...execution,
  }
}

export const adapter = createEntityAdapter<Normalized.Summary>({
  sortComparer: (a, b) => {
    // Within a single batch, sort by name.  Otherwise by descending time.
    if (a.batchId === b.batchId) return ialphaSort(a.name, b.name)
    return b.createdAt.getTime() - a.createdAt.getTime()
  },
})
