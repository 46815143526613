import React, { CSSProperties, ReactNode } from 'react'
import styled from 'styled-components'

import { Spinner } from './spinner'
import { Style } from './style'

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: 8,
  ...Style.muted,
})

type Message = {
  spin?: boolean
  className?: string
  style?: CSSProperties
  children: ReactNode
}
export const Message = ({
  spin = false,
  className,
  style,
  children,
}: Message) => (
  <Container className={className} style={style}>
    {spin ? <Spinner /> : null}
    {children}
  </Container>
)
