import React, { Redirect, Route, Switch } from 'react-router-dom'

import AccountPage from 'account'
import ChangePassword from 'change-password'
import NotFound from 'not-found'

// Credentials.
import Credentials from 'credentials'
import AddCredential from 'add-credential'
import AddedDropbox from 'added-dropbox'

// Executions.
import Execution from 'execution'
import Executions from 'executions'
import AddExecution from 'add-execution'

// Regions.
import Region from 'region'
import Regions from 'regions'
import AddRegion from 'add-region'
import RegionTags from 'region-tags'

// Workflows.
import Workflow from 'workflow'
import WorkflowHistory from 'workflow-history'
import Workflows from 'workflows'
import AddWorkflow from 'add-workflow'

// Resources.
import Resource, { routes as resourceRoutes } from 'resource'
import Resources from 'resources'
import ResourceTags from 'resource-tags'
import AddResources from 'add-resources'
import SharedView from 'shared-view'

// Copies.
import Copies from 'copies'

const resourceMatch = resourceRoutes.join('|')
const resourcePaths = `/(${resourceMatch})/:alias`

const RedirectToHome = () => <Redirect to='/' />

export default function () {
  return (
    <Switch>
      <Route exact path='/login' component={RedirectToHome} />

      <Route exact path='/account' component={AccountPage} />
      <Route exact path='/change-password' component={ChangePassword} />
      <Route exact path='/credentials' component={Credentials} />
      <Route exact path='/add-credential' component={AddCredential} />
      <Route exact path='/added-dropbox' component={AddedDropbox} />
      <Route exact path='/add-resources' component={AddResources} />

      <Route exact path='/' component={Resources} />
      <Route exact path={resourcePaths} component={Resource} />
      <Route exact path='/resources/tags' component={ResourceTags} />

      <Route exact path='/regions' component={Regions} />
      <Route exact path='/add-regions' component={AddRegion} />
      <Route exact path='/regions/tags' component={RegionTags} />
      <Route exact path='/regions/:alias' component={Region} />

      <Route exact path='/add-workflow' component={AddWorkflow} />
      <Route exact path='/workflows' component={Workflows} />
      <Route
        exact
        path='/workflows/:alias/:version?'
        component={WorkflowHistory}
      />

      <Route exact path='/add-executions' component={AddExecution} />
      <Route exact path='/executions' component={Executions} />
      <Route exact path='/executions/:alias' component={Execution} />

      <Route exact path='/copies' component={Copies} />

      <Route exact path='/v/:token' component={SharedView} />

      <Route component={NotFound} />
    </Switch>
  )
}
