import { Bounds } from '@landrush/entwine'
import { Potree as P } from '@landrush/common'

import * as Movement from './movement'

type Background = P.Background
type PointColor = P.PointColor
type RampRange = P.RampRange
type ClipType = P.ClipType

type Point = number[]
type Callback<T> = (value: T, index: number) => void

const defaultFps = 20

const toArray3 = (p: any) => [p.x, p.y, p.z]
function extractBounds(boxVolume: any) {
  const center = toArray3(boxVolume.position)
  const radius = toArray3(boxVolume.scale).map((v) => v / 2)

  const min = center.map((v, i) => v - radius[i])
  const max = center.map((v, i) => v + radius[i])

  return min.concat(max)
}
function getSizes(bounds: Bounds) {
  return [bounds[3] - bounds[0], bounds[4] - bounds[1], bounds[5] - bounds[2]]
}
function getCenter(bounds: Bounds) {
  return getSizes(bounds).map((size, i) => bounds[i] + size / 2)
}

function pointColorToPotree(p: PointColor) {
  const map = {
    rgb: Potree.PointColorType.RGB,
    intensity: Potree.PointColorType.INTENSITY,
    intensityGradient: Potree.PointColorType.INTENSITY_GRADIENT,
    elevation: Potree.PointColorType.ELEVATION,
    classification: Potree.PointColorType.CLASSIFICATION,
    returnNumber: Potree.PointColorType.RETURN_NUMBER,
  }
  return map[p]
}

function clipTypeToPotree(c: ClipType) {
  switch (c) {
    case 'highlight':
      return Potree.ClipTask.HIGHLIGHT
    case 'filter':
      return Potree.ClipTask.SHOW_INSIDE
  }
}

export type Camera = { position: Point; target: Point; rotation: number }
export type OnMove = (camera: Camera) => void
export type OnTick = () => void
export type OnVolumeChange = (v: Bounds) => void
export type Destroy = () => void

export type Volume = {
  bounds?: Bounds
  destroy(): Promise<void>
}

export type Renderer = {
  viewer: Potree.Viewer

  onTick: OnTick
  onMove: OnMove
  loadPointCloud(path: string, name: string): Promise<Potree.PointCloudOctree>
  removePointCloud(pointCloud: Potree.PointCloudOctree): void

  setOnTick(f: OnTick): void

  addVolume(onChange: OnVolumeChange): Destroy
  setVolume(bounds: Bounds): Destroy
  setClipType(c: ClipType): void

  getZoom(bounds: Bounds): { position: Point; target: Point }
  setCamera(position: Point, target: Point): void
  zoomTo(bounds: Bounds, ms?: number): Promise<void>
  navigateTo(position: Point, target: Point, ms?: number): Promise<void>
  rotateToNorth(ms?: number): Promise<void>

  setFps(v: number): void
  setBackground(background: Background): void
  setPointSize(v: number): void
  setPointBudget(v: number): void
  setPointColor(v: PointColor): void

  setEdlEnabled(v: boolean): void
  setEdlRadius(v: number): void
  setEdlStrength(v: number): void

  setElevationRange(v: RampRange): void
  setIntensityRange(v: RampRange): void

  destroy(): void
}

export function create(
  el: HTMLDivElement,
  onMove: OnMove = () => {}
): Renderer {
  const viewer = (window.viewer = new Potree.Viewer(el, {
    useDefaultRenderLoop: false,
  }))

  // Initialize the viewer with some basic defaults (which may be immediately
  // overwritten) and hook up event handlers to track any state changes.
  viewer.setBackground('')
  viewer.useHQ = true
  viewer.useEDL = true
  viewer.addEventListener('camera_changed', () => {
    const v = viewer as any
    const position = v.scene.view.position.toArray()
    const target = v.scene.view.getPivot().toArray()
    const rotation = v.scene.getActiveCamera().rotation.z
    onMove({ position, target, rotation })
  })
  let onTick: OnTick = () => {}

  async function loadPointCloud(
    path: string,
    name: string
  ): Promise<Potree.PointCloudOctree> {
    return new Promise(async (resolve, reject) => {
      try {
        Potree.EptLoader.load(path, (geometry: Potree.Geometry) => {
          if (!geometry) {
            throw new Error(`Failed to load point cloud: ${name}`)
          }
          const pc = new Potree.PointCloudOctree(geometry)
          viewer.scene.addPointCloud(pc)
          resolve(pc)
        })
      } catch (e) {
        reject(e)
      }
    })
  }

  function removePointCloud(pc: Potree.PointCloudOctree) {
    const scene = viewer.scene as any
    scene.scenePointCloud.remove(pc)
    scene.pointclouds = scene.pointclouds.filter(
      (v: Potree.PointCloudOctree) => v !== pc
    )
  }

  function addVolume(onChange: OnVolumeChange) {
    const tool = new Potree.VolumeTool(viewer)
    const pv = tool.startInsertion({ clip: true })

    viewer.inputHandler.toggleSelection(pv)

    const onDrop = ({ target: boxVolume }: any) => {
      onChange(extractBounds(boxVolume))
    }
    const onMove = ({ object: boxVolume }: any) => {
      onChange(extractBounds(boxVolume))
    }
    pv.addEventListener('drop', onDrop)
    pv.addEventListener('position_changed', onMove)
    pv.addEventListener('scale_changed', onMove)

    return () => {
      viewer.dispatchEvent({ type: 'cancel_insertions' })
      viewer.inputHandler.drag = null
      pv.removeEventListener('drop', onDrop)
      pv.removeEventListener('position_changed', onMove)
      pv.removeEventListener('scale_changed', onMove)
      viewer.scene.removeVolume(pv)
    }
  }

  const v = viewer as any
  const volumeScene = new THREE.Scene()
  volumeScene.name = 'scene_volume'
  v.inputHandler.registerInteractiveScene(volumeScene)
  v.scene.addEventListener('volume_added', (e: any) =>
    volumeScene.add(e.volume)
  )
  v.scene.addEventListener('volume_removed', (e: any) =>
    volumeScene.remove(e.volume)
  )
  v.addEventListener('render.pass.scene', (e: any) => {
    v.renderer.render(volumeScene, v.scene.getActiveCamera(), e.renderTarget)
  })

  function setVolume(bounds: Bounds, zoom?: boolean) {
    const pv = new Potree.BoxVolume({ clip: true })
    pv.position.set(...getCenter(bounds))
    pv.scale.set(...getSizes(bounds))
    viewer.scene.addVolume(pv)

    // This is a readonly volume, so if it is selected by the input handler,
    // simply immediately deselect it.
    pv.addEventListener('select', () => viewer.inputHandler.deselect(pv))

    if (zoom) zoomTo(bounds)
    return () => viewer.scene.removeVolume(pv)
  }

  function setClipType(c: ClipType) {
    viewer.setClipTask(clipTypeToPotree(c))
  }

  function getZoom(bounds: Bounds) {
    return Movement.getZoom(bounds)
  }
  function setCamera(position: Point, target: Point) {
    Movement.setCamera({ viewer, position, target })
  }
  async function zoomTo(bounds: Bounds, ms = 0) {
    await Movement.zoomTo({ viewer, bounds, ms })
  }
  async function navigateTo(position: Point, target: Point, ms: number) {
    await Movement.navigateTo({ viewer, position, target, ms })
  }
  async function rotateToNorth(ms?: number) {
    await Movement.rotateToNorth({ viewer, ms })
  }

  function setBackground(background: Background) {
    viewer.setBackground(
      background === 'none' || background === 'terrain' ? '' : background
    )
  }

  function setEdlEnabled(isEdlEnabled: boolean) {
    viewer.setEDLEnabled(isEdlEnabled)
  }
  function setEdlRadius(edlRadius: number) {
    viewer.setEDLRadius(edlRadius)
  }
  function setEdlStrength(edlStrength: number) {
    viewer.setEDLStrength(edlStrength)
  }

  function forEachPointCloud(f: Callback<Potree.PointCloudOctree>) {
    viewer.scene.pointclouds.forEach(f)
  }
  function forEachMaterial(f: Callback<Potree.Material>) {
    forEachPointCloud((pc, index) => f(pc.material, index))
  }

  function setPointSize(pointSize: number) {
    forEachMaterial((m) => (m.size = pointSize))
  }
  function setPointBudget(pointBudget: number) {
    viewer.setPointBudget(pointBudget)
  }
  function setPointColor(pointColor: PointColor) {
    forEachMaterial((m) => (m.pointColorType = pointColorToPotree(pointColor)))
  }

  function setElevationRange(elevationRange: RampRange) {
    forEachMaterial((m) => (m.elevationRange = elevationRange))
  }
  function setIntensityRange(intensityRange: RampRange) {
    forEachMaterial((m) => (m.intensityRange = intensityRange))
  }

  function tick(timestamp: number) {
    viewer.update(viewer.clock.getDelta(), timestamp)
    viewer.render()
    onTick()
  }
  function setOnTick(f: OnTick) {
    onTick = f
  }

  function animate() {
    window.requestAnimationFrame(tick)
  }

  let delay: number = 1000 / defaultFps
  let interval: NodeJS.Timeout = setInterval(animate, delay)

  function setFps(fps: number) {
    delay = 1000 / fps
    clearInterval(interval)
    interval = setInterval(animate, delay)
  }

  function destroy() {
    clearInterval(interval)
  }

  return {
    viewer,
    onTick,
    onMove,
    setOnTick,

    loadPointCloud,
    removePointCloud,

    addVolume,
    setVolume,
    setClipType,

    getZoom,
    setCamera,
    zoomTo,
    navigateTo,
    rotateToNorth,
    setFps,

    setBackground,
    setPointSize,
    setPointBudget,
    setPointColor,

    setEdlEnabled,
    setEdlRadius,
    setEdlStrength,

    setElevationRange,
    setIntensityRange,

    destroy,
  }
}
export const Renderer = { create }
