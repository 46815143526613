import React from 'react'
import { useMount } from 'react-use'

import { Id, Resource } from '@landrush/common'

import { ErrorMessage, Message } from 'components'
import { Resources } from 'store'
import {
  ResourceContext,
  useAppDispatch,
  useAppSelector,
  useAsyncResult,
} from 'utils'

type Options = { id: Id; alias?: undefined } | { id?: undefined; alias: string }
export const ResourceProvider: React.FC<Options> = ({
  id,
  alias = '',
  children,
}) => {
  const dispatch = useAppDispatch()
  const resource: Resource.Summary | undefined = useAppSelector((state) =>
    id ? Resources.selectById(state, id) : Resources.selectByAlias(state, alias)
  )

  const [{ error }, load] = useAsyncResult(
    async () =>
      dispatch(id ? Resources.hydrate(id) : Resources.hydrateByAlias(alias)),
    [id, alias]
  )

  useMount(load)

  if (error) return <ErrorMessage onRetry={load}>{error}</ErrorMessage>
  if (!resource || !Resource.isDetailed(resource)) {
    return <Message spin>Loading...</Message>
  }

  return (
    <ResourceContext.Provider value={resource}>
      {children}
    </ResourceContext.Provider>
  )
}
