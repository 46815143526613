import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Bookmark, Resource } from '@landrush/common'

import { Color, DeleteIcon, InfoMessage, StyledTable } from 'components'
import { Resources, Viewer } from 'store'
import { useAppDispatch, useAsyncResult } from 'utils'

type StyledRow = { $isActive: boolean }
const StyledRow = styled.tr(({ $isActive }: StyledRow) =>
  $isActive
    ? `td { background-color: ${Color.landrushOrange}; }`
    : `&:hover { color: ${Color.landrushOrange}; }`
)

type Row = { resource: Resource.Success | Resource.Shared; bookmark: Bookmark }
const Row = ({ resource, bookmark }: Row) => {
  const isOwner = Resource.isOwned(resource)
  const { id, name, state } = bookmark

  const dispatch = useAppDispatch()
  const [isSelecting, setSelecting] = useState(false)

  const [
    { isPending: isDeleting, error, dismissError },
    remove,
  ] = useAsyncResult(async () => {
    return dispatch(Resources.bookmarks.remove(resource.id, id))
  })

  useEffect(() => {
    if (!isSelecting) return
    const timeout = setTimeout(() => setSelecting(false), 600)
    return () => clearTimeout(timeout)
  }, [isSelecting])

  const select = () => {
    setSelecting(true)
    dispatch(Viewer.apply(state))
  }

  return (
    <StyledRow $isActive={isSelecting}>
      <td className='name' onClick={select}>
        {name}
      </td>
      {isOwner ? (
        <td className='delete'>
          <DeleteIcon
            onClick={remove}
            isPending={isDeleting}
            error={error}
            dismissError={dismissError}
          />
        </td>
      ) : null}
    </StyledRow>
  )
}

const BookmarksTable = styled(StyledTable)`
  user-select: none;
  margin-top: 8px;

  td.name {
    cursor: pointer;
  }

  td.delete {
    text-align: right;
    padding-right: 15px;
  }
`

type WithResource = { resource: Resource.Success | Resource.Shared }
export default ({ resource }: WithResource) => {
  const { bookmarks } = resource
  if (!bookmarks.length) {
    return (
      <div style={{ paddingLeft: 12, paddingRight: 12, paddingTop: 8 }}>
        <InfoMessage
          icon='info'
          body='Bookmarks capture the viewer position and visual settings.'
        />
      </div>
    )
  }

  return (
    <BookmarksTable>
      <tbody>
        {bookmarks.map((bookmark) => (
          <Row key={bookmark.id} resource={resource} bookmark={bookmark} />
        ))}
      </tbody>
    </BookmarksTable>
  )
}
