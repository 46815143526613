import gfm from 'remark-gfm'
import React from 'react'
import Markdown from 'react-markdown'
import styled from 'styled-components'

import { Color, TextArea } from 'components'

type Readme = { readme: string }
export const Readme = ({ readme }: Readme) => {
  return (
    <ReadmeStyles>
      <Markdown plugins={[gfm]}>{readme}</Markdown>
    </ReadmeStyles>
  )
}
export const ReadmeStyles = styled.div`
  width: 100%;
`

type EditorWithPreview = {
  text: string
  setText: (text: string) => void
  isEnabled?: boolean
}
Readme.EditorWithPreview = ({
  text,
  setText,
  isEnabled = true,
}: EditorWithPreview) => {
  return (
    <Styles>
      <div className='side-by-side'>
        <ReadmeEditorField
          text={text}
          setText={setText}
          isEnabled={isEnabled}
        />
        <ReadmePreviewField text={text} />
      </div>
    </Styles>
  )
}

type EditorFieldProps = {
  text: string
  setText: (text: string) => void
  isEnabled: boolean
}
const ReadmeEditorField: React.FC<EditorFieldProps> = ({
  text,
  setText,
  isEnabled,
}) => (
  <div className='markdown-editor'>
    <TextArea value={text} setValue={setText} isEnabled={isEnabled} />
  </div>
)
type PreviewFieldProps = { text: string }
const ReadmePreviewField: React.FC<PreviewFieldProps> = ({ text }) => (
  <div className='markdown-preview'>
    <Markdown plugins={[gfm]}>{text}</Markdown>
  </div>
)

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;

  .readme-container {
    width: 100%;
    /* max-width: 800px; */
    align-self: center;
  }

  .buttons {
    align-self: center;
    display: flex;
    margin-top: 8px;
  }

  .cancel-readme-button {
    margin-right: 4px;
    background-color: ${Color.lightGray};
    &:hover {
      background-color: ${Color.dangerRed};
    }
  }

  .submit-readme-button {
    margin-left: 4px;
  }

  .edit-readme-icon {
    align-self: flex-end;
    cursor: pointer;
    font-size: 20px;
    margin-top: 8px;

    color: ${Color.lightGray};
    &:hover {
      color: ${Color.darkGray};
    }
  }

  .side-by-side {
    display: flex;
  }
  .markdown-editor {
    flex: 1 1 0;
    margin-right: 12px;
  }
  .markdown-preview {
    flex: 1 1 0;
    margin-left: 12px;
  }
`
