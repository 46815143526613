import React, { useEffect, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons'

import { JsonSerializable, Workflow } from '@landrush/common'
import { getSubstitutions } from '@landrush/util'

import {
  Button,
  Color,
  ErrorMessage,
  Overview,
  Readme,
  Spaced,
  Style,
  Tag,
  TextArea,
  TextInput,
  WorkflowAnalysis,
  WorkflowOption,
} from 'components'
import { Workflows } from 'store'
import { Json, useAppDispatch, useAsync } from 'utils'

import * as Dummy from './dummy'
import * as Preset from './preset'

type Pipeline = Workflow.Pipeline
type Reducers = Workflow.Reducers

type Formatter = { isValid: boolean; format(): void }
const Formatter: React.FC<Formatter> = ({ isValid, format }) =>
  isValid ? (
    <FontAwesomeIcon
      icon={faCheckCircle}
      className='format-icon valid'
      onClick={format}
    />
  ) : (
    <FontAwesomeIcon
      icon={faExclamationCircle}
      className='format-icon invalid'
    />
  )

type State = { pipeline?: Pipeline; reducers?: Reducers }
export default () => {
  const dispatch = useAppDispatch()
  const location = useLocation<State | undefined>()

  const [name, setName] = useState('')
  const [readme, setReadme] = useState<string>(
    '# My workflow\n\n- Markdown documentation'
  )
  const [pipelineText, setPipeline] = useState(
    location.state?.pipeline
      ? JSON.stringify(location.state.pipeline, null, 2)
      : Dummy.pipeline
  )
  const [reducersText, setReducers] = useState(
    location.state?.reducers
      ? JSON.stringify(location.state.reducers, null, 2)
      : Dummy.reducers
  )

  const pipeline = Json.isValid(pipelineText)
    ? (JSON.parse(pipelineText) as Workflow.Pipeline)
    : undefined
  const reducers = Json.isValid(reducersText)
    ? (JSON.parse(reducersText) as Workflow.Reducers)
    : undefined

  const [optionsText, setOptions] = useState<Workflow.Options>({})
  useEffect(() => {
    const subs = getSubstitutions([
      Workflow.eptReaderTemplate,
      pipeline,
      reducers,
    ])
    setOptions(
      subs.reduce<Workflow.Options>((result, name) => {
        result[name] = {
          description: Workflow.defaults[name]?.description || '',
          default: Workflow.defaults[name]?.default?.toString() || '',
          ...(optionsText || {})[name],
        }
        return result
      }, {})
    )
  }, [pipelineText, reducersText])

  const parseValue = (s: string): JsonSerializable.Primitive | undefined => {
    if (s === 'null') return null
    if (s === 'true') return true
    if (s === 'false') return false
    const n = parseFloat(s)
    if (!isNaN(n)) return n
    if (s.length > 0) return s
  }
  const options = Object.entries(optionsText).reduce<Workflow.Options>(
    (options, [name, { default: defaultValue = '', ...option } = {}]) => {
      options[name] = { ...option, default: parseValue(defaultValue as string) }
      return options
    },
    {}
  )

  const setOption = (name: string, option: Workflow.Option) =>
    setOptions({ ...optionsText, [name]: option })

  console.log('o', options.resolution)

  const [
    { error, dismissError, isPending, value },
    submit,
  ] = useAsync(async () => {
    if (!pipeline || !reducers) throw new Error('Awaiting valid JSON')
    console.log('Submitting:', options)
    const result = await dispatch(
      Workflows.add({ name, pipeline, reducers, readme, options })
    )
    if (result.error) throw new Error(result.error)
    return result.value
  }, [name, pipeline, reducers, readme, options])

  if (value) return <Redirect to={`/workflows/${value.alias}`} />

  const isValid = Boolean(name.length && pipeline)

  const setFromTemplate = ({
    name,
    readme,
    pipeline,
    reducers,
    options,
  }: Workflow.Add) => {
    setName(name)
    setPipeline(JSON.stringify(pipeline, null, 2))
    setReducers(JSON.stringify(reducers, null, 2))
    setReadme(readme || '# New workflow')
    setOptions(
      Object.entries(options || {}).reduce(
        (options, [name, option = {}]) => ({
          ...options,
          [name]: {
            ...option,
            default: option.default?.toString() || '',
          },
        }),
        {}
      )
    )
  }

  return (
    <Styles>
      <Overview page='workflows' />
      <Spaced>
        <h2 style={{ marginTop: 16 }}>Add workflow</h2>
        <span>
          <span style={Style.muted}>Presets:</span>
          <Tag
            name='DSM'
            color={Color.gray}
            onClick={() => setFromTemplate(Preset.dsm)}
          />
          <Tag
            name='DTM'
            color={Color.gray}
            onClick={() => setFromTemplate(Preset.dtm)}
          />
          <Tag
            name='HAG'
            color={Color.gray}
            onClick={() => setFromTemplate(Preset.hag)}
          />
          <Tag
            name='RII'
            color={Color.gray}
            onClick={() => setFromTemplate(Preset.rii)}
          />
        </span>
      </Spaced>

      {error ? (
        <ErrorMessage onDismiss={dismissError}> {error} </ErrorMessage>
      ) : null}

      <div className='submit-container'>
        <TextInput
          className='name'
          value={name}
          onChange={setName}
          placeholder='Workflow name'
          onSubmit={submit}
          isValid={isValid}
        />
        <Button
          isEnabled={name.length > 0 && Boolean(pipeline)}
          spin={isPending}
          onClick={() => {
            if (isValid) submit()
          }}
        >
          Submit
        </Button>
      </div>

      <Readme.EditorWithPreview
        text={readme}
        setText={setReadme}
        isEnabled={!isPending}
      />

      <div className='container'>
        <div className='editor-column'>
          <h3>Pipeline</h3>
          <div className='editor'>
            <TextArea value={pipelineText} setValue={setPipeline} />
            <Formatter
              isValid={Boolean(pipeline)}
              format={() => setPipeline(Json.format(pipelineText))}
            />
          </div>

          <h3>Reducers</h3>
          <div className='editor'>
            <TextArea value={reducersText} setValue={setReducers} />
            <Formatter
              isValid={Boolean(reducers)}
              format={() => setReducers(Json.format(reducersText))}
            />
          </div>
        </div>
        <div className='analysis-column'>
          {pipeline && reducers ? (
            <>
              <h3>Options</h3>
              {Object.entries(optionsText).map(([name, o = {}]) => (
                <WorkflowOption
                  key={name}
                  name={name}
                  description={o.description}
                  default={o.default as string}
                  isEnabled={!isPending}
                  set={setOption}
                />
              ))}

              <h3>Outputs</h3>
              <WorkflowAnalysis.Outputs
                items={Workflow.getOutputs({ pipeline, reducers })}
              />
            </>
          ) : (
            <ErrorMessage>Awaiting valid JSON</ErrorMessage>
          )}
        </div>
      </div>
    </Styles>
  )
}

const Styles = styled.div`
  .submit-container {
    display: flex;
    margin-bottom: 16px;
  }
  .name {
    margin-right: 8px;
  }
  .container {
    display: flex;
    padding: 0;
    margin-top: 24px;
  }
  .editor-column {
    flex: 1 1 0;
    margin-right: 8px;
  }
  .analysis-column {
    flex: 1 1 0;
    margin-left: 8px;
  }

  .editor {
    position: relative;
    margin-bottom: 16px;
  }
  .format-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 24px;
  }
  .valid {
    color: ${Color.successGreen};
    cursor: pointer;
  }
  .invalid {
    color: ${Color.dangerRed};
  }

  .tag {
    margin-left: 3px;
    &:hover {
      background-color: ${Color.darkGray};
    }
  }
`
