import React, { useState } from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'

import {
  Button,
  Color,
  Column,
  ErrorMessage,
  Style,
  StyledTable,
} from 'components'
import { Shares } from 'store'
import { useAppDispatch, useAsync, useResource } from 'utils'

import Share from './share'

const Empty = () => (
  <>
    <FontAwesomeIcon
      icon={faLock}
      style={{ color: Color.gray, paddingBottom: 8, fontSize: 42 }}
      fixedWidth
    />
    <div style={{ ...Style.small, marginBottom: 8 }}>
      This resource is <span style={Style.strong}>private</span>
    </div>
  </>
)

const Add = () => {
  const dispatch = useAppDispatch()
  const resource = useResource()

  const [{ isPending, error, dismissError }, add] = useAsync(async () => {
    const { error } = await dispatch(Shares.add(resource.id))
    if (error) throw new Error(error)
  })

  const { shares } = useResource()

  if (shares.length >= 3) return null
  return (
    <>
      {error ? (
        <ErrorMessage onDismiss={dismissError}>{error}</ErrorMessage>
      ) : null}
      <Button isEnabled={!isPending} onClick={add} spin={isPending}>
        Share
      </Button>
    </>
  )
}

type WithAddError = { addError(e: string): void }
const List = ({ addError }: WithAddError) => {
  const { shares } = useResource()
  return (
    <StyledTable>
      <tbody>
        {shares.map((share) => (
          <tr key={share.id}>
            <Share share={share} addError={addError} />
          </tr>
        ))}
      </tbody>
    </StyledTable>
  )
}

const Body = () => {
  const { shares } = useResource()

  const [errors, setErrors] = useState<string[]>([])
  const addError = (error: string) => setErrors([...errors, error])
  const dismissErrors = () => setErrors([])

  return (
    <Column>
      {errors.length ? (
        <ErrorMessage onDismiss={dismissErrors}>
          {errors.map((e) => (
            <p>{e}</p>
          ))}
        </ErrorMessage>
      ) : null}
      {shares.length ? (
        <>
          <List addError={addError} />
          <p className='public'>
            This resource is <span style={Style.strong}>public </span>- anyone
            with these links can view.
          </p>
        </>
      ) : (
        <Empty />
      )}
      <Add />
    </Column>
  )
}

export default () => {
  return (
    <Styles>
      <h4>Sharing</h4>
      <Body />
    </Styles>
  )
}

const Styles = styled.div`
  p.public {
    font-size: small;
    color: ${Color.gray};
    margin-bottom: 8px;
  }

  td.name {
    width: 100%;
    padding-right: 32px;
  }

  .icon-container {
    padding-right: 32px;
    font-size: 18px;
  }

  .icon {
    cursor: pointer;
    color: ${Color.lightGray};
    &:hover {
      color: ${Color.landrushOrange};
    }
  }
  .copied {
    color: ${Color.landrushOrange};
  }
  .save {
    font-size: 20px;
  }
`
