import { DependencyList, useCallback, useRef, useState } from 'react'
import { useMountedState } from 'react-use'

import { Result } from '@landrush/util'

export type FnReturningPromise = (...args: any[]) => Promise<any>

export type PromiseType<P extends Promise<any>> = P extends Promise<infer T>
  ? T
  : never

export type AsyncState<T> =
  | {
      isPending: boolean
      error?: undefined
      value?: undefined
    }
  | {
      isPending: true
      error?: string | undefined
      value?: T
    }
  | {
      isPending: false
      error: string
      value?: undefined
    }
  | {
      isPending: false
      error?: undefined
      value: T
    }

type StateFromFnReturningPromise<T extends FnReturningPromise> = AsyncState<
  PromiseType<ReturnType<T>>
>

export type AsyncFnReturn<T extends FnReturningPromise = FnReturningPromise> = [
  StateFromFnReturningPromise<T> & { dismissError(): void },
  T
]

export function useAsync<T extends FnReturningPromise>(
  fn: T,
  deps: DependencyList = [],
  initialState: StateFromFnReturningPromise<T> = { isPending: false }
): AsyncFnReturn<T> {
  const lastCallId = useRef(0)
  const isMounted = useMountedState()
  const [state, set] = useState<StateFromFnReturningPromise<T>>(initialState)
  const dismiss = () => set((prevState) => ({ ...prevState, error: undefined }))

  const callback = useCallback((...args: Parameters<T>): ReturnType<T> => {
    const callId = ++lastCallId.current
    set((prevState) => ({ ...prevState, isPending: true, error: undefined }))

    return fn(...args).then(
      (value) => {
        if (isMounted() && callId === lastCallId.current) {
          set({ value, isPending: false })
        }

        return value
      },
      (e) => {
        const error = e?.message || e || 'Unknown error'

        if (isMounted() && callId === lastCallId.current) {
          set({ error, isPending: false })
        }

        return error
      }
    ) as ReturnType<T>

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return [{ ...state, dismissError: dismiss }, (callback as unknown) as T]
}

type ResultFn<T> = () => Promise<Result<T>>
export function useAsyncResult<T>(f: ResultFn<T>, deps?: DependencyList) {
  return useAsync(async () => {
    const result = await f()
    if (Result.isSuccess(result)) return result.value
    throw new Error(result.error || 'Unknown error')
  }, deps)
}
