import { Options, Placement } from '@popperjs/core'
import React, { useEffect, useState } from 'react'
import { usePopper, PopperChildrenProps } from 'react-popper'

type Popper = {
  target: React.ReactNode
  popper: React.ReactNode
  isEnabled?: boolean
  placement?: Placement
  offset?: [number, number]
  strategy?: 'absolute' | 'fixed'
}
export const Popper: React.FC<Popper> = ({
  target,
  popper,
  isEnabled = true,
  placement = 'bottom',
  offset = [0, 8],
  strategy = 'fixed',
}) => {
  const [targetRef, setTargetRef] = useState<any>(null)
  const [popperRef, setPopperRef] = useState<any>(null)
  const [isOpen, setOpen] = useState(false)

  const { styles, attributes } = usePopper(targetRef, popperRef, {
    placement,
    modifiers: [{ name: 'offset', enabled: true, options: { offset } }],
    strategy,
  })

  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      if (
        targetRef &&
        !targetRef?.contains(e.target) &&
        popperRef &&
        !popperRef?.contains(e.target)
      ) {
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', onClick)
    return () => document.removeEventListener('mousedown', onClick)
  }, [targetRef, popperRef])

  return (
    <>
      <span
        style={{ cursor: 'pointer' }}
        ref={setTargetRef}
        onClick={() => setOpen((isOpen) => !isOpen && isEnabled)}
      >
        {target}
      </span>
      {isOpen && (
        <span
          ref={setPopperRef}
          style={{ ...styles.popper, zIndex: 1 }}
          {...attributes.popper}
        >
          {popper}
        </span>
      )}
    </>
  )
}
