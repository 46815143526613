import React from 'react'
import styled from 'styled-components'

const StyledHeader = styled.div({
  height: 44,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 20,
  paddingRight: 20,
  display: 'flex',
  alignItems: 'center',
})

type WithStyle = { style?: React.CSSProperties; className?: string }
export const PanelHeader: React.FC<WithStyle> = ({
  children,
  style,
  className,
}) => (
  <StyledHeader className={`${className} card-header`} style={style}>
    {children}
  </StyledHeader>
)

export const Panel: React.FC<WithStyle> = ({ children, style, className }) => (
  <div className={`${className} card`} style={style}>
    {children}
  </div>
)
