import React from 'react'
import { useMount } from 'react-use'

import { Id, Tag } from '@landrush/common'

import { ErrorMessage, Message } from 'components'
import { LoadState } from 'store/types'

import List from './list'
import Top from './top'

type ManageTags = {
  load(): void
  loadState?: LoadState
  loadError?: string
  tags: Tag[]
  addTag(params: Tag.Add): Promise<Tag>
  updateTag(id: Id, params: Tag.Patch): Promise<Tag>
  removeTag(id: Id): Promise<true>
}
export const ManageTags: React.FC<ManageTags> = ({
  load,
  loadState,
  loadError,
  tags,
  addTag,
  updateTag,
  removeTag,
}) => {
  useMount(load)

  if (loadError) return <ErrorMessage onRetry={load}>{loadError}</ErrorMessage>
  if (loadState !== 'done') return <Message spin>Loading...</Message>
  return (
    <>
      <Top tags={tags} addTag={addTag} />
      <List tags={tags} updateTag={updateTag} removeTag={removeTag} />
    </>
  )
}

export default ManageTags
