const Measures = {
  headerHeight: 80,
  footerHeight: 40,

  bodyMarginTop: 30,
  bodyMarginBottom: 45,

  sidebarIconWidth: 56,
  sidebarPaneWidth: 300,
}
export { Measures }
