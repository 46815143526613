import React from 'react'
import BadRcSlider, { Range as BadRange } from 'rc-slider'

import { Color } from './color'

// This project has bad type definitions.
const RcSlider = BadRcSlider as any
const Range = BadRange as any

const handleWidth = 34
const handleHeight = 26

const trackHeight = 14
const borderRadius = 4

const trackStyle = {
  backgroundColor: Color.landrushOrange,
  height: trackHeight,
  borderRadius: borderRadius,
  borderWidth: 1,
  borderColor: Color.veryLightGray,
  borderStyle: 'solid',
}

const handleStyle = {
  width: handleWidth,
  height: handleHeight,
  borderColor: Color.veryLightGray,
  borderWidth: 1,
  backgroundColor: 'white',
  borderStyle: 'solid',
  borderRadius: borderRadius,
}

type Props<T = number | number[]> = {
  min: number
  max: number
  step?: number
  value: T
  onChange(v: T): void
}

// TODO: Consolidate all this copy/paste.
export const Slider = ({
  value,
  min,
  max,
  step = 1,
  onChange,
}: Props<number>) => {
  return (
    <div
      style={{
        paddingLeft: handleWidth / 2,
        paddingRight: handleWidth / 2,
        marginBottom: handleHeight / 2,
      }}
    >
      <RcSlider
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={onChange}
        trackStyle={trackStyle}
        handleStyle={handleStyle}
        railStyle={{
          backgroundColor: 'white',
          height: trackHeight,
          borderRadius: borderRadius,
          borderWidth: 1,
          borderColor: Color.veryLightGray,
          borderStyle: 'solid',
        }}
        activeDotStyle={{
          borderColor: 'white',
          boxShadow: '0 0 5px white',
        }}
      />
    </div>
  )
}

export const RangeSlider = ({
  value,
  min,
  max,
  step = 1,
  onChange,
}: Props<number[]>) => {
  return (
    <div
      style={{
        paddingLeft: handleWidth / 2,
        paddingRight: handleWidth / 2,
        marginBottom: handleHeight / 2,
      }}
    >
      <Range
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={onChange}
        trackStyle={value.map(() => trackStyle)}
        handleStyle={value.map(() => handleStyle)}
        railStyle={{
          backgroundColor: 'white',
          height: trackHeight,
          borderRadius: borderRadius,
          borderWidth: 1,
          borderColor: Color.veryLightGray,
          borderStyle: 'solid',
        }}
        activeDotStyle={{
          borderColor: 'white',
          boxShadow: '0 0 5px white',
        }}
      />
    </div>
  )
}
