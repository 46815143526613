import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Credential, Id } from '@landrush/common'
import { ialphaSort } from '@landrush/util'

import { AppState, AppThunk } from 'store'
import { Session } from 'store/session'

type LoadState = 'none' | 'loading' | 'done'
type State = {
  loadState: LoadState
  error?: string
  credentials: Credential[]
}
const initialState: State = {
  loadState: 'none',
  credentials: [],
}

const slice = createSlice({
  name: 'credentials',
  initialState,
  reducers: {
    loadRequest(state) {
      state.loadState = 'loading'
      state.error = undefined
      state.credentials = []
    },
    loadFailure(state, action: PayloadAction<string>) {
      const error = action.payload
      state.loadState = 'none'
      state.error = error
    },
    loadSuccess(state, action: PayloadAction<Credential[]>) {
      const credentials = action.payload
      state.loadState = 'done'
      state.credentials = credentials
    },
    addSuccess(state, action: PayloadAction<Credential>) {
      const credential = action.payload
      state.credentials = [...state.credentials, credential].sort((a, b) =>
        ialphaSort(a.name, b.name)
      )
    },
    removeSuccess(state, action: PayloadAction<Id>) {
      const id = action.payload
      state.credentials = state.credentials.filter((c) => c.id !== id)
    },
    updateSuccess(state, action: PayloadAction<Credential>) {
      const credential = action.payload
      state.credentials = state.credentials.map((current) =>
        current.id === credential.id ? credential : current
      )
    },
  },
})

const { reducer, actions } = slice
const { loadRequest, loadFailure, loadSuccess } = actions

const selectSlice = (state: AppState) => state.credentials
const selectLoadState = createSelector(selectSlice, (s) => s.loadState)
const selectError = createSelector(selectSlice, (s) => s.error)
const selectCredentials = createSelector(selectSlice, (s) => s.credentials)
const selectDropbox = createSelector(selectCredentials, (credentials) =>
  credentials.find((c) => c.protocol === 'dropbox')
)
const findById = (state: AppState, id: Id) => {
  const c = selectCredentials(state).find((c) => c.id === id)
  if (!c) throw new Error('Invalid credential lookup')
  return c
}

const selectors = {
  selectSlice,
  selectLoadState,
  selectError,
  selectCredentials,
  selectDropbox,
  findById,
}

const load = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadRequest())
    const client = Session.selectClient(getState())
    const credentials = await client.credentials.list()
    dispatch(loadSuccess(credentials))
  } catch (e) {
    dispatch(loadFailure(e.message || 'Failed to load credentials'))
  }
}
const maybeLoad = (): AppThunk => async (dispatch, getState) => {
  const state = selectSlice(getState())
  if (state.loadState !== 'done') dispatch(load())
}

const thunks = { load, maybeLoad }

export const Credentials = { ...actions, ...thunks, ...selectors }
export { reducer }
