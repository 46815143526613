import { Landrush } from '@landrush/client'
import { Id, Tag } from '@landrush/common'
import { Result } from '@landrush/util'

import { AppThunk, AppDispatch, AppState, Session } from 'store'

type WithId = { id: Id }
export function extractId(v: WithId) {
  return v.id
}

export function extractRelations<Target, Relation extends WithId>(
  list: Target[],
  getRelations: (t: Target) => Relation[]
): Relation[] {
  type RelationMap = { [id: string]: Relation }
  return Object.values(
    list.reduce<RelationMap>((obj, target) => {
      getRelations(target).forEach((relation) => (obj[relation.id] = relation))
      return obj
    }, {})
  )
}

type WithTag = { tags: Tag[] }
export function extractTags<Target extends WithTag>(list: Target[]): Tag[] {
  type TagMap = { [id: string]: Tag }
  return Object.values(
    list.reduce<TagMap>((obj, target) => {
      target.tags.forEach((tag) => (obj[tag.id] = tag))
      return obj
    }, {})
  )
}

type Context = {
  dispatch: AppDispatch
  getState: () => AppState
  client: Landrush
}
type Action<Args extends unknown[], R> = (...args: [...Args]) => Promise<R>
export const createThunk = <Args extends unknown[], R>(
  f: (ctx: Context) => Action<Args, R>
): ((...args: [...Args]) => AppThunk<Result<R>>) => (...args) => async (
  dispatch,
  getState
) => {
  try {
    const client = Session.selectClient(getState())
    return Result.success(await f({ dispatch, getState, client })(...args))
  } catch (e) {
    return Result.failure(e.message)
  }
}
