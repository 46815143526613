import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { faBookmark } from '@fortawesome/free-solid-svg-icons'

import { Resource } from '@landrush/common'
import { Result } from '@landrush/util'

import { ErrorMessage, SidebarAddForm, Style } from 'components'
import { Resources, Viewer } from 'store'
import { useAppDispatch, useAsync, useResource } from 'utils'

import { StyledIcon } from '../icon'

import List from './list'

type Icon = { isActive: boolean; activate(): void }
export const Icon = ({ isActive, activate }: Icon) => {
  return (
    <StyledIcon
      className='icon'
      icon={faBookmark}
      $isActive={isActive}
      onClick={activate}
    />
  )
}

const Add = () => {
  const dispatch = useAppDispatch()
  const { id } = useResource()
  const rendererState = useSelector(Viewer.selectRendererState)
  const [name, setName] = useState('')

  const [
    { isPending, value, error, dismissError },
    submit,
  ] = useAsync(async () => {
    if (!name) throw new Error('Missing name')
    const result = await dispatch(
      Resources.bookmarks.add(id, name, rendererState)
    )
    if (Result.isFailure(result)) throw new Error(result.error)
    return result.value
  }, [id, name, rendererState])

  useEffect(() => setName(''), [value])

  return (
    <>
      {error ? (
        <ErrorMessage onDismiss={dismissError}>{error}</ErrorMessage>
      ) : null}
      <SidebarAddForm
        placeholder='Save current view...'
        text={name}
        setText={setName}
        submit={submit}
        isPending={isPending}
      />
    </>
  )
}

type WithResource = { resource: Resource.Success | Resource.Shared }
export const Tool: React.FC<WithResource> = ({ resource }) => {
  return (
    <>
      <h4 style={Style.alignCenter}>Bookmarks</h4>
      {!Resource.isShared(resource) && <Add />}
      <List resource={resource} />
    </>
  )
}
