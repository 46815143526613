import { Socket } from 'socket.io-client'
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Landrush, Auth } from '@landrush/client'
import {
  AccessToken,
  Copy,
  Execution,
  Id,
  Info,
  Resource,
  User,
} from '@landrush/common'

import { AppState, AppThunk } from 'store'
import * as Types from 'store/types'

import { Copies } from 'store/copies'
import { Staging } from 'store/staging'
import { Resources } from 'store/resources'
import { Executions } from 'store/executions'

// TODO: Remove Connection export.
export type Connection = { socket: typeof Socket; error?: string }
export type Account = { auth: Auth.Identity; user: User }

type FlowState = 'logging-in' | 'logging-out'

export type State = Types.LoadGuard & {
  flowState?: FlowState
  error?: string
  client?: Landrush
  user?: User
}
const initialState: State = {}

type LoadSuccess = { client: Landrush; user?: User }
const slice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    ...Types.createGuards(),
    loadSuccess(state, action: PayloadAction<LoadSuccess>) {
      const { client, user } = action.payload
      state.client = client
      state.user = user
      state.loadState = 'done'
    },
    flowRequest(state, action: PayloadAction<FlowState>) {
      const flowState = action.payload
      state.flowState = flowState
      state.error = undefined
    },
    flowFailure(state, action: PayloadAction<string>) {
      const error = action.payload
      state.error = error
    },
    loginSuccess(state, action: PayloadAction<User>) {
      const user = action.payload
      state.flowState = undefined
      state.user = user
    },
    logoutSuccess(state) {
      state.flowState = undefined
      state.user = undefined
    },
    addAccessTokenSuccess(state, action: PayloadAction<AccessToken>) {
      const t = action.payload
      if (!state.user) throw new Error('Invalid user state')
      state.user.accessTokens = [...state.user.accessTokens, t].sort(
        (a, b) => a.createdAt.getTime() - b.createdAt.getTime()
      )
    },
    deleteAccessTokenSuccess(state, action: PayloadAction<Id>) {
      const id = action.payload
      if (!state.user) throw new Error('Invalid user state')
      state.user.accessTokens = state.user.accessTokens.filter(
        (t) => t.id !== id
      )
    },
  },
})

const { reducer, actions } = slice
const {
  loadRequest,
  loadFailure,
  loadSuccess,
  flowRequest,
  flowFailure,
  logoutSuccess,
} = actions

const selectSlice = (state: AppState) => state.session
const selectLoadState = createSelector(selectSlice, (s) => s.loadState)
const selectLoadError = createSelector(selectSlice, (s) => s.loadError)
const selectFlowState = createSelector(selectSlice, (s) => s.flowState)
const selectIsLoggedIn = createSelector(selectSlice, (s) => Boolean(s.user))
const maybeSelectClient = createSelector(selectSlice, (s) => s.client)
const selectIsLoaded = createSelector(maybeSelectClient, (client) => !!client)
const selectIsLoggingIn = createSelector(
  selectFlowState,
  (f) => f === 'logging-in'
)
const selectIsLoggingOut = createSelector(
  selectFlowState,
  (f) => f === 'logging-out'
)
const selectUnconnectedClient = createSelector(maybeSelectClient, (client) => {
  if (!client) throw new Error('Invalid session')
  return client
})
const selectConfig = createSelector(
  selectUnconnectedClient,
  (client) => client.config
)
const selectClient = createSelector(selectUnconnectedClient, (client) => {
  if (!client.connected()) throw new Error('Not connected')
  return client
})

const selectors = {
  selectSlice,
  selectLoadState,
  selectLoadError,
  selectFlowState,
  maybeSelectClient,
  selectIsLoaded,
  selectIsLoggedIn,
  selectIsLoggingIn,
  selectIsLoggingOut,
  selectUnconnectedClient,
  selectConfig,
  selectClient,
}

type Channels = {
  info: Info.Event[]
  resource: Resource.Event[]
  execution: Execution.Event[]
  copy: Copy.Event[]
}

const load = (): AppThunk<User | undefined> => async (dispatch) => {
  try {
    dispatch(loadRequest())
    const client = await Landrush.create(
      window.LANDRUSH_API_ROOT || process.env.REACT_APP_API_URL
    )
    const user = client.connected() ? await client.users.add() : undefined

    client.events.attach((list) => {
      const channels = list.reduce<Channels>(
        (channels, event) => {
          if (event.target === 'info') channels.info.push(event)
          else if (event.target === 'resource') channels.resource.push(event)
          else if (event.target === 'execution') channels.execution.push(event)
          else if (event.target === 'copy') channels.copy.push(event)
          return channels
        },
        { info: [], resource: [], execution: [], copy: [] }
      )

      dispatch(Staging.receive(channels.info))
      dispatch(Resources.receive(channels.resource))
      dispatch(Executions.receive(channels.execution))
      dispatch(Copies.receive(channels.copy))
    })

    dispatch(loadSuccess({ client, user }))
    return user
  } catch (e) {
    dispatch(loadFailure(e.message))
  }
}

const logout = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(flowRequest('logging-out'))
    const client = selectClient(getState())

    await dispatch(Resources.destroy())

    client.events.detach()

    await client.auth.logout()
    dispatch(logoutSuccess())
  } catch (e) {
    dispatch(flowFailure(e.message))
  }
}

const thunks = { load, logout }

export const Session = { ...actions, ...selectors, ...thunks }
export { reducer }
