export class ResponseError extends Error {
  code: number
  body: any
  constructor(code: number, text: string, body?: any) {
    super(text)
    this.code = code
    this.body = body
  }
}

export async function request(url: RequestInfo, options?: RequestInit) {
  if (options?.body) {
    options = {
      ...options,
      body: JSON.stringify(options.body),
      headers: {
        'content-type': 'application/json',
      },
    }
  }

  const response = await fetch(url, options)
  const { ok, status, statusText } = response
  if (!ok) {
    const type = response.headers.get('content-type')
    const body =
      type && type.startsWith('application/json')
        ? await response.json()
        : await response.text()
    throw new ResponseError(status, statusText, body)
  }
  return response.json()
}

type Coerce<T> = (data: any) => T
function noop<T>(data: any): T {
  return data
}

export async function get<T = any>(url: string, coerce: Coerce<T> = noop) {
  return coerce(await request(url))
}

export async function put<T = any>(
  url: string,
  body?: any,
  coerce: Coerce<T> = noop
) {
  return coerce(await request(url, { method: 'PUT', body }))
}

export async function post<T = any>(
  url: string,
  body?: any,
  coerce: Coerce<T> = noop
) {
  return coerce(await request(url, { method: 'POST', body }))
}

export async function patch<T = any>(
  url: string,
  body?: any,
  coerce: Coerce<T> = noop
) {
  return coerce(await request(url, { method: 'PATCH', body }))
}

export async function del<T = any>(url: string, coerce: Coerce<T> = noop) {
  return coerce(await request(url, { method: 'DELETE' }))
}

export const Fetch = {
  Error: ResponseError,
  request,
  get,
  put,
  post,
  patch,
  delete: del,
}
export default Fetch
