import React from 'react'
import { useSelector } from 'react-redux'

import { Id, Tag } from '@landrush/common'
import { ialphaSort, Result } from '@landrush/util'

import { TaggableHeader } from 'components'
import { Regions, RegionState } from 'store'
import { useAppDispatch, useAsync } from 'utils'

import { useTags, useVisibleRegions } from './hooks'

type Props = {
  isModifiable?: boolean
}
export default ({ isModifiable = true }: Props) => {
  const dispatch = useAppDispatch()
  const tags = useTags()
  const visibleRegions = useVisibleRegions()
  const visibleCount = visibleRegions.length
  const regionsCount = useSelector(Regions.selectCount)
  const selectedCount = useSelector(RegionState.selectSelectedRegionsCount)

  const isEverythingVisibleSelected = selectedCount === visibleCount

  const handleAll = () => {
    if (isEverythingVisibleSelected) dispatch(RegionState.performUnselectAll())
    else {
      dispatch(
        RegionState.performSelectMany(visibleRegions.map((region) => region.id))
      )
    }
  }

  const filterText = useSelector(RegionState.selectFilterText)
  const setFilterText = (text: string) =>
    dispatch(RegionState.setFilterText(text))

  const tagEntities = useSelector(Regions.Tags.selectEntities)
  const filterTags = Object.keys(useSelector(RegionState.selectFilterTags))
    .map((id) => tagEntities[id])
    .filter((tag): tag is Tag => Boolean(tag))
    .sort((a, b) => ialphaSort(a.name, b.name))
  const removeFilterTag = (id: Id) => dispatch(RegionState.removeFilterTag(id))

  const [{ isPending: isApplyingTag }, applyTag] = useAsync(
    async (id: Id) => {
      if (!selectedCount) return dispatch(RegionState.toggleFilterTag(id))

      const result = await dispatch(RegionState.applyTag(id))
      if (Result.isFailure(result)) {
        dispatch(RegionState.addError(result.error))
        throw new Error(result.error)
      } else return result.value
    },
    [selectedCount]
  )

  const selected = useSelector(RegionState.selectSelectedRegions)
  const [
    { isPending: isRemovingSelected },
    removeSelected,
  ] = useAsync(
    async () => dispatch(RegionState.removeSelected(Object.keys(selected))),
    [selected]
  )

  return (
    <TaggableHeader
      targetName='region'
      totalTargetsCount={regionsCount}
      selectedTargetsCount={selectedCount}
      visibleTargetsCount={visibleCount}
      filterText={filterText}
      setFilterText={setFilterText}
      filterTags={filterTags}
      removeFilterTag={removeFilterTag}
      handleCheckAll={handleAll}
      tags={tags}
      isModifiable={isModifiable}
      applyTag={applyTag}
      isApplyingTag={isApplyingTag}
      handleRemoveSelected={removeSelected}
      isRemovingSelected={isRemovingSelected}
    />
  )
}
