import { Id, Workflow } from '@landrush/common'
import * as Utils from 'store/utils'

import * as Selectors from './selectors'
import * as Workflows from './slice'

export const list = Utils.createThunk(
  ({ dispatch, getState, client }) => async () => {
    if (!Selectors.selectIsListed(getState())) {
      const workflows = await client.workflows.list()
      dispatch(Workflows.actions.upsert(workflows))
      dispatch(Workflows.actions.setListed())
    }

    return Selectors.selectAll(getState())
  }
)

export const add = Utils.createThunk(
  ({ dispatch, client }) => async (params: Workflow.Add) => {
    const workflow = await client.workflows.add(params)
    dispatch(Workflows.actions.upsert([workflow]))
    return workflow
  }
)

export const hydrate = Utils.createThunk(
  ({ dispatch, getState, client }) => async (id: Id) => {
    const existing = Selectors.selectById(getState(), id)
    if (existing && Workflow.isDetailed(existing)) return existing

    const workflow = await client.workflows.get(id)
    dispatch(Workflows.actions.upsert([workflow]))
    return workflow
  }
)

export const hydrateByAlias = Utils.createThunk(
  ({ dispatch, getState, client }) => async (alias: string) => {
    const existing = Selectors.selectByAlias(getState(), alias)
    if (existing && Workflow.isDetailed(existing)) return existing

    const workflow = await client.workflows.getByAlias(alias)
    dispatch(Workflows.actions.upsert([workflow]))
    return workflow
  }
)

export const patch = Utils.createThunk(
  ({ dispatch, client }) => async (id: Id, patch: Workflow.Patch) => {
    const workflow = await client.workflows.patch(id, patch)
    dispatch(Workflows.actions.update([workflow]))
    return workflow
  }
)

export const remove = Utils.createThunk(
  ({ dispatch, client }) => async (id: Id) => {
    await client.workflows.remove(id)
    dispatch(Workflows.actions.remove([id]))
    return id
  }
)
