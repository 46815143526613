import { PathType } from 'forager'
import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuid } from 'uuid'

import { Event, Id, Info } from '@landrush/common'
import { EntwineSettings } from '@landrush/entwine'
import { ialphaSort } from '@landrush/util'

import { AppState, AppThunk } from 'store'
import { Credentials } from 'store/credentials'
import { Session } from 'store/session'

export declare namespace Staging {
  export type ItemIdentifier = {
    credentialId?: string
    type: PathType
    path: string
  }
  export type Item = {
    identifier: ItemIdentifier
    info?: Info
  }
}
type ItemIdentifier = Staging.ItemIdentifier
type Item = Staging.Item

const itemEquals = (a: ItemIdentifier, b: ItemIdentifier) =>
  a.credentialId === b.credentialId && a.type === b.type && a.path === b.path

type State = { error?: string; items: Item[] }
const initialState: State = { items: [] }

type InfoPayload = { identifier: ItemIdentifier; info?: Info }
const slice = createSlice({
  name: 'staging',
  initialState,
  reducers: {
    setError(state, action: PayloadAction<string | undefined>) {
      const error = action.payload
      state.error = error
    },
    initialize(state, action: PayloadAction<ItemIdentifier>) {
      const identifier = action.payload
      // Expected to be the case only on a redo-scan dispatch.
      const exists = state.items.some((item) =>
        itemEquals(item.identifier, identifier)
      )
      if (exists) return
      state.items = [
        // Remove any paths are encapsulated by the new path.
        ...state.items.filter(
          ({ identifier: existing }) =>
            existing.credentialId !== identifier.credentialId ||
            !existing.path.startsWith(`${identifier.path}/`)
        ),
        { identifier },
      ].sort((a, b) => ialphaSort(a.identifier.path, b.identifier.path))
    },
    set(state, action: PayloadAction<InfoPayload>) {
      const { identifier, info } = action.payload
      state.items = state.items.map((v) =>
        itemEquals(v.identifier, identifier) ? { identifier, info } : v
      )
    },
    receive(state, action: PayloadAction<Event[]>) {
      action.payload.forEach((v) => {
        if (v.target !== 'info') return

        if (v.action === 'update') {
          const update = v.payload
          state.items = state.items.map((item) => {
            if (item?.info?.id !== update.id) return item
            const next: Item = { ...item, info: Info.update(item.info, update) }
            return next
          })
        }
      })
    },
    remove(state, action: PayloadAction<ItemIdentifier>) {
      const identifier = action.payload
      state.items = state.items.filter(
        (v) => !itemEquals(v.identifier, identifier)
      )
    },
    destroy(state) {
      state.items = []
    },
  },
})

const { actions, reducer } = slice
const { setError, initialize, set, remove } = actions

const add = (
  identifier: ItemIdentifier,
  partialSettings?: Partial<EntwineSettings.Info>
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(initialize(identifier))
    const client = Session.selectClient(getState())

    const credentialId = identifier.credentialId
    const settings = { ...partialSettings, input: identifier.path }

    const info = await client.info.add({ settings, credentialId })
    dispatch(set({ identifier, info }))
  } catch (e) {
    const info: Info.Failure = {
      id: uuid(),
      createdAt: new Date(),
      status: 'failure',
      errors: [e.message],
    }
    dispatch(set({ identifier, info }))
  }
}
const cancel = (item: Item): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const client = Session.selectClient(getState())
      if (item.info) await client.info.remove(item.info.id)
      dispatch(remove(item.identifier))
    } catch (e) {
      dispatch(setError(e.message))
    }
  }
}
const redo = (identifier: ItemIdentifier): AppThunk => async (dispatch) => {
  dispatch(set({ identifier }))
  dispatch(add(identifier, { force: true }))
}

const thunks = { add, cancel, redo }

const selectSlice = (state: AppState) => state.staging
const selectError = createSelector(selectSlice, (s) => s.error)
const selectItems = createSelector(selectSlice, (s) => s.items)
const selectStagedCredential = (state: AppState) => {
  const ids = selectItems(state)
    .map((v) => v.identifier.credentialId)
    .filter((c): c is Id => Boolean(c))
  const id = ids.length ? ids[0] : undefined
  if (!id) return
  return Credentials.findById(state, id)
}
const selectors = {
  selectSlice,
  selectError,
  selectItems,
  selectStagedCredential,
}

// eslint-disable-next-line no-redeclare
export const Staging = {
  itemEquals,
  ...actions,
  ...thunks,
  ...selectors,
}
export { reducer }
