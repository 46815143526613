import { PathInfo } from 'forager'
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppState, AppThunk } from 'store'
import { Credentials } from 'store/credentials'
import { Session } from 'store/session'

type DirProps = {
  credentialId: string
  root: string
  parts: string[]
}
type DirState = {
  isLoading: boolean
  error?: string
  items: PathInfo[]
}
type DirMap = { [key: string]: DirState }

function getDirectoryKey({ credentialId, root, parts }: DirProps) {
  return `${credentialId}@${root}/${parts.join('/')}`
}

function getParentDirectory({ parts, ...cwd }: DirProps): DirProps | undefined {
  if (parts.length) return { ...cwd, parts: parts.slice(0, -1) }
}

export declare namespace Navigator {
  export namespace Directory {
    export type Props = DirProps
    export type State = DirState
    export type Map = DirMap
  }
}

type State = { directories: DirMap; cwd?: DirProps }
const initialState: State = { directories: {} }

const slice = createSlice({
  name: 'navigator',
  initialState,
  reducers: {
    setCwd(state, action: PayloadAction<DirProps | undefined>) {
      const dir = action.payload
      state.cwd = dir
    },
    setDirectoryState(
      state,
      action: PayloadAction<{ dirprops: DirProps; dirstate: DirState }>
    ) {
      const { dirprops, dirstate } = action.payload
      state.directories[getDirectoryKey(dirprops)] = dirstate
    },
    destroy(state) {
      state.directories = {}
      state.cwd = undefined
    },
  },
})
const { reducer, actions } = slice
const { setCwd, setDirectoryState } = actions

const selectSlice = (state: AppState) => state.navigator
const maybeSelectCwd = createSelector(selectSlice, (s) => s.cwd)
const forceSelectCwd = createSelector(maybeSelectCwd, (cwd) => {
  if (!cwd) throw new Error('No directory selected')
  return cwd
})
const selectCwdState = (state: AppState) => {
  const cwd = forceSelectCwd(state)
  return selectSlice(state).directories[getDirectoryKey(cwd)]
}
const maybeSelectCwdCredential = createSelector(
  maybeSelectCwd,
  Credentials.selectCredentials,
  (cwd, credentials) => {
    if (!credentials || !cwd) return
    return credentials.find((c) => c.id === cwd.credentialId)
  }
)
const selectCwdCredential = createSelector(
  forceSelectCwd,
  Credentials.selectCredentials,
  (cwd, credentials) => {
    const c = credentials.find((c) => c.id === cwd.credentialId)
    if (!c) throw new Error('Failed to look up credential')
    return c
  }
)
const findDirectory = (state: AppState, dir: DirProps) => {
  const found = selectSlice(state).directories[getDirectoryKey(dir)]
  if (!found) throw new Error('Failed to find directory')
  return found
}

const selectors = {
  selectSlice,
  maybeSelectCwd,
  forceSelectCwd,
  selectCwdState,
  maybeSelectCwdCredential,
  selectCwdCredential,
  findDirectory,
}

const list = (dirprops: DirProps): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(
      setDirectoryState({
        dirprops,
        dirstate: { isLoading: true, items: [] },
      })
    )

    const { credentialId, root, parts } = dirprops
    let path = [root].concat(parts).join('/')
    if (path === '') path = '/'

    const client = Session.selectClient(getState())
    const items = await client.credentials.paths.list(credentialId, path)
    dispatch(
      setDirectoryState({
        dirprops,
        dirstate: { isLoading: false, items },
      })
    )
  } catch (e) {
    dispatch(
      setDirectoryState({
        dirprops,
        dirstate: {
          isLoading: false,
          error: e.message || 'Failed to list path',
          items: [],
        },
      })
    )
  }
}

const listCwd = (): AppThunk => async (dispatch, getState) => {
  const dirprops = forceSelectCwd(getState())
  dispatch(list(dirprops))
}

const navigateTo = (dirprops: DirProps | undefined): AppThunk => {
  return async (dispatch, getState) => {
    if (!dirprops) {
      dispatch(setCwd(undefined))
      return
    }

    const key = getDirectoryKey(dirprops)
    const isNew = !(key in selectSlice(getState()).directories)

    if (isNew) {
      dispatch(
        setDirectoryState({
          dirprops,
          dirstate: { isLoading: true, items: [] },
        })
      )
    }

    dispatch(setCwd(dirprops))
    if (isNew) dispatch(listCwd())
  }
}

const thunks = { navigateTo, listCwd }

// eslint-disable-next-line no-redeclare
export const Navigator = {
  ...actions,
  ...thunks,
  ...selectors,
  getDirectoryKey,
  getParentDirectory,
}
export { reducer }
