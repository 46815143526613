import CopyToClipboard from 'react-copy-to-clipboard'
import React, { useState } from 'react'

import { Srs } from '@landrush/entwine'
import { Color, Style } from 'components'

type Props = { srs?: Srs }
export const SrsDisplay = ({ srs }: Props) => {
  const [copied, setCopied] = useState(false)
  function copy() {
    setCopied(true)
    setTimeout(() => setCopied(false), 500)
  }

  const prettySrs = srs ? Srs.beautify(srs) : undefined
  if (!prettySrs) return <div style={Style.muted}>None</div>

  const maxLength = 48
  if (prettySrs.length > maxLength + 8) {
    return (
      <div>
        <span style={{ marginRight: 6 }}>{prettySrs.slice(0, maxLength)}</span>
        <CopyToClipboard text={prettySrs}>
          {copied ? (
            <i
              className='fas fa-check'
              style={{ color: Color.landrushOrange }}
            />
          ) : (
            <span
              onClick={copy}
              style={{
                ...Style.clickable,
                ...Style.strong,
                color: Color.landrushOrange,
              }}
            >
              ...
            </span>
          )}
        </CopyToClipboard>
      </div>
    )
  }
  return <div>{prettySrs}</div>
}
