import React from 'react'
import styled from 'styled-components'

import { Color } from 'components'

export default () => {
  return (
    <Styles>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <h1>Point clouds</h1>
            <p>
              Point clouds are a difficult management challenge for many
              organizations. They are bulky to manage, expensive to collect,
              frustrating to compute, and puzzling to analyze.
            </p>
            <p>
              Landrush attacks their bulk and computation by enabling your
              organization to visualize your entire data holding at once in a
              web browser and to apply computational algorithms across the data
              using cloud computing.
            </p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <h1>Web Services</h1>
            <p>
              Landrush delivers{' '}
              <a href='https://entwine.io'>Entwine Point Tile</a> (EPT) web
              services over the internet. EPT allows applications such as{' '}
              <a href='https://www.safe.com/fme/'>Safe FME</a> and{' '}
              <a href='https://pdal.io'>PDAL</a> to incrementally ask for data
              in support of visualization or processing tasks.
            </p>
            <p>
              USGS also delivers the{' '}
              <a href='https://usgs.entwine.io'>3DEP LiDAR</a> data via Amazon
              Web Services using EPT.
            </p>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-lg-4'>
            <p className='emphasis'>
              Landrush simplifies management of massive point cloud collections
              by putting the power of cloud computing in your hands
            </p>
          </div>
          <div className='col-xs-6 col-lg-2'>
            <h4>Any type</h4>
            <p>
              Landrush supports LiDAR, SONAR, and dense-matched imagery point
              cloud data types.
            </p>
          </div>
          <div className='col-xs-6 col-lg-2'>
            <h4>Any format</h4>
            <p>
              Landrush supports any point cloud format readable by the{' '}
              <a href='https://pdal.io'>PDAL</a> open source library.
            </p>
          </div>
          <div className='col-xs-6 col-lg-2'>
            <h4>Any size</h4>
            <p>
              No point cloud or collection of point clouds is too large. Data
              for an entire country can be processed by Landrush at one time.
            </p>
          </div>
          <div className='col-xs-6 col-lg-2'>
            <h4>Any application</h4>
            <p>
              Landrush provides data service endpoints to deliver data to your
              own applications.
            </p>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-lg-6'>
            <h4>Any density</h4>
            <p>
              Landrush supports the storage and management of any LiDAR
              collection mode:
            </p>
            <ul>
              <li>Aerial</li>
              <li>Mobile </li>
              <li>Terrestrial</li>
            </ul>
          </div>
          <div className='col-xs-12 col-lg-6'>
            <h4>Access from anywhere</h4>
            <p>
              Browser-based visualization of your data frees you from special
              purpose point cloud applications. Any browser that supports WebGL
              2.0 is supported.
            </p>
          </div>
        </div>
      </div>
    </Styles>
  )
}

const Styles = styled.div`
  width: 100%;

  .container {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
  }

  .emphasis {
    font-size: 28px;
    color: ${Color.darkGray};
  }
`
