import React from 'react'
import { useSelector } from 'react-redux'
import Media from 'react-media'

import { Info, Task } from '@landrush/common'
import { PointCloudInfo, Srs } from '@landrush/entwine'
import { pretty } from '@landrush/util'

import { SrsDisplay } from 'components'
import { Staging } from 'store'

import Position from './position'
import MapDisplay from './map'
import Warnings from './warnings'

type Details = { srs: Srs | undefined; points: number }
const Details: React.FC<Details> = ({ srs, points }) => {
  const hstyle = { marginBottom: 2 }
  const Pad = () => <div style={{ paddingBottom: 12 }} />
  return (
    <>
      <h4 style={hstyle}>Coordinate system</h4>
      <SrsDisplay srs={srs} />
      <Pad />

      <h4 style={hstyle}>Points</h4>
      {pretty(points)}
      <Pad />
    </>
  )
}

export default () => {
  const items = useSelector(Staging.selectItems)
  const infoList = items
    .map((item) => item.info)
    .filter((info): info is Info => Boolean(info))
    .filter(Task.isSuccess)

  if (!infoList.length) return null

  const { bounds, points, schema, srs } = PointCloudInfo.reduce(infoList)

  const flexStyle = { flex: '1 1 0', width: '50%' }
  return (
    <>
      <div style={{ paddingTop: 24, paddingLeft: 20, paddingRight: 20 }}>
        <Media query={{ minWidth: 767 }}>
          {(matches) =>
            matches ? (
              <div style={{ display: 'flex' }}>
                <div style={{ ...flexStyle, paddingRight: 4 }}>
                  <Details points={points} srs={srs} />
                </div>
                <div style={{ ...flexStyle, paddingLeft: 4 }}>
                  <MapDisplay />
                </div>
              </div>
            ) : (
              <>
                <MapDisplay />
                <Details points={points} srs={srs} />
              </>
            )
          }
        </Media>

        <Warnings />
      </div>
      <Position bounds={bounds} schema={schema} />
    </>
  )
}
