import React from 'react'

import { ErrorMessage, Panel } from 'components'

import { Credential } from '@landrush/common'

import Header from './header'
import { useCredentialState } from './state'
import Paths from './paths'

type WithCredential = { credential: Credential }
export default ({ credential }: WithCredential) => {
  const state = useCredentialState(credential)
  const { error, dismissError } = state

  return (
    <Panel style={{ marginTop: 8, marginBottom: 16 }}>
      <Header {...state} />
      {error ? (
        <ErrorMessage style={{ marginTop: 8 }} onDismiss={dismissError}>
          {error}
        </ErrorMessage>
      ) : (
        <Paths {...state} />
      )}
    </Panel>
  )
}
