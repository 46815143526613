import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { Color } from './color'

type StyledInput = { isEnabled: boolean }
const StyledInput = styled.input(({ isEnabled }: StyledInput) => ({
  width: '100%',
  flex: 1,
  paddingTop: 6,
  paddingBottom: 6,
  paddingLeft: 12,
  paddingRight: 12,
  marginTop: 0,
  marginBottom: 8,
  borderTopWidth: 1,
  borderBottomWidth: 1,
  borderLeftWidth: 1,
  borderRightWidth: 1,
  borderStyle: 'solid',
  borderColor: 'rgb(206, 212, 218)',
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
  borderBottomLeftRadius: 4,
  borderBottomRightRadius: 4,
  '&:focus': {
    boxShadow: `inset 0 0 2px ${Color.landrushOrange}`,
    borderColor: Color.landrushOrange,
    outline: 'none',
  },
  backgroundColor: isEnabled ? 'white' : Color.disabled,
}))

const StyledPrefix = styled.div({
  backgroundColor: '#f0f0f0',
  borderStyle: 'solid',
  borderColor: 'rgb(206, 212, 218)',
  borderTopWidth: 1,
  borderBottomWidth: 1,
  borderLeftWidth: 1,
  borderRightWidth: 0,
  borderTopLeftRadius: 4,
  borderBottomLeftRadius: 4,
  marginBottom: 8,
  paddingTop: 6,
  paddingBottom: 6,
  paddingLeft: 12,
  paddingRight: 6,
})

type Input = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

type TextInput = {
  id?: string // TODO: Make required.
  value: string
  onChange(text: string): void
  onSubmit?: () => void
  onCancel?: () => void
  onKeyDown?: (key: string) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  isValid?: boolean
  isEnabled?: boolean
  isPrefixed?: boolean
  style?: React.CSSProperties
  className?: string
  type?: string
  placeholder?: string
  autoFocus?: boolean
  spellCheck?: boolean
}
export const TextInput = ({
  id,
  value,
  onChange,
  className,
  style,
  type = 'text',
  isEnabled = true,
  isValid = true,
  isPrefixed = false,
  onSubmit = () => {},
  onCancel = () => {},
  onKeyDown = () => {},
  onFocus = () => {},
  placeholder,
  autoFocus,
  spellCheck = false,
}: TextInput) => {
  return (
    <StyledInput
      id={id}
      aria-label={id}
      className={className}
      style={{
        ...(isPrefixed
          ? { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }
          : {}),
        ...style,
      }}
      type={type}
      isEnabled={isEnabled}
      disabled={!isEnabled}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={({ key }) => {
        if (key === 'Enter' && isValid) onSubmit()
        if (key === 'Escape') onCancel()
        onKeyDown(key)
      }}
      placeholder={placeholder}
      autoFocus={autoFocus}
      onFocus={onFocus}
      spellCheck={spellCheck}
    />
  )
}

type Prefix = {
  children: ReactNode
  style?: React.CSSProperties
  className?: string
}
export const Prefix = ({ children, style, className }: Prefix) => (
  <StyledPrefix style={style} className={className}>
    {children}
  </StyledPrefix>
)
