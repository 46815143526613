import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { Info, Task } from '@landrush/common'
import { Result } from '@landrush/util'

import { Button, TextInput } from 'components'
import { Resources, Staging } from 'store'
import { useAppDispatch, useAsync } from 'utils'

const getStem = (p: string) => {
  const basename = p.split('/').slice(-1)[0]
  const parts = basename.split('.')
  if (parts.length > 1) parts.pop()
  return parts.join('.')
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 8px;

  #name {
    margin-right: 8px;
  }
`

export default () => {
  const dispatch = useAppDispatch()
  const items = useSelector(Staging.selectItems)

  const [name, setName] = useState('')
  const [isUserControlled, setUserControlled] = useState(false)

  useEffect(() => {
    if (isUserControlled) return
    if (items.length === 1 && !name.length) {
      setName(getStem(items[0].identifier.path))
    } else if (items.length === 0) {
      setName('')
    }
  }, [isUserControlled, items, name.length])

  const ids = items
    .map((item) => item.info)
    .filter((info): info is Info => Boolean(info))
    .filter(Task.isSuccess)
    .map((info) => info.id)

  const isValid = ids.length === items.length

  const [{ error, isPending, value }, submit] = useAsync(async () => {
    const result = await dispatch(Resources.add(ids, name))
    if (Result.isFailure(result)) throw new Error(result.error)
    return result.value
  }, [ids, name])

  // Propagate submission errors to the staging store for display.
  useEffect(() => {
    dispatch(Staging.setError(error))
  }, [dispatch, error])

  if (!ids.length) return null
  if (value) return <Redirect to='/' />

  return (
    <Container>
      <TextInput
        id='name'
        placeholder='Resource name...'
        value={name}
        onChange={(text) => {
          setUserControlled(true)
          setName(text)
        }}
        onSubmit={submit}
        isEnabled={!isPending}
      />
      <Button
        onClick={submit}
        isEnabled={isValid && !isPending}
        spin={isPending}
      >
        Submit
      </Button>
    </Container>
  )
}
