import * as Selectors from './selectors'
import * as Thunks from './thunks'
import * as Types from './types'

import { actions, reducer } from './slice'
export { reducer }

export declare namespace Executions {
  export type Normalized = Types.Normalized
}
// eslint-disable-next-line no-redeclare
export const Executions = { actions, ...Selectors, ...Thunks }
