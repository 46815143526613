import React from 'react'
import styled from 'styled-components'

import { Style, TextInput } from 'components'

import { CredentialState } from 'credentials'

type WithIsIdle = { isIdle: boolean }
const StyledName = styled.div(({ isIdle }: WithIsIdle) => ({
  ...(isIdle ? Style.clickable : undefined),
  ...Style.strong,
  ...Style.unselectable,
}))

type Props = CredentialState & { text?: string; setText(v?: string): void }
export default ({ credential, flowState, rename, text, setText }: Props) => {
  const isIdle = !flowState

  if (text === undefined) {
    return (
      <StyledName
        isIdle={isIdle}
        onDoubleClick={() => {
          if (isIdle) setText(credential.name)
        }}
      >
        {credential.name}
      </StyledName>
    )
  }

  return (
    <TextInput
      style={{ marginBottom: 0 }}
      autoFocus
      onFocus={(e) => e.target.select()}
      value={text}
      isEnabled={isIdle}
      isValid={text.length !== 0}
      onChange={setText}
      onSubmit={() => rename(text)}
      onKeyDown={(key: string) => {
        if (key === 'Escape') setText(undefined)
      }}
    />
  )
}
