import React from 'react'

import { Panel, PanelHeader } from 'components'

import NavBody from './body'
import NavHeader from './header'

export default () => (
  // Note: lifecycle effects for the Navigator are applied in the Body, so
  // we still render our header/panel while we're loading.
  <Panel>
    <PanelHeader>
      <NavHeader />
    </PanelHeader>
    <NavBody />
  </Panel>
)
