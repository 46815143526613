import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { BigError } from './big-error'
import { Button } from './button'
import { Color } from './color'
import { Column } from './column'
import { Style } from './style'

const Dismiss = styled.div({
  ...Style.small,
  ...Style.muted,
  ...Style.clickable,
})

type ErrorMessage = {
  onRetry?: () => void
  onDismiss?: () => void
  children?: ReactNode
  style?: React.CSSProperties
  className?: string
}
export function ErrorMessage({
  onRetry,
  onDismiss,
  children,
  style,
  className,
}: ErrorMessage) {
  return (
    <Column
      style={{ color: Color.darkGray, marginBottom: 8, ...style }}
      className={className}
    >
      <BigError onClick={onDismiss} />
      {children || <div>Unknown error</div>}
      {onDismiss ? (
        <Dismiss onClick={onDismiss}>(click to dismiss)</Dismiss>
      ) : null}
      {onRetry ? (
        <Button onClick={onRetry} style={{ marginBottom: 0 }}>
          Retry
        </Button>
      ) : null}
    </Column>
  )
}

export default ErrorMessage
