import React from 'react'

import { Style } from 'components'
import { dateString } from '@landrush/util'

type HeaderDate = { prefix?: string; date: Date }
export const HeaderDate: React.FC<HeaderDate> = ({
  prefix = 'Created',
  date,
}) => (
  <div
    style={{
      ...Style.small,
      ...Style.muted,
      ...Style.alignRight,
      marginTop: 4,
      marginBottom: 4,
    }}
  >
    {prefix} {dateString(date)}
  </div>
)
