import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons'

import { Id, Tag } from '@landrush/common'

import { Color } from './color'
import { InlineTagList } from './inline-tag-list'
import { List as ItemList } from './list'

type TaggableRow = {
  name: React.ReactNode
  tags: Tag[]
  isSelected: boolean
  toggleSelected: () => void
  toggleFilterTag: (id: Id) => void
  style?: React.CSSProperties
  className?: string
}
export const TaggableRow: React.FC<TaggableRow> = ({
  name,
  tags,
  isSelected,
  toggleSelected,
  toggleFilterTag,
  style,
  className,
  children,
}) => (
  <StyledRow className={className} style={style}>
    <div className='taggable-left-container'>
      <FontAwesomeIcon
        className='taggable-checkbox'
        icon={isSelected ? faCheckSquare : faSquare}
        fixedWidth
        fontSize={12}
        color={isSelected ? Color.landrushOrange : Color.veryLightGray}
        onClick={toggleSelected}
      />
      <span className='taggable-name'>{name}</span>
      <InlineTagList tags={tags} onClick={toggleFilterTag} />
    </div>
    <div className='taggable-right-container'>{children}</div>
  </StyledRow>
)

const StyledRow = styled(ItemList.Row)`
  overflow-y: hidden;
  display: flex;
  justify-content: space-between;

  .taggable-left-container {
    display: inline-block;
  }

  .taggable-checkbox {
    cursor: pointer;
    margin-right: 20px;
  }

  .taggable-name {
    margin-right: 12px;
    user-select: none;
  }

  .taggable-right-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`
