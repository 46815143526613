import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

import { Color } from './color'
import { Style } from './style'

type StyledIcon = { style?: React.CSSProperties }
const StyledIcon = styled(FontAwesomeIcon)(({ style }: StyledIcon) => ({
  ...Style.icon,
  color: Color.gray,
  ...style,
}))

type Spinner = {
  small?: boolean
  className?: string
  style?: React.CSSProperties
}
export function Spinner({ small = false, className, style }: Spinner) {
  return (
    <StyledIcon
      spin
      className={className}
      icon={faCircleNotch}
      size={small ? undefined : '3x'}
      style={style}
      fixedWidth
    />
  )
}
