import React from 'react'
import styled from 'styled-components'

import { Workflow } from '@landrush/common'
import { Pipeline } from '@landrush/pdal'
import { getSubstitutions } from '@landrush/util'

import { Color, Panel, Style, StyledTable } from 'components'

type Reducers = Workflow.Reducers

const List = ({ list }: { list: string[] }) => {
  if (!list.length) {
    return <div style={Style.muted}>Nothing here</div>
  }

  return (
    <StyledTable>
      <tbody>
        {list.map((v, i) => (
          <tr key={i}>
            <td>{v}</td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  )
}

type Props = {
  pipeline?: Pipeline
  reducers?: Reducers
  options?: Workflow.Options
}
export const WorkflowAnalysis = ({
  pipeline,
  reducers,
  options: initialOptions = {},
}: Props) => {
  if (!pipeline) {
    return <div style={Style.muted}>Waiting for valid JSON</div>
  }
  if (!pipeline.every((v) => v.type)) {
    return <div style={Style.muted}>Missing pipeline stage type</div>
  }

  const subs = getSubstitutions([pipeline, reducers])
  const options = subs.reduce<Workflow.Options>((result, name) => {
    const option = initialOptions[name]
    result[name] = option || Workflow.defaults[name] || {}
    return result
  }, {})

  const outs = Workflow.getOutputs({ pipeline, reducers })

  return (
    <Styles>
      <h4>Parameters</h4>
      <Panel>
        <StyledTable>
          <tbody>
            {Object.entries(options).map(([name, option], i) => {
              const { description, default: defaultValue } = option || {}
              return (
                <tr key={i}>
                  <td>
                    <div className='option-cell'>
                      <div className='option-name'>{name}</div>
                      {description && (
                        <div className='option-description'>{description}</div>
                      )}
                      {defaultValue && (
                        <div className='option-default'>
                          Default: {defaultValue as any}
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </StyledTable>
      </Panel>

      <h4>Outputs</h4>
      <Panel>
        <List list={outs} />
      </Panel>
    </Styles>
  )
}

WorkflowAnalysis.Outputs = ({ items }: { items: string[] }) => (
  <Panel>
    <List list={items} />
  </Panel>
)

const Styles = styled.div`
  h4 {
    margin-top: 16px;
    margin-bottom: 4px;
  }

  .option-cell {
    display: flex;
    flex-direction: column;
  }

  .option-name {
  }
  .option-description {
    margin-left: 12px;
    color: ${Color.darkGray};
    font-size: small;
  }
  .option-default {
    margin-left: 12px;
    color: ${Color.darkGray};
    font-size: small;
  }
`
