import React, { useEffect, useState } from 'react'

import { PanelHeader, ProtocolIcon, Spaced } from 'components'

import Controls from './controls'
import Name from './name'

import { CredentialState } from 'credentials'

export default (state: CredentialState) => {
  const { credential, flowState } = state
  const [text, setText] = useState<string | undefined>()

  // If some other flowState like a path modification or delete occurs, cancel
  // any pending rename.
  useEffect(() => {
    if (flowState !== 'renaming') setText(undefined)
  }, [flowState])

  const props = { ...state, text, setText }
  return (
    <PanelHeader>
      <Spaced>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <ProtocolIcon
            protocol={credential.protocol}
            style={{ fontSize: 20, marginRight: 16 }}
          />
          <Name {...props} />
        </div>
        <Controls {...props} />
      </Spaced>
    </PanelHeader>
  )
}
