export function isValid(text: string) {
  try {
    JSON.parse(text)
    return true
  } catch (e) {
    return false
  }
}

export function format(text: string) {
  try {
    return JSON.stringify(JSON.parse(text), null, 2)
  } catch (e) {
    return text
  }
}
