import cn from 'classnames'
import isequal from 'lodash.isequal'
import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationCircle,
  faCheckCircle,
  faCheckSquare,
  faDotCircle,
  faFilter,
  faPlusSquare,
  faSquare,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'

import { Id, Tag, Task } from '@landrush/common'

import { Color } from './color'
import { Tag as TagDisplay } from './tag'
import { TaggablePopper } from './taggable-popper'
import { Prefix, TextInput } from './text-input'
import { DeleteIcon } from './delete-control'

type TargetName = 'region' | 'resource' | 'execution'

type Summary = {
  targetName: TargetName
  totalTargetsCount: number
  selectedTargetsCount: number
  visibleTargetsCount: number
}
const Summary: React.FC<Summary> = ({
  targetName,
  totalTargetsCount,
  selectedTargetsCount,
  visibleTargetsCount,
}) => {
  const isAnythingSelected = selectedTargetsCount > 0

  const addendum =
    visibleTargetsCount !== totalTargetsCount ? (
      <span className='addendum'>
        {' '}
        • showing {visibleTargetsCount} of {totalTargetsCount}
      </span>
    ) : null

  return (
    <StyledSummary $targetName={targetName}>
      {isAnythingSelected ? (
        <span className='selected-target-count'>
          {`${selectedTargetsCount} ${targetName}${
            selectedTargetsCount > 1 ? 's' : ''
          } selected`}
        </span>
      ) : (
        <span className='total-target-count'>{`${totalTargetsCount} ${targetName}${
          totalTargetsCount !== 1 ? 's' : ''
        }`}</span>
      )}
      {addendum}
    </StyledSummary>
  )
}
type StyledSummary = { $targetName: string }
const StyledSummary = styled.div(
  ({ $targetName }: StyledSummary) => `
  margin-bottom: 0;
  font-size: small;

  .total-target-count {
    color: ${Color.gray};
  }

  .selected-target-count {
    font-weight: bold;
  }

  .addendum {
    color: ${Color.gray};
  }
`
)

type FilterStatus = {
  value?: Task.Status
  set(v: Task.Status): void
}
const FilterStatus: React.FC<FilterStatus> = ({ value, set }) => {
  return (
    <StyledFilterStatus>
      <FontAwesomeIcon
        title='Running'
        className={cn('filter-status-icon', value === 'running' && 'active')}
        icon={faDotCircle}
        onClick={() => set('running')}
      />
      <FontAwesomeIcon
        title='Failure'
        className={cn('filter-status-icon', value === 'failure' && 'active')}
        icon={faExclamationCircle}
        onClick={() => set('failure')}
      />
      <FontAwesomeIcon
        title='Success'
        className={cn('filter-status-icon', value === 'success' && 'active')}
        icon={faCheckCircle}
        onClick={() => set('success')}
      />
    </StyledFilterStatus>
  )
}
const StyledFilterStatus = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
  margin-right: 12px;

  .filter-status-icon {
    margin-left: 12px;
    cursor: pointer;
    font-size: 22px;

    color: ${Color.gray};
    &:hover {
      color: ${Color.darkGray};
    }
  }
  .active {
    color: ${Color.landrushOrange};
    &:hover {
      color: ${Color.landrushOrange};
    }
  }
`

type TaggableHeader = {
  targetName: TargetName
  totalTargetsCount: number
  selectedTargetsCount: number
  visibleTargetsCount: number
  filterText: string
  filterTags: Tag[]
  setFilterText(v: string): void
  removeFilterTag(id: Id): void
  handleCheckAll(): void

  filterStatus?: Task.Status
  setFilterStatus?: (v: Task.Status) => void

  handleRemoveSelected?: () => void
  isRemovingSelected?: boolean

  tags: Tag[]
  isModifiable: boolean
  isApplyingTag: boolean
  applyTag(tagId: Id): void
}
export const TaggableHeader: React.FC<TaggableHeader> = React.memo(
  ({
    targetName,
    totalTargetsCount,
    selectedTargetsCount,
    visibleTargetsCount,
    filterText,
    filterTags,
    setFilterText,
    removeFilterTag,
    handleCheckAll,

    filterStatus,
    setFilterStatus,

    handleRemoveSelected,
    isRemovingSelected = false,

    tags,
    isModifiable,
    applyTag,
    isApplyingTag,

    children,
  }) => {
    const removeAllFilterTags = () => {
      filterTags.forEach((tag) => removeFilterTag(tag.id))
    }

    const isAnythingSelected = selectedTargetsCount > 0
    const isEverythingVisibleSelected =
      isAnythingSelected && selectedTargetsCount === visibleTargetsCount

    return (
      <HeaderStyles>
        <div className='targets-header-control-row'>
          <div className='targets-header-left'>
            <FontAwesomeIcon
              className='all-targets-checkbox'
              icon={
                isEverythingVisibleSelected
                  ? faCheckSquare
                  : isAnythingSelected
                  ? faPlusSquare
                  : faSquare
              }
              fixedWidth
              fontSize={12}
              color={
                isEverythingVisibleSelected
                  ? Color.landrushOrange
                  : isAnythingSelected
                  ? Color.gray
                  : Color.veryLightGray
              }
              onClick={handleCheckAll}
            />
            <Summary
              targetName={targetName}
              totalTargetsCount={totalTargetsCount}
              selectedTargetsCount={selectedTargetsCount}
              visibleTargetsCount={visibleTargetsCount}
            />
          </div>
          <div className='targets-header-right'>
            {children}
            <div className='apply-tags-icon-container'>
              <TaggablePopper
                targetName={targetName}
                tags={tags}
                isModifiable={isModifiable}
                applyTag={applyTag}
                isApplyingTag={isApplyingTag}
                selectedTargetsCount={selectedTargetsCount}
              />
            </div>
            <Prefix className='filter-prefix-container'>
              <FontAwesomeIcon
                className='filter-prefix-icon'
                icon={faFilter}
                fixedWidth
                color={Color.gray}
              />
            </Prefix>
            <TextInput
              className='filter-text'
              placeholder={`Filter ${targetName} names...`}
              value={filterText}
              onChange={setFilterText}
              isPrefixed={true}
            />
            {setFilterStatus && (
              <FilterStatus value={filterStatus} set={setFilterStatus} />
            )}
            {handleRemoveSelected && (
              <DeleteIcon
                className='remove-selected-icon'
                onClick={handleRemoveSelected}
                isPending={isRemovingSelected}
                isEnabled={isAnythingSelected}
              />
            )}
          </div>
        </div>
        {filterTags.length > 0 && (
          <div className='targets-header-tags-row'>
            {filterTags.map(({ id, color, name }) => (
              <TagDisplay
                key={id}
                color={color}
                name={name}
                style={{ marginLeft: 6 }}
                onClick={() => removeFilterTag(id)}
              />
            ))}
            <FontAwesomeIcon
              className='remove-all-filter-tags'
              icon={faTimes}
              fixedWidth
              onClick={removeAllFilterTags}
            />
          </div>
        )}
      </HeaderStyles>
    )
  },
  isequal
)
const HeaderStyles = styled.div`
  background-color: #fafafa;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid ${Color.veryLightGray};

  .targets-header-control-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .targets-header-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .all-targets-checkbox {
    cursor: pointer;
    margin-right: 20px;
  }

  .apply-tags-icon-container {
    margin-right: 20px;
    font-size: 20px;
  }

  .apply-tags-icon {
  }

  .targets-header-right {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .filter-prefix-container {
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    padding-left: 6px;
    padding-right: 6px;
  }

  .filter-text {
    margin-bottom: 0;
    font-size: small;
  }

  .remove-selected-icon {
    margin-left: 16px;
    margin-right: 2px;
  }

  .targets-header-tags-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 4px;
  }

  .remove-all-filter-tags {
    cursor: pointer;
    margin-left: 6px;
    color: ${Color.lightGray};
    &:hover {
      color: ${Color.darkGray};
    }
  }
`
