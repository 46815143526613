import CopyToClipboard from 'react-copy-to-clipboard'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faCopy,
  faKey,
  faMinus,
} from '@fortawesome/free-solid-svg-icons'

import { dateString, timeString } from '@landrush/util'

import { AccessTokens } from 'store'
import { useAppDispatch } from 'utils'

import { BigError } from './big-error'
import { Button } from './button'
import { Color } from './color'
import { Column } from './column'
import { DataHeader, DataTable } from './table'
import { DeleteIcon } from './delete-control'
import { Panel } from './panel'
import { Tooltip } from './tooltip'
import { Spinner } from './spinner'
import { Style } from './style'

const displayDate = (d: Date) => `${dateString(d)} ${timeString(d)}`

const List = () => {
  const shrinkStyle = { ...Style.tableShrink, paddingRight: 32 }

  const dispatch = useAppDispatch()
  const accessTokens = useSelector(AccessTokens.selectAccessTokens)
  const flowState = useSelector(AccessTokens.selectFlowState)
  const flowId = useSelector(AccessTokens.selectFlowId)

  if (!accessTokens.length) {
    return (
      <div style={{ ...Style.muted, ...Style.small, ...Style.alignCenter }}>
        (none here)
      </div>
    )
  }

  return (
    <DataTable>
      <DataHeader columns={['#', 'Created', 'Last accessed', '']} />
      <tbody>
        {accessTokens.map((v, i) => (
          <tr key={v.id}>
            <td style={shrinkStyle}>{i + 1}</td>
            <td style={shrinkStyle}>{displayDate(v.createdAt)}</td>
            <td style={{ width: '100%' }}>
              {v.accessedAt ? (
                displayDate(v.accessedAt)
              ) : (
                <div style={Style.muted}>-</div>
              )}
            </td>
            <td>
              <div style={Style.icon}>
                <DeleteIcon
                  isEnabled={flowState === 'none'}
                  isPending={flowState === 'removing' && flowId === v.id}
                  onClick={() => dispatch(AccessTokens.remove(v.id))}
                />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </DataTable>
  )
}

const Add = () => {
  const dispatch = useAppDispatch()
  const accessTokens = useSelector(AccessTokens.selectAccessTokens)
  const flowState = useSelector(AccessTokens.selectFlowState)

  if (accessTokens.length >= 2) return null
  return (
    <div style={{ ...Style.alignCenter, marginTop: 8 }}>
      <Button
        isEnabled={flowState === 'none'}
        onClick={() => dispatch(AccessTokens.add())}
      >
        {flowState === 'adding' ? <Spinner small /> : <>Create token</>}
      </Button>
    </div>
  )
}

const StyledCopyIcon = styled(FontAwesomeIcon)({
  ...Style.clickable,
  color: Color.landrushOrange,
})
const CopySecret = ({ token }: { token: string }) => {
  const [copied, setCopied] = useState(false)
  useEffect(() => {
    const timeout = copied ? setTimeout(() => setCopied(false), 400) : null
    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [copied])

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={Style.strong}>{token}</div>
      <div
        style={{ ...Style.icon, marginLeft: 12, color: Color.landrushOrange }}
      >
        {copied ? (
          <StyledCopyIcon icon={faCheck} />
        ) : (
          <Tooltip content='Copy token to clipboard' place='right'>
            <CopyToClipboard text={token} onCopy={() => setCopied(true)}>
              <StyledCopyIcon icon={faCopy} />
            </CopyToClipboard>
          </Tooltip>
        )}
      </div>
    </div>
  )
}

const BigIcon = styled(FontAwesomeIcon)({
  color: Color.landrushOrange,
  fontSize: 36,
  marginBottom: 8,
})

const StyledDismissIcon = styled(FontAwesomeIcon)({
  ...Style.alignRight,
  ...Style.clickable,
  color: Color.gray,
  marginRight: 24,
  marginTop: 12,
})
const Secret = () => {
  const dispatch = useAppDispatch()
  const token = useSelector(AccessTokens.selectSecretToken)

  useEffect(
    () => () => {
      dispatch(AccessTokens.dismissSecretToken())
    },
    []
  )

  if (!token) return null
  return (
    <Panel
      style={{
        marginBottom: 32,
        paddingBottom: 8,
        backgroundColor: Color.backgroundGray,
      }}
    >
      <StyledDismissIcon
        icon={faMinus}
        onClick={() => dispatch(AccessTokens.dismissSecretToken())}
      />
      <Column>
        <BigIcon icon={faKey} />
        <p style={Style.muted}>
          This is the only time this token will be displayed.
        </p>
        <CopySecret token={token} />
      </Column>
    </Panel>
  )
}

const ErrorDisplay: React.FC = () => {
  const dispatch = useAppDispatch()
  const error = useSelector(AccessTokens.selectError)
  if (!error) return null
  return (
    <Column>
      <BigError onClick={() => dispatch(AccessTokens.dismissError())} />
      {error}
    </Column>
  )
}

export function AccessTokensDisplay() {
  return (
    <>
      <ErrorDisplay />
      <List />
      <Secret />
      <Add />
    </>
  )
}
