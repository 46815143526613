import React from 'react'
import { useMount } from 'react-use'
import styled from 'styled-components'

import { ErrorMessage, InfoMessage, Message, Overview } from 'components'
import { Workflows } from 'store'

import { List } from './list'
import { useAsyncResult, useAppDispatch, useAppSelector } from 'utils'

const Contents = () => {
  const workflows = useAppSelector(Workflows.selectAll)

  if (!workflows.length) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
        <InfoMessage>Nothing here</InfoMessage>
      </div>
    )
  }

  return <List workflows={workflows} />
}

const Loader = () => {
  const dispatch = useAppDispatch()

  const [{ isPending, error }, list] = useAsyncResult(() =>
    dispatch(Workflows.list())
  )
  useMount(list)

  if (error) {
    return (
      <ErrorMessage className='top' onRetry={list}>
        {error}
      </ErrorMessage>
    )
  }
  if (isPending) {
    return (
      <Message className='top' spin>
        Loading...
      </Message>
    )
  }
  return <Contents />
}

export default () => {
  return (
    <Styles>
      <Overview page='workflows' create='/add-workflow' />
      <Loader />
    </Styles>
  )
}

const Styles = styled.div`
  .top {
    margin-top: 8px;
    text-align: center;
  }
`
