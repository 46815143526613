import React from 'react'
import { useSelector } from 'react-redux'
import { useMount, useUnmount } from 'react-use'

import { ErrorMessage, Message, Overview } from 'components'
import { Resources, ResourceState } from 'store'
import { useAppDispatch } from 'utils'

import Header from './header'
import List from './list'

const Body = () => {
  const dispatch = useAppDispatch()
  const errors = useSelector(ResourceState.selectErrors)
  const dismiss = () => dispatch(ResourceState.clearErrors())

  return (
    <>
      <Header />
      {errors.length > 0 && (
        <ErrorMessage onDismiss={dismiss} style={{ paddingTop: 8 }}>
          {errors.map((error) => (
            <div>{error}</div>
          ))}
        </ErrorMessage>
      )}
      <List />
    </>
  )
}

const Loader = () => {
  const dispatch = useAppDispatch()
  const load = () => {
    dispatch(Resources.load())
    dispatch(Resources.Tags.load())
  }
  useMount(load)
  useUnmount(() => dispatch(ResourceState.destroy()))

  const loadState = useSelector(Resources.selectLoadState)
  const loadError = useSelector(Resources.selectLoadError)
  const tagLoadState = useSelector(Resources.Tags.selectLoadState)
  const tagLoadError = useSelector(Resources.Tags.selectLoadError)

  if (loadError || tagLoadError) {
    return (
      <ErrorMessage style={{ marginTop: 16 }} onRetry={load}>
        {loadError}
      </ErrorMessage>
    )
  }
  if (loadState === 'done' && tagLoadState === 'done') return <Body />
  return (
    <Message style={{ marginTop: 16 }} spin>
      Loading...
    </Message>
  )
}

export default () => (
  <>
    <Overview page='home' create='/add-resources' />
    <Loader />
  </>
)
