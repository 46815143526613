import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Id, Region } from '@landrush/common'
import { ialphaSort } from '@landrush/util'

import { Color, TaggableRow } from 'components'
import { RegionState } from 'store'
import { useAppDispatch, useForwardingSelector } from 'utils'

type Row = { region: Region.Summary; style?: React.CSSProperties }
const Row: React.FC<Row> = ({ region, style }) => {
  const { id, name, tags, alias } = region
  const dispatch = useAppDispatch()
  const isSelected = useForwardingSelector(
    RegionState.selectIsRegionSelected,
    id
  )
  const toggleSelected = () => dispatch(RegionState.toggleRegion(id))
  const toggleFilterTag = (id: Id) => dispatch(RegionState.toggleFilterTag(id))

  return (
    <StyledRow
      name={
        <Link to={`/regions/${alias}`}>
          <span className='region-name'>{name}</span>
        </Link>
      }
      tags={tags.sort((a, b) => ialphaSort(a.name, b.name))}
      isSelected={isSelected}
      toggleSelected={toggleSelected}
      toggleFilterTag={toggleFilterTag}
      style={style}
    />
  )
}
const StyledRow = styled(TaggableRow)`
  .region-name {
    color: ${Color.veryDarkGray};
    &:hover {
      color: ${Color.landrushOrange};
    }
  }
`

export default Row
