import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Column, ErrorMessage, Message, Panel } from 'components'
import { Credentials } from 'store'

import Form from './form'
import Header from './header'

const Body = () => {
  const dispatch = useDispatch()

  const error = useSelector(Credentials.selectError)
  const loadState = useSelector(Credentials.selectLoadState)

  const maybeLoad = () => {
    dispatch(Credentials.maybeLoad())
  }
  useEffect(maybeLoad, [])

  if (error) return <ErrorMessage onRetry={maybeLoad}>{error}</ErrorMessage>
  if (loadState === 'loading') return <Message spin>Loading...</Message>
  return <Form />
}

export default () => {
  return (
    <Panel>
      <Header />
      <Column style={{ marginTop: 8 }}>
        <Body />
      </Column>
    </Panel>
  )
}
