import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons'

import { Task } from '@landrush/common'

import { Color, Progress, Spinner } from 'components'

type TaskStatusIcon = { task: Task; spin?: boolean }
const TaskStatusIcon: React.FC<TaskStatusIcon> = ({ task, spin = true }) => {
  if (Task.isFailure(task)) {
    return (
      <FontAwesomeIcon
        fixedWidth
        className='task-failure'
        icon={faExclamation}
      />
    )
  }

  if (Task.isSuccess(task)) {
    return (
      <FontAwesomeIcon fixedWidth className='task-success' icon={faCheck} />
    )
  }

  const { index, total } = task
  if (spin && !index) return <Spinner small />

  return <Progress className='task-running' index={index} total={total} />
}

type TaskStatus = TaskStatusIcon & { className?: string }
export const TaskStatus = ({ className, ...params }: TaskStatus) => (
  <Styles className={className}>
    <TaskStatusIcon {...params} />
  </Styles>
)

const Styles = styled.div`
  .task-running {
    width: 12vw;
    min-width: 120px;
  }
  .task-failure {
    color: ${Color.dangerRed};
  }
  .task-success {
    color: ${Color.landrushOrange};
  }
`
