import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHistory, faPen } from '@fortawesome/free-solid-svg-icons'

import {
  Color,
  ErrorMessage,
  HeaderDate,
  NameHeader,
  Select,
  Spaced,
  Style,
} from 'components'
import { WorkflowHistory } from 'store'
import { useAppDispatch, useAsyncResult, useClient } from 'utils'

import { WithHistoryAndSwitcher } from './types'

const VersionSwitcher = ({
  history,
  version,
  setVersion,
}: WithHistoryAndSwitcher) => {
  const options: Select.Option[] = history.list
    .slice()
    .reverse()
    .map(({ version }) => ({
      label:
        version === history.list.length ? (
          <div>
            {version}{' '}
            <span style={{ margin: 4, ...Style.small, color: Color.darkGray }}>
              latest
            </span>
          </div>
        ) : (
          version
        ),
      value: version,
    }))
  return (
    <Select options={options} onChange={(v) => setVersion(v)} value={version} />
  )
}

export const Header = ({
  history,
  version,
  setVersion,
}: WithHistoryAndSwitcher) => {
  const client = useClient()
  const dispatch = useAppDispatch()

  const { id, name } = history
  const leaf = history.list[version - 1]

  const [text, setText] = useState<string>()
  useEffect(() => setText(undefined), [name])
  const [
    { isPending, error, dismissError, value },
    submit,
  ] = useAsyncResult(async () => {
    try {
      if (!text || text.length < 0) throw new Error('Name cannot be empty')
      await client.workflows.patch(id, { name: text })
      dispatch(WorkflowHistory.actions.assign({ ...history, name: text }))
      return { value: text }
    } catch (e) {
      return { error: e.message || 'Failed to rename' }
    }
  }, [client, id, history, text])

  return (
    <>
      <Spaced
        style={{
          paddingBottom: 2,
          borderBottom: `2px solid ${Color.landrushOrange}`,
        }}
      >
        <NameHeader
          name={name}
          text={text}
          setText={setText}
          isRenaming={isPending}
          submit={submit}
        />
        <SwitcherStyle>
          <FontAwesomeIcon
            className='icon'
            icon={faPen}
            title='Rename'
            style={{
              color:
                typeof text === 'string' ? Color.landrushOrange : undefined,
            }}
            onClick={() => setText(name)}
          />
          {version < history.list.length && (
            <FontAwesomeIcon
              className='icon'
              icon={faHistory}
              title='Click to go to the latest version'
              onClick={() => setVersion(history.list.length)}
            />
          )}
          <div style={{ width: 120 }}>
            <VersionSwitcher
              history={history}
              version={version}
              setVersion={setVersion}
            />
          </div>
        </SwitcherStyle>
      </Spaced>
      <HeaderDate prefix='Updated' date={leaf.updatedAt} />
      {error && <ErrorMessage onDismiss={dismissError}>{error}</ErrorMessage>}
    </>
  )
}

const SwitcherStyle = styled.div`
  display: flex;
  align-items: center;

  .icon {
    cursor: pointer;
    margin-right: 12px;
    margin-left: 8px;
    margin-bottom: 8px;
    font-size: 24px;

    color: ${Color.gray};
    &:hover {
      color: ${Color.darkGray};
    }
  }
`
