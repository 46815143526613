import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { faChevronCircleUp } from '@fortawesome/free-solid-svg-icons'

import { Viewer } from 'store'

import { StyledIcon } from './icon'

const rotationMs = 400

type RotatableIcon = { $rotation: number }
const RotatableIcon = styled(StyledIcon)(
  ({ $rotation }: RotatableIcon) => `
  transform: rotate(${$rotation}rad);
`
)

export const Icon = () => {
  const dispatch = useDispatch()
  const rotation = useSelector(Viewer.selectRotation)
  const [isNorthing, setNorthing] = useState(false)
  useEffect(() => {
    if (!isNorthing) return
    const timeout = setTimeout(() => setNorthing(false), rotationMs * 1.25)
    return () => clearTimeout(timeout)
  }, [isNorthing])

  return (
    <RotatableIcon
      className='icon'
      icon={faChevronCircleUp}
      onClick={() => {
        setNorthing(true)
        dispatch(Viewer.rotateToNorth(rotationMs))
      }}
      $isActive={isNorthing}
      $rotation={rotation}
    />
  )
}
