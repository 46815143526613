import Colors from 'color'
import { createContext, useContext, useEffect, useRef, useState } from 'react'

import { Fetch } from '@landrush/client'
import * as Common from '@landrush/common'

import * as Ol from './ol'
import * as Styled from './map.styles'

import { Color } from 'components/color'
import { StyleFunction } from 'ol/style/Style'

const fontFamily =
  '"Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'

const Context = createContext<Ol.Map | undefined>(undefined)
const useMap = () => {
  const map = useContext(Context)
  if (!map) throw new Error('Cannot find map context')
  return map
}

type Map = { className?: string }
export const Map: React.FC<Map> = ({ className, children }) => {
  const ref = useRef(null)
  const [map, setMap] = useState<Ol.Map>()

  useEffect(() => {
    var map = new Ol.Map({
      view: new Ol.View({ center: [0, 0], zoom: 2 }),
      layers: [
        new Ol.Layers.Tile({
          source: new Ol.Sources.OSM({ attributions: '' }),
        }),
      ],
      target: ref.current!,
    })

    setMap(map)

    return () => map.setTarget(undefined)
  }, [])

  return (
    <Context.Provider value={map}>
      <Styled.Map className={className} ref={ref}>
        {map ? children : null}
      </Styled.Map>
    </Context.Provider>
  )
}

const grow = (b: Ol.Extent, factor = 0.05): Ol.Extent => {
  if (b.length !== 4) throw new Error('Invalid bounds: ' + b)
  const w = (b[2] - b[0]) * factor
  const h = (b[3] - b[1]) * factor
  return [b[0] - w, b[1] - h, b[2] + w, b[3] + h]
}

type Feature = { autozoom?: boolean; color?: string; zIndex?: number }
type WithGeometry = Feature & { name?: string; geometry: GeoJSON.Geometry }
type WithCollection = Feature & { collection: GeoJSON.FeatureCollection }

export const Collection: React.FC<WithCollection> = ({
  collection,
  autozoom = true,
  color = Color.landrushOrange,
  zIndex = 0,
}) => {
  const map = useMap()

  useEffect(() => {
    const getStyle = (
      feature: Ol.Features.FeatureLike,
      _resolution: number
    ) => {
      const properties = feature.getProperties()

      return new Ol.Styles.Style({
        stroke: new Ol.Styles.Stroke({
          color: Colors(color).fade(0.2).string(),
          width: 3,
        }),
        fill: new Ol.Styles.Fill({ color: Colors(color).fade(0.7).string() }),
        text: new Ol.Styles.Text({
          text: properties.name,
          font: `18px ${fontFamily}`,
          stroke: new Ol.Styles.Stroke({ color: 'white', width: 3 }),
          fill: new Ol.Styles.Fill({
            color: Colors(color).darken(0.3).string(),
          }),
        }),
      })
    }
    const features = new Ol.Formats.GeoJSON().readFeatures(collection, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857',
    })

    if (autozoom) {
      const extent = features.reduce<Ol.Extent>(
        (extent, feature) => {
          const current = feature.getGeometry()?.getExtent()
          if (current) {
            extent = [
              Math.min(extent[0], current[0]),
              Math.min(extent[1], current[1]),
              Math.max(extent[2], current[2]),
              Math.max(extent[3], current[3]),
            ]
          }
          return extent
        },
        [Infinity, Infinity, -Infinity, -Infinity]
      )

      if (extent) map.getView().fit(grow(extent, 0.05))
    }

    const source = new Ol.Sources.Vector({ features })
    const layer = new Ol.Layers.Vector({ style: getStyle, source, zIndex })
    map.addLayer(layer)
    return () => {
      map.removeLayer(layer)
    }
  }, [collection])

  return null
}

export const Geometry: React.FC<WithGeometry> = ({
  name,
  geometry,
  autozoom,
  color,
  zIndex,
}) => {
  const collection: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features: [{ type: 'Feature', properties: { name }, geometry }],
  }

  return (
    <Collection
      collection={collection}
      autozoom={autozoom}
      color={color}
      zIndex={zIndex}
    />
  )
}

type WithUrl = Feature & { url: string }
export const Mvt: React.FC<WithUrl> = ({ url, zIndex = 0 }) => {
  const map = useMap()
  useEffect(() => {
    const layer = new Ol.Layers.VectorTile({
      source: getMvtSource(url),
      style: mvtStyleFunction,
      declutter: true,
      zIndex,
    })
    map.addLayer(layer)
    return () => {
      map.removeLayer(layer)
    }
  }, [url])

  return null
}

const getMvtSource = (url: string) => {
  return new Ol.Sources.VectorTile({
    format: new Ol.Formats.MVT(),
    url,
    tileLoadFunction: async (tile, url) => {
      if (!(tile instanceof Ol.VectorTile)) {
        throw new Error('Invalid tile format')
      }

      tile.setLoader(async (extent, _resolution, featureProjection) => {
        const response = await Fetch.getResponse(url)
        const data = await response.arrayBuffer()

        const format = tile.getFormat()
        const features = format.readFeatures(data, {
          extent,
          featureProjection,
        })

        tile.setFeatures(features as Ol.Feature<Ol.Geometries.Geometry>[])
      })
    },
  })
}

const mvtStyleFunction: StyleFunction = (feature) => {
  const geometry = feature.getGeometry()
  const { name } = geometry!.getProperties() as Common.Mvt.Properties
  const color = Color.random(getHash(name))
  return getMvtStyle({ text: name, color })
}

type MvtStyle = { text?: string; color?: string }
const getMvtStyle = ({ text, color = Color.landrushComplement }: MvtStyle) => {
  return new Ol.Styles.Style({
    stroke: new Ol.Styles.Stroke({
      color: Colors(color).fade(0.2).string(),
      width: 3,
    }),
    fill: new Ol.Styles.Fill({ color: Colors(color).fade(0.7).string() }),
    text: new Ol.Styles.Text({
      text,
      scale: 1.25,
      stroke: new Ol.Styles.Stroke({ color: 'white', width: 2 }),
      fill: new Ol.Styles.Fill({ color: 'black' }),
    }),
  })
}

function getHash(s: string) {
  let hash = 0
  for (let i = 0; i < s.length; ++i) {
    const c = s.charCodeAt(i)
    hash = (hash << 5) - hash + c
  }
  return Math.abs(hash)
}
