import { Id, Tag } from '@landrush/common'

// TODO: We need to denormalize our Region Tags for external use from the store,
// then this type can simply be Tag[].
type WithTag = { id: Id; name: string; tags: Id[] | Tag[] }
type Taggable<T = unknown> = T extends WithTag ? T : never

type UseVisibleTargets<T> = {
  allTargets: Taggable<T>[]
  selectedTargets: Id[]
  filterText: string
  filterTags: Id[]
}
export function useVisibleTargets<T extends { id: Id; name: string }>({
  allTargets,
  selectedTargets,
  filterText,
  filterTags,
}: UseVisibleTargets<T>) {
  let targets = allTargets
  const selectedTargetsSet = new Set(...selectedTargets)

  if (filterText.length) {
    const isAllLowerCase = filterText.toLocaleLowerCase() === filterText
    const transformText = isAllLowerCase
      ? (s: string) => s.toLocaleLowerCase()
      : (s: string) => s

    const transformedFilterText = transformText(filterText)

    targets = targets.filter(
      (target) =>
        selectedTargetsSet.has(target.id) ||
        transformText(target.name).includes(transformedFilterText)
    )
  }

  if (filterTags.length) {
    targets = targets.filter(
      (target) =>
        selectedTargetsSet.has(target.id) ||
        filterTags.some((tagId) =>
          target.tags.some((tagOrId: string | Tag) =>
            typeof tagOrId === 'string'
              ? tagOrId === tagId
              : tagOrId['id'] === tagId
          )
        )
    )
  }

  return targets
}
