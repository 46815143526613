import { Copy, Event, Id } from '@landrush/common'
import * as Utils from 'store/utils'

import * as Selectors from './selectors'
import * as Copies from './slice'

export const list = Utils.createThunk(
  ({ dispatch, getState, client }) => async () => {
    if (!Selectors.selectIsListed(getState())) {
      const copies = await client.copies.list()
      dispatch(Copies.actions.upsert(copies))
      dispatch(Copies.actions.setListed())
    }

    return Selectors.selectAll(getState())
  }
)

export const add = Utils.createThunk(
  ({ dispatch, client }) => async (params: Copy.Add) => {
    const copy = await client.copies.add(params)
    dispatch(Copies.actions.upsert([copy]))
    return copy
  }
)

export const hydrateByAlias = Utils.createThunk(
  ({ dispatch, getState, client }) => async (alias: string) => {
    const existing = Selectors.selectByAlias(getState(), alias)
    if (existing) return existing

    const copy = await client.copies.getByAlias(alias)
    dispatch(Copies.actions.upsert([copy]))
    return copy
  }
)

type OrderedEvents = {
  create: Event.Copy.Create[]
  update: Event.Copy.Update[]
  delete: Event.Copy.Delete[]
}
export const receive = Utils.createThunk(
  ({ dispatch }) => async (events: Copy.Event[]) => {
    const ordered = events.reduce<OrderedEvents>(
      (ordered, e) => {
        if (e.action === 'create') ordered.create.push(e)
        else if (e.action === 'update') ordered.update.push(e)
        else if (e.action === 'delete') ordered.delete.push(e)
        return ordered
      },
      { create: [], update: [], delete: [] }
    )

    dispatch(
      Copies.actions.upsert(
        ordered.create.map((v) => v.payload).map(Copy.parse)
      )
    )
    dispatch(Copies.actions.update(ordered.update.map((v) => v.payload)))
    dispatch(Copies.actions.remove(ordered.delete.map((v) => v.payload.id)))
  }
)

export const remove = Utils.createThunk(
  ({ dispatch, client }) => async (id: Id) => {
    await client.copies.remove(id)
    dispatch(Copies.actions.remove([id]))
    return id
  }
)
