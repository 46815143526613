import styled from 'styled-components'

import { Workflow } from '@landrush/common'

import { CancelOrSubmit, Style, TextInput } from 'components'
import { Json } from 'utils'

import { EditingSettings } from './types'

export const Options = ({
  text: { pipeline, reducers, options },
  setText,
  submit,
  isPending,
  error,
  dismissError,
}: EditingSettings) => (
  <>
    <h3>Options</h3>
    {Object.entries(options).map(([name, o = {}]) => {
      return (
        <Option
          key={name}
          name={name}
          description={o.description}
          default={o.default}
          isEnabled={Json.isValid(pipeline) && Json.isValid(reducers)}
          set={(name: string, option: Workflow.Option) =>
            setText({
              pipeline,
              reducers,
              options: { ...options, [name]: option },
            })
          }
        />
      )
    })}
    <CancelOrSubmit
      onSubmit={submit}
      isPending={isPending}
      onCancel={() => setText(undefined)}
      error={error}
      dismissError={dismissError}
    />
  </>
)

type Option = {
  name: string
  default?: any
  description?: string
  isEnabled?: boolean
  set(name: string, option: Workflow.Option): void
}
const Option = ({
  name,
  default: defaultValue = '',
  description = '',
  isEnabled = true,
  set,
}: Option) => (
  <div style={{ marginBottom: 12 }}>
    <h4 style={{ marginBottom: 4 }}>{name}</h4>

    <Group>
      <label className='label' style={Style.muted}>
        description
      </label>
      <TextInput
        className='input'
        value={description}
        isEnabled={isEnabled}
        onChange={(description) =>
          set(name, { default: defaultValue, description })
        }
      />
    </Group>

    <Group>
      <label className='label' style={Style.muted}>
        default
      </label>
      <TextInput
        className='input'
        value={defaultValue}
        isEnabled={isEnabled}
        onChange={(defaultValue) =>
          set(name, { default: defaultValue, description })
        }
      />
    </Group>
  </div>
)

const Group = styled.div`
  display: flex;
  margin-bottom: 4px;
  margin-left: 18px;
  align-items: center;

  .label {
    flex: 1 1 0;
    margin-bottom: 0;
    justify-self: flex-end;
    text-align: right;
    margin-right: 6px;
  }

  .input {
    flex: 4 1 0;
    margin-bottom: 0;
  }
`
