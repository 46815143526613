import { useEffect, useState } from 'react'

import { Workflow } from '@landrush/common'
import { dateString, timeString } from '@landrush/util'

import { StyledTable, TextInput } from 'components'
import { AddExecutionsState, Workflows } from 'store'
import { useAppDispatch, useAppSelector, useForwardingSelector } from 'utils'

const Settings = () => {
  const dispatch = useAppDispatch()
  const name = useAppSelector(AddExecutionsState.selectName)
  const setName = (name: string) => dispatch(AddExecutionsState.setName(name))
  const id = useAppSelector(AddExecutionsState.selectWorkflowId)
  const workflow = id
    ? (useForwardingSelector(Workflows.selectById, id) as Workflow)
    : undefined

  const [date, setDate] = useState(dateString(new Date()))
  const [time, setTime] = useState(timeString(new Date()))

  useEffect(() => {
    const interval = setInterval(() => {
      const date = new Date()
      setDate(dateString(date))
      setTime(timeString(date))
    }, 500)
    return () => clearInterval(interval)
  }, [])

  const { options = {} } = workflow || {}

  return (
    <>
      <label htmlFor='execution-name'>Naming template:</label>
      <TextInput id='execution-name' value={name} onChange={setName} />

      <label htmlFor='execution-parameters' style={{ marginTop: 12 }}>
        Available parameters:
      </label>
      <StyledTable id='execution-parameters'>
        <thead>
          <td>Name</td>
          <td>Description</td>
        </thead>
        <tbody>
          <tr>
            <td>workflow</td>
            <td>Workflow name</td>
          </tr>
          <tr>
            <td>region</td>
            <td>Region name</td>
          </tr>
          <tr>
            <td>date</td>
            <td>Current date: {date}</td>
          </tr>
          <tr>
            <td>time</td>
            <td>Current time: {time}</td>
          </tr>
          {Object.entries(options)
            .filter(([name]) => name !== 'id' && name !== 'bounds')
            .map(([name, { description = '' } = {}]) => (
              <tr key={name}>
                <td>{name}</td>
                <td>{description}</td>
              </tr>
            ))}
        </tbody>
      </StyledTable>
    </>
  )
}

export default Settings
