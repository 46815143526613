import { Event, Execution, Id } from '@landrush/common'

import { Regions } from 'store/regions'
import { Resources } from 'store/resources'
import { Workflows } from 'store/workflows'

import * as Utils from 'store/utils'

import * as Selectors from './selectors'
import * as Executions from './slice'

const upsertWithRelations = Utils.createThunk(
  ({ dispatch }) => async (executions: Execution[]) => {
    const resources = Utils.extractRelations(executions, (e) => e.resources)
    const regions = Utils.extractRelations(executions, (e) => e.regions)
    const workflows = Utils.extractRelations(executions, (e) => [e.workflow])

    dispatch(Resources.addManySuccess(resources))
    dispatch(Regions.addManySuccess(regions))
    dispatch(Workflows.actions.upsert(workflows))
    dispatch(Executions.actions.upsert(executions))
  }
)

export const addMany = Utils.createThunk(
  ({ dispatch, getState, client }) => async (params: Execution.AddMany) => {
    const executions = await client.executions.addMany(params)
    dispatch(Executions.actions.upsert(executions))
    return executions
  }
)

export const list = Utils.createThunk(
  ({ dispatch, getState, client }) => async () => {
    if (!Selectors.selectIsListed(getState())) {
      const executions = await client.executions.list()
      dispatch(Executions.actions.upsert(executions))
      dispatch(Executions.actions.setListed())
    }
    return Selectors.selectAll(getState())
  }
)

export const hydrateByAlias = Utils.createThunk(
  ({ dispatch, getState, client }) => async (alias: string) => {
    const existing = Selectors.selectByAlias(getState(), alias)
    if (existing && Execution.isDetailed(existing)) return existing

    const execution = await client.executions.getByAlias(alias)
    dispatch(upsertWithRelations([execution]))
    return execution
  }
)

type OrderedEvents = {
  create: Event.Execution.Create[]
  update: Event.Execution.Update[]
  delete: Event.Execution.Delete[]
}
export const receive = Utils.createThunk(
  ({ dispatch }) => async (events: Execution.Event[]) => {
    const ordered = events.reduce<OrderedEvents>(
      (ordered, e) => {
        if (e.action === 'create') ordered.create.push(e)
        else if (e.action === 'update') ordered.update.push(e)
        else if (e.action === 'delete') ordered.delete.push(e)
        return ordered
      },
      { create: [], update: [], delete: [] }
    )

    dispatch(
      upsertWithRelations(
        ordered.create.map((v) => v.payload).map(Execution.parse)
      )
    )
    dispatch(Executions.actions.update(ordered.update.map((v) => v.payload)))
    dispatch(Executions.actions.remove(ordered.delete.map((v) => v.payload.id)))
  }
)

export const patch = Utils.createThunk(
  ({ dispatch, client }) => async (id: Id, patch: Execution.Patch) => {
    const execution = await client.executions.patch(id, patch)
    dispatch(Executions.actions.update([execution]))
    return execution
  }
)

export const remove = Utils.createThunk(
  ({ dispatch, client }) => async (id: Id) => {
    await client.executions.remove(id)
    dispatch(Executions.actions.remove([id]))
    return id
  }
)

export const removeMany = Utils.createThunk(
  ({ dispatch, client }) => async (ids: Id[]) => {
    await client.executions.removeMany(ids)
    dispatch(Executions.actions.remove(ids))
    return ids
  }
)
