import { useSelector } from 'react-redux'

import { Resources, ResourceState } from 'store'

import { useVisibleTargets } from 'utils'

function recordToList(o: Record<string, any>): string[] {
  return Object.entries(o)
    .filter(([, v]) => Boolean(v))
    .map(([k]) => k)
}

export function useVisibleResources() {
  const allTargets = useSelector(Resources.selectAll)
  const filterText = useSelector(ResourceState.selectFilterText)
  const filterTags = recordToList(useSelector(ResourceState.selectFilterTags))
  const selectedTargets = recordToList(
    useSelector(ResourceState.selectSelectedResources)
  )

  return useVisibleTargets({
    allTargets,
    filterText,
    filterTags,
    selectedTargets,
  })
}

export function useTags() {
  return useSelector(Resources.Tags.selectAll)
}
