import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { Button, Column, ErrorMessage, Select, TextInput } from 'components'
import { Credentials, Session } from 'store'
import { useAsync } from 'utils'

const regions = [
  'us-east-1',
  'us-east-2',
  'us-west-1',
  'us-west-2',
  'ap-east-1',
  'ap-south-1',
  'ap-northeast-1',
  'ap-northeast-2',
  'ap-northeast-3',
  'ap-southeast-1',
  'ap-southeast-2',
  'ca-central-1',
  'cn-north-1',
  'cn-northwest-1',
  'eu-central-1',
  'eu-west-1',
  'eu-west-2',
  'eu-west-3',
  'eu-north-1',
  'sa-east-1',
  'me-south-1',
].map((r) => ({ label: r, value: r }))

function getName(access: string, region: string) {
  if (region === 'us-east-1') return access
  return `${access}@${region}`
}
export default () => {
  const dispatch = useDispatch()
  const client = useSelector(Session.selectClient)
  const [access, setAccess] = useState<string>('')
  const [secret, setSecret] = useState<string>('')
  const [region, setRegion] = useState<string>('us-east-1')

  const [
    { isPending, value, error, dismissError },
    submit,
  ] = useAsync(async () => {
    dismissError()
    const c = await client.credentials.add(getName(access, region), 's3', {
      access,
      secret,
      region,
    })
    dispatch(Credentials.addSuccess(c))
    return true
  }, [access, secret, region])

  if (value) return <Redirect to='/credentials' />
  const isValid = Boolean(access.length && secret.length)

  return (
    <Column style={{ width: 380 }}>
      {error ? (
        <ErrorMessage onDismiss={dismissError}>{error}</ErrorMessage>
      ) : null}
      <TextInput
        value={access}
        onChange={setAccess}
        onSubmit={submit}
        isEnabled={!isPending}
        isValid={isValid}
        placeholder='Access key'
      />
      <TextInput
        value={secret}
        onChange={setSecret}
        onSubmit={submit}
        isEnabled={!isPending}
        isValid={isValid}
        placeholder='Secret key'
        type='password'
      />
      <Select
        options={regions}
        value={region}
        onChange={setRegion}
        isEnabled={!isPending}
      />
      <Button
        isEnabled={isValid && !isPending}
        onClick={submit}
        spin={isPending}
      >
        Submit
      </Button>
    </Column>
  )
}
