import styled from 'styled-components'

import { Color } from 'components'

export const Styles = styled.div`
  padding-bottom: 2px;
  border-bottom: 2px solid ${Color.landrushOrange};

  .controls {
    display: flex;
    align-items: center;
  }

  .control-container {
    margin-left: 48px;
  }

  .control {
    font-size: 1.8em;
  }

  .unclickable {
    color: ${Color.gray};
  }

  .clickable {
    cursor: pointer;
    color: ${Color.lightGray};
    &:hover {
      color: ${Color.landrushOrange};
    }
  }

  .cancellable {
    &:hover {
      color: ${Color.dangerRed};
    }
  }

  .pen {
    font-size 1.6em; # The pen icon seems large in comparison - shrink it a bit.
  }
`
