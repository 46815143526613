import { PathType } from 'forager'
import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faFolderOpen } from '@fortawesome/free-solid-svg-icons'

import { Color } from './color'

type FileTypeIcon = { type: PathType }
export const FileTypeIcon: React.FC<FileTypeIcon> = ({ type }) => (
  <FontAwesomeIcon
    icon={type === 'directory' ? faFolderOpen : faFile}
    style={{ color: Color.lightGray }}
    fixedWidth
  />
)
