import React from 'react'
import { useUnmount } from 'react-use'

import { Workflow } from '@landrush/common'

import { CancelOrSubmit, Readme } from 'components'

type ReadmePage = {
  workflow: Workflow
  text?: string
  setText(v: string | undefined): void
  isEnabled?: boolean
  isPending?: boolean
  submit: () => void
  error?: string
  dismissError?: () => void
}

export const ReadmePage = ({
  workflow: { readme },
  text,
  setText,
  isEnabled = true,
  isPending = false,
  submit,
  error,
  dismissError,
}: ReadmePage) => {
  const cancelEdit = () => setText(undefined)
  useUnmount(cancelEdit)

  if (text === undefined) return <Readme readme={readme} />

  return (
    <>
      <Readme.EditorWithPreview
        text={text}
        setText={setText}
        isEnabled={isEnabled}
      />
      <CancelOrSubmit
        onCancel={cancelEdit}
        isEnabled={isEnabled}
        isPending={isPending}
        onSubmit={submit}
        error={error}
        dismissError={dismissError}
      />
    </>
  )
}
