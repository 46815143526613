import React, { ReactNode, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { faCog } from '@fortawesome/free-solid-svg-icons'

import { Dimension } from '@landrush/entwine'
import { Potree, Resource } from '@landrush/common'
import { pretty } from '@landrush/util'

import { Viewer } from 'store'
import { Color, RangeSlider, Select, Slider, Spaced, Style } from 'components'

import { StyledIcon } from './icon'

type Background = Potree.Background
type PointColor = Potree.PointColor
type RampRange = Potree.RampRange

type Icon = { isActive: boolean; activate(): void }
export const Icon = ({ isActive, activate }: Icon) => {
  return (
    <StyledIcon
      className='icon'
      icon={faCog}
      $isActive={isActive}
      onClick={activate}
    />
  )
}

const Styles = styled.div`
  label {
    margin-bottom: 0;
  }

  .section {
    padding-bottom: 16px;
  }

  .value {
    font-size: small;
    color: ${Color.muted};
  }

  .value-container {
    display: flex;
    align-items: flex-end;
  }
`

type Option<T> = { label: string; value: T; enabled?: boolean }

const PointBudget = () => {
  const dispatch = useDispatch()
  const pointBudget = useSelector(Viewer.selectPointBudget)
  return (
    <div className='section'>
      <Spaced>
        <label>Point budget</label>
        <div className='value'>{pretty(pointBudget)}</div>
      </Spaced>
      <Slider
        min={1e5}
        max={1e7}
        step={1e5}
        value={pointBudget}
        onChange={(v) => dispatch(Viewer.setPointBudget(v))}
      />
    </div>
  )
}

const PointSize = () => {
  const dispatch = useDispatch()
  const pointSize = useSelector(Viewer.selectPointSize)
  return (
    <div className='section'>
      <Spaced>
        <label>Point size</label>
        <div className='value'>{pretty(pointSize)}</div>
      </Spaced>
      <Slider
        min={1}
        max={4}
        step={0.1}
        value={pointSize}
        onChange={(v) => dispatch(Viewer.setPointSize(v))}
      />
    </div>
  )
}

const BackgroundDisplay = () => {
  const dispatch = useDispatch()
  const toLonLat = useSelector(Viewer.selectToLonLat)
  const background = useSelector(Viewer.selectBackground)

  const options: Option<Background>[] = [
    { label: 'Terrain', value: 'terrain', enabled: Boolean(toLonLat) },
    { label: 'White', value: 'white' },
    { label: 'Black', value: 'black' },
    { label: 'Gradient', value: 'gradient' },
  ]

  return (
    <div className='section'>
      <label>Background</label>
      <Select
        options={options}
        value={background}
        onChange={(v) => dispatch(Viewer.setBackground(v))}
      />
    </div>
  )
}

const PointColorDisplay: React.FC<WithResource> = ({ resource }) => {
  const dispatch = useDispatch()
  const pointColor = useSelector(Viewer.selectPointColor)
  const elevationRange = useSelector(Viewer.selectElevationRange)
  const intensityRange = useSelector(Viewer.selectIntensityRange)

  const { schema } = resource

  function hasPopulatedDimension(name: string) {
    const dim = schema.find((d) => d.name === name)
    if (!dim) return false
    if (!Dimension.hasStats(dim)) return true
    return dim.minimum !== 0 || dim.maximum !== 0
  }

  const hasColor = hasPopulatedDimension('Red')
  const hasIntensity = hasPopulatedDimension('Intensity')
  const hasClassification = hasPopulatedDimension('Classification')
  const hasReturnNumber = hasPopulatedDimension('ReturnNumber')

  const intensityDim = schema.find((d) => d.name === 'Intensity')
  const initialMaxIntensity =
    intensityDim && Dimension.hasStats(intensityDim)
      ? intensityDim.maximum > 255
        ? 65535
        : 255
      : 255
  const [maxIntensity, setMaxIntensity] = useState(initialMaxIntensity)
  function toggleIntensityRange() {
    const ratios = intensityRange.map((v) => v / maxIntensity)
    const nextMaxIntensity = maxIntensity === 255 ? 65535 : 255
    const nextIntensityRange = ratios.map((v) =>
      Math.round(v * nextMaxIntensity)
    )

    setMaxIntensity(nextMaxIntensity)
    dispatch(Viewer.setIntensityRange(nextIntensityRange as RampRange))
  }

  const options: Option<PointColor>[] = [
    {
      label: 'Color',
      value: 'rgb',
      enabled: hasColor,
    },
    {
      label: 'Intensity',
      value: 'intensity',
      enabled: hasIntensity,
    },
    {
      label: 'Intensity gradient',
      value: 'intensityGradient',
      enabled: hasIntensity,
    },
    {
      label: 'Elevation',
      value: 'elevation',
      enabled: true,
    },
    {
      label: 'Classification',
      value: 'classification',
      enabled: hasClassification,
    },
    {
      label: 'ReturnNumber',
      value: 'returnNumber',
      enabled: hasReturnNumber,
    },
  ]

  let ramp: ReactNode
  if (pointColor === 'elevation') {
    ramp = (
      <>
        <div className='value'>{`${elevationRange[0]} • ${elevationRange[1]}`}</div>
        <RangeSlider
          min={Math.floor(resource.bounds[2])}
          max={Math.ceil(resource.bounds[5])}
          step={1}
          value={elevationRange}
          onChange={(v) => dispatch(Viewer.setElevationRange(v as RampRange))}
        />
      </>
    )
  } else if (pointColor === 'intensity' || pointColor === 'intensityGradient') {
    ramp = (
      <>
        <div className='value'>
          <div
            style={{ ...Style.clickable, ...Style.unselectable }}
            onClick={toggleIntensityRange}
          >
            {`${intensityRange[0]} • ${intensityRange[1]}`}
          </div>
        </div>
        <RangeSlider
          min={0}
          max={maxIntensity}
          step={1}
          value={intensityRange}
          onChange={(v) => dispatch(Viewer.setIntensityRange(v as RampRange))}
        />
      </>
    )
  }

  return (
    <div className='section'>
      <label>Point color</label>
      <Select
        options={options}
        value={pointColor}
        onChange={(v) => dispatch(Viewer.setPointColor(v))}
      />
      {ramp}
    </div>
  )
}

const FpsDisplay = () => {
  const dispatch = useDispatch()
  const fps = useSelector(Viewer.selectFps)
  return (
    <div className='section'>
      <Spaced>
        <label>FPS limit</label>
        <div className='value'>{fps}</div>
      </Spaced>
      <Slider
        value={fps}
        min={5}
        max={60}
        step={5}
        onChange={(v) => dispatch(Viewer.setFps(v))}
      />
    </div>
  )
}

type WithResource = { resource: Resource.Success | Resource.Shared }
export const Tool: React.FC<WithResource> = ({ resource }) => (
  <Styles className='padded'>
    <h4 className='title'>Settings</h4>
    <PointBudget />
    <PointSize />
    <BackgroundDisplay />
    <PointColorDisplay resource={resource} />
    <FpsDisplay />
  </Styles>
)
