import React from 'react'
import styled from 'styled-components'

import { Button, TextInput } from 'components'

type TextForm = {
  isPending: boolean
  text: string
  setText(text: string): void
  submit(): void
  placeholder: string
  buttonText: string
}
export const TextForm: React.FC<TextForm> = ({
  text,
  setText,
  isPending,
  submit,
  placeholder,
  buttonText,
}) => {
  return (
    <Styles>
      <TextInput
        className='text-form-input'
        value={text}
        onChange={setText}
        onSubmit={submit}
        isEnabled={!isPending}
        isValid={text.length > 0}
        placeholder={placeholder}
      />
      <Button
        className='text-form-button'
        onClick={submit}
        spin={isPending}
        isEnabled={text.length > 0}
      >
        {buttonText}
      </Button>
    </Styles>
  )
}

const Styles = styled.div`
  display: flex;

  .text-form-button {
    margin-left: 8px;
  }
`
