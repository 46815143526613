import React from 'react'
import { useSelector } from 'react-redux'
import { useMount, useUnmount } from 'react-use'

import { ErrorMessage, Message, Overview } from 'components'
import { Regions, RegionState } from 'store'
import { useAppDispatch } from 'utils'

import Header from './header'
import List from './list'

const Body = () => {
  const dispatch = useAppDispatch()
  const errors = useSelector(RegionState.selectErrors)
  const dismiss = () => dispatch(RegionState.clearErrors())
  return (
    <>
      <Header />
      {errors.length > 0 && (
        <ErrorMessage onDismiss={dismiss} style={{ paddingTop: 8 }}>
          {errors.map((error) => (
            <div>{error}</div>
          ))}
        </ErrorMessage>
      )}
      <List />
    </>
  )
}

const Loader = () => {
  const dispatch = useAppDispatch()
  const load = () => {
    dispatch(Regions.load())
    dispatch(Regions.Tags.load())
  }
  useMount(load)
  useUnmount(() => dispatch(RegionState.destroy()))

  const loadState = useSelector(Regions.selectLoadState)
  const loadError = useSelector(Regions.selectLoadError)
  const tagLoadState = useSelector(Regions.Tags.selectLoadState)
  const tagLoadError = useSelector(Regions.Tags.selectLoadError)

  if (loadError || tagLoadError) {
    return (
      <div style={{ marginTop: 16 }}>
        <ErrorMessage onRetry={load}>{loadError || tagLoadError}</ErrorMessage>
      </div>
    )
  }
  if (loadState === 'done' && tagLoadState === 'done') return <Body />
  return (
    <div style={{ marginTop: 16 }}>
      <Message spin>Loading...</Message>
    </div>
  )
}

export default () => (
  <>
    <Overview page='regions' create='/add-regions' />
    <Loader />
  </>
)
