import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import { Color, PanelHeader, Spaced, Style } from 'components'

const StyledCancel = styled(FontAwesomeIcon)`
  font-size: 20px;
  cursor: pointer;
  color: ${Color.lightGray};
  &:hover {
    color: ${Color.dangerRed};
  }
`
export default () => (
  <PanelHeader>
    <Spaced>
      <div style={{ ...Style.strong }}>Add data source</div>
      <Link to='/credentials'>
        <StyledCancel icon={faTimes} fixedWidth />
      </Link>
    </Spaced>
  </PanelHeader>
)
