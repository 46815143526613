import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { Resource } from '@landrush/common'

import { Viewer } from 'store'

import { Styles } from './containers'
import * as Bookmarks from './bookmarks'
import * as Compass from './compass'
import * as Fullscreen from './fullscreen'
import * as Settings from './settings'

type ToolName = 'bookmarks' | 'settings'
function getTool(
  resource: Resource.Success | Resource.Shared,
  name?: ToolName
) {
  switch (name) {
    case 'bookmarks':
      return <Bookmarks.Tool resource={resource} />
    case 'settings':
      return <Settings.Tool resource={resource} />
    default:
      return null
  }
}

type WithResource = { resource: Resource.Success | Resource.Shared }
export default ({ resource }: WithResource) => {
  const isMounted = Boolean(useSelector(Viewer.maybeSelectRenderer))

  const [toolName, setToolName] = useState<ToolName | undefined>()
  function activate(v: ToolName) {
    setToolName(v === toolName ? undefined : v)
  }
  const tool = getTool(resource, toolName)

  return (
    <Styles>
      <div className='icon-container'>
        {isMounted ? (
          <>
            <Settings.Icon
              isActive={toolName === 'settings'}
              activate={() => activate('settings')}
            />
            <Bookmarks.Icon
              isActive={toolName === 'bookmarks'}
              activate={() => activate('bookmarks')}
            />
            <Compass.Icon />
            <Fullscreen.Icon />
          </>
        ) : null}
      </div>
      {tool ? <div className='tool-container'>{tool}</div> : null}
    </Styles>
  )
}
