import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'

import { Id, Region, Tag } from '@landrush/common'
import { ialphaSort, Result } from '@landrush/util'

import { AppState, AppThunk } from 'store'
import { Session } from 'store/session'
import { createTagSlice, createTagOperations } from 'store/tags'

import { extractTags } from './utils'

const selectTagSlice = (state: AppState) => state.regionTags
const tagSlice = createTagSlice('regions')
const tagReducer = tagSlice.reducer
const Tags = createTagOperations('regions', tagSlice, selectTagSlice)

type NormalizedRegionSummary = Omit<Region.Summary, 'tags'> & { tags: Id[] }
const normalize = (region: Region.Summary): NormalizedRegionSummary => ({
  ...region,
  tags: region.tags.map((tag) => tag.id),
})
const denormalize = (
  state: AppState,
  norm: NormalizedRegionSummary
): Region.Summary => ({
  ...norm,
  tags: norm.tags
    .map((id) => Tags.selectById(state, id))
    .filter((t): t is Tag => Boolean(t)),
})

type AliasMap = Record<string, Id>
type CustomState = {
  loadState?: 'loading' | 'done'
  loadError?: string
  aliasMap: AliasMap
}
const adapter = createEntityAdapter<NormalizedRegionSummary>({
  sortComparer: (a, b) => ialphaSort(a.name, b.name),
})
const initialState = adapter.getInitialState<CustomState>({ aliasMap: {} })

type TagIntersection = { regionIds: Id[]; tagId: Id }

const slice = createSlice({
  name: 'regions',
  initialState,
  reducers: {
    loadRequest(state) {
      state.loadState = 'loading'
      state.loadError = undefined
    },
    loadFailure(state, action: PayloadAction<string>) {
      state.loadState = undefined
      state.loadError = action.payload
    },
    loadSuccess(state, action: PayloadAction<Region.Summary[]>) {
      const regions = action.payload.map(normalize)
      adapter.addMany(state, regions)
      state.loadState = 'done'
    },
    destroy() {
      return initialState
    },
    addSuccess(state, action: PayloadAction<Region>) {
      const region = normalize(action.payload)
      adapter.upsertOne(state, region)
    },
    addManySuccess(state, action: PayloadAction<Region.Summary[]>) {
      const regions = action.payload.map(normalize)
      adapter.addMany(state, regions)
    },
    removeSuccess(state, action: PayloadAction<Id>) {
      const id = action.payload
      adapter.removeOne(state, id)
    },
    removeManySuccess(state, action: PayloadAction<Id[]>) {
      const ids = action.payload
      adapter.removeMany(state, ids)
    },
    applySuccess(state, action: PayloadAction<TagIntersection>) {
      const { regionIds, tagId } = action.payload
      regionIds.forEach((regionId) => {
        const region = state.entities[regionId]
        if (region && !region.tags.includes(tagId)) region.tags.push(tagId)
      })
    },
    hydrateSuccess(state, action: PayloadAction<Region>) {
      const region = normalize(action.payload)
      adapter.upsertOne(state, region)
      state.aliasMap[region.alias] = region.id
    },
    hydrateManySuccess(state, action: PayloadAction<Region[]>) {
      const regions = action.payload.map(normalize)
      adapter.upsertMany(state, regions)
      regions.forEach((r) => (state.aliasMap[r.alias] = r.id))
    },
    unapplySuccess(state, action: PayloadAction<TagIntersection>) {
      const { regionIds, tagId } = action.payload
      regionIds.forEach((regionId) => {
        const region = state.entities[regionId]
        if (region) region.tags = region.tags.filter((id) => id !== tagId)
      })
    },
  },
})

const doHydrate = (
  select: () => Region.Summary | undefined,
  fetch: () => Promise<Region>
): AppThunk<Result<Region>> => async (dispatch) => {
  try {
    const existing = select()

    if (existing && Region.isDetailed(existing)) {
      return Result.success(existing)
    }

    const region = await fetch()
    dispatch(Tags.upsertMany(region.tags))
    dispatch(hydrateSuccess(region))
    return Result.success(region)
  } catch (e) {
    const error: string = e.message
    return Result.failure(error)
  }
}

const hydrate = (id: Id): AppThunk<Result<Region>> => async (
  dispatch,
  getState
) => {
  return dispatch(
    doHydrate(
      () => selectById(getState(), id),
      () => Session.selectClient(getState()).regions.get(id)
    )
  )
}

const hydrateByAlias = (alias: string): AppThunk<Result<Region>> => async (
  dispatch,
  getState
) => {
  return dispatch(
    doHydrate(
      () => selectByAlias(getState(), alias),
      () => Session.selectClient(getState()).regions.getByAlias(alias)
    )
  )
}

const { actions, reducer } = slice
const {
  loadRequest,
  loadFailure,
  loadSuccess,
  addManySuccess,
  hydrateSuccess,
  removeSuccess,
  removeManySuccess,
} = actions

const selectSlice = (state: AppState) => state.regions
const adapterSelectors = adapter.getSelectors(selectSlice)

const selectAll = (s: AppState) =>
  adapterSelectors.selectAll(s).map((norm) => denormalize(s, norm))

const selectById = (s: AppState, id: Id): Region.Summary | undefined => {
  const norm = adapterSelectors.selectById(s, id)
  if (!norm) return
  return denormalize(s, norm)
}

const selectByAlias = (state: AppState, alias: string) => {
  const aliases = selectAliasMap(state)
  const id = aliases[alias]
  return selectById(state, id)
}

const selectAliasMap = createSelector(selectSlice, (s) => s.aliasMap)
const selectLoadState = createSelector(selectSlice, (s) => s.loadState)
const selectLoadError = createSelector(selectSlice, (s) => s.loadError)
const selectCount = createSelector(adapterSelectors.selectAll, (s) => s.length)

const selectors = {
  selectAll,
  selectById,
  selectLoadState,
  selectLoadError,
  selectCount,
}

const load = (): AppThunk<Result<Region.Summary[]>> => async (
  dispatch,
  getState
) => {
  try {
    const loadState = selectLoadState(getState())
    if (loadState === 'done') return Result.success(selectAll(getState()))

    dispatch(loadRequest())
    const client = Session.selectClient(getState())
    const regions = await client.regions.list()

    const tags = extractTags(regions)
    dispatch(Tags.upsertMany(tags))

    dispatch(loadSuccess(regions))
    return Result.success(regions)
  } catch (e) {
    dispatch(loadFailure(e.message))
    return Result.failure(e.message)
  }
}
const addMany = ({
  name,
  description,
  input,
}: Region.AddMany): AppThunk<Result<Region.Summary[]>> => async (
  dispatch,
  getState
) => {
  try {
    const client = Session.selectClient(getState())
    const regions = await client.regions.addMany({ name, description, input })
    dispatch(addManySuccess(regions))
    return Result.success(regions)
  } catch (e) {
    console.log(e)
    return Result.failure(e.message)
  }
}

const remove = (id: Id): AppThunk<Result<true>> => async (dispatch, gs) => {
  try {
    const client = Session.selectClient(gs())
    await client.regions.remove(id)
    dispatch(removeSuccess(id))
    return Result.success(true)
  } catch (e) {
    console.log(e)
    return Result.failure(e.message)
  }
}

const removeMany = (ids: Id[]): AppThunk<Result<true>> => async (
  dispatch,
  getState
) => {
  try {
    const client = Session.selectClient(getState())
    await client.regions.removeMany(ids)
    dispatch(removeManySuccess(ids))
    return Result.success(true)
  } catch (e) {
    console.log(e)
    return Result.failure(e.message)
  }
}

const thunks = { load, addMany, hydrate, hydrateByAlias, remove, removeMany }

export const Regions = {
  ...actions,
  ...selectors,
  ...thunks,
  Tags,
}
export { reducer, tagReducer }
