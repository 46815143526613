import { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useMount } from 'react-use'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList } from '@fortawesome/free-solid-svg-icons'

import { Id } from '@landrush/common'

import {
  Button,
  Color,
  ErrorMessage,
  InfoMessage,
  Message,
  Prefix,
  TextInput,
  Select,
} from 'components'
import { Copies, Credentials, ExecutionState } from 'store'
import { useAppDispatch, useAppSelector, useAsyncResult } from 'utils'

export const Body = () => {
  const dispatch = useAppDispatch()

  const executionIds = Object.keys(
    useAppSelector(ExecutionState.selectSelectedExecutions)
  )
  const count = executionIds.length

  const credentials = useAppSelector(Credentials.selectCredentials)

  const [output, setOutput] = useState('')
  const [test, setTest] = useState('')
  const [credentialId, setCredentialId] = useState<Id | undefined>(
    credentials.length === 1 ? credentials[0].id : undefined
  )

  const credential = credentialId
    ? credentials.find((v) => v.id === credentialId)
    : undefined
  const options = credentials.map((c) => ({ label: c.name, value: c.id }))

  const isValid = Boolean(credentialId && count && output.length)

  const [
    { isPending, error, dismissError, value },
    submit,
  ] = useAsyncResult(() => {
    if (!credentialId) throw new Error('Invalid state')
    return dispatch(Copies.add({ credentialId, executionIds, output, test }))
  }, [credentialId, executionIds, output, test])

  if (value) return <Redirect to='/copies' />

  return (
    <>
      {count ? (
        <p>
          <small>
            Copying data from <strong>{count}</strong> execution
            {count > 1 ? 's' : ''}
          </small>
        </p>
      ) : (
        <InfoMessage>Select executions to export</InfoMessage>
      )}
      <label className='text-label'>Credential</label>
      <Select
        isEnabled={count > 0}
        placeholder='Select a credential'
        options={options}
        value={credentialId}
        onChange={setCredentialId}
      />
      <label className='text-label'>Output path</label>
      <div style={{ display: 'flex' }}>
        {credential && <Prefix>{`${credential.protocol}://`}</Prefix>}
        <TextInput
          style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          isEnabled={credential && count > 0}
          placeholder='output/directory/here...'
          value={output}
          onChange={setOutput}
          onSubmit={submit}
        />
      </div>
      <label className='text-label'>
        Filename match <small>(optional)</small>
      </label>
      <TextInput
        isEnabled={credential && count > 0}
        placeholder='E.g. *.tif'
        value={test}
        onChange={setTest}
        onSubmit={submit}
      />
      {error && <ErrorMessage onDismiss={dismissError}>{error}</ErrorMessage>}
      <Button spin={isPending} isEnabled={isValid} onClick={submit}>
        Export
      </Button>
    </>
  )
}

const Loader = () => {
  const dispatch = useAppDispatch()
  const load = () => dispatch(Credentials.load())
  useMount(load)

  const loadState = useAppSelector(Credentials.selectLoadState)
  const loadError = useAppSelector(Credentials.selectError)

  if (loadError) return <ErrorMessage onRetry={load}>{loadError}</ErrorMessage>
  if (loadState !== 'done') return <Message spin>Loading...</Message>
  return <Body />
}

export const CopyMenu = () => {
  return (
    <Styles>
      <div className='header'>
        <h4>Export outputs</h4>
        <Link to='/copies'>
          <FontAwesomeIcon icon={faList} className='copies-list-icon' />
        </Link>
      </div>
      <Loader />
    </Styles>
  )
}

const Styles = styled.div`
  background-color: white;
  border: 1px solid ${Color.lightGray};
  border-radius: 4px;
  width: 20vw;
  min-width: 360px;
  padding-top: 8px;
  padding-left: 12px;
  padding-right: 12px;

  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
  }

  .copies-list-icon {
    font-size: 20px;
  }

  .text-label {
    margin-bottom: 2px;
    font-size: small;
    color: ${Color.veryDarkGray};
  }

  button {
    align-self: center;
  }
`
