import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Id, Region } from '@landrush/common'
import { Result } from '@landrush/util'

import { AppState, AppThunk } from 'store'
import { Regions } from 'store/regions'
import { Session } from 'store/session'

type State = {
  errors: string[]
  filterText: string
  filterTags: Record<Id, true | undefined>
  selectedRegions: Record<Id, true | undefined>
}
const initialState: State = {
  errors: [],
  filterText: '',
  filterTags: {},
  selectedRegions: {},
}

const slice = createSlice({
  name: 'regions-state',
  initialState,
  reducers: {
    setFilterText(state, { payload: text }: PayloadAction<string>) {
      state.filterText = text
    },
    addFilterTag(state, { payload: id }: PayloadAction<Id>) {
      state.filterTags[id] = true
    },
    removeFilterTag(state, { payload: id }: PayloadAction<Id>) {
      delete state.filterTags[id]
    },
    toggleFilterTag(state, { payload: id }: PayloadAction<Id>) {
      if (state.filterTags[id]) delete state.filterTags[id]
      else state.filterTags[id] = true
    },
    removeAllFilterTags(state) {
      state.filterTags = {}
    },
    toggleRegion(state, { payload: id }: PayloadAction<Id>) {
      if (state.selectedRegions[id]) delete state.selectedRegions[id]
      else state.selectedRegions[id] = true
    },
    performSelectMany(state, { payload: ids }: PayloadAction<Id[]>) {
      ids.forEach((id) => (state.selectedRegions[id] = true))
    },
    performUnselectMany(state, { payload: ids }: PayloadAction<Id[]>) {
      ids.forEach((id) => delete state.selectedRegions[id])
    },
    performUnselectAll(state) {
      state.selectedRegions = {}
    },
    addError(state, { payload: error }: PayloadAction<string>) {
      if (!state.errors.includes(error)) state.errors = [...state.errors, error]
    },
    clearErrors(state) {
      state.errors = []
    },
    destroy() {
      return initialState
    },
  },
})

const { actions, reducer } = slice

const selectSlice = (state: AppState) => state.regionState

const selectErrors = createSelector(selectSlice, (s) => s.errors)
const selectFilterText = createSelector(selectSlice, (s) => s.filterText)
const selectFilterTags = createSelector(selectSlice, (s) => s.filterTags)

const selectSelectedRegions = createSelector(
  selectSlice,
  (s) => s.selectedRegions
)
const selectSelectedRegionsList = createSelector(selectSlice, (s) =>
  Object.keys(s.selectedRegions)
)
const selectSelectedRegionsCount = createSelector(
  selectSelectedRegions,
  (selected) => Object.keys(selected).length
)
const selectIsEverythingSelected = createSelector(
  Regions.selectCount,
  selectSelectedRegions,
  (all, selections) => all === Object.values(selections).length
)
const selectIsAnythingSelected = createSelector(
  selectSelectedRegions,
  (selections) => {
    for (let _k in selections) return true
    return false
  }
)
const selectIsRegionSelected = (state: AppState, id: Id) =>
  Boolean(selectSlice(state).selectedRegions[id])

const selectors = {
  selectSlice,
  selectErrors,
  selectFilterText,
  selectFilterTags,
  selectSelectedRegions,
  selectSelectedRegionsCount,
  selectIsEverythingSelected,
  selectIsAnythingSelected,
  selectIsRegionSelected,
}

const applyTag = (tagId: Id): AppThunk<Result<Id>> => async (dispatch, gs) => {
  try {
    const client = Session.selectClient(gs())
    const regionIds = selectSelectedRegionsList(gs())
    if (!regionIds.length) return Result.failure('No regions selected')

    const regions = regionIds
      .map((id) => Regions.selectById(gs(), id))
      .filter((v): v is Region.Summary => Boolean(v))

    if (regions.every((region) => region.tags.some((t) => t.id === tagId))) {
      // If *all* regions already have this tag, then unapply it.
      await client.regions.tags.unapply(tagId, regionIds)
      dispatch(Regions.unapplySuccess({ tagId, regionIds }))
    } else {
      // Otherwise, apply this tag to all selected regions.
      await client.regions.tags.apply(tagId, regionIds)
      dispatch(Regions.applySuccess({ tagId, regionIds }))
    }

    return Result.success(tagId)
  } catch (e) {
    return Result.failure(e.message)
  }
}

const removeSelected = (ids: Id[]): AppThunk<Result<true>> => async (
  dispatch
) => {
  const result = await dispatch(Regions.removeMany(ids))
  if (result.value) dispatch(actions.performUnselectMany(ids))
  return result
}

const thunks = { applyTag, removeSelected }

export const RegionState = { ...actions, ...selectors, ...thunks }
export { reducer }
