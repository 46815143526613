import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useUnmount } from 'react-use'

import {
  CredentialProfileIcon,
  ErrorMessage,
  Panel,
  PanelHeader,
  Spaced,
  Style,
} from 'components'
import { Staging } from 'store'

import Summary from '../summary'
import List from './list'
import Launch from './launch'

export default () => {
  const dispatch = useDispatch()

  const stagedCredential = useSelector(Staging.selectStagedCredential)
  const error = useSelector(Staging.selectError)
  const dismiss = () => dispatch(Staging.setError())

  useUnmount(() => dispatch(Staging.destroy()))

  return (
    <>
      <Panel style={{ marginBottom: 16 }}>
        <PanelHeader>
          <Spaced>
            <div style={Style.strong}>Staged items</div>
            {stagedCredential ? (
              <CredentialProfileIcon name={stagedCredential.name} />
            ) : (
              <div />
            )}
          </Spaced>
        </PanelHeader>
        {error ? (
          <ErrorMessage style={{ marginTop: 8 }} onDismiss={dismiss}>
            {error}
          </ErrorMessage>
        ) : null}
        <List />
        <Launch />
        <Summary />
      </Panel>
    </>
  )
}
