import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Id, Resource } from '@landrush/common'
import { Result } from '@landrush/util'

import { AppState, AppThunk } from 'store'
import { Resources } from 'store/resources'
import { Session } from 'store/session'

type State = {
  errors: string[]
  filterText: string
  filterTags: Record<Id, true | undefined>
  selectedResources: Record<Id, true | undefined>
}
const initialState: State = {
  errors: [],
  filterText: '',
  filterTags: {},
  selectedResources: {},
}

const slice = createSlice({
  name: 'resources-state',
  initialState,
  reducers: {
    setFilterText(state, { payload: text }: PayloadAction<string>) {
      state.filterText = text
    },
    addFilterTag(state, { payload: id }: PayloadAction<Id>) {
      state.filterTags[id] = true
    },
    removeFilterTag(state, { payload: id }: PayloadAction<Id>) {
      delete state.filterTags[id]
    },
    toggleFilterTag(state, { payload: id }: PayloadAction<Id>) {
      if (state.filterTags[id]) delete state.filterTags[id]
      else state.filterTags[id] = true
    },
    removeAllFilterTags(state) {
      state.filterTags = {}
    },
    toggleResource(state, { payload: id }: PayloadAction<Id>) {
      if (state.selectedResources[id]) delete state.selectedResources[id]
      else state.selectedResources[id] = true
    },
    performSelectMany(state, { payload: ids }: PayloadAction<Id[]>) {
      ids.forEach((id) => (state.selectedResources[id] = true))
    },
    performUnselectAll(state) {
      state.selectedResources = {}
    },
    unselect(state, { payload: id }: PayloadAction<Id>) {
      delete state.selectedResources[id]
    },
    addError(state, { payload: error }: PayloadAction<string>) {
      if (!state.errors.includes(error)) state.errors = [...state.errors, error]
    },
    clearErrors(state) {
      state.errors = []
    },
    destroy() {
      return initialState
    },
  },
  extraReducers: {
    // TODO: We can't hook into this action directly due to our AppState type,
    // which would become dependent on itself.  Maybe we can organize things
    // differently to keep this interception in sync.
    // What we want is something like:
    // [Resources.removeSuccess]: (state, action) => { ... },
    'resources/removeSuccess': (state, action: PayloadAction<Id>) => {
      delete state.selectedResources[action.payload]
    },
  },
})

const { actions, reducer } = slice

const selectSlice = (state: AppState) => state.resourceState

const selectErrors = createSelector(selectSlice, (s) => s.errors)
const selectFilterText = createSelector(selectSlice, (s) => s.filterText)
const selectFilterTags = createSelector(selectSlice, (s) => s.filterTags)

const selectSelectedResources = createSelector(
  selectSlice,
  (s) => s.selectedResources
)
const selectSelectedResourcesList = createSelector(selectSlice, (s) =>
  Object.keys(s.selectedResources)
)
const selectSelectedResourcesCount = createSelector(
  selectSelectedResources,
  (selected) => Object.keys(selected).length
)
const selectIsEverythingSelected = createSelector(
  Resources.selectCount,
  selectSelectedResources,
  (all, selections) => all === Object.values(selections).length
)
const selectIsAnythingSelected = createSelector(
  selectSelectedResources,
  (selections) => {
    for (let _k in selections) return true
    return false
  }
)
const selectIsResourceSelected = (state: AppState, id: Id) =>
  Boolean(selectSlice(state).selectedResources[id])

const selectors = {
  selectSlice,
  selectErrors,
  selectFilterText,
  selectFilterTags,
  selectSelectedResources,
  selectSelectedResourcesCount,
  selectIsEverythingSelected,
  selectIsAnythingSelected,
  selectIsResourceSelected,
}

const applyTag = (tagId: Id): AppThunk<Result<Id>> => async (dispatch, gs) => {
  try {
    const client = Session.selectClient(gs())
    const resourceIds = selectSelectedResourcesList(gs())
    if (!resourceIds.length) return Result.failure('No resources selected')

    const resources = resourceIds
      .map((id) => Resources.selectById(gs(), id))
      .filter((v): v is Resource.Summary => Boolean(v))

    if (
      resources.every((resource) => resource.tags.some((t) => t.id === tagId))
    ) {
      // If *all* resources already have this tag, then unapply it.
      await client.resources.tags.unapply(tagId, resourceIds)
      dispatch(Resources.unapplySuccess({ tagId, targetIds: resourceIds }))
    } else {
      // Otherwise, apply this tag to all selected resources.
      await client.resources.tags.apply(tagId, resourceIds)
      dispatch(Resources.applySuccess({ tagId, targetIds: resourceIds }))
    }

    return Result.success(tagId)
  } catch (e) {
    return Result.failure(e.message)
  }
}

const removeSelected = (ids: Id[]): AppThunk<Result<true>> => async (
  dispatch
) => {
  const result = await dispatch(Resources.removeMany(ids))
  if (result.value) ids.forEach((id) => dispatch(actions.unselect(id)))
  return result
}

const thunks = { applyTag, removeSelected }

export const ResourceState = { ...actions, ...selectors, ...thunks }
export { reducer }
