import { useSelector } from 'react-redux'

import { Execution, Tag } from '@landrush/common'

import { Executions, ExecutionState } from 'store'

import { useVisibleTargets } from 'utils'

function recordToList(o: Record<string, any>): string[] {
  return Object.entries(o)
    .filter(([, v]) => Boolean(v))
    .map(([k]) => k)
}

type TaggedExecution = Execution.Summary & { tags: string[] }
export function useVisibleExecutions() {
  const allTargets: TaggedExecution[] = useSelector(
    Executions.selectAll
  ).map((v) => ({ ...v, tags: [] }))
  const filterText = useSelector(ExecutionState.selectFilterText)
  const filterStatus = useSelector(ExecutionState.selectFilterStatus)
  const selectedTargets = recordToList(
    useSelector(ExecutionState.selectSelectedExecutions)
  )

  const targets = useVisibleTargets({
    allTargets,
    filterText,
    filterTags: [],
    selectedTargets,
  })

  if (filterStatus) return targets.filter((v) => v.status === filterStatus)
  else return targets
}

export function useTags(): Tag[] {
  // For now we'll just list everything in order.  What we really want is to
  // prioritize based on:
  //  - Tags which are applied to *all* selected regions.  Action: unapply.
  //  - Tags which are applied to *some* selected regions.  Action: apply some.
  //  - Tags which are not applied to any selected regions.  Action: apply all.
  //
  // Within each category, sort by name, and provide a contextual icon
  // indicating the action that will be taken if the tag is clicked.
  /*
  const regionEntities = useSelector(Regions.selectEntities)
  const selectedRegions = Object.keys(
    useSelector(RegionState.selectSelectedRegions)
  )
    .map((id) => regionEntities[id])
    .filter((v): v is Regions.Normalized => Boolean(v))

  const completelyAppliedTags = allTags.filter((tag) =>
    selectedRegions.every((region) => region.tags.includes(tag.id))
  )
  const partiallyAppliedTags = allTags.filter((tag) =>
    selectedRegions.some(region => region.tags.includes(tag.id)) &&
    !completelyAppliedTags.includes(tag)
  )
  const unappliedTags = allTags.filter((tag) =>
    !completelyAppliedTags.includes(tag) &&
    !partiallyAppliedTags.includes(tag)
  )
  */

  return []
}
