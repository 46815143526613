import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Id, Task } from '@landrush/common'
import { Result } from '@landrush/util'

import { AppState, AppThunk } from 'store'
import { Executions } from 'store/executions'

type State = {
  errors: string[]
  filterText: string
  filterStatus?: Task.Status
  selected: Record<Id, true | undefined>
}
const initialState: State = {
  errors: [],
  filterText: '',
  selected: {},
}

const slice = createSlice({
  name: 'executions-state',
  initialState,
  reducers: {
    setFilterText(state, { payload: text }: PayloadAction<string>) {
      state.filterText = text
    },
    setFilterStatus(
      state,
      { payload: s }: PayloadAction<Task.Status | undefined>
    ) {
      if (state.filterStatus === s) state.filterStatus = undefined
      else state.filterStatus = s
    },
    toggle(state, { payload: id }: PayloadAction<Id>) {
      if (state.selected[id]) delete state.selected[id]
      else state.selected[id] = true
    },
    performSelectMany(state, { payload: ids }: PayloadAction<Id[]>) {
      ids.forEach((id) => (state.selected[id] = true))
    },
    performUnselectAll(state) {
      state.selected = {}
    },
    unselect(state, { payload: id }: PayloadAction<Id>) {
      delete state.selected[id]
    },
    addError(state, { payload: error }: PayloadAction<string>) {
      if (!state.errors.includes(error)) state.errors = [...state.errors, error]
    },
    clearErrors(state) {
      state.errors = []
    },
    destroy() {
      return initialState
    },
  },
})

const { actions, reducer } = slice

const selectSlice = (state: AppState) => state.executionState

const selectErrors = createSelector(selectSlice, (s) => s.errors)
const selectFilterText = createSelector(selectSlice, (s) => s.filterText)
const selectFilterStatus = createSelector(selectSlice, (s) => s.filterStatus)

const selectSelectedExecutions = createSelector(selectSlice, (s) => s.selected)
/*
const selectSelectedExecutionsList = createSelector(selectSlice, (s) =>
  Object.keys(s.selected)
)
*/
const selectSelectedExecutionsCount = createSelector(
  selectSelectedExecutions,
  (selected) => Object.keys(selected).length
)
const selectIsEverythingSelected = createSelector(
  Executions.selectTotal,
  selectSelectedExecutions,
  (all, selections) => all === Object.values(selections).length
)
const selectIsAnythingSelected = createSelector(
  selectSelectedExecutions,
  (selections) => {
    for (let _k in selections) return true
    return false
  }
)
const selectIsExecutionSelected = (state: AppState, id: Id) =>
  Boolean(selectSlice(state).selected[id])

const selectors = {
  selectSlice,
  selectErrors,
  selectFilterText,
  selectFilterStatus,
  selectSelectedExecutions,
  selectSelectedExecutionsCount,
  selectIsEverythingSelected,
  selectIsAnythingSelected,
  selectIsExecutionSelected,
}

const removeSelected = (ids: Id[]): AppThunk<Result<Id[]>> => async (
  dispatch
) => {
  const removed = await dispatch(Executions.removeMany(ids))
  if (removed.value) {
    removed.value.forEach((id) => dispatch(actions.unselect(id)))
  }
  return removed
}

export const ExecutionState = { ...actions, ...selectors, removeSelected }
export { reducer }
