// Local storage access can fail for lots of reasons we don't really care about,
// like private browsing mode, users disabling local storage, etc.

function set(key: string, val: string) {
  try {
    localStorage.setItem(key, val)
  } catch (e) {}
}
function get(key: string): string | undefined {
  try {
    return localStorage.getItem(key) || undefined
  } catch (e) {}
}
function getInteger(key: string): number | undefined {
  const str = get(key)
  if (!str) return
  const n = parseInt(str, 10)
  if (!isNaN(n)) return n
}
function remove(key: string): void {
  localStorage.removeItem(key)
}

export const LocalStorage = { set, get, getInteger, remove }
