import React, { useState } from 'react'

import External from './external'
import Items from './items'
import Navigator from './navigator'

import { Button, Color, Overview, Spaced } from 'components'

export default function () {
  const [isExternal, setExternal] = useState(false)

  return (
    <>
      <Overview page='home' />
      <Spaced style={{ marginTop: 16 }}>
        <h2 style={{ marginBottom: 0 }}>Add resources</h2>
        <Button
          onClick={() => setExternal(!isExternal)}
          style={{
            backgroundColor: isExternal
              ? Color.landrushOrange
              : Color.lightGray,
          }}
        >
          External link
        </Button>
      </Spaced>
      {isExternal ? (
        <External />
      ) : (
        <>
          <Items />
          <Navigator />
        </>
      )}
    </>
  )
}
