import { PathInfo } from 'forager'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faPlus, faReply } from '@fortawesome/free-solid-svg-icons'

import {
  Color,
  Column,
  Button,
  ErrorMessage,
  Message,
  StyledTable,
  FileTypeIcon,
} from 'components'

import { Navigator, Staging } from 'store'

import { useIsStaged, useItemIdentifier } from './hooks'

const StyledUpOne = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: ${Color.gray};
  &:hover {
    color: ${Color.landrushOrange};
  }
`
const UpOne = () => {
  const dispatch = useDispatch()
  const cwd = useSelector(Navigator.forceSelectCwd)
  return (
    <StyledUpOne
      icon={faReply}
      onClick={() =>
        dispatch(Navigator.navigateTo(Navigator.getParentDirectory(cwd)))
      }
      fixedWidth
    />
  )
}

const StyledName = styled.div`
  cursor: pointer;
  user-select: none;
  color: ${Color.veryDarkGray};
  &:hover {
    color: ${Color.landrushOrange};
  }
`
const UnstagedName = styled(StyledName)`
  color: ${Color.veryDarkGray};
  &:hover {
    color: ${Color.landrushOrange};
  }
`
const AlreadyStagedName = styled(StyledName)`
  color: ${Color.lightGray};
`

type WithItem = { item: PathInfo }
const Name: React.FC<WithItem> = ({ item }) => {
  const dispatch = useDispatch()

  const identifier = useItemIdentifier(item)
  const cwd = useSelector(Navigator.forceSelectCwd)
  const isStaged = useIsStaged(item)

  if (isStaged) {
    const onClick = () => dispatch(Staging.remove(identifier))
    return <AlreadyStagedName onClick={onClick}>{item.path}</AlreadyStagedName>
  }

  const onClick =
    item.type === 'file'
      ? () => dispatch(Staging.add(identifier))
      : () =>
          dispatch(
            Navigator.navigateTo({
              ...cwd,
              parts: [...cwd.parts, item.path],
            })
          )

  return <UnstagedName onClick={onClick}>{item.path}</UnstagedName>
}

const UnstagedAction = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: ${Color.lightGray};
  &:hover {
    color: ${Color.landrushOrange};
  }
`
const AlreadyStagedAction = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: ${Color.landrushOrange};
`
const Action: React.FC<WithItem> = ({ item }) => {
  const dispatch = useDispatch()
  const isStaged = useIsStaged(item)
  const identifier = useItemIdentifier(item)

  if (isStaged) {
    const onClick = () => dispatch(Staging.remove(identifier))
    return <AlreadyStagedAction icon={faCheck} onClick={onClick} fixedWidth />
  }

  const onClick = () => dispatch(Staging.add(identifier))
  return <UnstagedAction icon={faPlus} onClick={onClick} fixedWidth />
}

type Row = { item: PathInfo }
const Row: React.FC<Row> = ({ item }) => (
  <tr>
    <td>
      <FileTypeIcon type={item.type} />
    </td>
    <td style={{ width: '100%', paddingLeft: 16 }}>
      <Name item={item} />
    </td>
    <td>
      <Action item={item} />
    </td>
  </tr>
)

const pad = { marginTop: 8 }
export default () => {
  const dispatch = useDispatch()
  const reload = () => dispatch(Navigator.listCwd())
  const { error, isLoading, items } = useSelector(Navigator.selectCwdState)

  if (error) {
    return (
      <ErrorMessage onRetry={reload} style={pad}>
        {error}
      </ErrorMessage>
    )
  }
  if (isLoading) {
    return (
      <Message spin style={pad}>
        Loading...
      </Message>
    )
  }
  if (!items.length) {
    return (
      <Column>
        <Message style={pad}>(nothing here)</Message>
        <Button onClick={reload}>Reload</Button>
      </Column>
    )
  }
  return (
    <StyledTable>
      <tbody>
        <tr>
          <td>
            <FileTypeIcon type='directory' />
          </td>
          <td style={{ width: '100%', paddingLeft: 16 }}>
            <UpOne />
          </td>
          <td></td>
        </tr>
        {items.map((item) => (
          <Row key={`${item.path}-${item.type}`} item={item} />
        ))}
      </tbody>
    </StyledTable>
  )
}
