import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Id, Workflow } from '@landrush/common'

import { GetSliceState } from 'utils'

import { adapter } from './types'

const initialState = adapter.getInitialState({ isListed: false })
const slice = createSlice({
  name: 'workflows',
  initialState,
  reducers: {
    setListed(state) {
      state.isListed = true
    },
    upsert(state, { payload }: PayloadAction<Workflow.Summary[]>) {
      adapter.upsertMany(state, payload)
    },
    update(state, { payload }: PayloadAction<Workflow.Summary[]>) {
      adapter.updateMany(
        state,
        payload.map((changes) => ({ id: changes.id, changes }))
      )
    },
    remove(state, { payload }: PayloadAction<Id[]>) {
      adapter.removeMany(state, payload)
    },
    destroy() {
      return initialState
    },
  },
})

export const { actions, reducer } = slice
export type Reducer = typeof reducer
export type Slice = GetSliceState<Reducer>
