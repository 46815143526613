import React from 'react'
import styled from 'styled-components'

import { Color as ColorUtil } from '@landrush/util'

import { Color } from './color'

type Tag = {
  name: string
  color: string
  className?: string
  style?: React.CSSProperties
  onClick?: () => void
  isEnabled?: boolean
}
export const Tag: React.FC<Tag> = ({
  name,
  color,
  className,
  style,
  onClick,
  isEnabled = true,
}) => {
  if (!ColorUtil.validate(color)) console.trace(`Invalid color: ${color}`)
  if (!color.startsWith('#')) color = `#${color}`

  if (!isEnabled) {
    return (
      <DisabledStyles
        className={'tag' + (className ? ` ${className}` : '')}
        style={style}
      >
        {name}
      </DisabledStyles>
    )
  }

  return (
    <Styles
      className={'tag' + (className ? ` ${className}` : '')}
      $backgroundColor={color}
      $fontColor={ColorUtil.isLight(color.slice(1)).value ? 'black' : 'white'}
      $clickable={Boolean(onClick)}
      style={style}
      onClick={onClick}
    >
      {name}
    </Styles>
  )
}

const StructuralStyles = styled.span`
  display: inline-block;
  padding: 1px;
  font-size: 14px;
  border-radius: 14px;
  padding-left: 12px;
  padding-right: 12px;
`

type Styles = {
  $backgroundColor: string
  $fontColor: string
  $clickable: boolean
}
const Styles = styled(StructuralStyles)(
  ({ $backgroundColor, $fontColor, $clickable }: Styles) => `
  background-color: ${$backgroundColor};
  cursor: ${$clickable ? 'pointer' : 'default'};
  color: ${$fontColor};
  display: inline-block;
  padding: 1px;
  font-size: 14px;
  border-radius: 14px;
  padding-left: 12px;
  padding-right: 12px;
`
)

const DisabledStyles = styled(StructuralStyles)`
  background-color: ${Color.lightGray};
  color: white;
  cursor: default;
`
