import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Credential } from '@landrush/common'

import { Credentials } from 'store'
import { useClient } from 'utils'

export type FlowState =
  | undefined
  | 'renaming'
  | 'removing'
  | 'adding-path'
  | 'removing-path'

export function useCredentialState(credential: Credential) {
  const { id } = credential
  const dispatch = useDispatch()
  const client = useClient()
  const [error, setError] = useState<string | undefined>()
  const [flowState, setFlowState] = useState<FlowState>()
  const dismissError = () => setError(undefined)

  async function rename(name: string) {
    try {
      setFlowState('renaming')
      const c = await client.credentials.patch(id, { name })
      dispatch(Credentials.updateSuccess(c))
    } catch (e) {
      setError(e.message)
    } finally {
      setFlowState(undefined)
    }
  }

  async function remove() {
    try {
      setFlowState('removing')
      await client.credentials.remove(id)
      dispatch(Credentials.removeSuccess(id))
    } catch (e) {
      setError(e.message)
      // No 'finally' here - a successful delete will trigger the
      // unmounting of this component so we don't want to call any hooks.
      setFlowState(undefined)
    }
  }

  async function addPath(path: string) {
    try {
      setFlowState('adding-path')
      const c = await client.credentials.paths.add(id, path)
      dispatch(Credentials.updateSuccess(c))
    } catch (e) {
      setError(e.message)
    } finally {
      setFlowState(undefined)
    }
  }

  async function removePath(path: string) {
    try {
      setFlowState('removing-path')
      const c = await client.credentials.paths.remove(id, path)
      dispatch(Credentials.updateSuccess(c))
    } catch (e) {
      setError(e.message)
    } finally {
      setFlowState(undefined)
    }
  }

  return {
    credential,
    error,
    dismissError,
    flowState,
    rename,
    remove,
    addPath,
    removePath,
  }
}
export type CredentialState = ReturnType<typeof useCredentialState>
