import styled from 'styled-components'

import { Workflow } from '@landrush/common'

import { Style, TextInput } from 'components'

export type WorkflowOption = {
  name: string
  default?: string
  description?: string
  isEnabled?: boolean
  set(name: string, option: Workflow.Option): void
}
export const WorkflowOption = ({
  name,
  default: defaultValue = '',
  description = '',
  isEnabled = true,
  set,
}: WorkflowOption) => (
  <div style={{ marginBottom: 12 }}>
    <h4 style={{ marginBottom: 4 }}>{name}</h4>

    <Group>
      <label className='label' style={Style.muted}>
        description
      </label>
      <TextInput
        className='input'
        value={description}
        isEnabled={isEnabled}
        onChange={(description) =>
          set(name, { default: defaultValue, description })
        }
      />
    </Group>

    {name !== 'id' && name !== 'bounds' && (
      <Group>
        <label className='label' style={Style.muted}>
          default
        </label>
        <TextInput
          className='input'
          value={defaultValue}
          isEnabled={isEnabled}
          onChange={(defaultValue) =>
            set(name, { default: defaultValue, description })
          }
        />
      </Group>
    )}
  </div>
)

const Group = styled.div`
  display: flex;
  margin-bottom: 4px;
  margin-left: 18px;
  align-items: center;

  .label {
    flex: 1 1 0;
    margin-bottom: 0;
    justify-self: flex-end;
    text-align: right;
    margin-right: 6px;
  }

  .input {
    flex: 4 1 0;
    margin-bottom: 0;
  }
`
