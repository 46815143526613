import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Bounds } from '@landrush/entwine'
import { Id, Potree } from '@landrush/common'

import * as Renderers from 'renderers'
import { LocalStorage } from 'utils'

import { AppState, AppThunk } from 'store'
import * as Types from 'store/types'

type Renderer = Renderers.Potree.Renderer

type MutableVolume = { isMutable: true; bounds?: Bounds }
type FixedVolume = { isMutable: false; bounds: Bounds }
type Volume = MutableVolume | FixedVolume

type State = Types.LoadGuard & {
  isReady: boolean
  isFullscreen: boolean
  renderer?: Renderer
  rendererState: Potree.State
  toLonLat?: proj4.Converter
  volume?: Volume
  activeSelectionId?: Id
}
const initialState: State = {
  isFullscreen: false,
  isReady: false,
  rendererState: {} as any,
}

type Camera = { position: number[]; target: number[]; rotation: number }

type MountSuccess = {
  renderer: Renderer
  toLonLat?: proj4.Converter
}
type LoadSuccess = {
  renderer: Renderer
  toLonLat?: proj4.Converter
}
const slice = createSlice({
  name: 'viewer',
  initialState,
  reducers: {
    ...Types.createGuards(),
    mountSuccess(state, action: PayloadAction<MountSuccess>) {
      const { renderer, toLonLat } = action.payload
      state.renderer = renderer
      state.toLonLat = toLonLat
    },
    loadSuccess(state, action: PayloadAction<LoadSuccess>) {
      const { renderer, toLonLat } = action.payload
      state.loadState = 'done'
      state.renderer = renderer
      state.toLonLat = toLonLat
    },
    setRendererState(state, action: PayloadAction<Potree.State>) {
      state.rendererState = action.payload
    },
    setReady(state) {
      state.isReady = true
    },
    setFullscreen(state, action: PayloadAction<boolean>) {
      state.isFullscreen = action.payload
    },
    rendererDestroyed(state) {
      state.isReady = false
      state.renderer = undefined
    },
    destroy() {
      return initialState
    },
    onCameraChange(state, action: PayloadAction<Camera>) {
      const { position, target, rotation } = action.payload
      state.rendererState.position = position
      state.rendererState.target = target
      state.rendererState.rotation = rotation
    },
    setFps(state, action: PayloadAction<number>) {
      const fps = action.payload
      state.rendererState.fps = fps
      LocalStorage.set('fps', fps.toString())
    },
    setBackground(state, action: PayloadAction<Potree.Background>) {
      state.rendererState.background = action.payload
    },
    setPointBudget(state, action: PayloadAction<number>) {
      const pointBudget = action.payload
      state.rendererState.pointBudget = pointBudget
      LocalStorage.set('pointBudget', pointBudget.toString())
    },
    setPointSize(state, action: PayloadAction<number>) {
      state.rendererState.pointSize = action.payload
    },
    setPointColor(state, action: PayloadAction<Potree.PointColor>) {
      state.rendererState.pointColor = action.payload
    },
    setElevationRange(state, action: PayloadAction<Potree.RampRange>) {
      state.rendererState.elevationRange = action.payload
    },
    setIntensityRange(state, action: PayloadAction<Potree.RampRange>) {
      state.rendererState.intensityRange = action.payload
    },
    // Volumes.
    editVolume(state) {
      state.volume = { isMutable: true }
    },
    setVolume(state, action: PayloadAction<Volume>) {
      state.volume = action.payload
    },
    clearVolume(state) {
      state.activeSelectionId = undefined
      state.volume = undefined
      state.rendererState.clipType = 'highlight'
    },
    setClipType(state, action: PayloadAction<Potree.ClipType>) {
      state.rendererState.clipType = action.payload
    },
    setActiveSelectionId(state, action: PayloadAction<Id>) {
      if (state.volume) state.volume.isMutable = false
      state.activeSelectionId = action.payload
    },
  },
})

const { actions, reducer } = slice
const {
  setReady,
  setFps,
  setBackground,
  setPointBudget,
  setPointSize,
  setPointColor,
  setElevationRange,
  setIntensityRange,
  setClipType,
} = actions

const selectSlice = (state: AppState) => state.viewer
const maybeSelectRenderer = createSelector(selectSlice, (s) => s.renderer)
const selectRenderer = createSelector(maybeSelectRenderer, (r) => {
  if (!r) throw new Error('Invalid renderer state')
  return r
})
const selectRendererState = createSelector(selectSlice, (s) => s.rendererState)
const selectReady = createSelector(selectSlice, (s) => s.isReady)
const selectFullscreen = createSelector(selectSlice, (s) => s.isFullscreen)
const selectToLonLat = createSelector(selectSlice, (s) => s.toLonLat)
const selectRotation = createSelector(selectRendererState, (s) => s.rotation)
const selectFps = createSelector(selectRendererState, (s) => s.fps)
const selectBackground = createSelector(
  selectRendererState,
  (s) => s.background
)
const selectPointBudget = createSelector(
  selectRendererState,
  (s) => s.pointBudget
)
const selectPointColor = createSelector(
  selectRendererState,
  (s) => s.pointColor
)
const selectPointSize = createSelector(selectRendererState, (s) => s.pointSize)
const selectElevationRange = createSelector(
  selectRendererState,
  (s) => s.elevationRange
)
const selectIntensityRange = createSelector(
  selectRendererState,
  (s) => s.intensityRange
)
const maybeSelectVolume = createSelector(selectSlice, (s) => s.volume)
const selectVolume = createSelector(maybeSelectVolume, (v) => {
  if (!v) throw new Error('Missing volume')
  return v
})
const selectClipType = createSelector(selectRendererState, (s) => s.clipType)
const selectActiveSelectionId = createSelector(
  selectSlice,
  (s) => s.activeSelectionId
)

const selectors = {
  selectSlice,
  maybeSelectRenderer,
  selectRenderer,
  selectRendererState,
  selectReady,
  selectFullscreen,
  selectToLonLat,
  selectRotation,
  selectFps,
  selectBackground,
  selectPointBudget,
  selectPointColor,
  selectPointSize,
  selectElevationRange,
  selectIntensityRange,
  maybeSelectVolume,
  selectVolume,
  selectClipType,
  selectActiveSelectionId,
}

const loadScripts = (): AppThunk => async (dispatch) => {
  await Renderers.Potree.loadScripts()
  dispatch(setReady())
}

const loadPointCloud = (endpoint: string, name: string): AppThunk => {
  return async (dispatch, getState) => {
    const renderer = selectRenderer(getState())
    await renderer.loadPointCloud(endpoint, name)
    // TODO: Dispatch this.
    // dispatch()
  }
}

// These are a little weird since they don't `dispatch` anything so technically
// they are not really thunks.  Their movement calls imperatively move the
// camera directly via the renderer which will end up dispatching
// onCameraChange events.  Externally they behave like thunks so they fit here,
// and eventually it would be nice if the camera were a controlled component so
// these could actually be thunks.
const navigateTo = (
  position: Potree.Point,
  target: Potree.Point,
  ms?: number
): AppThunk => {
  return async (_dispatch, getState) => {
    selectRenderer(getState()).navigateTo(position, target, ms)
  }
}

const zoomTo = (bounds: Bounds, ms?: number): AppThunk => {
  return async (_dispatch, getState) => {
    selectRenderer(getState()).zoomTo(bounds, ms)
  }
}

const rotateToNorth = (ms?: number): AppThunk => {
  return async (dispatch, getState) => {
    return selectRenderer(getState()).rotateToNorth(ms)
  }
}

const apply = (settings: Partial<Potree.State>, ms?: number): AppThunk => {
  return async (dispatch) => {
    function applyOne(key: string, v: any) {
      switch (key) {
        case 'fps':
          return dispatch(setFps(v))
        case 'background':
          return dispatch(setBackground(v))
        case 'pointBudget':
          return dispatch(setPointBudget(v))
        case 'pointSize':
          return dispatch(setPointSize(v))
        case 'pointColor':
          return dispatch(setPointColor(v))
        case 'elevationRange':
          return dispatch(setElevationRange(v))
        case 'intensityRange':
          return dispatch(setIntensityRange(v))
        case 'clipType':
          return dispatch(setClipType(v))
      }
    }

    const { position, target, ...rest } = settings
    if (position && target) dispatch(navigateTo(position, target, ms))

    for (const [key, value] of Object.entries(rest)) applyOne(key, value)
  }
}

const thunks = {
  loadScripts,
  loadPointCloud,
  apply,
  navigateTo,
  zoomTo,
  rotateToNorth,
}

export const Viewer = {
  ...actions,
  ...selectors,
  ...thunks,
}
export { reducer }
