import React, { useEffect, useState } from 'react'
import { HuePicker } from 'react-color'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faSyncAlt } from '@fortawesome/free-solid-svg-icons'

import { Tag } from '@landrush/common'
import { Color as Colors } from '@landrush/util'

import { Button, Color, Prefix, Tag as TagDisplay, TextInput } from 'components'

type EditTag = {
  name?: string
  color?: string
  description?: string
  showCancelIcon?: boolean
  isPending: boolean
  onSubmit(params: Tag.Add): void
  onCancel(): void
}
export const EditTag: React.FC<EditTag> = ({
  name: initialName = '',
  description: initialDescription = '',
  color: initialColor = Colors.generate(),
  showCancelIcon = false,
  isPending,
  onSubmit: actuallySubmit,
  onCancel,
}) => {
  const [name, setName] = useState(initialName)
  const [description, setDescription] = useState(initialDescription)
  const [colorText, setColorText] = useState(
    initialColor.startsWith('#') ? initialColor : `#${initialColor}`
  )

  const isValidColor =
    colorText.slice(0, 1) === '#' &&
    Boolean(Colors.validate(colorText.slice(1)).value)
  const isValid = name.length > 0 && isValidColor

  // Last valid color.
  const [color, setColor] = useState(colorText)
  useEffect(() => {
    if (colorText.length === 0) setColorText('#')
    if (isValidColor) setColor(colorText)
  }, [colorText, isValidColor])

  const randomize = () => setColorText(`#${Colors.generate()}`)
  const onSubmit = () =>
    isValid && actuallySubmit({ name, color: color.slice(1), description })

  return (
    <Styles>
      <div className='tag-preview-container'>
        <TagDisplay
          className='tag-preview'
          name={name || 'Preview'}
          color={color}
        />
        {showCancelIcon && !isPending && (
          <FontAwesomeIcon
            className='edit-tag-cancel-icon'
            icon={faMinus}
            onClick={onCancel}
          />
        )}
      </div>
      <div className='edit-tag-container'>
        <div className='tag-name-container'>
          <TextInput
            className='tag-name'
            value={name}
            onChange={setName}
            placeholder='Tag name'
            isEnabled={!isPending}
            isValid={isValid && !isPending}
            onSubmit={onSubmit}
            onCancel={onCancel}
            autoFocus={true}
          />
        </div>
        <div className='tag-description-container'>
          <TextInput
            className='tag-description'
            value={description}
            onChange={setDescription}
            placeholder='Description (optional)'
            isEnabled={!isPending}
            isValid={isValid && !isPending}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        </div>
        <div onClick={randomize}>
          <Prefix
            className='prefix'
            style={{ backgroundColor: color, marginBottom: 0 }}
          >
            <FontAwesomeIcon icon={faSyncAlt} className='random-color' />
          </Prefix>
        </div>
        <div className='tag-color-text-container'>
          <TextInput
            className='tag-color-text'
            value={colorText}
            onChange={setColorText}
            isEnabled={!isPending}
            isValid={isValid && !isPending}
            onSubmit={onSubmit}
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              color: isValidColor ? undefined : Color.dangerRed,
            }}
          />
        </div>
        <HuePicker
          className='hue-picker'
          color={color}
          onChange={({ hex }) => !isPending && setColorText(hex)}
        />
        <Button
          isEnabled={!isPending}
          onClick={onCancel}
          className='cancel-button'
        >
          Cancel
        </Button>
        <Button
          isEnabled={isValid && !isPending}
          spin={isPending}
          onClick={onSubmit}
          className='submit-button'
        >
          Save
        </Button>
      </div>
    </Styles>
  )
}
const Styles = styled.div`
  .tag-preview-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }

  .edit-tag-cancel-icon {
    cursor: pointer;
    color: ${Color.lightGray};
    &:hover {
      color: ${Color.darkGray};
    }
  }

  .edit-tag-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  input {
    margin-bottom: 0;
  }

  .tag-name-container {
    flex: 1;
    min-width: 160px;
  }

  .tag-description-container {
    margin-left: 8px;
    flex: 3;
  }

  .prefix {
    width: 38px;
    margin-left: 8px;
    cursor: pointer;
    color: white;
  }

  .random-color {
  }

  .tag-color-text-container {
    width: 96px;
    font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  }

  .hue-picker {
    margin-left: 8px;
    flex: 2.5;
  }

  button {
    width: 80px;
    margin-left: 8px;
    margin-bottom: 0;
    font-size: 16px;
  }

  .cancel-button {
    background-color: ${Color.lightGray};
    &:hover {
      background-color: ${Color.gray};
    }
  }

  .submit-button {
  }

  .control-row-container {
    display: flex;
    justify-content: space-between;
  }

  .cancel-icon {
    cursor: pointer;
    color: ${Color.lightGray};
    &:hover {
      color: ${Color.dangerRed};
    }
  }
`
