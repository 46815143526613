import React from 'react'
import { useSelector } from 'react-redux'

import { Id, Tag } from '@landrush/common'
import { ialphaSort, Result } from '@landrush/util'

import { TaggableHeader } from 'components'
import { Resources, ResourceState } from 'store'
import { useAppDispatch, useAsync } from 'utils'

import { useTags, useVisibleResources } from './hooks'

type Props = { isModifiable?: boolean }
export default ({ isModifiable = true }: Props) => {
  const dispatch = useAppDispatch()

  const tags = useTags()
  const tagEntities = useSelector(Resources.Tags.selectEntities)

  const visibleTargets = useVisibleResources()

  const totalTargetsCount = useSelector(Resources.selectCount)
  const selectedTargetsCount = useSelector(
    ResourceState.selectSelectedResourcesCount
  )
  const visibleTargetsCount = visibleTargets.length

  const filterText = useSelector(ResourceState.selectFilterText)
  const setFilterText = (text: string) =>
    dispatch(ResourceState.setFilterText(text))

  const filterTags = Object.keys(useSelector(ResourceState.selectFilterTags))
    .map((id) => tagEntities[id])
    .filter((tag): tag is Tag => Boolean(tag))
    .sort((a, b) => ialphaSort(a.name, b.name))
  const removeFilterTag = (id: Id) =>
    dispatch(ResourceState.removeFilterTag(id))

  const [{ isPending: isApplyingTag }, applyTag] = useAsync(
    async (id: Id) => {
      if (!selectedTargetsCount) {
        return dispatch(ResourceState.toggleFilterTag(id))
      }

      const result = await dispatch(ResourceState.applyTag(id))
      if (Result.isFailure(result)) {
        dispatch(ResourceState.addError(result.error))
        throw new Error(result.error)
      } else return result.value
    },
    [selectedTargetsCount]
  )

  const selected = useSelector(ResourceState.selectSelectedResources)
  const [{ isPending: isRemovingMany }, removeSelected] = useAsync(async () => {
    await dispatch(ResourceState.removeSelected(Object.keys(selected)))
  }, [selected])

  const handleCheckAll = () => {
    const isEverythingVisibleSelected =
      selectedTargetsCount === visibleTargetsCount

    if (isEverythingVisibleSelected) {
      dispatch(ResourceState.performUnselectAll())
    } else {
      dispatch(
        ResourceState.performSelectMany(visibleTargets.map(({ id }) => id))
      )
    }
  }

  const props = {
    targetName: 'resource' as const,
    totalTargetsCount,
    selectedTargetsCount,
    visibleTargetsCount,
    filterText,
    setFilterText,
    filterTags,
    removeFilterTag,
    handleCheckAll,
    tags,
    isModifiable,
    applyTag,
    isApplyingTag,
    handleRemoveSelected: removeSelected,
    isRemovingSelected: isRemovingMany,
  }

  return <TaggableHeader {...props} />
}
