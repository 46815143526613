import React, { useEffect, useState } from 'react'
import isequal from 'lodash.isequal'

import { Workflow } from '@landrush/common'
import { getSubstitutions } from '@landrush/util'

import { WorkflowHistory } from 'store'
import { useAppDispatch, useAsyncResult, useClient, Json } from 'utils'

import { Controls } from './controls'
import { Header } from './header'
import { ReadmePage } from './readme'
import { Settings } from './settings'
import { Page, SettingsText, WithHistoryAndSwitcher } from './types'

function getSettings({
  pipeline,
  reducers,
  options: originalOptions,
}: Pick<Workflow, 'pipeline' | 'reducers' | 'options'>): SettingsText {
  const names = getSubstitutions({ pipeline, reducers })
  const options = names.reduce<SettingsText['options']>((options, name) => {
    const curr = originalOptions[name]
    options[name] = {
      description:
        curr?.description || Workflow.defaults[name]?.description || '',
      default: curr?.default || Workflow.defaults[name]?.default || '',
    }
    return options
  }, {})

  return {
    pipeline: JSON.stringify(pipeline, null, 2),
    reducers: JSON.stringify(reducers, null, 2),
    options,
  }
}

export const Body = ({
  history,
  version,
  setVersion,
}: WithHistoryAndSwitcher) => {
  const client = useClient()
  const dispatch = useAppDispatch()

  const { id } = history
  const leaf = history.list[version - 1]
  const workflow = { ...history, ...leaf }
  const isLatest = version === history.list.length

  const [page, setPage] = useState<Page>('readme')

  const [readmeText, setReadmeText] = useState<string>()
  const toggleReadmeEditing = () =>
    setReadmeText((v) => (v === undefined ? leaf.readme : undefined))

  const [settingsText, _setSettingsText] = useState<SettingsText>()
  const toggleSettingsEditing = () =>
    _setSettingsText((v) =>
      v === undefined ? getSettings(workflow) : undefined
    )

  const setSettingsText = (settings?: SettingsText) => {
    if (!settings) return _setSettingsText(undefined)

    const { pipeline, reducers, options } = settings
    return Json.isValid(pipeline) && Json.isValid(reducers)
      ? _setSettingsText(
          getSettings({
            pipeline: JSON.parse(pipeline),
            reducers: JSON.parse(reducers),
            options,
          })
        )
      : _setSettingsText(settings)
  }

  const toggleEditing =
    page === 'readme' ? toggleReadmeEditing : toggleSettingsEditing

  const [
    { isPending, error, dismissError, value },
    submit,
  ] = useAsyncResult(async () => {
    try {
      if (readmeText !== undefined) {
        await client.workflows.patch(id, { readme: readmeText })
      } else if (settingsText) {
        const pipeline = JSON.parse(settingsText.pipeline)
        const reducers = JSON.parse(settingsText.reducers)
        const { options } = settingsText
        if (
          isequal(pipeline, workflow.pipeline) &&
          isequal(reducers, workflow.reducers)
        ) {
          console.log('Patching', options)
          await client.workflows.patch(id, { options })
        } else {
          console.log('Pushing new version')
          await client.workflows.push(id, { pipeline, reducers, options })
        }
      }

      const history = await client.workflows.getHistory(id)
      return { value: history }
    } catch (e) {
      return { error: e.message || 'Failed to update workflow' }
    }
  }, [client, id, readmeText, settingsText])

  useEffect(dismissError, [page])

  useEffect(() => {
    if (!value) return
    setReadmeText(undefined)
    setSettingsText(undefined)
    dispatch(WorkflowHistory.actions.assign(value))
    setVersion(value.list.length)
  }, [value])

  return (
    <>
      <Header history={history} version={version} setVersion={setVersion} />
      <Controls
        isEditing={
          page === 'readme'
            ? readmeText !== undefined
            : settingsText !== undefined
        }
        isEditable={isLatest}
        toggleEditing={toggleEditing}
        page={page}
        setPage={setPage}
      />
      {page === 'readme' ? (
        <ReadmePage
          workflow={workflow}
          text={readmeText}
          setText={setReadmeText}
          submit={submit}
          isPending={isPending}
          error={error}
          dismissError={dismissError}
        />
      ) : (
        <Settings
          history={history}
          version={version}
          text={settingsText}
          setText={setSettingsText}
          submit={submit}
          isPending={isPending}
          error={error}
          dismissError={dismissError}
        />
      )}
    </>
  )
}
