import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faPlus } from '@fortawesome/free-solid-svg-icons'

import { Color, TextInput } from 'components'

const Styles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;

  input {
    margin-right: 8px;
  }
  .button {
    font-size: 24px;
  }
`

type Icon = { isAdding: boolean; isValid: boolean; submit(): void }
const Icon: React.FC<Icon> = ({ isAdding, isValid, submit }) => {
  if (isAdding) {
    return (
      <FontAwesomeIcon
        className='button adding'
        style={{ color: Color.gray }}
        icon={faCircleNotch}
        fixedWidth
      />
    )
  }

  return (
    <FontAwesomeIcon
      className='button submit'
      style={{ color: isValid ? Color.landrushOrange : Color.lightGray }}
      icon={faPlus}
      onClick={submit}
      fixedWidth
    />
  )
}

type Add = {
  placeholder: string
  text: string
  setText(v: string): void
  submit(): void
  isPending: boolean
  isEnabled?: boolean
}
export const SidebarAddForm: React.FC<Add> = ({
  placeholder,
  text,
  setText,
  submit,
  isPending,
  isEnabled = true,
}) => {
  const isValid = Boolean(text.length)

  return (
    <Styles>
      <TextInput
        value={text}
        onChange={setText}
        onSubmit={submit}
        isEnabled={isEnabled && !isPending}
        isValid={text.length > 0}
        placeholder={placeholder}
        style={{ marginBottom: 0 }}
      />
      <Icon isAdding={isPending} isValid={isValid} submit={submit} />
    </Styles>
  )
}
