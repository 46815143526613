import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Landrush } from '@landrush/client'
import { Resource } from '@landrush/common'

import { AppState, AppDispatch, Session } from 'store'

export const useAppDispatch = () => useDispatch<AppDispatch>()

export function useClient(): Landrush {
  return useSelector(Session.selectClient)
}
export function useAppSelector<T>(f: (s: AppState) => T) {
  return useSelector<AppState, T>(f)
}
// TODO: Extend this to forward generic args once TS 4.0 is out.  For now we're
// really only using this to forward an ID for selection.
// https://fettblog.eu/variadic-tuple-types-preview/
export function useForwardingSelector<T, TArg>(
  f: (s: AppState, arg: TArg) => T,
  arg: TArg
) {
  return useAppSelector((s) => f(s, arg))
}

/**
 * Listens to keydown events but ignores them if they occur in textarea or input
 * nodes.
 */
function listen(key: string, f: () => void) {
  return (e: KeyboardEvent) => {
    const type: string | undefined = (e.target as any)?.tagName
    if (!type) return
    if (['input', 'textarea'].includes(type.toLowerCase())) return
    if (e.key.toLowerCase() === key.toLowerCase()) f()
  }
}
export function useKeyListener(key: string, f: () => void, deps?: any[]) {
  useEffect(() => {
    const listener = listen(key, f)
    document.addEventListener('keydown', listener)
    return () => document.removeEventListener('keydown', listener)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

export const ResourceContext = React.createContext<Resource>(undefined as any)
export const useResource = () => useContext(ResourceContext)

export function useAsyncState() {
  const [isPending, setPending] = useState(false)
  const [error, setError] = useState<string>()
  const [isDone, setDone] = useState(false)
  return {
    isPending,
    error,
    isDone,
    setPending: () => {
      setPending(true)
      setError(undefined)
    },
    setError: (error: string) => {
      setPending(false)
      setError(error)
    },
    setDone: () => {
      setPending(false)
      setDone(true)
    },
    dismissError: () => setError(undefined),
  }
}
