import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useUnmount } from 'react-use'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import { Result, basename, dirname } from '@landrush/util'

import { Color, ErrorMessage, Message, Overview } from 'components'
import { AddRegionsState, Regions } from 'store'
import { useAppDispatch, useAppSelector, useAsync } from 'utils'

import { CollectionDisplay } from './collection'
import { Dropzone } from './dropzone'
import { TextForm } from './text-form'

type Item = AddRegionsState.Item

function getSubmissionInput({
  filename,
  buffer,
  collection,
}: Item): GeoJSON.FeatureCollection | ArrayBuffer | string {
  // If we're dealing with a remote file, send the URL directly.  Having the
  // server re-fetch the data is probably better than uploading a potentially
  // large file.
  if (filename?.startsWith('http://') || filename?.startsWith('https://')) {
    return filename
  }

  // Otherwise, if we have a GeoJSON collection, use that.
  if (collection) return collection

  // At this point, we have an unanalyzed zipped shapefile buffer.
  if (buffer) return buffer

  throw new Error('Invalid submission')
}

const kb = 1024
const mb = 1024 * 1024
const gb = 1024 * 1024 * 1024
function filesize(v: number) {
  if (v < kb) return `${v} bytes`
  if (v < mb) return `${(v / kb).toFixed(1)} KB`
  if (v < gb) return `${(v / mb).toFixed(1)} MB`
  return `${(v / gb).toFixed(1)} GB`
}
const ItemDisplay: React.FC = () => {
  const dispatch = useAppDispatch()
  const clear = () => dispatch(AddRegionsState.clearItem())

  const filename = useAppSelector(AddRegionsState.selectFilename)
  const buffer = useAppSelector(AddRegionsState.selectBuffer)
  const collection = useAppSelector(AddRegionsState.selectCollection)

  if (!filename) throw new Error('Invalid item')

  const bn = basename(filename)
  const stem = bn.slice(0, bn.lastIndexOf('.'))
  const [name, setName] = useState(`${stem} - {{name}}`)

  const [
    { isPending, error, dismissError, value },
    submit,
  ] = useAsync(async () => {
    console.log('Submitting', name, filename, buffer, collection)

    const result = await dispatch(
      Regions.addMany({
        name,
        input: getSubmissionInput({ filename, buffer, collection }),
      })
    )

    if (Result.isFailure(result)) throw new Error(result.error)
    return result.value
  }, [name, filename, buffer, collection])

  if (value) return <Redirect to='/regions' />

  return (
    <>
      <div className='item-header'>
        <h4 className='item-filename'>{basename(filename)}</h4>
        {basename(filename) !== filename && (
          <div className='item-dirname'>({dirname(filename)})</div>
        )}
        <FontAwesomeIcon
          className='item-clear'
          icon={faTimes}
          onClick={clear}
        />
      </div>
      {error && <ErrorMessage onDismiss={dismissError}>{error}</ErrorMessage>}
      <TextForm
        isPending={isPending}
        text={name}
        setText={setName}
        submit={submit}
        placeholder='Region naming template'
        buttonText='Upload'
      />
      {buffer && <p>Zipped shapefile: {filesize(buffer.byteLength)}</p>}
      {collection && <CollectionDisplay collection={collection} />}
    </>
  )
}

export default () => {
  const dispatch = useAppDispatch()
  useUnmount(() => dispatch(AddRegionsState.destroy()))

  const filename = useAppSelector(AddRegionsState.selectFilename)

  const [url, setUrl] = useState('')
  const [
    { isPending, error, dismissError },
    analyze,
  ] = useAsync(async (input: string | File) =>
    dispatch(AddRegionsState.ingestItem(input))
  )

  return (
    <>
      <Overview page='regions' />
      <Styles>
        <h2>Add regions</h2>
        {filename ? (
          <ItemDisplay />
        ) : (
          <>
            {error && (
              <ErrorMessage onDismiss={dismissError}>{error}</ErrorMessage>
            )}
            <TextForm
              isPending={isPending}
              text={url}
              setText={setUrl}
              submit={() => analyze(url)}
              placeholder='GeoJSON or zipped shapefile URL'
              buttonText='Analyze'
            />
            {isPending ? (
              <Message spin style={{ marginTop: 8 }}>
                Analyzing...
              </Message>
            ) : (
              <Dropzone analyze={analyze} />
            )}
          </>
        )}
      </Styles>
    </>
  )
}

const Styles = styled.div`
  margin-top: 16px;

  .item-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  .item-filename {
    margin-bottom: 0;
  }
  .item-dirname {
    margin-left: 4px;
    color: ${Color.gray};
    font-size: small;
  }
  .item-clear {
    margin-left: 4px;
    cursor: pointer;
    color: ${Color.lightGray};
    &:hover {
      color: ${Color.dangerRed};
    }
  }
`
