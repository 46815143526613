import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AccessToken, Id, User } from '@landrush/common'

import { AppState, AppThunk } from 'store'
import { Session } from 'store/session'

type FlowState = 'adding' | 'removing' | 'none'

export type State = {
  error?: string
  flowState: FlowState
  flowId?: Id
  accessTokens: AccessToken[]
  secretToken?: string
}
const initialState: State = {
  flowState: 'none',
  accessTokens: [],
}
const loadSuccessType: string = Session.loadSuccess.type
const loginSuccessType: string = Session.loginSuccess.type

type FlowRequest = { state: FlowState; id?: Id }

const slice = createSlice({
  name: 'access-tokens',
  initialState,
  reducers: {
    flowRequest(state, action: PayloadAction<FlowRequest>) {
      const { state: flowState, id: flowId } = action.payload
      state.flowState = flowState
      state.flowId = flowId
      state.error = undefined
      state.secretToken = undefined
    },
    flowFailure(state, action: PayloadAction<string>) {
      state.flowState = 'none'
      state.flowId = undefined
      state.error = action.payload
    },
    dismissError(state) {
      state.error = undefined
    },
    dismissSecretToken(state) {
      state.secretToken = undefined
    },
    addSuccess(state, action: PayloadAction<AccessToken>) {
      const { token, ...accessToken } = action.payload
      state.accessTokens.push(accessToken)
      state.secretToken = token
      state.flowState = 'none'
    },
    removeSuccess(state, action: PayloadAction<Id>) {
      const id = action.payload
      state.accessTokens = state.accessTokens.filter((t) => t.id !== id)
      state.flowState = 'none'
      state.flowId = undefined
    },
  },
  extraReducers: {
    [loadSuccessType]: (state, action: PayloadAction<{ user?: User }>) => {
      const { user } = action.payload
      if (!user) return
      const { accessTokens } = user
      state.accessTokens = accessTokens
    },
    [loginSuccessType]: (state, action: PayloadAction<User>) => {
      const user = action.payload
      const { accessTokens } = user
      state.accessTokens = accessTokens
    },
  },
})

const { reducer, actions } = slice

const { flowRequest, flowFailure, addSuccess, removeSuccess } = actions

const selectSlice = (state: AppState) => state.accessTokens
const selectError = createSelector(selectSlice, (s) => s.error)
const selectAccessTokens = createSelector(selectSlice, (s) => s.accessTokens)
const selectSecretToken = createSelector(selectSlice, (s) => s.secretToken)
const selectFlowState = createSelector(selectSlice, (s) => s.flowState)
const selectFlowId = createSelector(selectSlice, (s) => s.flowId)
const selectIsAdding = createSelector(selectFlowState, (f) => f === 'adding')
const selectIsRemoving = createSelector(
  selectFlowState,
  (f) => f === 'removing'
)

const selectors = {
  selectSlice,
  selectError,
  selectAccessTokens,
  selectSecretToken,
  selectFlowState,
  selectFlowId,
  selectIsAdding,
  selectIsRemoving,
}

const add = (): AppThunk<AccessToken | undefined> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(flowRequest({ state: 'adding' }))
    const client = Session.selectClient(getState())
    const accessToken = await client.accessTokens.add()

    dispatch(addSuccess(accessToken))
    return accessToken
  } catch (e) {
    dispatch(flowFailure(e.message))
  }
}

const remove = (id: Id): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(flowRequest({ state: 'removing', id }))
    const client = Session.selectClient(getState())
    await client.accessTokens.remove(id)

    dispatch(removeSuccess(id))
  } catch (e) {
    dispatch(flowFailure(e.message))
  }
}

const thunks = { add, remove }

export const AccessTokens = { ...actions, ...selectors, ...thunks }
export { reducer }
