import React from 'react'
import styled from 'styled-components'

import { ialphaSort } from '@landrush/util'

import { TextInput } from 'components'

type StringMap = Record<string, string>

type WithEntry = { name: string; value: string; set(v: string): void }
const Entry: React.FC<WithEntry> = ({ name, value, set }) => (
  <div key={name} className='context-entry'>
    <div className='context-key'>{name}</div>
    <div className='context-value'>
      <TextInput value={value} onChange={set} placeholder='Choose a value...' />
    </div>
  </div>
)

type WithContext = {
  context: StringMap
  set(name: string, value: string): void
}
const ContextEditor: React.FC<WithContext> = ({ context, set }) => (
  <Styles>
    {Object.entries(context)
      .sort((a, b) => ialphaSort(a[0], b[0]))
      .map(([name, value]) => (
        <Entry
          key={name}
          name={name}
          value={value}
          set={(value) => set(name, value)}
        />
      ))}
  </Styles>
)

export default ContextEditor

const Styles = styled.div`
  padding-bottom: 16px;

  .context-entry {
    display: flex;
    align-items: center;
  }
  .context-key {
    flex: 1;
  }
  .context-value {
    flex: 1;
  }
`
