import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'

import 'bootstrap/dist/css/bootstrap.css'
import 'ol/ol.css'
import 'potree.css'
import 'rc-slider/assets/index.css'
import 'style.css'

import { ErrorMessage, Message, Wrapper, Ol } from 'components'
import { configureStore, Session } from 'store'

import Outside from './outside'
import Inside from './inside'

function Body() {
  const dispatch = useDispatch()
  const isLoaded = useSelector(Session.selectIsLoaded)
  const isLoggedIn = useSelector(Session.selectIsLoggedIn)
  const loadError = useSelector(Session.selectLoadError)
  const load = () => {
    dispatch(Session.load())
  }
  useEffect(load, [])

  if (loadError) return <ErrorMessage onRetry={load}>{loadError}</ErrorMessage>
  if (!isLoaded) return <Message spin>Initializing...</Message>
  if (!isLoggedIn) return <Outside />
  return <Inside />
}

export default () => {
  const store = configureStore()
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Wrapper>
          <Body />
        </Wrapper>
      </BrowserRouter>
    </Provider>
  )
}
