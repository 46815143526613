import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CopyToClipboard from 'react-copy-to-clipboard'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCopy } from '@fortawesome/free-solid-svg-icons'

import { Color, Panel, Spaced } from 'components'
import { getOrigin, useResource } from 'utils'

// TODO: This code display is very much a one-off but fine for this little
// snippet.  If we need to do it much more we'll need to pull in a formatter.
const Tab = () => <>&nbsp;&nbsp;</>
const Line = styled.p`
  font-family: 'Lucida Console', Monaco, monospace;
  margin-top: 1px;
  margin-bottom: 1px;
`

const Styles = styled.div`
  .card {
    background-color: #f5f5f5;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 4px;
    padding-right: 4px;
  }

  .copy {
    cursor: pointer;
    color: ${Color.lightGray};
    &:hover {
      color: ${Color.darkGray};
    }
  }

  .copied {
    color: ${Color.landrushOrange};
  }
`

export default () => {
  const { alias } = useResource()
  const filename = `${getOrigin()}/data/resources/${alias}/ept.json`

  const [isCopied, setCopied] = useState(false)
  useEffect(() => {
    if (!isCopied) return
    const timer = setTimeout(() => setCopied(false), 500)
    return () => {
      clearTimeout(timer)
    }
  }, [isCopied])

  const text =
    '{\n' +
    `    "filename": "${filename}",\n` +
    `    "header": { "Authorization": "Bearer YOUR_ACCESS_TOKEN_HERE" }\n` +
    '}\n'

  return (
    <Styles>
      <div style={{ display: 'flex' }}>
        <h4 style={{ marginRight: 8 }}>PDAL access</h4>
        <a href='https://pdal.io/stages/readers.ept.html'>
          <i className='fas fa-external-link-alt' />
        </a>
      </div>
      <Panel>
        <Spaced>
          <Line>{'{'}</Line>
          {isCopied ? (
            <FontAwesomeIcon icon={faCheck} className='copied' fixedWidth />
          ) : (
            <CopyToClipboard text={text}>
              <FontAwesomeIcon
                icon={faCopy}
                className='copy'
                fixedWidth
                onClick={() => setCopied(true)}
              />
            </CopyToClipboard>
          )}
        </Spaced>
        <Line>
          <Tab />
          {`"filename": "${filename}",`}
        </Line>
        <Line>
          <Tab />
          {`"header": { "Authorization": "Bearer `}
          <Link to='/account'>YOUR_ACCESS_TOKEN_HERE</Link>
          {`" }`}
        </Line>
        <Line>{'}'}</Line>
      </Panel>
    </Styles>
  )
}
