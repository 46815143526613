import { PathInfo } from 'forager'
import { useSelector } from 'react-redux'

import { Id } from '@landrush/common'
import { join } from '@landrush/util'

import { Credentials, Navigator, Staging } from 'store'
export type DirProps = Navigator.Directory.Props

export function useCredentialById(id: Id) {
  const credentials = useSelector(Credentials.selectCredentials)
  const c = credentials.find((c) => c.id === id)
  if (!c) throw new Error('Failed to find credentials')
  return c
}

export function usePathDisplayName({ credentialId, root }: DirProps) {
  const { protocol } = useCredentialById(credentialId)
  return protocol === 'dropbox' ? 'Apps/Landrush' : root
}

export function useItemIdentifier(item: PathInfo): Staging.ItemIdentifier {
  const cwd = useSelector(Navigator.forceSelectCwd)
  const { protocol } = useSelector(Navigator.selectCwdCredential)

  const { credentialId, root, parts } = cwd
  const { type, path } = item
  return {
    credentialId,
    type,
    path: `${protocol}://${join(root, ...parts, path)}`,
  }
}

export function useIsStaged(item: PathInfo) {
  const stagedItems = useSelector(Staging.selectItems)
  const identifier = useItemIdentifier(item)
  return stagedItems.some((stagedItem) =>
    Staging.itemEquals(stagedItem.identifier, identifier)
  )
}
