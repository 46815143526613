import React, { useState } from 'react'
import { Link, Redirect, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { Button, ErrorMessage, TextInput } from 'components'
import { Session } from 'store'
import { LocalStorage, useAppSelector, useAsync } from 'utils'
import { useMount } from 'react-use'

type FromState = { email?: string }
export default () => {
  const { state } = useLocation<FromState | undefined>()
  const client = useAppSelector(Session.selectUnconnectedClient)

  useMount(() => LocalStorage.remove('email'))

  const [email, setEmail] = useState(state?.email || '')
  const [password, setPassword] = useState('')

  const [
    { isPending, value, error, dismissError },
    submit,
  ] = useAsync(async () => {
    await client.auth.register(email, password)
    LocalStorage.set('email', email)
    return true
  }, [email, password])

  const isValid = email.length > 0 && password.length > 0

  if (value) return <Redirect to={{ pathname: '/confirm', state: { email } }} />

  return (
    <Styles>
      <div className='container'>
        <h1 className='header'>Create an account</h1>
        <TextInput
          id='email-input'
          placeholder='Email'
          value={email}
          onChange={setEmail}
          onSubmit={submit}
          isEnabled={!isPending}
          isValid={isValid}
        />
        <TextInput
          id='password-input'
          value={password}
          onChange={setPassword}
          onSubmit={submit}
          type='password'
          placeholder='Password'
          isEnabled={!isPending}
          isValid={isValid}
        />
        {error && (
          <ErrorMessage onDismiss={dismissError}> {error} </ErrorMessage>
        )}
        <Button
          isEnabled={isValid && !isPending}
          onClick={submit}
          style={{ alignSelf: 'center' }}
          spin={isPending}
        >
          Sign up
        </Button>
        {!isPending && (
          <div className='prompt'>
            Already registered?{' '}
            <Link to={{ pathname: '/login', state: { email } }}>Log in</Link>.
          </div>
        )}
      </div>
    </Styles>
  )
}

const Styles = styled.div`
  .container {
    width: 480px;

    display: flex;
    flex-direction: column;
  }

  .header {
    align-self: center;
  }

  .prompt {
    text-align: right;
  }
`
