import { useMemo } from 'react'
import { createSelector } from '@reduxjs/toolkit'

import { Id } from '@landrush/common'

import { Execution, Region, Resource } from '@landrush/common'
import { AppState, Regions, Resources, Workflows } from 'store'

import { Slice } from './slice'
import { Normalized, adapter } from './types'

type State = { executions: Slice }
const selectSlice = (s: State) => s.executions

export const {
  selectAll,
  selectById,
  selectEntities,
  selectIds,
  selectTotal,
} = adapter.getSelectors(selectSlice)
export const selectIsListed = createSelector(selectSlice, (s) => s.isListed)

export const selectByAlias = (state: State, alias: string) =>
  selectAll(state).find((v) => v.alias === alias)

export const denormalizeByAlias = (
  state: AppState,
  alias: string
): Execution => {
  const norm = selectByAlias(state, alias)
  if (!norm || !Normalized.isDetailed(norm)) {
    throw new Error('Invalid execution selection')
  }

  const resources: Resource.Summary[] = norm.resourceIds
    .map((id) => Resources.selectById(state, id))
    .filter((r): r is Resource.Summary => Boolean(r))

  const regions: Region.Summary[] = norm.regionIds
    .map((id) => Regions.selectById(state, id))
    .filter((r): r is Region.Summary => Boolean(r))

  const workflow = Workflows.selectById(state, norm.workflowId)
  if (!workflow) throw new Error('Missing workflow')

  return { ...norm, resources, regions, workflow }
}

// TODO: Should probably be moved to a "hooks" file.
export const useSelectById = (id: Id) =>
  useMemo(
    () =>
      createSelector(
        (s: State) => s,
        (state) => selectById(state, id)
      ),
    [id]
  )

export const useSelectByAlias = (alias: string) =>
  useMemo(
    () =>
      createSelector(
        (s: State) => s,
        (state) => selectByAlias(state, alias)
      ),
    [alias]
  )

export const useDenormalizeByAlias = (alias: string) =>
  useMemo(
    () =>
      createSelector(
        (s: AppState) => s,
        (state) => denormalizeByAlias(state, alias)
      ),
    [alias]
  )
