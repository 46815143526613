import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { Button } from './button'
import { Color } from './color'

const Container = styled.div`
  display: flex;
  button {
    width: 72px;
    height: 72px;
    background-color: ${Color.gray};

    :not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left-width: 0;
    }
    :not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`

type GetBackgroundColor = { isSelected: boolean; isEnabled: boolean }
function getBackgroundColor({ isSelected, isEnabled }: GetBackgroundColor) {
  if (isEnabled) {
    if (isSelected) return Color.landrushOrange
    return Color.gray
  }
  if (isSelected) return '#fbbe93'
  return Color.lightGray
}

type Option = { value: string; component: ReactNode }
type Radio = {
  value: string
  onChange(v: string): void
  options: Option[]
  isEnabled?: boolean
}
export const Radio: React.FC<Radio> = ({
  value,
  onChange,
  options,
  isEnabled = true,
}: Radio) => (
  <Container>
    {options.map((v, i) => {
      const isSelected = v.value === value
      return (
        <Button
          key={i}
          label={v.value}
          isEnabled={isEnabled}
          style={{
            backgroundColor: getBackgroundColor({ isSelected, isEnabled }),
          }}
          onClick={() => {
            if (isEnabled) onChange(v.value)
          }}
        >
          {v.component}
        </Button>
      )
    })}
  </Container>
)
