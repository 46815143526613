import React from 'react'

import { Color, Style, TextInput } from 'components'

type NameHeader = {
  name: string
  isRenaming?: boolean
  isRenamable?: boolean
  text?: string
  setText?: (text?: string) => void
  submit?: () => void
  className?: string
}

export const NameHeader: React.FC<NameHeader> = ({
  name,
  isRenaming = false,
  isRenamable = true,
  text,
  setText = () => {},
  submit = () => {},
  className,
}) => {
  if (!isRenamable) return <h1 className={className}>{name}</h1>
  if (text === undefined) {
    return (
      <h1
        className={className}
        onDoubleClick={() => setText(name)}
        style={Style.clickable}
      >
        {name}
      </h1>
    )
  }

  const isValid = text.length > 0
  return (
    <TextInput
      className={className}
      autoFocus
      value={text}
      isEnabled={!isRenaming}
      isValid={isValid}
      onChange={setText}
      onSubmit={() => {
        if (isValid) submit()
      }}
      onCancel={() => setText(undefined)}
      style={{
        height: 46,
        fontSize: 32,
        color: Color.darkGray,
        paddingTop: 0,
        paddingBottom: 0,
        marginBottom: 0,
      }}
    />
  )
}
