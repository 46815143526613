import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage } from '@fortawesome/free-solid-svg-icons'

import { Execution, Task } from '@landrush/common'
import { ialphaSort } from '@landrush/util'

import {
  Color,
  ErrorMessage,
  InfoMessage,
  Message,
  Ol,
  Panel,
  Progress,
  Spaced,
  Style,
  StyledTable,
} from 'components'
import { getOrigin } from 'utils'

const Preview = ({ shortname, url }: { shortname?: string; url?: string }) => {
  const [isLoaded, setLoaded] = useState(false)
  useEffect(() => setLoaded(false), [url])

  if (!url) {
    return (
      <div className='preview-wrapper'>
        <div className='preview-placeholder'>Select an item to preview</div>
      </div>
    )
  }

  return (
    <>
      <img
        className='preview-image'
        alt='preview'
        src={url}
        onLoad={() => setLoaded(true)}
        style={{ display: isLoaded ? undefined : 'none' }}
      />
      {isLoaded ? null : <Message spin>Loading</Message>}
      {isLoaded ? <div className='preview-caption'>{shortname}</div> : null}
    </>
  )
}
const Status = ({ execution }: { execution: Execution }) => {
  if (Task.isFailure(execution)) {
    const { errors, logs } = execution
    return (
      <ErrorMessage>
        <div style={{ ...Style.small, ...Style.muted }}>
          Pipeline execution failed
        </div>
        {errors.map((e, i) => (
          <p key={i}>{e}</p>
        ))}
        {logs && (
          <Panel
            style={{
              backgroundColor: Color.backgroundGray,
              width: '60%',
              maxWidth: '60%',
              paddingTop: 4,
              paddingLeft: 8,
              paddingRight: 8,
            }}
          >
            {logs.map((v, i) => (
              <p key={i} style={{ marginBottom: 6 }}>
                {v}
              </p>
            ))}
          </Panel>
        )}
      </ErrorMessage>
    )
  }

  if (Task.isRunning(execution)) {
    const { index, total } = execution
    return (
      <div className='progress-wrapper'>
        <div className='progress-container'>
          <InfoMessage
            icon='info'
            title='Execution in progress'
            body='Results will be displayed here when available'
          />
          <Progress striped animated index={index} total={total} />
        </div>
      </div>
    )
  }

  return null
}

const Outputs = ({
  id,
  outputs,
  isDone,
  select,
  selected,
}: {
  id: string
  outputs: string[]
  isDone: boolean
  select(s: string): void
  selected?: string
}) => {
  useEffect(() => {
    if (!isDone) return
    const viewable = outputs.filter(
      (v) => v.endsWith('.png') || v.endsWith('.jpg')
    )
    if (viewable.length === 1) select(viewable[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDone])
  const origin = getOrigin()
  return (
    <StyledTable>
      <tbody>
        {outputs.map((o, i) => (
          <tr key={i}>
            <td style={{ width: '100%' }}>
              {isDone ? (
                <a
                  href={`${
                    origin === 'http://localhost:3030'
                      ? 'http://localhost:3000'
                      : origin
                  }/data/executions/${id}/${o}`}
                >
                  <div
                    className='output-pathname'
                    style={{ color: Color.veryDarkGray }}
                  >
                    {o}
                  </div>
                </a>
              ) : (
                <div style={{ color: Color.veryLightGray }}>{o}</div>
              )}
            </td>
            <td className='output-preview-icon-container'>
              {isDone && (o.endsWith('.png') || o.endsWith('.jpg')) ? (
                <FontAwesomeIcon
                  className='output-preview-icon'
                  icon={faImage}
                  style={{ color: selected ? Color.landrushOrange : undefined }}
                  onClick={() => select(o)}
                />
              ) : null}
            </td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  )
}
type WithExecution = { execution: Execution }
const Results = ({ execution }: WithExecution) => {
  const { id, regions, resources, workflow, context, outputs } = execution
  const [previewing, setPreviewing] = useState<string>()

  const isDone = Task.isSuccess(execution)

  return (
    <>
      <h3>Outputs</h3>
      <div className='output-container'>
        <div className='output-list-column'>
          <Outputs
            id={id}
            outputs={outputs}
            isDone={isDone}
            select={(o: string) =>
              setPreviewing(o === previewing ? undefined : o)
            }
            selected={previewing}
          />

          <h3 className='header'>Region</h3>
          <StyledTable>
            <tbody>
              {regions
                .sort((a, b) => ialphaSort(a.name, b.name))
                .map((region) => (
                  <tr key={region.id}>
                    <td>
                      <Link to={`/regions/${region.alias}`}>
                        <div className='region-name'>{region.name}</div>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </StyledTable>

          <h3 className='header'>Resources</h3>
          <StyledTable>
            <tbody>
              {resources
                .sort((a, b) => ialphaSort(a.name, b.name))
                .map((resource) => (
                  <tr key={resource.id}>
                    <td>
                      <Link to={`/info/${resource.alias}`}>
                        <div className='resource-name'>{resource.name}</div>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </StyledTable>

          <h3 className='header'>Workflow</h3>
          <StyledTable>
            <tbody>
              <tr>
                <td>
                  <Link to={`/workflows/${workflow.alias}/${workflow.version}`}>
                    <div className='workflow-name'>
                      {workflow.name}{' '}
                      <span>
                        <sup style={Style.small}>v{workflow.version}</sup>
                      </span>
                    </div>
                  </Link>
                </td>
              </tr>
            </tbody>
          </StyledTable>

          <h3 className='header'>Context</h3>
          <StyledTable>
            <tbody>
              {Object.entries(context)
                .sort((a, b) => ialphaSort(a[0], b[0]))
                .map(([k, v], i) => (
                  <tr key={i}>
                    <td style={{ width: '100%' }}>{k}</td>
                    <td style={{ textAlign: 'right' }}>{`${v}`}</td>
                  </tr>
                ))}
            </tbody>
          </StyledTable>
        </div>
        <div className='output-preview-column'>
          {previewing ? (
            <Preview
              url={
                previewing
                  ? `${getOrigin()}/data/executions/${id}/${previewing}`
                  : undefined
              }
              shortname={previewing}
            />
          ) : (
            <Ol.Map>
              {resources.length && (
                <Ol.Mvt
                  url={`${
                    process.env.NODE_ENV === 'development' ? '' : getOrigin()
                  }/api/resources/tiles/{z}/{x}/{y}.pbf?ids=${resources
                    .map((v) => v.id)
                    .join(',')}`}
                  color={Color.landrushComplement}
                  zIndex={1}
                />
              )}
              <Ol.Geometry geometry={execution.geometry} zIndex={2} />
            </Ol.Map>
          )}
        </div>
      </div>
    </>
  )
}

export default ({ execution }: WithExecution) => {
  return (
    <>
      <Status execution={execution} />
      <Results execution={execution} />
    </>
  )
}
