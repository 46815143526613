import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppState, AppThunk } from 'store'

type Item = {
  filename?: string
  buffer?: ArrayBuffer
  collection?: GeoJSON.FeatureCollection
}
type State = Item & {}

const initialState: State = {}

const slice = createSlice({
  name: 'add-regions-state',
  initialState,
  reducers: {
    destroy() {
      return initialState
    },
    setItem(state, action: PayloadAction<Item>) {
      const item = action.payload
      const { filename, buffer, collection } = item
      state.filename = filename
      state.buffer = buffer
      state.collection = collection
    },
    clearItem(state) {
      state.filename = undefined
      state.buffer = undefined
      state.collection = undefined
    },
  },
})

const { actions, reducer } = slice

const { setItem } = actions

const selectSlice = (state: AppState) => state.addRegionsState
const selectFilename = createSelector(selectSlice, (s) => s.filename)
const selectBuffer = createSelector(selectSlice, (s) => s.buffer)
const selectCollection = createSelector(selectSlice, (s) => s.collection)

const selectors = {
  selectSlice,
  selectFilename,
  selectBuffer,
  selectCollection,
}

const ingestItem = (input: string | File): AppThunk => async (dispatch) => {
  if (typeof input === 'string') {
    if (!input.endsWith('zip') && !input.endsWith('json')) {
      throw new Error('URL must be JSON or a zip file')
    }

    const filename = input
    const req = await window.fetch(filename)
    if (!req.ok) throw new Error(`${req.status}: ${req.statusText}`)

    if (input.endsWith('zip')) {
      const buffer = await req.arrayBuffer()
      dispatch(setItem({ filename, buffer }))
    } else {
      const json: GeoJSON.FeatureCollection = await req.json()
      if (typeof json !== 'object' || json.type !== 'FeatureCollection') {
        throw new Error('Invalid feature collection')
      }
      dispatch(setItem({ filename, collection: json }))
    }
  } else {
    const filename = input.name
    const item = await new Promise<Item>((resolve, reject) => {
      const reader = new FileReader()
      reader.onabort = () => reject('Analysis was aborted')
      reader.onerror = () => reject('Failed to read file')
      reader.onload = async () => {
        const { result } = reader
        if (result === null) return reject('No file contents found')
        if (typeof result !== 'string') return resolve({ buffer: result })

        const json: GeoJSON.FeatureCollection = JSON.parse(result)
        if (typeof json !== 'object' || json.type !== 'FeatureCollection') {
          return reject(new Error('Invalid feature collection'))
        }
        resolve({ collection: json })
      }

      if (filename.endsWith('json')) reader.readAsText(input)
      else reader.readAsArrayBuffer(input)
    })

    dispatch(setItem({ ...item, filename }))
  }
}

const thunks = { ingestItem }

export declare namespace AddRegionsState {
  export type { Item }
}
// eslint-disable-next-line no-redeclare
export const AddRegionsState = { ...actions, ...selectors, ...thunks }
export { reducer }
