import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { Color } from './color'
import { Spinner } from './spinner'

type StyledButton = { $isEnabled: boolean }
export const StyledButton = styled.button(({ $isEnabled }: StyledButton) => ({
  color: 'white',
  height: 38,
  borderTopWidth: 1,
  borderBottomWidth: 1,
  borderLeftWidth: 1,
  borderRightWidth: 1,
  borderStyle: 'solid',
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
  borderBottomLeftRadius: 4,
  borderBottomRightRadius: 4,
  marginBottom: 8,
  width: 120,
  backgroundColor: $isEnabled ? Color.landrushOrange : Color.lightGray,
  borderColor: $isEnabled ? Color.veryLightGray : Color.lightGray,
}))

type Props = {
  isEnabled?: boolean
  spin?: boolean
  style?: React.CSSProperties
  className?: string
  onClick?: () => void
  children: ReactNode
  label?: string
}
export const Button = ({
  isEnabled = true,
  spin = false,
  onClick = () => {},
  style,
  className,
  children,
  label,
}: Props) => {
  return (
    <StyledButton
      disabled={!isEnabled || spin}
      $isEnabled={isEnabled && !spin}
      style={style}
      aria-label={label}
      className={className}
      onClick={onClick}
    >
      {spin ? <Spinner small /> : children}
    </StyledButton>
  )
}

export default Button
