import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'

import { Id, Share } from '@landrush/common'
import { ialphaSort, randomString, Result } from '@landrush/util'

import { AppState, AppThunk } from 'store'
import { Resources } from 'store/resources'
import { Session } from 'store/session'

const adapter = createEntityAdapter<Share>({
  sortComparer: (a, b) => ialphaSort(a.name, b.name),
})
const initialState = adapter.getInitialState()

const slice = createSlice({
  name: 'shares',
  initialState,
  reducers: {
    add(state, action: PayloadAction<Share>) {
      const { payload: share } = action
      adapter.addOne(state, share)
    },
    update(state, action: PayloadAction<Share>) {
      const { payload: share } = action
      const { id } = share
      adapter.updateOne(state, { id, changes: share })
    },
    remove(state, action: PayloadAction<Id>) {
      const { payload: id } = action
      adapter.removeOne(state, id)
    },
    destroy() {
      return initialState
    },
  },
})

const { actions, reducer } = slice

const selectSlice = (state: AppState) => state.shares
const { selectById } = adapter.getSelectors(selectSlice)
const selectors = { selectById }

const add = (
  resourceId: Id,
  name = `Share token ${randomString(4)}`
): AppThunk<Result<Share>> => async (dispatch, getState) => {
  try {
    const client = Session.selectClient(getState())
    const share = await client.resources.shares.add(resourceId, name)
    dispatch(actions.add(share))
    dispatch(Resources.addShare({ resourceId, shareId: share.id }))
    return Result.success(share)
  } catch (e) {
    return Result.failure(e.message)
  }
}

const remove = (resourceId: Id, id: Id): AppThunk<Result<Id>> => async (
  dispatch,
  getState
) => {
  try {
    const client = Session.selectClient(getState())
    await client.resources.shares.remove(id)
    dispatch(actions.remove(id))
    dispatch(Resources.removeShare({ resourceId, shareId: id }))
    return Result.success(id)
  } catch (e) {
    return Result.failure(e.message)
  }
}

const rename = (id: Id, name: string): AppThunk<Result<Share>> => async (
  dispatch,
  getState
) => {
  try {
    const existing = selectById(getState(), id)
    if (!existing) return Result.failure('Not found')

    const client = Session.selectClient(getState())
    const share = await client.resources.shares.rename(id, name)
    dispatch(actions.update(share))
    return Result.success(share)
  } catch (e) {
    return Result.failure(e.message)
  }
}

const thunks = { add, rename, remove }

// eslint-disable-next-line no-redeclare
export const Shares = { actions, ...selectors, ...thunks }
export { reducer }
