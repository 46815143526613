import React from 'react'
import Media from 'react-media'
import styled from 'styled-components'

import { Schema, Dimension } from '@landrush/entwine'

import { pretty } from '@landrush/util'

import { Color } from './color'
import { DataTable, StyledTable } from './table'

type Props = { schema: Schema }

const Names = ({ schema }: Props) => (
  <DataTable style={{ width: 256, minWidth: 256 }}>
    <thead>
      <tr>
        <th>Name</th>
      </tr>
    </thead>
    <tbody>
      {schema.map(({ name }, i) => (
        <tr key={i}>
          <td>{name}</td>
        </tr>
      ))}
    </tbody>
  </DataTable>
)

const SchemaTable = styled(StyledTable)`
  th,
  td {
    /* Style the numeric columns. */
    :not(:first-child) {
      text-align: right;
      padding-left: 32px;
      margin-right: 32px;
    }
  }
`

const Details = ({ schema }: { schema: Dimension.WithStats[] }) => {
  return (
    <SchemaTable>
      <thead>
        <tr>
          <th>Name</th>
          <th>Minimum</th>
          <th>Maximum</th>
          <th>Average</th>
          <th>Stddev</th>
        </tr>
      </thead>
      <tbody>
        {schema.map(({ name, minimum, maximum, mean, variance }) => (
          <tr
            key={name}
            style={
              minimum === maximum && minimum === 0
                ? { color: Color.veryLightGray }
                : undefined
            }
          >
            <td style={{ width: '100%' }}>{name}</td>
            <td>{pretty(minimum)}</td>
            <td>{pretty(maximum)}</td>
            <td>{pretty(mean)}</td>
            <td>{pretty(Math.sqrt(variance))}</td>
          </tr>
        ))}
      </tbody>
    </SchemaTable>
  )
}

export const SchemaDisplay = ({ schema }: { schema: Schema }) => (
  <Media query={{ minWidth: 767 }}>
    {(matches) =>
      matches && schema.every(Dimension.hasStats) ? (
        <Details schema={schema as Dimension.WithStats[]} />
      ) : (
        <Names schema={schema} />
      )
    }
  </Media>
)
