import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Protocol } from '@landrush/common'

import Dropbox from './dropbox'
import S3 from './s3'
import Selector from './selector'

type State = { protocol?: Protocol; error?: string } | undefined
export default () => {
  const { state = {} } = useLocation<State>()
  const [protocol, setProtocol] = useState<Protocol>(state.protocol || 's3')

  return (
    <>
      <Selector protocol={protocol} setProtocol={setProtocol} />
      {protocol === 's3' ? <S3 /> : <Dropbox remoteError={state.error} />}
    </>
  )
}
