import { Color } from './color'

const clickable = { cursor: 'pointer' as const }
const unclickable = { cursor: 'not-allowed' as const }
const unselectable = { userSelect: 'none' as const }
const normal = { cursor: 'default' as const }
const alignLeft = { marginRight: 'auto', textAlign: 'left' as const }
const alignRight = { marginLeft: 'auto', textAlign: 'right' as const }
const alignCenter = { textAlign: 'center' as const }
const muted = { color: '#6c757d' }
const strong = { fontWeight: 'bold' as const }
const small = { color: Color.gray, fontSize: 'small' as const }
const nowrap = { whiteSpace: 'nowrap' as const }
const icon = { width: 24, ...alignCenter }
const padControl = { paddingLeft: 48 }
const grow = { display: 'flex', flexGrow: 1 }
const tableShrink = { width: 0, ...nowrap }

const Style = {
  clickable,
  unclickable,
  unselectable,
  normal,
  alignLeft,
  alignRight,
  alignCenter,
  muted,
  strong,
  small,
  nowrap,
  icon,
  padControl,
  grow,
  tableShrink,
}

export { Style }
