import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAws, faDropbox } from '@fortawesome/free-brands-svg-icons'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

// For some reason the "brands" and the "solid" libs have different types for
// their icons.
function getIcon(protocol: string): any {
  switch (protocol) {
    case 's3':
      return faAws
    case 'dropbox':
      return faDropbox
    default:
      return faQuestionCircle
  }
}

type ProtocolIcon = { protocol: string; style?: React.CSSProperties }
export const ProtocolIcon: React.FC<ProtocolIcon> = ({ protocol, style }) => {
  return <FontAwesomeIcon icon={getIcon(protocol)} fixedWidth style={style} />
}
