import proj4 from 'proj4'
import { Srs } from '@landrush/entwine'

const getDef = (zone: number, north: boolean, ellps: string, datum: string) => {
  const pre = ['+proj=utm', `+zone=${zone}`]
  const ns = north ? [] : ['+south']
  const post = [`+ellps=${ellps}`, `+datum=${datum}`, '+units=m', '+no_defs']
  return pre.concat(ns).concat(post).join(' ')
}

const wgs84 = (zone: number, north: boolean) => {
  const nsid = north ? 6 : 7
  const codenum = zone.toString().padStart(2, '0')
  const code = `EPSG:32${nsid}${codenum}`
  const def = getDef(zone, north, 'WGS84', 'WGS84')
  return [code, def]
}

const nad83 = (zone: number) => {
  const codenum = zone.toString().padStart(2, '0')
  const code = `EPSG:269${codenum}`
  const def = getDef(zone, true, 'GRS80', 'NAD83')
  return [code, def]
}

const registerDef = (code: string, def: string) => {
  proj4.defs(code, def)
  proj4.defs(`${code}+4326`, def)
}

for (let north = 0; north < 2; ++north) {
  for (let zone = 1; zone <= 60; ++zone) {
    const [code, def] = wgs84(zone, Boolean(north))
    registerDef(code, def)

    if (north && zone >= 10 && zone < 20) {
      const [code, def] = nad83(zone)
      registerDef(code, def)
    }
  }
}

function hasNativeDef(def: string) {
  return Boolean(proj4.defs(def))
}

function getDefString(srs: Srs | undefined) {
  if (!srs) return
  const codeString = Srs.getCodeString(srs)
  if (codeString && hasNativeDef(codeString)) return codeString
}

function hasDef(srs: Srs | undefined) {
  return srs && Boolean(getDefString(srs))
}

function getLonLatConverter(srs?: Srs) {
  if (!srs) return
  const def = getDefString(srs)
  if (def) return proj4(def, 'EPSG:4326')
}

function reprojectPoint(v: number[], from: string, to = 'EPSG:4326') {
  const converter = proj4(from, to)
  const xy = converter.forward([v[0], v[1]])
  return v.length === 2 ? xy : xy.concat(v[2])
}

function reprojectBounds(v: number[], from: string, to = 'EPSG:4326') {
  if (v.length === 6) {
    return reprojectPoint(v.slice(0, 3), from, to).concat(
      reprojectPoint(v.slice(3, 6), from, to)
    )
  }
  return reprojectPoint(v.slice(0, 2), from, to).concat(
    reprojectPoint(v.slice(2, 4), from, to)
  )
}

function reproject(v: number[], from: string, to = 'EPSG:4326') {
  if (v.length <= 3) return reprojectPoint(v, from, to)
  else return reprojectBounds(v, from, to)
}

export const Proj = {
  proj4,
  hasDef,
  getDefString,
  getLonLatConverter,
  reprojectPoint,
  reprojectBounds,
  reproject,
}
