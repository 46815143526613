import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ErrorMessage, Message } from 'components'
import { Credentials, Navigator } from 'store'

import Overview from './overview'
import Directory from './directory'

const Body: React.FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(Credentials.maybeLoad())
    return () => {
      dispatch(Navigator.destroy())
    }
  }, [dispatch])

  const cwd = useSelector(Navigator.maybeSelectCwd)
  const error = useSelector(Credentials.selectError)
  const loadState = useSelector(Credentials.selectLoadState)

  const load = () => dispatch(Credentials.maybeLoad())
  if (error) return <ErrorMessage onRetry={load}>{error}</ErrorMessage>
  if (loadState !== 'done') return <Message spin>Loading...</Message>
  if (cwd) return <Directory />
  return <Overview />
}
export default Body
