import React from 'react'
import { useSelector } from 'react-redux'
import { useMount, useUnmount } from 'react-use'

import { ErrorMessage, InfoMessage, Message, Overview } from 'components'
import { Executions, ExecutionState } from 'store'
import { useAppDispatch, useAsyncResult } from 'utils'

import { Header } from './header'
import { List } from './list'

const Body = () => {
  const dispatch = useAppDispatch()
  const [{ error, value }, load] = useAsyncResult(() =>
    dispatch(Executions.list())
  )
  useMount(load)
  useUnmount(() => dispatch(ExecutionState.destroy()))

  const count = useSelector(Executions.selectTotal)

  if (error) {
    return (
      <ErrorMessage onRetry={load} style={{ marginTop: 16 }}>
        {error}
      </ErrorMessage>
    )
  }

  if (!value) {
    return (
      <Message spin style={{ marginTop: 16 }}>
        Loading...
      </Message>
    )
  }

  if (!count) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
        <InfoMessage>Nothing here</InfoMessage>
      </div>
    )
  }

  return <List />
}

export default () => (
  <>
    <Overview page='executions' create='/add-executions' />
    <Header />
    <Body />
  </>
)
