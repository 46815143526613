import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { Color, Measures, Style } from 'components'

type Children = { children: ReactNode }

export const IconColumn = ({ children }: Children) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: Measures.sidebarIconWidth,
      backgroundColor: '#eee',
      overflowY: 'auto',
    }}
  >
    {children}
  </div>
)

export const ActiveTool = ({ children }: Children) => {
  return (
    <div
      style={{
        height: '100%',
        width: Measures.sidebarPaneWidth,
        color: Color.veryDarkGray,
        backgroundColor: '#fafafa',
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 1,
        borderRightWidth: 0,
        borderColor: Color.veryLightGray,
        borderStyle: 'solid',
        overflowY: 'auto',
      }}
    >
      <div style={{ marginTop: 12, marginBottom: 12 }}>{children}</div>
    </div>
  )
}

type PaddedTool = Children & { title: string }
export const PaddedTool = ({ children, title }: PaddedTool) => (
  <div style={{ marginLeft: 12, marginRight: 12 }}>
    <h4 style={Style.alignCenter}>{title}</h4>
    {children}
  </div>
)

export const Styles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-top-width: 0;
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 1px;
  border-style: solid;
  border-color: ${Color.veryLightGray};

  .icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${Measures.sidebarIconWidth}px;
    background-color: #eee;
    overflow-y: auto;
  }

  .icon {
    cursor: pointer;
    user-select: none;
    margin-top: 12px;
    margin-bottom: 4px;
    font-size: 32px;
  }

  .tool-container {
    height: 100%;
    width: ${Measures.sidebarPaneWidth}px;
    color: ${Color.veryDarkGray};
    background-color: #fafafa;
    border-top-width: 0;
    border-bottom-width: 0;
    border-left-width: 1px;
    border-right-width: 0;
    border-color: ${Color.veryLightGray};
    border-style: solid;
    overflow-y: auto;
    padding-top: 12px;
    padding-bottom: 12px;

    .padded {
      padding-left: 12px;
      padding-right: 12px;
    }

    .title {
      text-align: center;
    }
  }
`
