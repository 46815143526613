import React from 'react'
import { useUnmount } from 'react-use'
import styled from 'styled-components'

import { Workflow } from '@landrush/common'

import { Color, Steps, Style, WorkflowAnalysis } from 'components'

import { EditSettings } from './edit-settings'
import { Settings as SettingsProps, WithHistory } from './types'

export const Settings = ({
  history,
  version,
  text,
  setText,
  submit,
  isPending,
  error,
  dismissError,
}: SettingsProps) => {
  useUnmount(() => setText(undefined))

  if (text) {
    return (
      <EditSettings
        history={history}
        version={version}
        text={text}
        setText={setText}
        submit={submit}
        isPending={isPending}
        error={error}
        dismissError={dismissError}
      />
    )
  }
  return <Specification history={history} version={version} />
}

type Specification = Omit<WithHistory, 'setVersion'>
const Specification = ({ history, version }: Specification) => {
  const leaf = history.list[version - 1]
  const workflow = { ...history, ...leaf }
  const { pipeline, reducers, options } = workflow

  return (
    <SpecificationStyles>
      <div className='data-column'>
        <h3>Pipeline</h3>
        <Steps steps={[Workflow.eptReaderTemplate, ...pipeline]} />

        <h3 className='reducers-header'>Reducers</h3>
        {reducers ? (
          <Steps steps={reducers} />
        ) : (
          <div style={Style.muted}>None</div>
        )}
      </div>

      <div className='analysis-column'>
        <h3>Analysis</h3>
        <WorkflowAnalysis
          pipeline={pipeline}
          reducers={reducers}
          options={options}
        />
      </div>
    </SpecificationStyles>
  )
}

const SpecificationStyles = styled.div`
  display: flex;
  padding: 0;
  margin-top: 16px;

  .data-column {
    flex: 1 1 0;
    margin-right: 12px;
  }
  .analysis-column {
    flex: 1 1 0;
    margin-left: 12px;
  }
  .reducers-header {
    margin-top: 24px;
  }
  .advanced-icon-container {
    display: flex;
    justify-content: center;
  }
  .advanced-icon {
    font-size: 24px;
    margin-top: 32px;
    text-align: center;
    cursor: pointer;
    color: ${Color.lightGray};
    &:hover {
      color: ${Color.darkGray};
    }
  }
`
