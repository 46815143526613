import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'

import bannerImage from 'img/banner.png'
import { Session } from 'store'

import { Color } from './color'
import { Measures } from './measures'
import { Style } from './style'

const height = Measures.headerHeight
const Container = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height,
  backgroundColor: '#f5f5f5',
  borderRadius: 0,
  borderTopWidth: 0,
  borderBottomWidth: 1,
  borderLeftWidth: 0,
  borderRightWidth: 0,
  borderStyle: 'solid',
  borderColor: '#e7e7e7',
  paddingTop: 11,
  paddingBottom: 11,
  paddingLeft: 16,
  paddingRight: 16,
})

type AccountIcon = { pathname: string }
const AccountIcon = styled(FontAwesomeIcon)(({ pathname }: AccountIcon) => ({
  fontSize: '1.8em',
  ...Style.clickable,
  ...(pathname === '/account'
    ? { color: Color.landrushOrange }
    : { color: Color.gray, '&:hover': { color: Color.darkGray } }),
}))

const bannerStyle = { position: 'relative' as const, height: '100%' }

export const Header = () => {
  const { pathname } = useLocation()
  const isLoggedIn = useSelector(Session.selectIsLoggedIn)

  const banner = (
    <Link to='/' aria-label='home' style={bannerStyle}>
      <img id='banner' alt='banner' src={bannerImage} style={bannerStyle} />
    </Link>
  )

  const controls = isLoggedIn ? (
    <Link
      to='/account'
      aria-label='account'
      aria-current={pathname === '/account' ? 'page' : undefined}
      style={{ marginRight: 16 }}
    >
      <AccountIcon icon={faUserCircle} pathname={pathname} fixedWidth />
    </Link>
  ) : (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        height: '100%',
      }}
    >
      <Link to='/signup'>
        <div
          style={{
            color: pathname === '/signup' ? Color.landrushOrange : Color.gray,
          }}
        >
          Sign up
        </div>
      </Link>
      <div style={{ marginRight: 32, marginLeft: 32 }}>
        <Link to='/login'>
          <div
            style={{
              color: pathname === '/login' ? Color.landrushOrange : Color.gray,
            }}
          >
            Log in
          </div>
        </Link>
      </div>
    </div>
  )

  return (
    <Container>
      {banner}
      {controls}
    </Container>
  )
}
