import React from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { useMount } from 'react-use'

import { Region } from '@landrush/common'

import { useAppDispatch, useAsyncResult } from 'utils'
import { Regions } from 'store'
import { DeleteIcon, ErrorMessage, Message, Ol, Overview } from 'components'

type WithRegion = { region: Region }
const Loader: React.FC = () => {
  const dispatch = useAppDispatch()
  const { alias } = useParams<{ alias: string }>()
  const [{ error, value }, load] = useAsyncResult(
    async () => dispatch(Regions.hydrateByAlias(alias)),
    [alias]
  )

  useMount(load)

  if (error) return <ErrorMessage>{error}</ErrorMessage>
  if (!value) return <Message spin>Loading...</Message>
  return <Page region={value} />
}

const Page: React.FC<WithRegion> = ({ region }) => {
  const dispatch = useAppDispatch()
  const [
    { isPending, error, value, dismissError },
    remove,
  ] = useAsyncResult(async () => dispatch(Regions.remove(region.id)))

  if (value) return <Redirect to='/regions' />

  return (
    <>
      <Overview page='regions' />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h4 style={{ marginTop: 16 }}>{region.name}</h4>
        <DeleteIcon onClick={remove} isPending={isPending} />
      </div>
      {error && <ErrorMessage onDismiss={dismissError}>{error}</ErrorMessage>}
      <Ol.Map>
        <Ol.Geometry geometry={region.geometry} />
      </Ol.Map>
    </>
  )
}

export default Loader
