import { Potree, Resource } from '@landrush/common'
import { Dimension, Schema } from '@landrush/entwine'

import * as Movement from './movement'
import { LocalStorage, Proj } from 'utils'

function hasPopulatedDimension(schema: Schema, name: string) {
  const dim = schema.find((d) => d.name === name)
  if (!dim) return false
  if (!Dimension.hasStats(dim)) return true
  return dim.minimum !== 0 || dim.maximum !== 0
}

export function getInitialState(
  resource: Resource | Resource.Shared
): Potree.State {
  const fps = LocalStorage.getInteger('fps') || 20
  const pointBudget = LocalStorage.getInteger('pointBudget') || 1.5e6

  // TODO: Enable "home".
  const { bounds, /* home, */ schema, srs } = resource

  const { position, target } = Movement.getZoom(bounds)
  const rotation = 0

  const hasColor = hasPopulatedDimension(schema, 'Red')
  const hasIntensity = hasPopulatedDimension(schema, 'Intensity')
  const pointColor = hasColor
    ? 'rgb'
    : hasIntensity
    ? 'intensityGradient'
    : 'elevation'

  const elevationRange: Potree.RampRange = [
    Math.floor(bounds[2]),
    Math.ceil(bounds[5]),
  ]

  const idim = resource.schema.find((d) => d.name === 'Intensity')
  const intensityRange: Potree.RampRange = [
    0,
    idim && Dimension.hasStats(idim) ? idim.maximum : 255,
  ]

  const background = Proj.hasDef(srs) ? 'terrain' : 'none'

  // TODO: In order, this should come from:
  //      - a list stored alongside this resource, possibly user-created
  //      - a list of the values from the detailed statistics counts
  //      - a default list of LAS classifications
  const classifications: Potree.Classification[] = []

  return {
    ...Potree.defaults,

    position,
    target,
    rotation,

    background,
    fps,
    elevationRange,
    intensityRange,
    pointBudget,
    pointColor,

    // ...home,
    classifications,
  }
}
