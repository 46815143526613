import React, { ReactNode } from 'react'
import ReactSelect from 'react-select'

import { Color } from './color'

// TODO: There's a flood of warnings from this component in development mode -
// the bug tracker is: https://github.com/JedWatson/react-select/issues/4094.
//
// No action needed currently, but once it's resolved we need to update.

export declare namespace Select {
  export type Option<T = any> = {
    label: ReactNode
    value: T
    isEnabled?: boolean
  }
}

type Props<T> = {
  options: Select.Option<T>[]
  value?: T
  onChange(value: T): void
  isEnabled?: boolean
  style?: React.CSSProperties
  className?: string
  [rest: string]: any
}

export function Select<T = any>({
  value,
  onChange,
  options,
  isEnabled = true,
  isClearable = false,
  style,
  className,
  ...rest
}: Props<T>) {
  return (
    <ReactSelect
      className={className}
      {...rest}
      options={options}
      value={options.find((v) => v.value === value)}
      isDisabled={!isEnabled}
      isOptionDisabled={({ isEnabled = true }: Select.Option) => !isEnabled}
      onChange={(e) => onChange((e as Select.Option<T>).value)}
      menuPlacement='auto'
      styles={{
        // Styling docs: https://react-select.com/styles
        container: (provided: any, state: any) => ({
          ...provided,
          color: Color.darkGray,
          boxShadow: 'none',
          width: '100%',
          marginBottom: 8,
          ...style,
        }),
        control: (provided: any, { menuIsOpen }: any) => ({
          ...provided,
          borderWidth: 1,
          backgroundColor: isEnabled ? undefined : Color.disabled,
          boxShadow: menuIsOpen
            ? 'inset 0 0 2px ' + Color.landrushOrange
            : null,
          borderColor: menuIsOpen ? Color.landrushOrange : '#ced4da',
          ':hover': {
            borderColor: menuIsOpen ? Color.landrushOrange : '#ced4da',
          },
        }),
        option: (provided: any, state: any) => ({
          ...provided,
          backgroundColor: state.isSelected
            ? Color.landrushOrange
            : state.isFocused
            ? Color.veryLightGray
            : undefined,
          ':active': {
            color: Color.landrushOrange,
          },
        }),
      }}
    />
  )
}
