import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPen, faSave } from '@fortawesome/free-solid-svg-icons'

import { CredentialState } from 'credentials'
import { Color, DeleteIcon, Spinner, Style } from 'components'

type Props = CredentialState & { text?: string; setText(v?: string): void }

const fontSize = 18
const Control: React.FC = ({ children }) => (
  <div style={{ paddingLeft: 48 }}>{children}</div>
)

type StyledIcon = { $isEnabled?: boolean; $hoverColor?: string }
const StyledIcon = styled(FontAwesomeIcon)(
  ({ $isEnabled = true, $hoverColor = Color.landrushOrange }: StyledIcon) => ({
    fontSize,
    color: Color.veryLightGray,
    ...($isEnabled
      ? { '&:hover': { cursor: 'pointer', color: $hoverColor } }
      : Style.unclickable),
  })
)
const Save = ({ flowState, rename, text }: Props) => {
  if (text === undefined) return null
  if (flowState === 'renaming') {
    return <Spinner style={{ fontSize }} />
  }
  if (!flowState) {
    return (
      <StyledIcon
        $isEnabled
        icon={faSave}
        style={{ fontSize: fontSize * 1.15 }}
        onClick={() => rename(text)}
      />
    )
  }
  return null
}

const Edit = ({ credential, flowState, text, setText }: Props) => {
  if (text === undefined) {
    return (
      <StyledIcon
        $isEnabled={!flowState}
        icon={faPen}
        onClick={() => {
          if (!flowState) setText(credential.name)
        }}
        fixedWidth
      />
    )
  }
  return (
    <StyledIcon
      $isEnabled={!flowState}
      $hoverColor={Color.dangerRed}
      icon={faMinus}
      onClick={() => {
        if (!flowState) setText(undefined)
      }}
      fixedWidth
    />
  )
}

const Delete = ({ flowState, remove }: Props) => {
  return (
    <DeleteIcon
      style={{ fontSize }}
      isEnabled={!flowState}
      isPending={flowState === 'removing'}
      onClick={remove}
    />
  )
}

export default (props: Props) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Control>
        <Save {...props} />
      </Control>
      <Control>
        <Edit {...props} />
      </Control>
      <Control>
        <Delete {...props} />
      </Control>
    </div>
  )
}
