import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Workflow } from '@landrush/common'

import { GetSliceState } from 'utils'

type State = { history?: Workflow.History }

const initialState: State = {}
const slice = createSlice({
  name: 'workflow-history',
  initialState,
  reducers: {
    assign(state, { payload: history }: PayloadAction<Workflow.History>) {
      state.history = history
    },
    destroy() {
      return initialState
    },
  },
})

export const { actions, reducer } = slice
export type Reducer = typeof reducer
export type Slice = GetSliceState<Reducer>
