import React, { useState, useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { usePopper } from 'react-popper'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamation,
  faMinus,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'

import { Color } from './color'
import { Spinner } from './spinner'
import { Style } from './style'
import { Button } from 'components'

export function DeleteControl() {} // TODO: Remove.

type StyledIcon = { $isEnabled?: boolean }
const StyledIcon = styled(FontAwesomeIcon)(
  ({ $isEnabled = true }: StyledIcon) => ({
    fontSize: 18,
    color: Color.veryLightGray,
    ...($isEnabled
      ? { '&:hover': { cursor: 'pointer', color: Color.dangerRed } }
      : {}),
  })
)

type IconName = 'trash' | 'times' | 'minus'
function getIcon(icon: IconName) {
  switch (icon) {
    case 'times':
      return faTimes
    case 'minus':
      return faMinus
    default:
      return faTrash
  }
}

type DeleteIcon = {
  onClick: () => void
  icon?: IconName
  error?: string
  dismissError?: () => void
  isEnabled?: boolean
  isPending?: boolean
  className?: string
  style?: React.CSSProperties
  confirmation?: React.ReactNode
}
export const DeleteIcon: React.FC<DeleteIcon> = ({
  onClick,
  icon = 'trash',
  error,
  dismissError = () => {},
  isEnabled = true,
  isPending = false,
  className,
  style,
  confirmation,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const [targetRef, setTargetRef] = useState<any>(null)
  const [popperRef, setPopperRef] = useState<any>(null)

  const { styles, attributes } = usePopper(targetRef, popperRef, {
    placement: 'left',
    strategy: 'fixed',
  })

  // close pop-up if there is a click elsewhere on the page
  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      if (
        targetRef &&
        !targetRef?.contains(e.target) &&
        popperRef &&
        !popperRef?.contains(e.target)
      ) {
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', onClick)
    return () => document.removeEventListener('mousedown', onClick)
  }, [targetRef, popperRef])

  if (error) {
    return (
      <div
        data-tip={error}
        data-place='left'
        data-effect='solid'
        className={className}
      >
        <StyledIcon
          icon={faExclamation}
          style={{ ...Style.icon, color: Color.dangerRed, ...style }}
          onClick={dismissError}
          fixedWidth
        />
        <ReactTooltip />
      </div>
    )
  }
  if (isPending) {
    return (
      <Spinner
        className={className}
        small
        style={{ ...Style.icon, ...style }}
      />
    )
  }
  if (!confirmation)
    return (
      <StyledIcon
        className={className}
        $isEnabled={isEnabled}
        onClick={() => {
          if (isEnabled) onClick()
        }}
        icon={getIcon(icon)}
        style={{ ...Style.icon, ...style }}
        fixedWidth
      />
    )
  if (!isOpen) {
    return (
      <StyledIcon
        className={className}
        $isEnabled={isEnabled}
        onClick={() => {
          if (isEnabled) setOpen(true)
        }}
        icon={getIcon(icon)}
        style={{ ...Style.icon, ...style }}
        fixedWidth
      />
    )
  }
  return (
    <>
      <div ref={setTargetRef} onClick={() => setOpen(!isOpen)}>
        <StyledIcon
          className={className}
          $isEnabled={isEnabled}
          onClick={() => {
            if (isEnabled) setOpen(false)
          }}
          icon={getIcon(icon)}
          style={{ ...Style.icon, ...style }}
          fixedWidth
        />
      </div>
      <PopperStyles
        ref={setPopperRef}
        style={{ ...styles.popper, zIndex: 1 }}
        {...attributes.popper}
      >
        <ConfirmationStyles>
          {confirmation}
          <div className='delete-button-container'>
            <Button
              className='cancel-delete-button'
              isEnabled
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button
              className='actually-delete-button'
              spin={isPending}
              onClick={onClick}
            >
              Delete
            </Button>
          </div>
        </ConfirmationStyles>
      </PopperStyles>
    </>
  )
}

const ConfirmationStyles = styled.div`
  background-color: white;
  border: 1px solid ${Color.lightGray};
  border-radius: 4px;
  width: 20vw;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${Color.veryLightGray};

  .delete-button-container {
    display: flex;
  }

  .cancel-delete-button {
    margin-right: 4px;
    background-color: ${Color.lightGray};
    &:hover {
      background-color: ${Color.gray};
    }
  }

  .actually-delete-button {
    margin-left: 4px;
    background-color: ${Color.lightGray};
    &:hover {
      background-color: ${Color.dangerRed};
    }
  }
`

const PopperStyles = styled.div`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: white;
  text-align: center;
`
