import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleNotch,
  faMinus,
  faPen,
  faSave,
} from '@fortawesome/free-solid-svg-icons'

import { useResource } from 'utils'

import { NameProps } from '.'

export const Save: React.FC<NameProps> = ({
  isRenamable,
  isRenaming,
  text,
  rename,
}) => {
  if (!isRenamable || text === undefined) return null

  if (isRenaming) {
    return (
      <FontAwesomeIcon
        className='control-container control unclickable'
        icon={faCircleNotch}
        spin
      />
    )
  }

  return (
    <FontAwesomeIcon
      className='control-container control clickable'
      icon={faSave}
      onClick={rename}
    />
  )
}

export const Edit: React.FC<NameProps> = ({
  isRenamable,
  isRenaming,
  text,
  setText,
}) => {
  const { name } = useResource()

  if (!isRenamable) return null
  if (text === undefined) {
    return (
      <FontAwesomeIcon
        className='control-container control clickable pen'
        icon={faPen}
        onClick={() => setText(name)}
      />
    )
  }

  if (isRenaming) {
    return (
      <FontAwesomeIcon
        className='control-container control unclickable'
        icon={faMinus}
      />
    )
  }

  return (
    <FontAwesomeIcon
      className='control-container control clickable cancellable'
      icon={faMinus}
      onClick={() => setText()}
    />
  )
}
