import * as Selectors from './selectors'
import * as Thunks from './thunks'
import * as Types from './types'

import { actions, reducer } from './slice'
export { reducer }

export declare namespace Workflows {
  export type WithList = Types.WithList
  export type WithSummary = Types.WithSummary
  export type WithDetail = Types.WithDetail
}
// eslint-disable-next-line no-redeclare
export const Workflows = { actions, ...Selectors, ...Thunks }
