import React from 'react'
import styled from 'styled-components'

import { Task } from '@landrush/common'

import { ErrorMessage, InfoMessage, Progress, Style } from 'components'
import { useResource } from 'utils'

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 8px;

  .container {
    display: flex;
    flex-direction: column;
    max-width: 480px;
    align-items: stretch;
  }
`

export default () => {
  const resource = useResource()

  if (Task.isSuccess(resource)) return null
  if (Task.isFailure(resource)) {
    const { errors } = resource
    return (
      <ErrorMessage>
        <div style={{ ...Style.small, ...Style.muted }}>
          Ingest failed - point cloud not viewable
        </div>
        {errors.map((e, i) => (
          <p key={i}>{e}</p>
        ))}
      </ErrorMessage>
    )
  }

  const { index = 0, total = 1 } = resource
  return (
    <Styles>
      <div className='container'>
        <InfoMessage
          icon='info'
          title='Ingest in progress'
          body='Information below is preliminary and may change'
        />
        <Progress striped animated index={index} total={total} />
      </div>
    </Styles>
  )
}
