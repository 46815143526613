export function getQueryParam(name: string) {
  const regexp = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`)
  const results = regexp.exec(window.location.href)
  if (!results) return undefined
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export function getHashParam(name: string) {
  const regexp = new RegExp(`[#&/]${name}=([^&]+)(&|$)`)
  const match = window.location.hash.match(regexp)
  return match ? match[1] : undefined
}
