import styled from 'styled-components'

import { Color } from '../color'

export const Map = styled.div`
  min-height: 480px;
  height: 480px;
  width: 100%;
  border: 1px solid ${Color.veryLightGray};
`
