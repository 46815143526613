import { Workflow } from '@landrush/common'

export const dsm: Workflow.Add = {
  name: 'DSM',
  description: 'Generate a digital surface model COG',
  readme: `### Read
Read from [EPT](https://entwine.io) data sources to the resolution specified at run-time, and with a user-specified buffer to eliminate edge effects.

### Filter
LAS classification values indicating noise are filtered out:
- \`Noise\` (\`7\`)
- \`High Noise\` (\`18\`)

This is done using the existing \`Classification\` attribute values - no classification algorithm is run by this workflow.

### Write
Generate tiled TIFF files representing the Digital Surface Model (DSM) to the user-supplied \`resolution\`, specified in output SRS units.

### Post-process
Generate a COG from the tiled data.
`,
  pipeline: [
    { type: 'filters.range', limits: 'Classification![7:7]' },
    { type: 'filters.range', limits: 'Classification![18:18]' },
    {
      type: 'writers.gdal',
      bounds: '{{bounds}}',
      filename: '{{id}}.tif',
      gdalopts: 'TILED=YES,COMPRESS=LZW,PREDICTOR=3',
      resolution: '{{resolution}}',
      output_type: 'idw',
      data_type: 'float32',
      window_size: 3,
      default_srs: '{{srs}}',
    },
  ],
  reducers: [
    {
      type: 'gdal.vrt',
      input: '*.tif',
      output: 'tiles.vrt',
    },
    {
      type: 'gdal.translate',
      args: [
        '-of',
        'COG',
        '-co',
        'COMPRESS=LZW',
        '-co',
        'RESAMPLING=BILINEAR',
        '-co',
        'PREDICTOR=3',
        '-co',
        'OVERVIEWS=IGNORE_EXISTING',
      ],
      input: 'tiles.vrt',
      output: 'dsm-{{resolution}}{{unit}}.tif',
    },
    {
      type: 'gdal.dem',
      mode: 'hillshade',
      input: 'tiles.vrt',
      output: 'dsm-hillshade-{{resolution}}{{unit}}.tif',
    },
    {
      type: 'gdal.translate',
      args: ['-of', 'JPEG'],
      input: 'dsm-hillshade-{{resolution}}{{unit}}.tif',
      output: 'dsm-hillshade-{{resolution}}{{unit}}.jpg',
    },
  ],
  options: {
    unit: { description: 'Output units (e.g. "m", "ft")', default: 'm' },
    resolution: { description: 'Output resolution' },
  },
  metadata: {
    author: 'Landrush',
  },
}

export const dtm: Workflow.Add = {
  name: 'DTM',
  description: 'Generate a digital terrain model COG',
  readme: `### Read
Read from [EPT](https://entwine.io) data sources to the resolution specified at run-time, and with a user-specified buffer to eliminate edge effects.

### Filter
First, re-assign all \`Classification\` values to \`0\`.  Then run the [SMRF filter](https://pdal.io/stages/filters.smrf.html) to classify values as ground/non-ground with a user-configurable window size (see \`cell\` option).  Non-ground points are then filtered out.

### Write
Generate tiled TIFF files representing the Digital Terrain Model (DTM) to the user-supplied \`resolution\`, specified in output SRS units.

### Post-process
Generate a COG from the tiled data.
  `,
  pipeline: [
    { type: 'filters.assign', assignment: 'Classification[:] = 0' },
    { type: 'filters.assign', assignment: 'ReturnNumber[0:0] = 1' },
    { type: 'filters.assign', assignment: 'NumberOfReturns[0:0] = 1' },
    { type: 'filters.smrf', cell: '{{cell}}' },
    { type: 'filters.range', limits: 'Classification[2:2]' },
    {
      type: 'writers.gdal',
      bounds: '{{bounds}}',
      filename: '{{id}}.tif',
      gdalopts: 'TILED=YES,COMPRESS=LZW,PREDICTOR=3',
      resolution: '{{resolution}}',
      output_type: 'idw',
      data_type: 'float32',
      window_size: 3,
      default_srs: '{{srs}}',
    },
  ],
  reducers: [
    {
      type: 'gdal.vrt',
      input: '*.tif',
      output: 'tiles.vrt',
    },
    {
      type: 'gdal.translate',
      args: [
        '-of',
        'COG',
        '-co',
        'COMPRESS=LZW',
        '-co',
        'RESAMPLING=BILINEAR',
        '-co',
        'PREDICTOR=3',
        '-co',
        'OVERVIEWS=IGNORE_EXISTING',
      ],
      input: 'tiles.vrt',
      output: 'dtm-{{resolution}}{{unit}}.tif',
    },
    {
      type: 'gdal.dem',
      mode: 'hillshade',
      input: 'tiles.vrt',
      output: 'dtm-hillshade-{{resolution}}{{unit}}.tif',
    },
    {
      type: 'gdal.translate',
      args: ['-of', 'JPEG'],
      input: 'dtm-hillshade-{{resolution}}{{unit}}.tif',
      output: 'dtm-hillshade-{{resolution}}{{unit}}.jpg',
    },
  ],
  options: {
    unit: { description: 'Output units (e.g. "m", "ft")', default: 'm' },
    resolution: { description: 'Output resolution' },
    cell: { description: 'SMRF cell size', default: 1 },
  },
  metadata: {
    author: 'Landrush',
  },
}

export const hag: Workflow.Add = {
  name: 'HAG',
  description: 'Generate a height-above-ground DEM',
  readme: `### Read
Read from [EPT](https://entwine.io) data sources to the resolution specified at run-time, and with a user-specified buffer to eliminate edge effects.

### Filter
First, re-assign all \`Classification\` values to \`0\`.  Then run the [SMRF filter](https://pdal.io/stages/filters.smrf.html) to classify values as ground/non-ground with a user-configurable window size (see \`cell\` option).  Then, compute a height-above-ground attribute for each point.

### Write
Generate tiled TIFF files for the height-above-ground surface to the user-supplied \`resolution\`, specified in output SRS units.

### Post-process
Generate a COG from the tiled data.
`,
  pipeline: [
    { type: 'filters.assign', assignment: 'Classification[:] = 0' },
    { type: 'filters.assign', assignment: 'ReturnNumber[0:0] = 1' },
    { type: 'filters.assign', assignment: 'NumberOfReturns[0:0] = 1' },
    { cell: '{{cell}}', type: 'filters.smrf' },
    { type: 'filters.hag_nn', count: '{{count}}' },
    {
      type: 'writers.gdal',
      bounds: '{{bounds}}',
      filename: '{{id}}.tif',
      dimension: 'HeightAboveGround',
      gdalopts: 'TILED=YES,COMPRESS=LZW,PREDICTOR=3',
      data_type: 'float32',
      resolution: '{{resolution}}',
      default_srs: '{{srs}}',
      output_type: 'max',
      window_size: 3,
    },
  ],
  reducers: [
    { type: 'gdal.vrt', input: '*.tif', output: 'tiles.vrt' },
    {
      args: [
        '-of',
        'COG',
        '-co',
        'COMPRESS=LZW',
        '-co',
        'RESAMPLING=BILINEAR',
        '-co',
        'PREDICTOR=3',
        '-co',
        'OVERVIEWS=IGNORE_EXISTING',
      ],
      type: 'gdal.translate',
      input: 'tiles.vrt',
      output: 'hag-{{resolution}}{{unit}}.tif',
    },
    {
      mode: 'hillshade',
      type: 'gdal.dem',
      input: 'tiles.vrt',
      output: 'hag-hillshade-{{resolution}}{{unit}}.tif',
    },
    {
      args: ['-of', 'JPEG'],
      type: 'gdal.translate',
      input: 'hag-hillshade-{{resolution}}{{unit}}.tif',
      output: 'hag-hillshade-{{resolution}}{{unit}}.jpg',
    },
  ],
  options: {
    unit: { description: 'Output units (e.g. "m", "ft")', default: 'm' },
    cell: { description: 'SMRF cell size', default: 1 },
    count: {
      description:
        'Number of ground neighbors to consider for height-above-ground',
      default: 1,
    },
    resolution: { description: 'Output resolution' },
  },
  metadata: {
    author: 'Landrush',
  },
}

export const rii: Workflow.Add = {
  name: 'RII',
  description: 'Generate a relative intensity image',
  readme: `### Read
Read from [EPT](https://entwine.io) data sources to the resolution specified at run-time, and with a user-specified buffer to eliminate edge effects.

### Filter
LAS classification values indicating noise are filtered out:
- \`Noise\` (\`7\`)
- \`High Noise\` (\`18\`)

This is done using the existing \`Classification\` attribute values - no classification algorithm is run by this workflow.

### Write
Generate tiled TIFF files representing the Relative Intensity Image (RII) to the user-supplied \`resolution\`, specified in output SRS units.

### Post-process
Generate a COG from the tiled data.
`,
  pipeline: [
    { type: 'filters.range', limits: 'Classification![7:7]' },
    { type: 'filters.range', limits: 'Classification![18:18]' },
    {
      type: 'writers.gdal',
      bounds: '{{bounds}}',
      filename: '{{id}}.tif',
      gdalopts: 'TILED=YES,COMPRESS=LZW',
      dimension: 'Intensity',
      resolution: '{{resolution}}',
      output_type: 'idw',
      data_type: 'uint16',
      window_size: 3,
      default_srs: '{{srs}}',
    },
  ],
  reducers: [
    {
      type: 'gdal.vrt',
      input: '*.tif',
      output: 'tiles.vrt',
    },
    {
      type: 'gdal.translate',
      args: [
        '-of',
        'COG',
        '-co',
        'COMPRESS=LZW',
        '-co',
        'RESAMPLING=BILINEAR',
        '-co',
        'OVERVIEWS=IGNORE_EXISTING',
      ],
      input: 'tiles.vrt',
      output: 'rii-{{resolution}}{{unit}}.tif',
    },
  ],
  options: {
    unit: { description: 'Output units (e.g. "m", "ft")', default: 'm' },
    resolution: { description: 'Output resolution' },
  },
  metadata: {
    author: 'Landrush',
  },
}
