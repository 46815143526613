import React from 'react'

import Header from '../header'
import PointCloudInfo from './point-cloud-info'
import Status from './status'

export default () => {
  return (
    <>
      <Header />
      <Status />
      <PointCloudInfo />
    </>
  )
}
