import { createEntityAdapter } from '@reduxjs/toolkit'

import { Workflow } from '@landrush/common'
import { ialphaSort } from '@landrush/util'

export const adapter = createEntityAdapter<Workflow.Summary>({
  sortComparer: (a, b) => ialphaSort(a.name, b.name),
})

export type WithList = { workflows: Workflow.Summary[] }
export type WithSummary = { workflow: Workflow.Summary }
export type WithDetail = { workflow: Workflow }
