import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, Redirect, useLocation } from 'react-router-dom'

import {
  Button,
  Column,
  ErrorMessage,
  InfoMessage,
  Style,
  TextInput,
} from 'components'
import { Session } from 'store'
import { Browser, useAsync } from 'utils'

const { getQueryParam } = Browser

type FromState = { email?: string }
export default () => {
  const { state, pathname } = useLocation<FromState | undefined>()
  const client = useSelector(Session.selectUnconnectedClient)

  const emailFromState = state?.email
  const emailFromQuery = getQueryParam('email')
  const codeFromQuery = getQueryParam('code')

  const [email, setEmail] = useState(emailFromState || emailFromQuery || '')
  const [code, setCode] = useState(codeFromQuery || '')
  const [password, setPassword] = useState('')
  useEffect(() => {
    if (emailFromQuery || codeFromQuery) {
      window.history.replaceState('', document.title, pathname)
    }
  }, [emailFromQuery, codeFromQuery, pathname])

  const [
    { isPending, value, error, dismissError },
    submit,
  ] = useAsync(async () => {
    await client.auth.submitPasswordReset(email, code, password)
    return true
  }, [email, code, password])

  if (value) {
    return <Redirect to={{ pathname: '/', state: { email, from: pathname } }} />
  }

  const isValid = Boolean(
    !isPending && email.length && code.length && password.length
  )

  const justSubmittedResetRequest = Boolean(emailFromState) || true
  return (
    <Column style={{ width: 480 }}>
      {justSubmittedResetRequest ? (
        <InfoMessage
          icon='envelope'
          title='Check email for verification code'
          body='Choose a new password'
        />
      ) : (
        <h2>Reset password</h2>
      )}
      <TextInput
        id='email-input'
        placeholder='Email'
        value={email}
        onChange={setEmail}
        onSubmit={submit}
        isEnabled={!isPending}
        isValid={isValid}
      />
      <TextInput
        id='verification-code-input'
        value={code}
        onChange={setCode}
        onSubmit={submit}
        placeholder='Verification code'
        isEnabled={!isPending}
        isValid={isValid}
      />
      <TextInput
        id='new-password'
        value={password}
        onChange={setPassword}
        onSubmit={submit}
        type='password'
        placeholder='Password'
        isEnabled={!isPending}
        isValid={isValid}
      />
      {error ? (
        <ErrorMessage onDismiss={dismissError}> {error} </ErrorMessage>
      ) : null}
      <Button
        isEnabled={isValid && !isPending}
        onClick={submit}
        style={{ alignSelf: 'center' }}
        spin={isPending}
      >
        Submit
      </Button>
      <p style={{ ...Style.muted, ...Style.alignRight }}>
        Don't have a code?{' '}
        <Link to='/forgot-password'>Request a password reset</Link>.
      </p>
    </Column>
  )
}
