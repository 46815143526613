import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faEnvelope,
  faInfo,
  faLightbulb,
} from '@fortawesome/free-solid-svg-icons'

import { Color, Column, Panel, Style } from 'components'

export const BigIcon = styled(FontAwesomeIcon)({
  fontSize: 36,
  color: Color.landrushOrange,
  marginBottom: 8,
})

type IconName = 'check' | 'envelope' | 'info' | 'lightbulb'
const getIcon = (icon: IconName) => {
  switch (icon) {
    case 'check':
      return faCheck
    case 'envelope':
      return faEnvelope
    case 'lightbulb':
      return faLightbulb
    case 'info':
    default:
      return faInfo
  }
}

type InfoMessage = {
  icon?: IconName
  title?: string
  body?: string
  style?: React.CSSProperties
}
export const InfoMessage: React.FC<InfoMessage> = ({
  icon = 'info',
  title,
  body,
  children,
  style,
}) => (
  <Panel
    style={{
      marginBottom: 8,
      backgroundColor: Color.backgroundGray,
      width: '100%',
      maxWidth: 480,
      ...style,
    }}
  >
    <Column style={{ margin: 16 }}>
      <BigIcon icon={getIcon(icon)} />
      {title ? <h4>{title}</h4> : null}
      {body ? <div style={Style.muted}>{body}</div> : null}
      {children}
    </Column>
  </Panel>
)
