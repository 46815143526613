import React from 'react'

import { Bounds, Schema } from '@landrush/entwine'
import { pretty } from '@landrush/util'
import { DataTable, Style } from 'components'

type Position = { bounds: Bounds; schema: Schema }
const Position = ({ bounds, schema }: Position) => {
  const scale = schema.slice(0, 3).map((dim) => dim.scale || 1)
  return (
    <DataTable>
      <thead>
        <tr>
          <th>Extent</th>
          <th>Minimum</th>
          <th>Maximum</th>
          <th>Scale</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={Style.strong}>X</td>
          <td>{pretty(bounds[0])}</td>
          <td>{pretty(bounds[3])}</td>
          <td>{scale[0] !== 1 ? scale[0] : '-'}</td>
        </tr>
        <tr>
          <td style={Style.strong}>Y</td>
          <td>{pretty(bounds[1])}</td>
          <td>{pretty(bounds[4])}</td>
          <td>{scale[1] !== 1 ? scale[1] : '-'}</td>
        </tr>
        <tr>
          <td style={Style.strong}>Z</td>
          <td>{pretty(bounds[2])}</td>
          <td>{pretty(bounds[5])}</td>
          <td>{scale[2] !== 1 ? scale[2] : '-'}</td>
        </tr>
      </tbody>
    </DataTable>
  )
}

export default Position
