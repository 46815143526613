import React, { useState, useEffect } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faCopy,
  faMinus,
  faPen,
  faSave,
} from '@fortawesome/free-solid-svg-icons'

import { Share } from '@landrush/common'
import { join } from '@landrush/util'

import { Color, DeleteIcon, Spinner, TextInput } from 'components'
import { Shares } from 'store'
import { useAppDispatch, useAsync, useAsyncState, useResource } from 'utils'

type WithShare = { share: Share; addError(e: string): void }
type WithState = WithShare & {
  text?: string
  setText(text?: string): void
  rename(): void
  isRenaming: boolean
}

const Name = ({ share, text, setText, rename, isRenaming }: WithState) => {
  if (text === undefined) return <td className='name'>{share.name}</td>
  return (
    <td className='name' style={{ paddingTop: 0, paddingBottom: 0 }}>
      <TextInput
        autoFocus
        value={text}
        isEnabled={!isRenaming}
        onChange={setText}
        onSubmit={rename}
        onCancel={() => setText(undefined)}
        isValid={text.length !== 0}
        style={{ marginBottom: 0 }}
      />
    </td>
  )
}

type SaveIcon = { $isValid: boolean }
const SaveIcon = styled(FontAwesomeIcon)(({ $isValid }: SaveIcon) =>
  $isValid
    ? `cursor: pointer; &:hover { color: ${Color.landrushOrange}; }`
    : `cursor: not-allowed;`
)
const SaveControl = ({ text, rename, isRenaming }: WithState) => {
  if (text === undefined) return null
  if (isRenaming) return <Spinner small style={{ fontSize: 18 }} />
  return (
    <SaveIcon
      icon={faSave}
      $isValid={text.length > 0}
      className='icon save'
      fixedWidth
      onClick={rename}
    />
  )
}

type CancelIcon = { $isRenaming: boolean }
const CancelIcon = styled(FontAwesomeIcon)(({ $isRenaming }: CancelIcon) =>
  $isRenaming
    ? `
      cursor: not-allowed;
      color: ${Color.veryLightGray};
    `
    : `
      cursor: pointer;
      color: ${Color.lightGray};
      &:hover { color: ${Color.dangerRed}; }
    `
)
const RenameControl = ({ share, text, setText, isRenaming }: WithState) => {
  if (text === undefined) {
    return (
      <FontAwesomeIcon
        icon={faPen}
        className='icon rename'
        fixedWidth
        onClick={() => setText(share.name)}
      />
    )
  }

  return (
    <CancelIcon
      icon={faMinus}
      fixedWidth
      $isRenaming={isRenaming}
      onClick={() => {
        if (!isRenaming) setText(undefined)
      }}
    />
  )
}

const CopyControl = ({ share }: WithShare) => {
  const [isCopied, setCopied] = useState(false)
  useEffect(() => {
    if (!isCopied) return
    const timer = setTimeout(() => setCopied(false), 500)
    return () => {
      clearTimeout(timer)
    }
  }, [isCopied])

  const link = join(window.location.origin, 'v', share.token)

  return isCopied ? (
    <FontAwesomeIcon icon={faCheck} className='icon copied' fixedWidth />
  ) : (
    <CopyToClipboard text={link}>
      <FontAwesomeIcon
        icon={faCopy}
        className='icon copy'
        fixedWidth
        onClick={() => setCopied(true)}
      />
    </CopyToClipboard>
  )
}

const DeleteControl = ({ share }: WithShare) => {
  const dispatch = useAppDispatch()
  const resource = useResource()

  const {
    isPending,
    setPending,
    error,
    setError,
    dismissError,
  } = useAsyncState()

  const remove = async () => {
    setPending()
    const { error } = await dispatch(Shares.remove(resource.id, share.id))
    if (error) setError(error)
  }

  return (
    <DeleteIcon
      onClick={remove}
      isPending={isPending}
      error={error}
      dismissError={dismissError}
    />
  )
}

export default ({ share, addError }: WithShare) => {
  const dispatch = useAppDispatch()
  const [text, setText] = useState<string | undefined>()

  const [{ isPending: isRenaming }, rename] = useAsync(async () => {
    if (!text) return
    const { error } = await dispatch(Shares.rename(share.id, text))
    setText(undefined)
    if (error) addError(error)
  }, [text])

  const props: WithState = {
    share,
    text,
    setText,
    addError,
    rename,
    isRenaming,
  }

  return (
    <>
      <Name {...props} />
      <td className='icon-container'>
        <SaveControl {...props} />
      </td>
      <td className='icon-container'>
        <RenameControl {...props} />
      </td>
      <td className='icon-container'>
        <CopyControl {...props} />
      </td>
      <td>
        <DeleteControl {...props} />
      </td>
    </>
  )
}
