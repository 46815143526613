import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import { Tag } from '@landrush/common'

import { Button, Color, EditTag, ErrorMessage, Panel } from 'components'
import { useAsync } from 'utils'

type Top = {
  tags: Tag[]
  addTag(params: Tag.Add): Promise<Tag>
}
export const Top: React.FC<Top> = ({ tags, addTag }) => {
  const [isEditing, setEditing] = useState(tags.length === 0)
  const stopEditing = () => setEditing(false)

  const [{ isPending, error, dismissError, value }, onSubmit] = useAsync(addTag)

  useEffect(() => value && stopEditing(), [value])

  return (
    <Styles>
      <div className='manage-tags-top-container'>
        <h4 className='manage-tags-header-text'>Tags</h4>
        {!isEditing && (
          <Button
            className='manage-tags-create-button'
            onClick={() => setEditing(true)}
          >
            Create tag
          </Button>
        )}
      </div>
      {isEditing && (
        <Panel className='add-tag-panel'>
          <div className='add-tag-control-container'>
            <h5>Create a tag</h5>
            {!isPending && (
              <FontAwesomeIcon
                className='add-tag-cancel-icon'
                icon={faTimes}
                onClick={stopEditing}
              />
            )}
          </div>
          {error && (
            <ErrorMessage
              className='add-tag-error-message'
              onDismiss={dismissError}
            >
              {error}
            </ErrorMessage>
          )}
          <EditTag
            onSubmit={onSubmit}
            onCancel={stopEditing}
            isPending={isPending}
          />
        </Panel>
      )}
    </Styles>
  )
}
export default Top

const Styles = styled.div`
  .manage-tags-top-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
  }

  .manage-tags-header-text {
    padding: 8px;
  }

  .manage-tags-create-button {
    margin-bottom: 4px;
  }

  .add-tag-panel {
    background-color: ${Color.backgroundGray};
    padding: 8px;
    margin-bottom: 8px;
  }

  .add-tag-control-container {
    display: flex;
    justify-content: space-between;
  }

  .add-tag-cancel-icon {
    cursor: pointer;
    color: ${Color.lightGray};
    &:hover {
      color: ${Color.dangerRed};
    }
  }

  .add-tag-error-message {
    margin-bottom: 0;
  }
`
