import { createSelector } from '@reduxjs/toolkit'

import { Slice } from './slice'

type State = { workflowHistory: Slice }
const selectSlice = (s: State) => s.workflowHistory

export const maybeSelectHistory = createSelector(selectSlice, (s) => s.history)
export const selectHistory = createSelector(maybeSelectHistory, (h) => {
  if (!h) throw new Error('Missing workflow history')
  return h
})
