"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Workflows = void 0;
var common_1 = require("@landrush/common");
var util_1 = require("@landrush/util");
var fetch_1 = require("./fetch");
function create(url) {
    var root = util_1.join(url, 'api/workflows');
    function add(params) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, fetch_1.Fetch.post(root, params, common_1.Workflow.parse)];
            });
        });
    }
    function get(id, version) {
        return __awaiter(this, void 0, void 0, function () {
            var q;
            return __generator(this, function (_a) {
                q = version ? "?version=" + version : '';
                return [2 /*return*/, fetch_1.Fetch.get(util_1.join(root, id) + q, common_1.Workflow.parse)];
            });
        });
    }
    function getByAlias(alias, version) {
        return __awaiter(this, void 0, void 0, function () {
            var q;
            return __generator(this, function (_a) {
                q = version ? "?version=" + version : '';
                return [2 /*return*/, fetch_1.Fetch.get(util_1.join(root, 'aliases', alias) + q, common_1.Workflow.parse)];
            });
        });
    }
    function getHistory(id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, fetch_1.Fetch.get(util_1.join(root, 'histories', id), common_1.Workflow.History.parse)];
            });
        });
    }
    function getHistoryByAlias(alias) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, fetch_1.Fetch.get(util_1.join(root, 'histories/aliases', alias), common_1.Workflow.History.parse)];
            });
        });
    }
    function list() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, fetch_1.Fetch.get(root, function (v) { return v.map(common_1.Workflow.Summary.parse); })];
            });
        });
    }
    function patch(id, patch) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, fetch_1.Fetch.patch(util_1.join(root, id), patch, common_1.Workflow.parse)];
            });
        });
    }
    function push(id, push) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, fetch_1.Fetch.post(util_1.join(root, id), push, common_1.Workflow.parse)];
            });
        });
    }
    function remove(id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch_1.Fetch.delete(util_1.join(root, id))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    return {
        add: add,
        get: get,
        getByAlias: getByAlias,
        getHistory: getHistory,
        getHistoryByAlias: getHistoryByAlias,
        list: list,
        patch: patch,
        push: push,
        remove: remove,
    };
}
exports.Workflows = { create: create };
