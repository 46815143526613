import isequal from 'lodash.isequal'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faExclamation,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons'

import { Task, Execution } from '@landrush/common'

import { Color, DeleteIcon, Progress, Spinner, TaggableRow } from 'components'
import { Executions, ExecutionState } from 'store'
import { useAppDispatch, useAsyncResult, useForwardingSelector } from 'utils'

const Status: React.FC<{ task: Task }> = ({ task }) => {
  if (Task.isFailure(task)) {
    if (task.errors[0] === 'No resources overlap this region') {
      return (
        <FontAwesomeIcon
          fixedWidth
          className='task-status task-missing'
          icon={faExclamationTriangle}
        />
      )
    }
    return (
      <FontAwesomeIcon
        fixedWidth
        className='task-status task-failure'
        icon={faExclamation}
      />
    )
  }

  if (Task.isSuccess(task)) {
    return (
      <FontAwesomeIcon
        fixedWidth
        className='task-status task-success'
        icon={faCheck}
      />
    )
  }

  if (!task.index) {
    return <Spinner small className='task-status' />
  }

  return (
    <Progress
      className='task-status task-running'
      index={task.index}
      total={task.total}
    />
  )
}

type WithExecution = {
  execution: Execution.Summary
  style?: React.CSSProperties
}
export const Row: React.FC<WithExecution> = React.memo(
  ({ execution, style }) => {
    const { id, alias, name } = execution
    const dispatch = useAppDispatch()

    const isSelected = useForwardingSelector(
      ExecutionState.selectIsExecutionSelected,
      id
    )
    const toggleSelected = () => dispatch(ExecutionState.toggle(id))

    const [
      { isPending, error, dismissError },
      remove,
    ] = useAsyncResult(async () => dispatch(Executions.remove(id)))

    return (
      <Styles
        name={
          <Link to={`/executions/${alias}`}>
            <span className='execution-name'>{name}</span>
          </Link>
        }
        tags={[]}
        isSelected={isSelected}
        toggleSelected={toggleSelected}
        toggleFilterTag={() => {}}
        style={style}
      >
        <Status task={execution} />
        <DeleteIcon
          className='delete-icon'
          onClick={remove}
          isPending={isPending}
          error={error}
          dismissError={dismissError}
        />
      </Styles>
    )
  },
  isequal
)

const Styles = styled(TaggableRow)`
  .execution-name {
    color: ${Color.veryDarkGray};
    &:hover {
      color: ${Color.landrushOrange};
    }
  }

  .task-status {
    margin-left: 24px;
  }

  .task-running {
    width: 12vw;
    min-width: 120px;
    text-align: right;
    margin-left: auto;
  }

  .task-failure {
    color: ${Color.dangerRed};
  }
  .task-missing {
    color: ${Color.dangerYellow};
  }
  .task-success {
    color: ${Color.landrushOrange};
  }

  .delete-icon {
    margin-left: 48px;
  }
`
