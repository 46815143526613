import React from 'react'
import Media from 'react-media'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

import { Task, Id } from '@landrush/common'
import { Dimension } from '@landrush/entwine'
import { Result, pretty } from '@landrush/util'

import {
  Column,
  ErrorMessage,
  InlineTagList,
  Ol,
  SchemaDisplay,
  SrsDisplay,
  Style,
  TaggablePopper,
} from 'components'
import { useResource, useAppDispatch, useAsync, useAppSelector } from 'utils'
import { Resources } from 'store'

const ViewLink = () => {
  const resource = useResource()
  if (!Task.isSuccess(resource)) return null
  return (
    <Column>
      <Link to={`/view/${resource.alias}`}>
        <FontAwesomeIcon icon={faMapMarkerAlt} className='view-link' />
      </Link>
      <p style={{ ...Style.small, ...Style.muted, paddingTop: 8 }}>
        View point cloud
      </p>
    </Column>
  )
}

const MetadataDisplay: React.FC = () => {
  const { points, srs, geometry } = useResource()

  return (
    <>
      <ViewLink />

      <h4>Coordinate system</h4>
      <SrsDisplay srs={srs} />

      {!geometry && (
        <div style={{ ...Style.small, ...Style.muted, paddingTop: 8 }}>
          Missing geometry - cannot display map
        </div>
      )}

      <h4>Points</h4>
      {pretty(points)}
    </>
  )
}

const TagDisplay = () => {
  const { id: resourceId, tags } = useResource()
  const dispatch = useAppDispatch()

  const allTags = useAppSelector(Resources.Tags.selectAll)

  const [
    { isPending: isApplyingTag, error, dismissError },
    toggleTag,
  ] = useAsync(async (id: Id) => {
    const result = await dispatch(Resources.toggleTag(id, resourceId))
    if (Result.isFailure(result)) {
      throw new Error(result.error)
    } else return result.value
  }, [])

  return (
    <TagStyles>
      <div className='tags-row'>
        <InlineTagList tags={tags} />
        <div>
          <TaggablePopper
            targetName='resource'
            tags={allTags}
            isModifiable={true}
            applyTag={toggleTag}
            isApplyingTag={isApplyingTag}
            selectedTargetsCount={1}
            placement='bottom-start'
          />
        </div>
      </div>
      {error && (
        <ErrorMessage onDismiss={dismissError} className='tags-error'>
          {error}
        </ErrorMessage>
      )}
    </TagStyles>
  )
}
const TagStyles = styled.div`
  margin-top: 8px;
  .tags-row {
    display: flex;
    justify-content: space-between;
  }
  .tags-error {
    margin-top: 8px;
  }
`

export default () => {
  const { geometry, schema } = useResource()

  return (
    <Styles>
      <Media query={{ minWidth: 767 }}>
        {(matches) =>
          matches || !geometry ? (
            <div style={{ display: 'flex' }}>
              <div className='split left'>
                <MetadataDisplay />
              </div>
              <div className='split right'>
                {geometry && (
                  <Ol.Map>
                    <Ol.Geometry geometry={geometry} />
                  </Ol.Map>
                )}
                <TagDisplay />
              </div>
            </div>
          ) : (
            <>
              {geometry && (
                <Ol.Map>
                  <Ol.Geometry geometry={geometry} />
                </Ol.Map>
              )}
              <TagDisplay />
              <MetadataDisplay />
            </>
          )
        }
      </Media>

      <h4>Dimensions</h4>
      <SchemaDisplay schema={schema} />
      {schema.every(Dimension.hasStats) ? null : (
        <div style={{ ...Style.small, ...Style.muted, marginTop: 16 }}>
          Detailed dimension statistics not available
        </div>
      )}
    </Styles>
  )
}

const Styles = styled.div`
  h4 {
    margin-top: 32px;
    margin-bottom: 0;
  }

  .split {
    flex: 1 1 0;
    width: 50%;
  }
  .left {
    padding-right: 4px;
  }
  .right {
    padding-left: 4px;
  }

  .view-link {
    margin-top: 16px;
    font-size: 2.8em;
  }
`
