import { PayloadAction } from '@reduxjs/toolkit'

export type LoadState = 'loading' | 'done'
export type LoadGuard = {
  loadState?: LoadState
  loadError?: string
}
export const createGuards = <SliceState extends LoadGuard>() => ({
  loadRequest(state: SliceState) {
    state.loadState = 'loading'
    state.loadError = undefined
  },
  loadFailure(state: SliceState, action: PayloadAction<string>) {
    const loadError = action.payload
    state.loadState = undefined
    state.loadError = loadError
  },
})
