"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Fetch = exports.del = exports.patch = exports.post = exports.put = exports.get = exports.request = exports.getResponse = exports.ResponseError = void 0;
var ResponseError = /** @class */ (function (_super) {
    __extends(ResponseError, _super);
    function ResponseError(code, text, body) {
        var _this = _super.call(this, text) || this;
        _this.code = code;
        _this.body = body;
        return _this;
    }
    return ResponseError;
}(Error));
exports.ResponseError = ResponseError;
var defaultOptions = { mode: 'cors', credentials: 'include' };
function getResponse(url, options) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if ((options === null || options === void 0 ? void 0 : options.body) && !(options.body instanceof FormData)) {
                options = __assign(__assign({}, options), { body: JSON.stringify(options.body), headers: { 'content-type': 'application/json' } });
            }
            return [2 /*return*/, fetch(url, __assign(__assign({}, defaultOptions), options))];
        });
    });
}
exports.getResponse = getResponse;
function request(url, options) {
    return __awaiter(this, void 0, void 0, function () {
        var response, ok, status, statusText, type, body, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, getResponse(url, options)];
                case 1:
                    response = _b.sent();
                    ok = response.ok, status = response.status, statusText = response.statusText;
                    if (!!ok) return [3 /*break*/, 6];
                    type = response.headers.get('content-type');
                    if (!(type && type.startsWith('application/json'))) return [3 /*break*/, 3];
                    return [4 /*yield*/, response.json()];
                case 2:
                    _a = _b.sent();
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, response.text()];
                case 4:
                    _a = _b.sent();
                    _b.label = 5;
                case 5:
                    body = _a;
                    throw new ResponseError(status, (body === null || body === void 0 ? void 0 : body.message) || statusText, body);
                case 6: return [2 /*return*/, response.json()];
            }
        });
    });
}
exports.request = request;
function noop(data) {
    return data;
}
function get(url, coerce) {
    if (coerce === void 0) { coerce = noop; }
    return __awaiter(this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = coerce;
                    return [4 /*yield*/, request(url)];
                case 1: return [2 /*return*/, _a.apply(void 0, [_b.sent()])];
            }
        });
    });
}
exports.get = get;
function put(url, body, coerce) {
    if (coerce === void 0) { coerce = noop; }
    return __awaiter(this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = coerce;
                    return [4 /*yield*/, request(url, { method: 'PUT', body: body })];
                case 1: return [2 /*return*/, _a.apply(void 0, [_b.sent()])];
            }
        });
    });
}
exports.put = put;
function post(url, body, coerce) {
    if (coerce === void 0) { coerce = noop; }
    return __awaiter(this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = coerce;
                    return [4 /*yield*/, request(url, { method: 'POST', body: body })];
                case 1: return [2 /*return*/, _a.apply(void 0, [_b.sent()])];
            }
        });
    });
}
exports.post = post;
function patch(url, body, coerce) {
    if (coerce === void 0) { coerce = noop; }
    return __awaiter(this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = coerce;
                    return [4 /*yield*/, request(url, { method: 'PATCH', body: body })];
                case 1: return [2 /*return*/, _a.apply(void 0, [_b.sent()])];
            }
        });
    });
}
exports.patch = patch;
function del(url, body, coerce) {
    if (coerce === void 0) { coerce = noop; }
    return __awaiter(this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = coerce;
                    return [4 /*yield*/, request(url, { method: 'DELETE', body: body })];
                case 1: return [2 /*return*/, _a.apply(void 0, [_b.sent()])];
            }
        });
    });
}
exports.del = del;
exports.Fetch = {
    Error: ResponseError,
    getResponse: getResponse,
    request: request,
    get: get,
    put: put,
    post: post,
    patch: patch,
    delete: del,
};
exports.default = exports.Fetch;
