import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { AccessTokensDisplay, Button, Color, Style } from 'components'
import { Session } from 'store'

export default () => {
  const dispatch = useDispatch()
  const isLoggingOut = useSelector(Session.selectIsLoggingOut)

  return (
    <>
      <div style={Style.alignRight}>
        <Button spin={isLoggingOut} onClick={() => dispatch(Session.logout())}>
          Log out
        </Button>
      </div>

      <div style={{ marginTop: 8, ...Style.alignRight }}>
        <Link to='/change-password'>
          <div style={{ color: Color.gray }}>Change password</div>
        </Link>
      </div>

      <h3>Access tokens</h3>
      <AccessTokensDisplay />
    </>
  )
}
