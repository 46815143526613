import { useEffect, useRef } from 'react'

// TODO: Remove this, current callers should import from react-use instead.
export function usePrevious<T = any>(value: T) {
  const ref = useRef<T>(value)
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}
