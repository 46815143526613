import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Button } from './button'
import { Color } from './color'

type Page = 'home' | 'regions' | 'executions' | 'workflows' | 'credentials'
type Overview = { page: Page; create?: string }
export const Overview: React.FC<Overview> = ({ page, create }) => {
  const getClass = (item: Page) => 'item ' + (page === item ? 'active' : 'idle')
  return (
    <Styles>
      <div className='wrapper'>
        <div className='nav'>
          <Link to='/'>
            <div className={getClass('home')}>Resources</div>
          </Link>
          <Link to='/regions'>
            <div className={getClass('regions')}>Regions</div>
          </Link>
          <Link to='/workflows'>
            <div className={getClass('workflows')}>Workflows</div>
          </Link>
          <Link to='/executions'>
            <div className={getClass('executions')}>Executions</div>
          </Link>
          <Link to='/credentials'>
            <div className={getClass('credentials')}>Credentials</div>
          </Link>
        </div>
        {create && (
          <div className='create'>
            <Link to={create}>
              <Button className='add-new-button'>
                <div className='add-new-text'>Add new</div>
              </Button>
            </Link>
          </div>
        )}
      </div>
    </Styles>
  )
}

const itemPadding = 24
const Styles = styled.div`
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid ${Color.veryLightGray};
  }

  .nav {
    margin-top: 12px;
  }

  .add-new-button {
    height: auto;
    margin-bottom: 4px;
  }

  .add-new-text {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .item {
    padding-left: ${itemPadding}px;
    padding-right: ${itemPadding}px;
    padding-bottom: 6px;
    margin-bottom: -1px;
  }

  .idle {
    color: ${Color.gray};

    &:hover {
      color: ${Color.veryDarkGray};
      border-bottom: 2px solid ${Color.gray};
    }
  }

  .active {
    color: ${Color.veryDarkGray};
    border-bottom: 2px solid ${Color.landrushOrange};
    font-weight: bold;
  }

  .create {
  }
`
