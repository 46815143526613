import { useMemo } from 'react'

import { ialphaSort } from '@landrush/util'

import { Ol } from 'components'

type Collection = GeoJSON.FeatureCollection
type WithCollection = { collection: Collection }
export const CollectionDisplay: React.FC<WithCollection> = ({ collection }) => {
  const { features } = collection
  const properties = useMemo(
    () =>
      [
        ...features.reduce((set, { properties }) => {
          for (const key of Object.keys(properties || {})) set.add(key)
          return set
        }, new Set<string>()),
      ].sort(ialphaSort),
    [features]
  )
  return (
    <>
      <span>Feature properties:&nbsp;&nbsp;</span>
      {properties.length
        ? properties.map((p, i) => (
            <span style={{ marginRight: 12 }} key={i}>
              {p}
            </span>
          ))
        : '(none)'}
      <Ol.Map>
        <Ol.Collection collection={collection} />
      </Ol.Map>
    </>
  )
}
